import { Button, Row } from "antd";
import { useTranslation } from "react-i18next";
import "./editingButtons.less";

export const EditingButtons = ({
  handleCancel,
  customButtons,
  isEditing,
}: {
  handleCancel: () => void;
  customButtons?: JSX.Element[] | null;
  isEditing?: boolean;
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Row className="wrapper fields-section__editing-buttons">
      <Row>
        {isEditing && (
          <Button className="cancel" onClick={handleCancel}>
            {t("Discard")}
          </Button>
        )}
        {customButtons}
        {isEditing && (
          <Button className="save" type="primary" htmlType="submit">
            {t("Save")}
          </Button>
        )}
      </Row>
    </Row>
  );
};
