import React from "react";
import { ReviewSeverity } from "../../consts/reviewConstants";
import { Answer, TAnswerSeverityLevel, TApiQuestionType, TBranding } from "../types";

/**
 * This symbol is used to define an empty string response (not null)
 */
const emptyAnswerCharacter = "¶";

/**
 * Used to render the severity level
 * @param severity
 * @todo - Probably remove this method there is nothing special inside it
 */
export const renderSeverityLevel = (severity?: TAnswerSeverityLevel): React.ReactElement | null => {
  if (!severity) {
    return null;
  }
  switch (severity) {
    case ReviewSeverity.Critical:
      return <span className="severity critical">!</span>;
    case ReviewSeverity.Defect:
      return <span className="severity defect">!</span>;
    case ReviewSeverity.Positive:
      return <span className="severity positive">+</span>;
    default:
      return null;
  }
};

/**
 * This method checks if the question has to be hidden based on the answer and the hideAnswer property
 * @param q
 * @param branding
 */
export const shouldPresentQuestion = (q: TApiQuestionType, branding: TBranding): boolean => {
  return !answerMatchesOneOfMultipleHideAnswers(q) && !emptyOrHidden(q, branding);
};

/**
 * Returns true if the answer is empty (string) or it matches the hideAnswer property
 * @param q
 * @param branding
 */
const emptyOrHidden = (q: TApiQuestionType, branding: TBranding): boolean => {
  const answerIsEmpty = (a: Answer): boolean => {
    return !a.text && !a.date && !a.image;
  };

  return (
    ((q.hideAnswer === emptyAnswerCharacter ||
      (!branding.pdf.showUnansweredQuestions && q.questionType !== "InfoTextTemplate")) &&
      q.selectedAnswers.filter(a => !a.parentAnswerId).find(answerIsEmpty) !== undefined) ||
    q.selectedAnswers.filter(a => !a.parentAnswerId).find(a => a.text === q.hideAnswer) !== undefined
  );
};

/**
 * Returns true if the answer matches any of the multiple | separated values in the hideAnswer property
 * @param q
 */
const answerMatchesOneOfMultipleHideAnswers = (q: TApiQuestionType): boolean => {
  return (
    q.selectedAnswers
      .filter(a => !a.parentAnswerId)
      .find(a => q.hideAnswer && q.hideAnswer.split("|")?.includes(a.text as string)) !== undefined
  );
};
