import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import cloneDeep from "lodash/cloneDeep";
import {
  CompanyMigrationSelection,
  CompanyMigration,
  CompanyMigrationState,
  CompanyUser,
  CompanyMigrationJobs,
  EntitiesDuplicationsToolsResponse,
} from "../../types/companyMigration";
import { OrgUnit } from "../../types/orgUnit";
import { hbApi, hbApiOptions } from "../api";
import { RootState } from "../store";

export const initialState: CompanyMigrationState = {
  migrations: [],
  migrationHangfireJobs: [],
  tools: {
    companyUsersInDestination: undefined,
    duplicateEntities: undefined,
    blobMigration: undefined,
  },
  destinationCompanyOrgUnits: [],
  isLoading: false,
};

export const fetchMigrations = createAsyncThunk<CompanyMigration[], void, { state: RootState }>(
  "@@COMPANY_MIGRATIONS/MIGRATIONS",
  async (_, { getState }) => {
    const { user } = getState();
    const { data: migrations } = await hbApi.get<CompanyMigration[]>("/CompanyMigration", hbApiOptions(user.jwt));

    return Array.isArray(migrations) ? migrations : [];
  }
);

export const fetchOrgUnitsOfCompany = createAsyncThunk<OrgUnit[], number, { state: RootState }>(
  "@@COMPANY_MIGRATIONS/DESTINATION_COMAPNY_ORGUNITS",
  async (companyId, { getState, dispatch }) => {
    const { user } = getState();
    const { data: orgUnits } = await hbApi.get<OrgUnit[]>(
      `/OrgUnit/GetWithFilter/?CompanyId=${companyId}`,
      hbApiOptions(user.jwt)
    );

    return Array.isArray(orgUnits) ? orgUnits : [];
  }
);

export const runCompanyUserInDestinationTool = createAsyncThunk<
  CompanyUser[],
  CompanyMigrationSelection,
  { state: RootState }
>(
  "@@COMPANY_MIGRATIONS/COMPANY_USER_IN_DEST_TOOL",
  async ({ sourceCompanyId, destinationCompanyId }, { getState, dispatch }) => {
    const { user } = getState();
    const { data: companyUsers } = await hbApi.get<CompanyUser[]>(
      `/CompanyMigration/CheckCompanyUsersInDestinationCompany/?sourceCompanyId=${sourceCompanyId}&destinationCompanyId=${destinationCompanyId}`,
      hbApiOptions(user.jwt)
    );

    return Array.isArray(companyUsers) ? companyUsers : [];
  }
);

export const runDulicateEntitiesCheck = createAsyncThunk<
  EntitiesDuplicationsToolsResponse,
  CompanyMigrationSelection,
  { state: RootState }
>(
  "@@COMPANY_MIGRATIONS/ENTITIES_DUPLICATION_TOOL",
  async ({ sourceCompanyId, destinationCompanyId }, { getState, dispatch }) => {
    const { user } = getState();
    const { data: response } = await hbApi.get<EntitiesDuplicationsToolsResponse>(
      `/CompanyMigration/DuplicateEntities/?sourceCompanyId=${sourceCompanyId}&destinationCompanyId=${destinationCompanyId}`,
      hbApiOptions(user.jwt)
    );

    return response;
  }
);

export const runMigration = createAsyncThunk<CompanyMigrationJobs, number, { state: RootState }>(
  "@@COMPANY_MIGRATIONS/START_MIGRATION",
  async (migrationId, { getState, dispatch }) => {
    const { user } = getState();
    const { data: hangfireJobs } = await hbApi.post(
      `/CompanyMigration/StartMigration?migrationId=${migrationId}`,
      null,
      hbApiOptions(user.jwt)
    );
    dispatch(fetchMigrations());
    return { hangfireJobs, migrationId };
  }
);

export const runBlobMigration = createAsyncThunk<number, number, { state: RootState }>(
  "@@COMPANY_MIGRATIONS/START_BLOB_MIGRATION",
  async (migrationId, { getState, dispatch }) => {
    const { user, companyMigration } = getState();
    const migration = companyMigration.migrations.find(x => x.id === migrationId);
    if (migration) {
      const { data: jobId } = await hbApi.post(
        `/CompanyMigration/StartBlobMigration/?sourceCompanyId=${migration.sourceCompanyId}&destinationCompanyId=${migration.destinationCompanyId}&migrationLogId=${migrationId}`,
        null,
        hbApiOptions(user.jwt)
      );
      dispatch(fetchMigrations());
      return jobId;
    }
    return undefined;
  }
);

export const addMigration = createAsyncThunk<CompanyMigration, CompanyMigrationSelection, { state: RootState }>(
  "@@COMPANY_MIGRATIONS/CREATE_MIGRATION",
  async ({ sourceCompanyId, destinationCompanyId, isRollback, destinationOrgUnitId }, { getState, dispatch }) => {
    const { user } = getState();
    const { data: migration } = await hbApi.post<CompanyMigration>(
      `/CompanyMigration/CreateMigration?sourceCompanyId=${sourceCompanyId}&destinationCompanyId=${destinationCompanyId}&isRollback=${isRollback}&destinationOrgUnitId=${destinationOrgUnitId}`,
      null,
      hbApiOptions(user.jwt)
    );
    dispatch(fetchMigrations());
    return migration;
  }
);

const slice = createSlice({
  name: "cubeDimensions",
  initialState,
  // Note: User reducers only for synchronous logic
  reducers: {},
  // Note: User reducers only for asynchronous logic with AsyncThunk
  extraReducers: builder => {
    builder
      // Note - Pending:
      .addCase(fetchMigrations.pending, state => {
        state.isLoading = true;
      })
      .addCase(fetchOrgUnitsOfCompany.pending, state => {
        state.isLoading = true;
      })
      .addCase(runCompanyUserInDestinationTool.pending, state => {
        state.isLoading = true;
      })
      .addCase(runDulicateEntitiesCheck.pending, state => {
        state.isLoading = true;
      })
      .addCase(runBlobMigration.pending, state => {
        state.isLoading = true;
      })
      .addCase(addMigration.pending, state => {
        state.isLoading = true;
      })
      .addCase(runMigration.pending, state => {
        state.isLoading = true;
      })
      .addCase(fetchMigrations.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(fetchOrgUnitsOfCompany.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(runCompanyUserInDestinationTool.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(runDulicateEntitiesCheck.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(addMigration.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(runBlobMigration.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(runMigration.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(fetchMigrations.fulfilled, (state, action) => {
        state.migrations = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchOrgUnitsOfCompany.fulfilled, (state, action) => {
        state.destinationCompanyOrgUnits = action.payload;
        state.isLoading = false;
      })
      .addCase(runMigration.fulfilled, (state, action) => {
        state.isLoading = false;
        let modifiedMigrationHangfireJobs;
        if (state.migrationHangfireJobs.find(m => m.migrationId === action.payload.migrationId)) {
          modifiedMigrationHangfireJobs = state.migrationHangfireJobs.map(m => {
            if (m.migrationId === action.payload.migrationId) {
              const mCopy = cloneDeep(m);
              mCopy.hangfireJobs = action.payload.hangfireJobs;
              return mCopy;
            }
            return m;
          });
        } else {
          modifiedMigrationHangfireJobs = state.migrationHangfireJobs;
          modifiedMigrationHangfireJobs.push(action.payload);
        }
        state.migrationHangfireJobs = modifiedMigrationHangfireJobs;
      })
      .addCase(addMigration.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(runCompanyUserInDestinationTool.fulfilled, (state, action) => {
        state.tools.companyUsersInDestination = action.payload;
        state.isLoading = false;
      })
      .addCase(runBlobMigration.fulfilled, (state, action) => {
        state.tools.blobMigration = action.payload;
        state.isLoading = false;
      })
      .addCase(runDulicateEntitiesCheck.fulfilled, (state, action) => {
        state.tools.duplicateEntities = action.payload;
        state.isLoading = false;
      });
  },
});

export const {} = slice.actions;
export default slice.reducer;
