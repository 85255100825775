import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import { Input, Menu, Modal, Popover, Typography } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./qicklyCreateAppointment.less";
import { useSelector } from "react-redux";
import { quicklyCreateAppointment } from "../../store/slices/tasks";
import { RootState, useAppDispatch } from "../../store/store";
import { Options, ReviewOptions } from "../../types/tasks";

export type QuicklyCreateAppointmentModalProps = {
  visible: boolean;
  cancelClick?: () => void;
};

export const QuicklyCreateAppointment = ({ visible, cancelClick }: QuicklyCreateAppointmentModalProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { usersQuickCreateData } = useSelector((state: RootState) => state.actions);
  const [selectedReview, setSelectedReview] = useState<number | null>();
  const [selectedProject, setSelectedProject] = useState<number | null>();
  const [reviewOptions, setReviewOptions] = useState<ReviewOptions[] | null>(null);
  const [filteredReviewOptions, setFilteredReviewOptions] = useState<ReviewOptions[] | null>(null);
  const [initialProjectOptions, setInitialProjectOptions] = useState<Options[] | null>(null);
  const [projectOptions, setProjectOptions] = useState<Options[] | null>(null);
  const [filteredProjectOptions, setFilteredProjectOptions] = useState<Options[] | null>(null);
  const [selectedProjectFilter, setSelectedProjectFilter] = useState<number | null>(null);

  useEffect(() => {
    const allReviews = usersQuickCreateData.map(x => ({
      id: x.reviewId,
      label: x.reviewName,
      projectId: x.projectId,
    }));
    const reviewsArr = [...new Map(allReviews.map(item => [item.id, item])).values()];
    setReviewOptions(reviewsArr);
    setInitialProjectOptions(
      [...new Map(usersQuickCreateData.map(item => [item.projectId, item])).values()]
        .filter(x => x.projectId)
        .map(x => ({ id: x.projectId as number, label: x.projectName }))
    );
    setFilteredReviewOptions(reviewsArr);
  }, [usersQuickCreateData]);

  const onReviewChanged = (reviewId: number) => {
    setSelectedReview(reviewId);

    const filteredProjectsByReview = usersQuickCreateData.filter(x => x.reviewId === reviewId);

    const projOptions = filteredProjectsByReview.map(x => ({ id: x.projectId as number, label: x.projectName }));

    if (projOptions.length > 1) {
      setSelectedProject(null);
      setProjectOptions(projOptions);
      setFilteredProjectOptions(projOptions);
    } else {
      setSelectedProject(projOptions[0].id ?? 0);
    }
  };

  const onProjectChanged = (projectId: number) => {
    setSelectedProject(projectId);
  };

  const onBackClicked = () => {
    setProjectOptions(null);
    setFilteredProjectOptions(null);
    setSelectedProject(null);
    setSelectedReview(null);
  };

  const getInputComponent = (onChange: React.ChangeEventHandler<HTMLInputElement> | undefined) => {
    return (
      <Input
        className="search"
        prefix={<SearchOutlined style={{ opacity: 0.5 }} />}
        bordered={false}
        id="review-search"
        placeholder={t("SearchButton")}
        allowClear={true}
        onChange={onChange}
      />
    );
  };

  return (
    <Modal
      open={visible}
      wrapClassName="modalWrapper"
      onOk={() =>
        dispatch(
          quicklyCreateAppointment({
            ProjectId: selectedProject as number,
            ReviewInfoId: selectedReview as number,
            IsUsedProjectReviewEntity: false,
          })
        )
      }
      okButtonProps={{ disabled: !selectedReview || (!selectedProject && selectedProject !== 0) }}
      onCancel={filteredProjectOptions?.length ? onBackClicked : cancelClick}
      cancelText={filteredProjectOptions?.length ? t("Back") : null}
      centered
      className="review-creation-modal"
    >
      <div>
        <Typography.Paragraph className="new-review-title">{t("NewReview")}</Typography.Paragraph>
      </div>
      <Menu className="new-review-menu">
        {filteredProjectOptions?.length ? (
          <>
            <div className="search-bar">
              {getInputComponent(e =>
                setFilteredProjectOptions(
                  projectOptions?.filter(x => x.label.toLowerCase().includes(e.target.value.toLowerCase())) || []
                )
              )}
            </div>
            {filteredProjectOptions?.map(x => (
              <Menu.Item onClick={e => onProjectChanged(Number(e.key))} key={x.id}>
                {x.label}
              </Menu.Item>
            ))}
          </>
        ) : (
          <>
            <div className="search-bar">
              {getInputComponent(e =>
                setFilteredReviewOptions(
                  reviewOptions?.filter(x => {
                    return (
                      x.label.toLowerCase().includes(e.target.value.toLowerCase()) &&
                      (selectedProjectFilter === 0
                        ? !x.projectId
                        : selectedProjectFilter
                        ? x.projectId === selectedProjectFilter
                        : true)
                    );
                  }) || []
                )
              )}

              <Popover
                placement="right"
                content={
                  <Menu>
                    {initialProjectOptions?.map(x => (
                      <Menu.Item
                        onClick={e => {
                          setSelectedProjectFilter(Number(e.key));
                          setSelectedProject(null);
                          setSelectedReview(null);
                          setFilteredReviewOptions(reviewOptions?.filter(x => x.projectId === Number(e.key)) || []);
                        }}
                        key={x.id}
                      >
                        {x.label}
                      </Menu.Item>
                    ))}
                    <Menu.Item
                      onClick={e => {
                        setSelectedProjectFilter(0);
                        setFilteredReviewOptions(reviewOptions?.filter(x => !x.projectId) || []);
                      }}
                      key="reviewWithoutProject"
                    >
                      {t("[NoProject]")}
                    </Menu.Item>
                  </Menu>
                }
                trigger="click"
              >
                <FilterOutlined />
              </Popover>
            </div>
            {filteredReviewOptions?.map(x => (
              <Menu.Item onClick={e => onReviewChanged(Number(e.key))} key={x.id}>
                {x.label}
              </Menu.Item>
            ))}
          </>
        )}
      </Menu>
    </Modal>
  );
};
