import { AppstoreOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { Button, Col, Radio, RadioChangeEvent, Row, Typography } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PageConfigurationContext from "../../context/pageContext";
import { RootState, useAppDispatch } from "../../store/store";
import { BaseEntityType } from "../../types/entityBase";
import { CategoryPage, ColumnType } from "../../types/page";
import useRouter from "../../utils/hooks/useRouter";
import { FuseSearch } from "../FuseSearch/FuseSearch";
import { handleFetchPaginatedData } from "../HBComponents/PaginatedTable/PaginatedTable";
import AddFilterStripeComponent from "../SingleViewVTable/filters/AddFilterComponent";
import { View } from "./types";
import "./tableSearchBar.less";

const { Text } = Typography;
type TProps = {
  data: Record<string, unknown>[];
  resultColumns?: Partial<ColumnType<Record<string, unknown>>>[] | null;
  searchKeys?: string[] | null;
  entityKey: string;
};

export const TableSearchBar = ({ data, resultColumns, searchKeys, entityKey }: TProps): JSX.Element => {
  const pageConfig = useContext(PageConfigurationContext) as CategoryPage<BaseEntityType>;

  const { id, listSearch, hasGroupView, hasListView, defaultView, listView } = pageConfig;
  const [viewTypeValue, setViewTypeValue] = useState<View>(defaultView || "list");
  const { t } = useTranslation();
  const { history, location } = useRouter<{ viewType: View }>();
  const rootLocation = `/${id}`;
  const possibleResults = useSelector(
    hasListView && listView?.table?.possibleResultsSelector
      ? listView.table.possibleResultsSelector
      : (state: RootState) => 0
  );

  const direction = useSelector((state: RootState) => state.user.settings.direction);

  //temp for actions only
  const isLastPage = useSelector((state: RootState) => id === "actions" && state[id].paginationInfo.lastPage);

  const dispatch = useAppDispatch();
  const handleView = (e: RadioChangeEvent) => {
    handleFetchPaginatedData(pageConfig, dispatch);
    history.push({
      pathname: `..${rootLocation}`,
      state: { viewType: e.target.value },
    });
  };

  // Note: Set the viewType only if we're on the root location (e.g. /equipment, /location, etc.)
  useEffect(() => {
    // debugger;
    if (location.pathname === rootLocation) {
      setViewTypeValue(location?.state?.viewType || defaultView || "list");
    }
  }, [location]);

  const getCountText = useCallback(() => {
    if (hasListView && listView?.table.type === "paginatedTable" && viewTypeValue === "list") {
      //temp for actions only
      return `${t("Showing")} ${data.length} ${t("DashboarOfLabel")} ${
        id === "actions" ? (isLastPage ? data.length : t("ManyLabel")) : possibleResults
      } ${t("ShowResults")}`;
    }
    return `${t("Showing")} ${data.length} ${t("ShowResults")}`;
  }, [hasListView, possibleResults, data.length, listView?.table.type, viewTypeValue, isLastPage]);

  return (
    <Row align="middle" justify="space-between" style={{ flex: 1 }}>
      <Col style={{ display: "flex", flex: 1, alignItems: "center" }}>
        {hasGroupView && hasListView && (
          <Radio.Group
            value={location.pathname !== rootLocation ? null : viewTypeValue}
            onChange={handleView}
            className={`btn-group ${direction}`}
            optionType="button"
            buttonStyle="solid"
          >
            <Radio.Button value="list">
              <UnorderedListOutlined />
            </Radio.Button>
            <Radio.Button value="group">
              <AppstoreOutlined />
            </Radio.Button>
          </Radio.Group>
        )}
        <AddFilterStripeComponent entityKey={entityKey}>
          {listSearch && (
            <FuseSearch
              data={data!}
              resultColumns={resultColumns || listSearch?.columns || []}
              searchKeys={searchKeys || listSearch?.keys || []}
              fetchOptions={listView?.table.searchPaginatedData}
              resetSearchResults={listView?.table.resetSearchResult}
            />
          )}
        </AddFilterStripeComponent>
      </Col>
      <Col style={{ display: "flex" }}>
        <Text data-test="searchBarResults" className="noselect result-count">
          {getCountText()}
        </Text>
      </Col>
    </Row>
  );
};
