import { BaseEntityType } from "./entityBase";
import { ExplicitAdditionalProps } from "./utility";

export type EquipmentType = Record<string, unknown> & {
  id: number;
  name: string;
  createTaskOnBrokenStatus: boolean;
  createTaskOnInactiveStatus: boolean;
  iconType: string;
};

export type EqTypeInspTypeRelation = Record<string, unknown> & {
  id: number | string;
  equipmentTypeId: number | null;
  inspectionTypeId: number | null;
  status: EqLocTypeInspTypeRelationStatus;
};

export enum EqLocTypeInspTypeRelationStatus {
  Active = "Active",
  IsNoLongerNeeded = "IsNoLongerNeeded",
  CreatedByMistake = "CreatedByMistake",
}

export type EquipmentTypeSingleView = {
  id: number;
  name: string;
  createTaskOnBrokenStatus: boolean;
  createTaskOnInactiveStatus: boolean;
  iconType: string;
};

export interface EquipmentTypeState {
  data: EquipmentType[];
  isLoading: boolean;
  singleData: EquipmentTypeSingleView | null;
  subData: {
    eqTypeInspTypeRelation: EqTypeInspTypeRelation[];
  };
  error: string | null;
  defaultCustomProperties: ExplicitAdditionalProps[];
  lastUpdated: string;
}

export type EquipmentTypePage = BaseEntityType & {
  entityData: {
    primaryData: EquipmentTypeSingleView;
  };
  subData: {
    eqTypeInspTypeRelation: EqTypeInspTypeRelation[];
  };
  listViewData: EquipmentType;
};
