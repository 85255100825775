import { Spin } from "antd";
import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { EmbeddedPASProMode } from "../EmbeddedModal/useFormManagmentEvents";

const EmbeddedFormManagmentComponent = React.lazy(() => import("../EmbeddedModal/EmbeddedFormManagmentComponent"));

type LandingPageFormFillingProps = {
  onLoad: () => void;
  imageMode: boolean;
};

export default function LandingPageFormFilling({ onLoad, imageMode }: LandingPageFormFillingProps) {
  const { landingPageInfo } = useSelector((state: RootState) => state.landingPage);
  if (landingPageInfo?.appointmentId && landingPageInfo?.jwtToken) {
    return (
      <Suspense fallback={<Spin spinning />}>
        {
          <div className={imageMode ? "iframe-with-image" : "iframe"}>
            <EmbeddedFormManagmentComponent
              initialAppointmentId={landingPageInfo.appointmentId}
              initialJWT={landingPageInfo.jwtToken}
              isOpen={true}
              mode={EmbeddedPASProMode.LandingPage}
              onLoad={onLoad}
            />
          </div>
        }
      </Suspense>
    );
  }
  return null;
}
