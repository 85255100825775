import { Space, Select, TreeSelect } from "antd";
import { ChangeEventExtra } from "rc-tree-select/lib/TreeSelect";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { constructTableTree } from "../../../pages/pageConfig/category/utilities";
import { RootState } from "../../../store/store";

type UsersOrgUnitsSelectorProps = {
  employeeValues: number[];
  orgUnitValues: number[];
  onEmployeeSelected: (value: number[]) => void;
  onOrgUnitSelected: (value: any[], labelList: React.ReactNode[], extra: ChangeEventExtra) => void;
  selectUsers?: boolean;
  selectOU?: boolean;
};

export default function UsersOrgUnitsSelector({
  employeeValues,
  orgUnitValues,
  onOrgUnitSelected,
  onEmployeeSelected,
  selectUsers,
  selectOU,
}: UsersOrgUnitsSelectorProps) {
  const { t } = useTranslation();
  const usersBasicData = useSelector((state: RootState) =>
    state.employee.basicData.filter(e => e.isActive && e.isUser)
  );
  const orgUnitBasicData = useSelector((state: RootState) => state.orgUnit.basicData);

  const usersOptions = useMemo(
    () =>
      usersBasicData
        .sort(function (a, b) {
          return a.displayName.localeCompare(b.displayName);
        })
        .map(e => ({ value: e.id, label: e.displayName })),
    [usersBasicData]
  );

  const orgUnitsOptions = useMemo(
    () =>
      constructTableTree({
        data: orgUnitBasicData,
        itemLabelKey: "name",
        itemValueKey: "id",
        disableInactiveItems: false,
      }),
    [orgUnitBasicData]
  );
  return (
    <Space id="UsersOrgUnitsSelectorContainer" direction="vertical" style={{ width: "35rem" }}>
      {selectUsers && (
        <div className="employeesSelector">
          <div>{t("Employee")}</div>
          <Select
            style={{ width: "100%" }}
            mode="multiple"
            notFoundContent={<div></div>}
            optionFilterProp="label"
            value={employeeValues}
            options={usersOptions}
            onChange={onEmployeeSelected}
          />
        </div>
      )}
      {selectOU && (
        <div className="orgUnitSelector">
          <div>{t("OrgUnit")}</div>
          <TreeSelect
            style={{ width: "100%" }}
            treeCheckable
            treeCheckStrictly
            showSearch
            showCheckedStrategy="SHOW_ALL"
            treeNodeFilterProp="label"
            treeData={orgUnitsOptions}
            value={orgUnitValues}
            onChange={onOrgUnitSelected}
          />
        </div>
      )}
    </Space>
  );
}
