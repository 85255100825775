import { Badge, Modal, Tooltip } from "antd";
import dayjs from "dayjs";
import ActionSummaryTooltipComponent from "../../../../components/ActionSummaryTooltipComponent/ActionSummaryTooltipComponent";
import { HandleTaskModal } from "../../../../components/HBComponents/HandleTaskModal/HandleTaskModal";
import HBTextArea from "../../../../components/HBComponents/Input/HBTextArea";
import NewTabComponent from "../../../../components/HBComponents/NewTabIcon/NewTabComponent";
import { employeeSelectors, tasksSelectors } from "../../../../selectors";
import { addEntityPrivilege } from "../../../../store/slices/privileges";
import { deleteNewAccountableEntryTemplate, fillNewAccountableEntryTemplate } from "../../../../store/slices/tasks";
import { ColumnType, ColumnWithDifferentCells } from "../../../../types/page";
import { LinkedAction, Task } from "../../../../types/tasks";
import {
  ActionTypes,
  HistoryLog,
  OpenClosedStatus,
  PrivilegeData,
  PrivilegedEntityType,
} from "../../../../types/utility";
import PDFActions from "../../../TableActions/PDFActions";
import { getCellSearchResultColumns, openClosedOptions, priorityOptions } from "../common";
import { localizeText, renderBoolStatus, renderOpenCloseStatus, renderTaskPriority } from "../utilities";

export const tasksTableColumns: ColumnType<Task>[] = [
  {
    id: "id",
    label: "ID",
    valueIcon: value => <NewTabComponent value={value.id.toString()} href={`/actions/${value.id}`} />,
    renderValue: value => null,
    valueType: "number",
    sortable: false,
    optionsSelector: tasksSelectors.uniqueIds,
    width: 100,
  },
  {
    id: "title",
    label: "FieldTitle",
    renderValue: value => value.title,
    sortable: true,
    valueType: "string",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "HbTask",
    filterType: "multiSelect",
  },
  {
    id: "createDate",
    label: "FieldCreateDate",
    renderValue: value => (value.createDate ? dayjs(value.createDate).format("DD/MM/YYYY HH:mm") : null),
    filterType: "dateRange",
    optionsSelector: () => null,
    sortable: true,
  },
  {
    id: "dueDate",
    label: "FieldDueDate",
    optionsSelector: () => null,
    valueIcon: value => {
      if (value.dueDate) {
        const expDate = dayjs(value.dueDate);
        const dayDiff = expDate.diff(dayjs(), "days");
        return <Badge color={dayDiff >= 1 ? "green" : "red"} />;
      }
    },
    renderValue: value => (value.dueDate ? dayjs(value.dueDate).format("DD/MM/YYYY HH:mm") : null),
    sortable: true,
    filterType: "dateRange",
  },
  {
    id: "closeDate",
    label: "FieldCloseDate",
    renderValue: value => (value.closeDate ? dayjs(value.closeDate).format("DD/MM/YYYY HH:mm") : null),
    filterType: "dateRange",
    sortable: true,
    optionsSelector: () => null,
  },
  {
    id: "parentTaskId",
    label: "FieldParentTask",
    renderValue: value => null,
    valueIcon: value => {
      return (
        <>
          {!!value.parentTaskId ? (
            <NewTabComponent
              href={`/actions/${value.parentTaskId}`}
              component={<ActionSummaryTooltipComponent actionId={value.parentTaskId} />}
            />
          ) : null}
        </>
      );
    },
    filterType: "multiSelect",
    sortable: true,
    optionsSelector: tasksSelectors.uniqueParentTask,
  },
  {
    id: "createdByUserId",
    label: "FieldCreatedBy",
    renderValue: value => value.createdByUserName,
    sortable: true,
    valueType: "string",
    optionsSelector: () => null,
    propName: "displayName",
    asyncFetchFilterOptionsEntityType: "User",
    asyncFetchFilterOptionsUseKeyValue: true,
    filterType: "multiSelect",
  },
  {
    id: "responsibleUserId",
    label: "FieldResponsible",
    renderValue: value => value.responsibleUserName,
    sortable: true,
    valueType: "string",
    optionsSelector: () => null,
    propName: "displayName",
    asyncFetchFilterOptionsEntityType: "User",
    asyncFetchFilterOptionsUseKeyValue: true,
    filterType: "multiSelect",
  },
  {
    id: "status",
    label: "FieldStatus",
    renderValue: () => null,
    valueIcon: (value: Task): React.ReactNode | null => renderOpenCloseStatus(value.status),
    sortable: true,
    optionsSelector: () =>
      openClosedOptions.map(l => ({
        id: l.id,
        label: localizeText(l.label),
        icon: l.icon,
      })),
    filterType: "multiSelect",
  },
  {
    id: "priority",
    label: "FieldPriority",
    renderValue: () => null,
    cellType: () => "icon",
    width: 150,
    valueIcon: (value: Task): React.ReactNode | null => renderTaskPriority(value.priority),
    optionsSelector: () =>
      priorityOptions.map(l => ({
        id: l.id,
        label: localizeText(l.label),
        icon: l.icon,
      })),
    sortable: true,
    filterType: "multiSelect",
  },
  {
    id: "actions",
    label: "FieldActions",
    renderValue: () => null,
    optionsSelector: () => null,
    sortable: false,
    cellType: () => "actions",
    width: 200,
    actions: [
      {
        label: "",
        component: entity => (
          <HandleTaskModal
            status={entity.status}
            title={entity.title}
            isOpen={entity.status === OpenClosedStatus.Opened ? true : false}
            taskId={entity.id}
          />
        ),
        action: entity => null,
      },
      {
        label: ActionTypes.PDF,
        component: entity =>
          entity.pdfInfo?.livePdfGuid || entity.pdfInfo?.fileUrl ? (
            <PDFActions pdfLink={entity.pdfInfo?.livePdfGuid} title={entity.title} fileLink={entity.pdfInfo?.fileUrl} />
          ) : null,
        action: entity => null,
      },
    ],
  },
  {
    id: "followUpReviewId",
    label: "FieldReview",
    renderValue: value => value.followUpReviewName,
    valueType: "string",
    optionsSelector: () => null,
    propName: "name",
    asyncFetchFilterOptionsEntityType: "Review",
    asyncFetchFilterOptionsUseKeyValue: true,
    sortable: true,
    filterType: "multiSelect",
  },
  {
    id: "projectId",
    label: "FieldProject",
    renderValue: value => value.projectName,
    sortable: true,
    valueType: "string",
    optionsSelector: () => null,
    propName: "name",
    asyncFetchFilterOptionsEntityType: "Project",
    asyncFetchFilterOptionsUseKeyValue: true,
    filterType: "multiSelect",
  },
  {
    id: "tags",
    label: "FieldTags",
    renderValue: value => value.tags,
    optionsSelector: tasksSelectors.uniqueTags,
    sortable: true,
    filterType: "tags",
  },
  {
    id: "issueTypeId",
    label: "FieldIssueType",
    renderValue: value => value.issueTypeName,
    sortable: true,
    valueType: "string",
    optionsSelector: () => null,
    propName: "name",
    asyncFetchFilterOptionsEntityType: "IssueType",
    asyncFetchFilterOptionsUseKeyValue: true,
    filterType: "multiSelect",
  },
  {
    id: "employeeId",
    label: "FieldEmployee",
    valueIcon: value =>
      !!value.employeeId ? <NewTabComponent value={value.employeeName} href={`/employee/${value.employeeId}`} /> : null,
    optionsSelector: () => null,
    renderValue: value => null,
    sortable: true,
    valueType: "string",
    propName: "displayName",
    asyncFetchFilterOptionsEntityType: "User",
    asyncFetchFilterOptionsUseKeyValue: true,
    filterType: "multiSelect",
  },
  {
    id: "equipmentId",
    label: "FieldEquipment",
    valueIcon: value =>
      !!value.equipmentId ? (
        <NewTabComponent value={value.equipmentName} href={`/equipment/${value.equipmentId}`} />
      ) : null,
    optionsSelector: () => null,
    renderValue: value => null,
    sortable: true,
    valueType: "string",
    propName: "name",
    asyncFetchFilterOptionsEntityType: "Equipment",
    asyncFetchFilterOptionsUseKeyValue: true,
    filterType: "multiSelect",
  },
  {
    id: "equipmentTypeId",
    label: "FieldEquipmentType",
    valueIcon: value =>
      !!value.equipmentTypeId ? (
        <NewTabComponent value={value.equipmentTypeName} href={`/equipmentType/${value.equipmentTypeId}`} />
      ) : null,
    optionsSelector: () => null,
    renderValue: value => null,
    sortable: true,
    valueType: "string",
    propName: "name",
    asyncFetchFilterOptionsEntityType: "EquipmentType",
    asyncFetchFilterOptionsUseKeyValue: true,
    filterType: "multiSelect",
  },
  {
    id: "locationId",
    label: "FieldLocation",
    valueIcon: value =>
      !!value.locationId ? <NewTabComponent value={value.locationName} href={`/location/${value.locationId}`} /> : null,
    optionsSelector: () => null,
    renderValue: value => null,
    sortable: true,
    valueType: "string",
    propName: "name",
    asyncFetchFilterOptionsEntityType: "Location",
    asyncFetchFilterOptionsUseKeyValue: true,
    filterType: "multiSelect",
  },
  {
    id: "description",
    label: "FieldDescription",
    renderValue: value => null,
    valueIcon: value => (
      <Tooltip title={value.description} overlayStyle={{ whiteSpace: "pre-line" }}>
        {value.description && value.description.length >= 10
          ? `${value.description?.substring(0, 10)}...`
          : value.description}
      </Tooltip>
    ),
    sortable: true,
    valueType: "string",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "HbTask",
    filterType: "multiSelect",
  },
];

export const accountableTabColumns: ColumnWithDifferentCells<PrivilegeData>[] = [
  {
    id: "name",
    label: "FieldName",
    renderValue: entity => entity.name || "",
    sortable: true,
    filterType: "multiSelect",
    cellType: entity => (entity.staging ? "search" : "text"),
    cellSearchSelector: tasksSelectors.allTaskRelations,
    cellSearchResultColumns: getCellSearchResultColumns(["name", "externalId"]),
    cellSearchKeys: ["name"],
    cellSearchRecordClick: fillNewAccountableEntryTemplate,
    optionsSelector: () => null,
    width: 100,
    primaryColumn: entity => ({
      navigationTarget: `/employee/${entity.userId}`,
    }),
  },
  {
    id: "externalId",
    label: "ExternalID",
    renderValue: entity => entity.externalId || "",
    sortable: true,
    filterType: "multiSelect",
    cellType: entity => (entity.staging ? "search" : "text"),
    cellSearchSelector: tasksSelectors.allTaskRelations,
    cellSearchResultColumns: getCellSearchResultColumns(["name", "externalId"]),
    cellSearchKeys: ["externalId"],
    cellSearchRecordClick: fillNewAccountableEntryTemplate,
    optionsSelector: employeeSelectors.basicEmployeeExternalIDs,
    width: 100,
  },
  {
    id: "status",
    label: "FieldStatus",
    renderValue: entity => (entity.status ? localizeText(entity.status.toString()) : ""),
    sortable: true,
    optionsSelector: () => null,
    width: 100,
  },
  {
    id: "isExternal",
    label: "External",
    renderValue: entity => (entity.isExternal !== null ? renderBoolStatus(entity.isExternal) : ""),
    sortable: true,
    optionsSelector: () => null,
    width: 100,
  },
  {
    id: "role",
    label: "Accountability",
    renderValue: e => (e.role ? localizeText(e.role.toString()) : ""),
    cellType: () => "text",
    sortable: true,
    optionsSelector: () => null,
    width: 100,
  },
  {
    id: "action" as keyof PrivilegeData,
    label: "FieldAction",
    renderValue: () => null,
    sortable: true,
    optionsSelector: () => null,
    cellType: () => "buttonActions",
    width: 100,
    stagingActions: [
      {
        label: "Add",
        className: "hb-primary-button",
        action: entity => addEntityPrivilege({ entity: entity, entityType: PrivilegedEntityType.HbTask, id: null }),
      },
      {
        label: "Cancel",
        className: "hb-tertiary-button",
        action: entity => deleteNewAccountableEntryTemplate(entity),
      },
    ],
  },
];

export const tasksHistoryLogTableColumns: ColumnType<HistoryLog>[] = [
  {
    id: "userName",
    label: "FieldUserName",
    renderValue: value => value.userName,
    sortable: false,
    optionsSelector: () => null,
  },
  {
    id: "operation",
    label: "Operation",
    renderValue: value => localizeText(value.operation),
    sortable: false,
    optionsSelector: () => null,
  },
  {
    id: "oldValue",
    label: "OldValue",
    renderValue: value => localizeText(value.oldValue),
    sortable: false,
    optionsSelector: () => null,
    onCellOnClick(e) {
      Modal.info({
        title: localizeText(this.label),
        icon: null,
        content: <HBTextArea value={e.oldValue} disabled />,
        maskClosable: true,
      });
    },
  },
  {
    id: "newValue",
    label: "NewValue",
    renderValue: value => localizeText(value.newValue),
    sortable: false,
    optionsSelector: () => null,
    onCellOnClick(e) {
      Modal.info({
        title: localizeText(this.label),
        icon: null,
        content: <HBTextArea value={e.newValue} disabled />,
        maskClosable: true,
      });
    },
  },
  {
    id: "timeStamp",
    label: "Timestamp",
    renderValue: value => value.timeStamp,
    sortable: false,
    optionsSelector: () => null,
  },
  {
    id: "pdfLink",
    label: "FieldActions",
    renderValue: value => value.pdfLink || null,
    sortable: false,
    optionsSelector: () => null,
    cellType: () => "actions",
    //in this case, this is regular actions, not staging, just using this key
    actions: [
      {
        label: ActionTypes.PDF,
        component: entity => (entity.pdfLink ? <PDFActions pdfLink={entity.pdfLink} /> : null),
        action: entity => null,
      },
    ],
  },
];

export const tasksLinkedActionsColumns: ColumnType<LinkedAction>[] = [
  {
    id: "id",
    label: "ID",
    renderValue: value => value.id.toString(),
    sortable: false,
    optionsSelector: () => null,
    width: 100,
    filterType: "multiSelect",
    primaryColumn: entity => ({
      navigationTarget: `/actions/${entity.id}`,
    }),
  },

  {
    id: "title",
    label: "FieldTitle",
    renderValue: value => value.title,
    sortable: false,
    optionsSelector: () => null,
    primaryColumn: entity => ({
      navigationTarget: `/actions/${entity.id}`,
    }),
  },
  {
    id: "relationship",
    label: "FieldRelationshipType",
    renderValue: value => localizeText(value.relationship),
    sortable: false,
    optionsSelector: () => null,
  },

  {
    id: "createDate",
    label: "FieldCreateDate",
    renderValue: value => (value.createDate ? dayjs(value.createDate).format("DD/MM/YYYY HH:mm") : null),
    sortable: false,
    optionsSelector: () => null,
  },
  {
    id: "status",
    label: "FieldStatus",
    renderValue: () => null,
    valueIcon: (value: LinkedAction): React.ReactNode | null => renderOpenCloseStatus(value.status),
    sortable: true,
    optionsSelector: () => null,
  },
  {
    id: "actions" as keyof LinkedAction,
    label: "FieldActions",
    renderValue: () => null,
    optionsSelector: () => null,
    sortable: false,
    cellType: () => "actions",
    width: 200,
    actions: [
      {
        label: ActionTypes.PDF,
        component: entity =>
          entity.pdfInfo?.livePdfGuid || entity.pdfInfo?.fileUrl ? (
            <PDFActions pdfLink={entity.pdfInfo?.livePdfGuid} title={entity.title} fileLink={entity.pdfInfo?.fileUrl} />
          ) : null,
        action: entity => null,
      },
    ],
  },
];
