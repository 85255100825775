import { CheckOutlined, CloseOutlined, StarFilled, StarOutlined } from "@ant-design/icons";
import { Button, Popover, Space, Switch } from "antd";
import { SwitchChangeEventHandler } from "antd/lib/switch";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  setCompanyDefaultDashboard,
  togglCrossProjectDashboard,
  togglePrivateDashboard,
} from "../../../store/slices/dashboard";
import { fetchCubeToken } from "../../../store/slices/user";
import { RootState, useAppDispatch } from "../../../store/store";
import DashboardUserSelection from "./DashboardUserSelection";

type DashboardSettingsProps = {
  ownerMode: boolean;
  setToggleState: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function DashboardSettings({ ownerMode, setToggleState }: DashboardSettingsProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const primaryColor = useSelector((state: RootState) => state.common.primaryColor);
  const isSU = useSelector((state: RootState) => state.user.settings.isSysAdmin);
  const crossProjects = useSelector((state: RootState) => state.dashboard.single.crossProjects);
  const companyDefault = useSelector((state: RootState) => state.dashboard.single.isDefault);
  const dashboardId = useSelector((state: RootState) => state.dashboard.single.id);

  const isPrivate = useSelector((state: RootState) => state.dashboard.single.isPrivate);

  const onPrivateChange: SwitchChangeEventHandler = checked => {
    dispatch(togglePrivateDashboard());
  };

  const onCrossProject: SwitchChangeEventHandler = checked => {
    dispatch(togglCrossProjectDashboard());
    dispatch(fetchCubeToken(checked));
  };

  const onSetCompanyDefault: React.MouseEventHandler<HTMLElement> = checked => {
    dispatch(setCompanyDefaultDashboard());
  };

  return (
    <div id="dashboardSettings">
      {(ownerMode || isSU) && (
        <>
          <div className="dashboardUsersSettings">
            <Space style={{ marginRight: "0.5rem" }}>
              {isPrivate && (
                <Space>
                  <Popover trigger="click" placement="rightBottom" content={<DashboardUserSelection userType="User" />}>
                    <Button type="text" shape="round">
                      {t("DashboardUsersButton")}
                    </Button>
                  </Popover>
                  {!ownerMode && (
                    <Popover
                      trigger="click"
                      placement="rightBottom"
                      content={<DashboardUserSelection userType="Owner" />}
                    >
                      <Button type="text" shape="round">
                        {t("DashboardOwnersButton")}
                      </Button>
                    </Popover>
                  )}
                </Space>
              )}
              <Space style={{ margin: "0 0.5rem 0 0.5rem" }}>
                <div>{t("DashboardPrivateToggle")}</div>
                <Switch
                  disabled={ownerMode && !isSU}
                  onChange={onPrivateChange}
                  checked={isPrivate}
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </Space>
            </Space>
          </div>
          <div className="dashboardSettingsContainer">
            <Space size={1} style={{ margin: "0 1rem 0 0.5rem" }}>
              <div>{t("DashboardCrossProjectsToggle")}</div>
              <Switch
                disabled={ownerMode && !isSU}
                onChange={onCrossProject}
                checked={crossProjects}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Space>
          </div>
          <div className="dashboardSettingsContainer">
            <Space size={1} style={{ margin: "0 1rem 0 0.5rem" }}>
              <div>{t("DashboardCompanyDefaultButton")}</div>
              <Button
                type="text"
                shape="round"
                disabled={(ownerMode && !isSU) || isPrivate}
                onClick={onSetCompanyDefault}
                size="small"
                icon={companyDefault ? <StarFilled style={{ color: primaryColor }} /> : <StarOutlined />}
              />
            </Space>
          </div>
        </>
      )}
      <div className="dashboardSettingsFinishButton">
        <Button
          shape="round"
          type="text"
          onClick={() => {
            setToggleState(false);
          }}
          icon={<CheckOutlined />}
        />
      </div>
    </div>
  );
}
