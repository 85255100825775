import dayjs from "dayjs";
import {
  CancelTraining,
  ColumnManagementAction,
  ExportAction,
  GetFiltersUrl,
  ImportAction,
  SetSertificationAction,
} from "../../../../actions/menuActions";

import { ReactComponent as PageIcon } from "../../../../media/training-page-icon.svg";
import { pageSelectors, trainingSelectors } from "../../../../selectors";
import { setFileInfoToOpen } from "../../../../store/slices/common";
import { fetchHistoryLog } from "../../../../store/slices/commonThunks";
import { deleteMultipleEntityPrivileges, getEntityPrivileges } from "../../../../store/slices/privileges";
import {
  addTraining,
  changeBulkTraineeStatus,
  clearTrainingError,
  createNewParticipantTemplate,
  createNewTraineeTemplate,
  createTrainingTemplate,
  deleteBulkTraineeRelations,
  fetchPaginatedTraining,
  fetchSingleTraining,
  fetchTrainingCustomProps,
  getTrainingTrainees,
  removeFiles,
  resetCurrentPage,
  resetSearchResults,
  searchTraining,
  updateTraining,
  uploadFile,
} from "../../../../store/slices/training";
import store, { RootState } from "../../../../store/store";
import { BaseFile } from "../../../../types/files";
import { CategoryPage, SectionType } from "../../../../types/page";
import { Training, TrainingPage, TrainingStatus, TrainingTrainee } from "../../../../types/training";
import { UserState } from "../../../../types/user";
import { PrivilegeData, PrivilegedEntityType, TableAddButtonType, TraineeStatus } from "../../../../types/utility";
import { filesTableColumns, historyLogTableColumns } from "../common";
import {
  companyHasCertificates,
  fetchCertificateById,
  fetchSupplierByCertificateId,
  localizeText,
  pickSpecificColumns,
  prepareDynamicColumns,
  prepareDynamicColumnsPaginated,
  removeColumnFilters,
} from "../utilities";
import {
  attendedParticipantsCountField,
  certificateIdField,
  completedDateField,
  externalIdField,
  locationIdField,
  participantsCountField,
  plannedDateField,
  primaryFields,
  secondaryFields,
  statusField,
} from "./fields";
import { accountableTabColumns, traineeTabColumns, trainingTableColumns } from "./trainingConfigPageData";

const trainingConfigPage = (): CategoryPage<TrainingPage> => ({
  id: "training",
  title: "PluginTrainingName",
  entityEndpoint: "/Training",
  PageIcon: PageIcon,
  hasGroupView: false,
  hasListView: true,
  primaryErrorSelector: trainingSelectors.error,
  intializeDataActions: [],
  lastUpdatedSelector: trainingSelectors.trainingLastUpdatedText,
  // fetchPrimaryData: fetchTrainingCustomProps,
  fetchCustomProps: fetchTrainingCustomProps,
  // fetchSecondaryData: [fetchLocations],
  breadcrumbSingleValue: e => {
    const certificateId = fetchCertificateById(e.certificateId)?.name;
    return {
      id: e.id?.toString(),
      label: certificateId || "",
    };
  },
  addButton: {
    label: "AddTraining",
    action: () => console.log("Create new training"),
  },
  summaryCard: {
    CardIcon: PageIcon,
    navigationWarning: {
      okText: "CreateCertification",
      cancelText: "BackToEmployeeManagement",
      body: "CreateCertificationBeforeTraining",
      addValueToBody: false,
      addValueToOkText: false,
      showConditionally: () => !companyHasCertificates(),
      navigateOnCancel: "/employee",
      navigateOnOk: "/certification/new",
    },
    cardInformationalMessasge: e => {
      if (e.status === TrainingStatus.Canceled) {
        return "ThisTrainingIsCanceledNoEdit";
      }
      if (e.status === TrainingStatus.Passed) {
        return "ThisTrainingIsDoneNoEdit";
      }
      return "";
    },
    confirmationMessage: e => {
      if (
        e.status === TrainingStatus.Passed &&
        dayjs(e.plannedDate).isValid() &&
        dayjs(e.plannedDate).isAfter(dayjs())
      ) {
        return "TrainingFutureDateConfirmationMessage";
      }

      return "";
    },
    dependentFields: e => [
      {
        id: "supplier",
        parentId: "certificateId",
        defaultValue: e.supplier || fetchSupplierByCertificateId(e.certificateId),
      },
    ],
    customFieldsAdditionalProps: {
      editForbidden: e => (e.status === TrainingStatus.Canceled || e.status === TrainingStatus.Passed) && true,
    },
    globalEditForbidden: e => (e.status === TrainingStatus.Canceled || e.status === TrainingStatus.Passed) && true,
    mobileSingleView: {
      summary: {
        icon: PageIcon,
        title: plannedDateField,
        id: externalIdField,
        status: statusField,
        mainContextRelation: certificateIdField,
      },
      sections: [
        {
          type: SectionType.AdministrationSection,
          fields: [participantsCountField, attendedParticipantsCountField, locationIdField],
        },
        {
          type: SectionType.ContextSection,
          fields: [],
        },
        {
          type: SectionType.DatesSection,
          fields: [],
        },
      ],
    },
    primaryFields: primaryFields,
    secondaryFields: secondaryFields,
    canEditSelector: (state: RootState) => {
      return state.certification.subData.userCertifications;
    },
    canEdit: (user: UserState, current, selectedData?: any[]) => {
      return Boolean(
        //new
        (current?.id === 0 && selectedData && selectedData.length > 0) ||
          //existing
          selectedData?.some(item => item.id === current?.certificateId)
      );
    },
  },
  // primaryEntitySelector: trainingSelectors.all,
  tabsPanel: [
    /*{
      key: "location",
      label: "AppointmentLocationLink",
      tabSelector: locationEntitySelectors.basic,
      columns: removeColumnFilters(locationsTableColumns) as [],
      rowSelection: true,
      type: "tree",
      // currentSelection: () => fetchCurrentLocationId(),
      changeNewEntityParent: updateLocationId,
      changeExistingEntityParent: patchLocationId,
      selectedKeyId: "locationId",
      generateDynamicColumns: (props, data) => removeColumnFilters(prepareDynamicColumns<Location>(props, data)),
      customPropertiesSelector: locationEntitySelectors.locationCustomProperties,
    },*/
    {
      key: "employee",
      label: "Trainees",
      tabSelector: trainingSelectors.allTrainingParticipants,
      tabDataThunk: id => getTrainingTrainees(+id),
      columns: removeColumnFilters(traineeTabColumns) as [],
      rowSelection: true,
      type: "table",
      addButton: {
        action: createNewTraineeTemplate,
        label: "AddEmployee",
        type: TableAddButtonType.AddInline,
      },
      generateDynamicColumns: (props, data) => [],
      customPropertiesSelector: pageSelectors.nullableCustomPropertiesSelector,
      dropdownButtonActions: [
        {
          label: "DeleteBulk",
          action: entities => deleteBulkTraineeRelations(entities as TrainingTrainee[]), // TODO: Implement thunk for deleting multiple entities (both employees and orgUnits)
          type: "bulk",
          changesConfirmation: {
            body: "ConfirmEntitiesRemoval",
            okText: "Remove",
            cancelText: "Keep",
            addValueToBody: false,
            addValueToOkText: false,
          },
        },
        {
          label: "MarkAsAttended",
          action: entities =>
            changeBulkTraineeStatus({ entities: entities as TrainingTrainee[], newStatus: TraineeStatus.Attended }), // TODO: Implement thunk for deleting multiple entities (both employees and orgUnits)
          type: "bulk",
          changesConfirmation: {
            body: "ConfirmTraineesAttended",
            okText: "Mark",
            cancelText: "Cancel",
            addValueToBody: false,
            addValueToOkText: false,
          },
        },
      ],
    },
    {
      key: "accountable",
      label: "Accountable",
      tabSelector: trainingSelectors.allTrainingAccountable,
      tabDataThunk: id => getEntityPrivileges({ id: +id, entityType: PrivilegedEntityType.Training }),
      columns: removeColumnFilters(accountableTabColumns) as [],
      rowSelection: true,
      type: "table",
      addButton: {
        action: createNewParticipantTemplate,
        label: "AddEmployee",
        type: TableAddButtonType.AddInline,
      },
      generateDynamicColumns: (props, data) => [],
      customPropertiesSelector: pageSelectors.nullableCustomPropertiesSelector,
      dropdownButtonActions: [
        {
          label: "DeleteBulk",
          action: entities =>
            deleteMultipleEntityPrivileges({
              entity: entities as PrivilegeData[],
              entityType: PrivilegedEntityType.Training,
              id: null,
            }),
          type: "bulk",
          changesConfirmation: {
            body: "ConfirmEntitiesRemoval",
            okText: "Remove",
            cancelText: "Keep",
            addValueToBody: false,
            addValueToOkText: false,
          },
        },
      ],
    },
    {
      key: "files",
      label: "Files",
      tabSelector: trainingSelectors.files,
      columns: removeColumnFilters(filesTableColumns("training")) as [],
      rowSelection: true,
      addButton: {
        action: createNewParticipantTemplate,
        label: "AddFile",
        // controlUploadModal: true,
        type: TableAddButtonType.UploadFile,
        uploadFileAction: uploadFile,
      },
      type: "table",
      generateDynamicColumns: (props, data) => removeColumnFilters(prepareDynamicColumns<File>(props, data)),
      customPropertiesSelector: pageSelectors.nullableCustomPropertiesSelector,
      onRowClick: fileInfo => store.dispatch(setFileInfoToOpen(fileInfo as BaseFile)),
      dropdownButtonActions: [
        {
          label: localizeText("DeleteBulk"),
          action: entities => removeFiles(entities.map(x => x.id) as number[]),
          type: "bulk",
          changesConfirmation: {
            body: "Removing the files could not be reversible, Are you should you wish to remove the files?",
            okText: "Yes I wish to remove the files",
            cancelText: "Keep the files",
          },
        },
      ],
    },
    {
      key: "historyLog",
      label: "HistoryLog",
      tabDataThunk: id => fetchHistoryLog({ entityId: +id, entityType: "Training" }),
      tabSelector: trainingSelectors.trainingHistoryLog,
      columns: removeColumnFilters(historyLogTableColumns) as [],
      rowSelection: true,
      type: "table",
      generateDynamicColumns: (props, data) => [],
      customPropertiesSelector: pageSelectors.nullableCustomPropertiesSelector,
    },
  ],
  listView: {
    table: {
      tableSelector: trainingSelectors.all,
      fetchPaginatedData: fetchPaginatedTraining,
      searchResultsSelector: trainingSelectors.trainingSearchResults,
      possibleResultsSelector: trainingSelectors.trainingPossibleResults,
      columns: trainingTableColumns,
      rowSelection: true,
      defaultColumnKeys: [
        "plannedDate",
        "certificateId",
        "supplier",
        "attendedParticipentsCount",
        "participentsCount",
        "locationId",
        "status",
      ],
      type: "paginatedTable",
      searchPaginatedData: searchTraining,
      resetSearchResult: resetSearchResults,
      resetCurrentPage: resetCurrentPage,
    },
    generateDynamicColumns: (props, data) => prepareDynamicColumnsPaginated<Training>(props, data),
  },
  listSearch: {
    columns: pickSpecificColumns<Training>(trainingTableColumns, "externalId"),
    keys: ["externalId", "certificateId"],
  },
  isLoading: pageSelectors.isTrainingPageLoading,
  primarySingleEntitySelector: trainingSelectors.single,
  customPropertiesSelector: trainingSelectors.trainingCustomProperties,
  fetchSingle: fetchSingleTraining,
  clearError: clearTrainingError,
  createNewEntityTemplate: createTrainingTemplate,
  createNewEntity: addTraining,
  updateEntity: updateTraining,
  listViewActions: [ImportAction, ColumnManagementAction, ExportAction, SetSertificationAction, GetFiltersUrl],
  singleViewActions: [CancelTraining],
});

export default trainingConfigPage;
