import { CloseCircleOutlined, InboxOutlined, MoreOutlined, ReloadOutlined, UploadOutlined } from "@ant-design/icons";
import { unwrapResult } from "@reduxjs/toolkit";
import { Modal, Upload, Button, Spin, Space, Typography, Popover, Menu } from "antd";
import { RcFile } from "antd/lib/upload/interface";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { reasonOptions } from "../../../pages/pageConfig/category/common";
import { renderTaskPriority } from "../../../pages/pageConfig/category/utilities";
import PDFActions from "../../../pages/TableActions/PDFActions";
import useApplyFilters from "../../../pages/TodoList/useApplyFilters";
import { setAppointmentToOpen } from "../../../store/slices/common";
import {
  cloneAction,
  closeTask,
  getTaskCloseInfo,
  openTaskFollowUp,
  reopenTask,
  setActionsStatus,
} from "../../../store/slices/tasks";
import { updateReopenedTask } from "../../../store/slices/todolist";
import { RootState, useAppDispatch } from "../../../store/store";
import { PDFInfo, ResponsibleChangeReasonType, TaskInfo } from "../../../types/tasks";
import { OpenClosedStatus, TaskPriority } from "../../../types/utility";
import { constructTaskUrl, getAppointmentUrl } from "../../../utils/functions";
import useIsMobile from "../../../utils/hooks/useIsMobile";
import useLegacyBaseUrl from "../../../utils/hooks/useLegacyUrl";
import useOutsideClick from "../../../utils/hooks/useOutsideClick";
import usePasProFlag from "../../../utils/hooks/usePasProFlag";
import useRouter from "../../../utils/hooks/useRouter";
import { useUIErrorHandling } from "../../../utils/hooks/useUIErrorHandling";
import { ConfirmationModal } from "../../ConfirmationModal/ConfirmationModal";
import HBAutocomplete from "../Autocomplete/HBAutocomplete";
import HBInput from "../Input/HBInput";
import "./handleTaskModal.less";
import { hasSpecialChars } from "../UploadModal/HBUploadModal";

const { Dragger } = Upload;

type TProps = {
  taskId: number;
  title?: string;
  isFormBased?: boolean;
  priority?: TaskPriority;
  createdByName?: string | null;
  isOpen: boolean;
  isButton?: boolean;
  isTodoList?: boolean;
  children?: React.ReactNode;
  pdfInfo?: PDFInfo;
  status?: OpenClosedStatus;
};

enum Actions {
  NOT_CLOSE = "Do Not Close",
  CLOSE = "Close",
}

export const HandleTaskModal = ({
  taskId,
  title,
  isOpen,
  isButton,
  isTodoList,
  children,
  createdByName,
  priority,
  isFormBased,
  pdfInfo,
  status,
}: TProps): JSX.Element => {
  const { t } = useTranslation();
  const [description, setDescription] = useState("");
  const [assignee, setAssignee] = useState<{ id: number; displayName: string } | null>(null);
  const [reason, setReason] = useState<string | null>(null);
  const [file, setFile] = useState<RcFile | undefined>(undefined);
  const [taskInfo, setTaskInfo] = useState<TaskInfo>();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isFormBasedAction, setIsFormBasedAction] = useState<boolean | undefined>(isFormBased);
  const [openAppointment, setOpenAppointment] = useState<boolean>(false);
  const settings = useSelector((state: RootState) => state.user.settings);
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick(ref, () => setPopoverVisible(false));
  const [clonedTaskId, setClonedTaskID] = useState<number | null>();
  const [askEditModalVisible, setAskEditModalVisible] = useState(false);
  const [editAppointment, setEditAppointment] = useState<boolean | undefined>();
  const router = useRouter();
  const queryParams = new URLSearchParams(router.location.search);
  const isMobile = useIsMobile();
  const isPASProEnabled = usePasProFlag();
  const applyFilters = useApplyFilters();
  const employee = useSelector((state: RootState) => state.employee.basicData);
  const employeeOptions = (employee || []).map(l => ({
    id: l.id,
    label: l.displayName,
  }));

  const [selectedAction, setSelectedAction] = useState<Actions>(Actions.CLOSE);
  const dispatch = useAppDispatch();
  const mobileDropdownAlign = {
    points: settings.direction === "rtl" ? ["br", "tr"] : ["bl", "tl"],
    offset: [0, -4],
    overflow: {
      adjustX: 0,
      adjustY: 0,
    },
  };
  const { errorMessages, errorType, setErrors, handleClearErrors } = useUIErrorHandling();
  const legacyBaseUrl = useLegacyBaseUrl();

  const navigateToForm = (appointmentId: number) => {
    if (!isMobile && isPASProEnabled) {
      dispatch(setAppointmentToOpen(appointmentId));
    } else {
      window.location.href = getAppointmentUrl(legacyBaseUrl, appointmentId, isMobile, taskId);
    }
  };

  useEffect(() => {
    const openAppointment = (taskInfo: TaskInfo, edit: boolean) => {
      dispatch(
        openTaskFollowUp({
          ProjectId: taskInfo.projectId as number,
          ReviewInfoId: taskInfo.followUpInfo.review.id,
          TaskId: taskId,
          IsUsedProjectReviewEntity: false,
          IsTodoList: isTodoList,
          Edit: edit,
        })
      )
        .then(result => unwrapResult(result))
        .then(appointmentId => {
          if (appointmentId) {
            navigateToForm(appointmentId);
          }
        });
      setIsModalVisible(false);
    };

    if (!taskInfo || taskInfo.appointmentId) {
      return;
    } else {
      const shouldAskEdit = taskInfo.followUpInfo?.askEdit && editAppointment === undefined;
      const shouldReview = taskInfo.followUpInfo?.review?.id && isOpen;

      if (shouldAskEdit) {
        setAskEditModalVisible(true);
      } else if (shouldReview) {
        openAppointment(taskInfo, Boolean(editAppointment));
      } else {
        setSelectedAction(Actions.CLOSE);
      }
    }
  }, [taskInfo, editAppointment]);

  useEffect(() => {
    if (openAppointment) {
      if (taskInfo?.appointmentId) navigateToForm(taskInfo.appointmentId);
      setOpenAppointment(false);
      setIsModalVisible(false);
    }
  }, [openAppointment, taskInfo]);

  const onDescriptionChanged = (value: string | null | number) => {
    setDescription(value?.toString() || "");
  };

  const onAssigneeChanged = (value: string | null | number | boolean) => {
    setAssignee({ id: value as number, displayName: employee.find(x => x.id === value)?.displayName as string });
  };

  const onReasonChanged = (value: string | null | number | boolean) => {
    setReason(value as string);
  };

  const onFileSelected = (file: RcFile, _fileList: RcFile[]): boolean | Promise<void | File | Blob> => {
    if (hasSpecialChars(file.name)) {
      setErrors && setErrors({ title: [t("Error")], message: [t("ErrorSpecialCharactersInFileName")] });
      return false;
    }
    setFile(file);

    return false;
  };

  const handleChangeAction = (action: Actions) => {
    setSelectedAction(action);
  };

  const onSave = () => {
    if (isOpen) {
      dispatch(
        closeTask({
          task: {
            TaskId: taskId,
            IsHandled: selectedAction === Actions.CLOSE ? true : false,
            ...(selectedAction === Actions.CLOSE
              ? {
                  Handled: {
                    Description: description,
                  },
                }
              : {
                  ResponsibleChange: {
                    Description: description,
                    NewResponsibleId: assignee?.id ?? 0,
                    NewResponsibleDisplayName: assignee?.displayName ?? "",
                    ResponsibleChangeReasonType: reason as ResponsibleChangeReasonType,
                  },
                }),
          },
          file: file,
        })
      ).then(() => applyFilters({}));
    } else {
      dispatch(
        reopenTask({
          TaskId: taskId,
          Description: description,
        })
      ).then(() => {
        dispatch(updateReopenedTask(taskId));
      });
    }
    setFile(undefined);
    setDescription("");
    setIsModalVisible(!isModalVisible);
  };

  const onOpen = useCallback(() => {
    if (!isOpen && isTodoList) {
      const url = constructTaskUrl({
        taskId,
        isMobile,
        todoListInParams: isTodoList,
      });

      router.push(url);
      return;
    }

    dispatch(getTaskCloseInfo(taskId))
      .then(result => unwrapResult(result))
      .then(info => {
        if (!shouldShowViewButton()) {
          setTaskInfo(info);
        }
        const askEdit = info?.followUpInfo?.askEdit;
        !askEdit && setIsModalVisible(true);
      });
  }, [taskId, isModalVisible, isTodoList, openAppointment, isOpen]);

  const onClose = () => {
    setFile(undefined);
    setDescription("");
    setAssignee(null);
    setReason(null);
    setIsModalVisible(!isModalVisible);
  };

  const UploadComponent = isMobile ? Upload : Dragger;
  const UploadChild = isMobile ? (
    <Button icon={<UploadOutlined />}>{t("UploadFile")}</Button>
  ) : (
    <>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">{t("ClickOrDropFile")}</p>{" "}
    </>
  );

  const CloneActionMenuItem = (
    <Menu.Item>
      <div
        onClick={e => {
          e.stopPropagation();
          dispatch(cloneAction(taskId))
            .then(result => unwrapResult(result))
            .then(id => {
              setClonedTaskID(id);
            });
          setPopoverVisible(false);
        }}
      >
        {t("CloneAction")}
      </div>
    </Menu.Item>
  );

  const shouldShowViewButton = () => !isOpen && isFormBasedAction && (pdfInfo?.livePdfGuid || pdfInfo?.fileUrl);

  return (
    <div ref={ref} className="pointer" onClick={e => e.stopPropagation()}>
      <Modal
        open={askEditModalVisible}
        wrapClassName="modalWrapper"
        onCancel={() => {
          setAskEditModalVisible(false);
        }}
        footer={[
          <Button
            className="ask-edit-button"
            key="submit2"
            type="primary"
            style={{ minHeight: "4rem" }}
            onClick={() => {
              setAskEditModalVisible(false);
              setEditAppointment(false);
            }}
          >
            {t("AskEditNo")}
          </Button>,
          <Button
            className="ask-edit-button"
            key="submit"
            type="primary"
            style={{ minHeight: "4rem" }}
            onClick={() => {
              setAskEditModalVisible(false);
              setEditAppointment(true);
            }}
          >
            {t("AskEditYes")}
          </Button>,
        ]}
        centered
        className="message-modal"
      >
        <Typography.Paragraph className="message-modal__body-title">{t("PayAttention")}</Typography.Paragraph>
        <Typography.Paragraph className="message-modal__body-text">{t("AskEditMessage")}</Typography.Paragraph>
      </Modal>
      <div onClick={onOpen}>
        {isTodoList ? (
          children
        ) : (
          <>
            {isOpen ? (
              <>
                {isButton ? (
                  <>
                    {!isMobile && (
                      <Button type="primary" className="btn">
                        <CloseCircleOutlined />
                        {`${t("CompleteAction")}`}
                      </Button>
                    )}
                    <Popover
                      id="popover"
                      open={popoverVisible}
                      content={
                        <Menu selectable={false}>
                          <Menu.Item>
                            <div
                              onClick={e => {
                                e.stopPropagation();
                                dispatch(setActionsStatus([taskId]))
                                  .then(response => unwrapResult(response))
                                  .catch(errorMessage => {
                                    setErrors(
                                      {
                                        message: [errorMessage || "SomethingWentWrong"],
                                      },
                                      "operationFailed"
                                    );
                                  });
                                setPopoverVisible(false);
                              }}
                            >
                              {t("CancelAction")}
                            </div>
                          </Menu.Item>
                          {CloneActionMenuItem}
                          {isMobile && (
                            <Menu.Item>
                              <div
                                onClick={e => {
                                  setPopoverVisible(false);
                                  e.stopPropagation();
                                  onOpen();
                                }}
                              >
                                {t("CompleteAction")}
                              </div>
                            </Menu.Item>
                          )}
                        </Menu>
                      }
                      trigger={["click"]}
                    >
                      <Button
                        onClick={e => {
                          e.stopPropagation();
                          setPopoverVisible(!popoverVisible);
                        }}
                        className="dropdown-button"
                        type="text"
                        shape="round"
                      >
                        <MoreOutlined className="dropdown-button__icon" />
                      </Button>
                    </Popover>
                  </>
                ) : (
                  <Button type="text" size="small" shape="round" className="btn">
                    <CloseCircleOutlined className="icon-primary-color" />
                  </Button>
                )}
              </>
            ) : shouldShowViewButton() ? (
              <>
                {!isMobile && (
                  <Space direction="horizontal">
                    <PDFActions
                      pdfLink={pdfInfo?.livePdfGuid}
                      title={title}
                      fileLink={pdfInfo?.fileUrl}
                      primaryButton={true}
                      buttonTitle="ViewTheForm"
                    />
                  </Space>
                )}
                <Popover
                  id="popover"
                  open={popoverVisible}
                  content={
                    <Menu onClick={() => setPopoverVisible(false)} selectable={false}>
                      {isMobile && (
                        <Menu.Item>
                          <div
                            onClick={e => {
                              e.stopPropagation();
                            }}
                          >
                            <PDFActions
                              className={`${isMobile ? "pdf-actions-modal-mobile" : ""}`}
                              additionalAction={() => setPopoverVisible(false)}
                              textOnly={true}
                              pdfLink={pdfInfo?.livePdfGuid}
                              title={title}
                              fileLink={pdfInfo?.fileUrl}
                              primaryButton={true}
                              buttonTitle="ViewTheForm"
                            />
                          </div>
                        </Menu.Item>
                      )}
                      <Menu.Item>
                        <div
                          onClick={e => {
                            e.stopPropagation();
                            onOpen();
                            setPopoverVisible(false);
                          }}
                        >
                          {t("ReopenTask")}
                        </div>
                      </Menu.Item>
                      {CloneActionMenuItem}
                    </Menu>
                  }
                  trigger={["click"]}
                >
                  <Button
                    onClick={e => {
                      e.stopPropagation();
                      setPopoverVisible(!popoverVisible);
                    }}
                    className="dropdown-button"
                    type="text"
                    shape="round"
                  >
                    <MoreOutlined className="dropdown-button__icon" />
                  </Button>
                </Popover>
              </>
            ) : (
              <>
                {isButton ? (
                  isMobile ? (
                    <Popover
                      id="popover"
                      open={popoverVisible}
                      content={
                        <Menu onClick={() => setPopoverVisible(false)} selectable={false}>
                          <Menu.Item>
                            <div
                              onClick={e => {
                                e.stopPropagation();
                                onOpen();
                                setPopoverVisible(false);
                              }}
                            >
                              {t("ReopenTask")}
                            </div>
                          </Menu.Item>
                          {CloneActionMenuItem}
                        </Menu>
                      }
                      trigger={["click"]}
                    >
                      <Button
                        onClick={e => {
                          e.stopPropagation();
                          setPopoverVisible(!popoverVisible);
                        }}
                        className="dropdown-button"
                        type="text"
                        shape="round"
                      >
                        <MoreOutlined className="dropdown-button__icon" />
                      </Button>
                    </Popover>
                  ) : (
                    <>
                      <Button type="primary" className="btn">
                        <ReloadOutlined />
                        {`  ${t("ReopenTask")}`}
                      </Button>
                      <Popover
                        id="popover"
                        open={popoverVisible}
                        content={
                          <Menu onClick={() => setPopoverVisible(false)} selectable={false}>
                            {CloneActionMenuItem}
                          </Menu>
                        }
                        trigger={["click"]}
                      >
                        <Button
                          onClick={e => {
                            e.stopPropagation();
                            setPopoverVisible(!popoverVisible);
                          }}
                          className="dropdown-button"
                          type="text"
                          shape="round"
                        >
                          <MoreOutlined className="dropdown-button__icon" />
                        </Button>
                      </Popover>
                    </>
                  )
                ) : (
                  <Button type="text" size="small" shape="round" className="btn">
                    <ReloadOutlined className="icon-primary-color" />
                  </Button>
                )}
              </>
            )}
          </>
        )}
      </div>
      {isOpen ? (
        <>
          {taskInfo ? (
            taskInfo.isAnyOpenSubTasks ? (
              <Modal
                className="handle-task-modal"
                closable
                maskClosable
                title="Error"
                open={isModalVisible}
                footer={null}
                onCancel={onClose}
              >
                <div>{t("CloseSubTasks")}</div>
              </Modal>
            ) : (
              <Modal
                className="handle-task-modal"
                title={t("HandleTask")}
                closable
                maskClosable
                open={isModalVisible}
                footer={null}
                onCancel={onClose}
              >
                <div className="fields-todo-wrapper">
                  {title && <span className="field">{t("FieldTitle") + " : " + title}</span>}
                  {priority && (
                    <span className="field">
                      {t("FieldPriority")} : {renderTaskPriority(priority)}
                    </span>
                  )}
                  {createdByName && <span className="field">{t("FieldCreatedBy") + " : " + createdByName}</span>}
                </div>

                <div className="handle-task-modal-btns-container">
                  <Button
                    type={selectedAction === Actions.NOT_CLOSE ? "primary" : "default"}
                    className="btn"
                    onClick={() => handleChangeAction(Actions.NOT_CLOSE)}
                    dir={settings.direction}
                  >
                    {t("DoNotClose")}
                  </Button>
                  {taskInfo.followUpInfo?.canBeCompleted ? (
                    <Button
                      type={selectedAction === Actions.CLOSE ? "primary" : "default"}
                      className="btn"
                      dir={settings.direction}
                      onClick={() => handleChangeAction(Actions.CLOSE)}
                    >
                      {t("Close")}
                    </Button>
                  ) : null}
                </div>
                {selectedAction === Actions.CLOSE && (
                  <div className="fields-wrapper">
                    <div className="upload-modal-comment">
                      <span>{t("FieldDescription")}</span>
                      <span>
                        <HBInput onChange={onDescriptionChanged} value={description} />
                      </span>
                    </div>
                    <UploadComponent
                      accept=".xlsx, .csv, .png, .jpg, .jpeg, application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf,application/vnd.ms-excel"
                      beforeUpload={onFileSelected}
                      maxCount={1}
                      itemRender={() => <div>{file?.name}</div>}
                    >
                      {UploadChild}
                    </UploadComponent>
                  </div>
                )}
                {selectedAction === Actions.NOT_CLOSE && (
                  <div className="fields-wrapper">
                    <div className="upload-modal-comment">
                      <span>{t("FieldDescription")}</span>
                      <span>
                        <HBInput onChange={onDescriptionChanged} value={description} />
                      </span>
                    </div>
                    <div className="upload-modal-comment autocomplete-wrapper">
                      <span>{t("FieldReason")}</span>
                      <span>
                        <HBAutocomplete
                          dropdownAlign={isMobile && mobileDropdownAlign}
                          onChange={onReasonChanged}
                          options={reasonOptions}
                          localizeValue={true}
                        />
                      </span>
                    </div>
                    <div className="autocomplete-wrapper upload-modal-comment">
                      <span>{t("Assignee")}</span>
                      <HBAutocomplete
                        dropdownAlign={isMobile && mobileDropdownAlign}
                        onChange={onAssigneeChanged}
                        options={employeeOptions}
                      />
                    </div>

                    <UploadComponent
                      accept=".xlsx, .csv, .png, .jpg, .jpeg, application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf,application/vnd.ms-excel"
                      beforeUpload={onFileSelected}
                      maxCount={1}
                      itemRender={() => <div>{file?.name}</div>}
                    >
                      {UploadChild}
                    </UploadComponent>
                  </div>
                )}
                <div dir={settings.direction} className="upload-modal-action-button">
                  <Button className="btn" onClick={onSave}>
                    {t("Save")}
                  </Button>
                </div>
              </Modal>
            )
          ) : (
            <Modal
              className="handle-task-modal"
              title={t("Loading")}
              closable
              maskClosable={false}
              open={isModalVisible}
              footer={null}
              onCancel={onClose}
            >
              <div className="spinner">
                <Spin size="large" />
              </div>
            </Modal>
          )}
        </>
      ) : (
        isModalVisible && (
          <Modal
            open={isModalVisible}
            wrapClassName="modalWrapper"
            onCancel={onClose}
            footer={[
              <Button key="submit" type="default" style={{ minHeight: "4rem" }} onClick={onClose}>
                {t("Cancel")}
              </Button>,
              <Button
                className="ask-edit-button"
                key="submit2"
                type="primary"
                style={{ minHeight: "4rem" }}
                onClick={onSave}
              >
                {t("ReopenTask")}
              </Button>,
            ]}
            className="message-modal"
          >
            <Space className="reopen-modal-body-wrapper" direction="vertical">
              <div className="header">
                <div className="id-wrapper">
                  <div className="id-title">{`${t("ReopenTask")}`}</div>
                  <div className="id">
                    <span>{taskId}</span>
                  </div>
                </div>
                <div className="title">{title}</div>
                <div className="status">
                  <div>{`${t("FieldStatus")}:`}</div>
                  <div className="status-Closed">{t(status || "")}</div>
                </div>
              </div>
              <div>
                <span style={{ textAlign: "left" }}>{t("FieldDescription")}</span>
                <HBInput type="textArea" onChange={onDescriptionChanged} value={description} />
              </div>
            </Space>
          </Modal>
        )
      )}
      <ConfirmationModal
        visible={errorMessages.length > 0}
        confirmationType={errorType ?? "cardValidationErrors"}
        confirmOkClick={handleClearErrors}
        messages={errorMessages}
      />
      <ConfirmationModal
        visible={clonedTaskId !== undefined}
        confirmationType="cardValidationErrors"
        messages={[t("ActionClonedSuccessfullyNavigation")]}
        confirmOkClick={() => {
          setClonedTaskID(undefined);
          const taskUrl = constructTaskUrl({
            taskId: clonedTaskId,
            isMobile,
            todoListInParams: Boolean(queryParams.get("todolist")),
          });
          router.push(taskUrl);
        }}
      />
    </div>
  );
};
