import { useContext } from "react";
import { useSelector } from "react-redux";
import { ConfirmationModal } from "../../components/ConfirmationModal/ConfirmationModal";
import PageConfigurationContext from "../../context/pageContext";
import { useAppDispatch } from "../../store/store";
import { BaseEntityType } from "../../types/entityBase";
import { CategoryPage } from "../../types/page";

export default function CategoryErrorModal(): JSX.Element {
  const dispatch = useAppDispatch();
  const pageConfig = useContext(PageConfigurationContext);
  const { primaryErrorSelector, clearError } = pageConfig as CategoryPage<BaseEntityType>;
  const error = useSelector(primaryErrorSelector);
  return (
    <ConfirmationModal
      visible={!!error}
      confirmOkClick={() => dispatch(clearError())}
      confirmationType="notification"
      messages={["Error", error ?? "Please contact your administrator"]}
    />
  );
}
