import { BaseEntityType } from "./entityBase";
import { Inspection } from "./equipment";
import { File } from "./files";
import { Location } from "./location";
import {
  AdditionalProps,
  EmployeeOrgUnitRelations,
  ExplicitAdditionalProps,
  HistoryLog,
  PrivilegeData,
  Role,
  Status,
  TraineeStatus,
} from "./utility";

export enum TrainingStatus {
  Passed = "Passed",
  InProgress = "InProgress",
  Canceled = "Cancel",
  Planned = "Planned",
}

export type Training = {
  id: number;
  completedDate: string;
  plannedDate: string | null;
  description: string;
  supplier: string;
  status: TrainingStatus;
  locationId: number | null;
  locationName: string | null;
  externalId: string | null;
  certificateId: number;
  certificateName: string;
  customPropertyValues?: AdditionalProps[];
  attendedParticipantsCount?: number;
  participantsCount?: number;
  //todo: resolve difference between single view response and list view response models
  attendedParticipentsCount: number;
  participentsCount: number;
  employeeIds: number[] | null;
};

export type TrainingTraineeRequest = {
  trainingId: 0;
  status: TraineeStatus;
  employee: {
    // phone: string;
    externalId: string | null;
    status: Status;
    isExternal: boolean;
    id: number;
    name: string;
  };
};
export interface TrainingParticipantRequest extends EmployeeOrgUnitRelations {
  role: Role;
  trainingId: number | null;
}

export type TrainingTrainee = {
  externalId: string | undefined | null;
  status: TraineeStatus | null;
  id: number | string;
  name: string;
  isExternal: boolean | null;
  // phone: string;
  staging?: boolean; // Is this a 'new' record (still not saved)
  activeId?: number | string; // The ID of the active counterpart of a staged new entity
  isOrgUnit?: boolean;
  isEmployee?: boolean;
  trainingId: number | null;
};

export interface TrainingState {
  data: Training[];
  isLoading: boolean;
  singleData: Training | null | undefined;
  subData: {
    inspections: Inspection[];
    historyLog: HistoryLog[];
    participants: TrainingTrainee[];
    accountable: PrivilegeData[];
    files: File[];
  };
  searchResults: Training[];
  error: string | null;
  defaultCustomProperties: ExplicitAdditionalProps[];
  lastUpdated: string;
  paginationInfo: {
    currentPage: number;
    count: number;
  };
}

export type TrainingPaginatedData = {
  data: Training[];
  count: number;
};

export type TrainingPage = BaseEntityType & {
  entityData: {
    primaryData: Training;
  };
  subData: {
    inspections: Inspection[];
    historyLog: HistoryLog[];
    files: File[];
  };
  listViewData: Training;
  tabs: TrainingTrainee | Location | PrivilegeData | HistoryLog | File;
};
