import { Space } from "antd";
import React, { FC } from "react";
import { ReactComponent as Arrow } from "../../media/message-arrow.svg";

const NotAllowedComponent: FC = () => {
  return (
    <Space className="not-allowed-landingpage-container" direction="vertical" align="center">
      <Space size={1} direction="horizontal" align="baseline">
        <Arrow className="not-allowed-page-arrow" />
        <div className="not-allowed-title">
          {"The Landing Page you are trying to access not allowed for you.\n Please contact your System Admin"}
        </div>
      </Space>
    </Space>
  );
};

export default NotAllowedComponent;
