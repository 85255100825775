import { Dropdown, Menu, Row, Space, Typography } from "antd";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as DropdownIcon } from "../../../media/tableColumnActions/dropdown-arrow.svg";
import { FieldType, TOption } from "../../../types/page";
import "./HBDropdown.less";

type TProps = {
  onChange: (v: string | null | boolean | number) => void; // Change handler for the new value;
  value: string | null | boolean | number; // Current value;
  managedValue?: string | null | boolean | number;
  type: FieldType; // Type of input
  options: TOption[];
  conditionalFocus?: boolean;
  withHoverEffect?: boolean;
  icon?: string | null | React.ReactNode;
  title?: string | undefined; // Translatable name of the field (type/name/email/etc.)
  onCardEdited?: (value: boolean) => void;
};

// TODO: Review visibility functionality once API is ready (check if too much renders happen - remove/add functionality if needed)
const HBDropdown: React.FC<TProps> = ({
  onChange,
  value,
  managedValue,
  options,
  conditionalFocus,
  type,
  children,
  icon,
  withHoverEffect = false,
  title,
  onCardEdited,
}): JSX.Element => {
  const [inputValue, setInputValue] = useState(value);
  const [isVisible, setIsVisible] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  // Note: This is used only when the type is "boolean"
  const [boolOptionLabel, setBoolOptionLabel] = useState<string | undefined>();
  const { t } = useTranslation();
  const isWithHoverEffect = clsx(withHoverEffect && "HB-dropdown__value-withHoverEffect", "pointer");
  useEffect(() => {
    if (type === "boolean" || type === "Bool") {
      setBoolOptionLabel(options.find(o => o.id == inputValue)?.label);
    } else if (value) {
      const localizedValue = t(value.toString());
      setInputValue(localizedValue ?? "");
    }
  }, [value]);

  useEffect(() => {
    if ((type === "boolean" || type === "Bool") && typeof inputValue !== "boolean") {
      return;
    }
  }, [inputValue]);

  useEffect(() => {
    if (conditionalFocus) {
      setIsVisible(conditionalFocus);
    }
  }, [conditionalFocus]);

  const handleClick = (v: string | number | null | boolean) => () => {
    onCardEdited?.(true);
    if (type === "boolean" || type === "Bool") {
      handleBooleanType(v);
    } else {
      handleOtherTypes(v);
    }
    setIsVisible(false);
  };

  const handleBooleanType = (v: string | number | null | boolean) => {
    if (typeof v !== "boolean") {
      onChange(v);
    }
    if (type === "Bool") {
      setBoolOptionLabel(options.find(o => o.id == v)?.label);
    } else {
      setInputValue(v);
    }
  };

  const handleOtherTypes = (v: string | number | null | boolean) => {
    setInputValue(v);
    if (typeof v !== "boolean") {
      onChange(v);
    }
  };

  const onVisibleChange = (flag: boolean) => setIsVisible(flag);

  const optionItems = (
    <Menu className="HB-dropdown-menu">
      {options.map(o => (
        <Menu.Item key={o.id} onClick={handleClick(o.id)}>
          {o.icon && o.icon}
          <Typography.Text>{t(o.label)}</Typography.Text>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    // <Form.Item>
    <Row className="HB-dropdown-row">
      {title && <Typography.Text ellipsis title={title}>{`${title}: `}</Typography.Text>}
      <Row className={icon || children || type === "boolean" ? "HB-dropdown-wrapper" : ""}>
        {icon && icon}
        <Dropdown
          overlay={optionItems}
          open={isVisible}
          onOpenChange={onVisibleChange}
          trigger={["click"]}
          className="HB-dropdown"
        >
          <Row
            align="middle"
            className={isWithHoverEffect}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {withHoverEffect ? (
              <Space size={2}>
                <Typography.Text>
                  {t((boolOptionLabel || managedValue || inputValue)?.toString() || "")}
                </Typography.Text>
                {isHovered && <DropdownIcon />}
              </Space>
            ) : (
              <Space size={10}>
                <Typography.Text>
                  {t((boolOptionLabel || managedValue || inputValue)?.toString() || "")}
                </Typography.Text>
                {(icon || title) && <DropdownIcon />}
              </Space>
            )}
          </Row>
        </Dropdown>
      </Row>
    </Row>
  );
};

export default HBDropdown;
