import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { unwrapResult } from "@reduxjs/toolkit";
import { Button, Space, Spin } from "antd";
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { fetchTodoListSection } from "../../store/slices/todolist";
import { RootState, useAppDispatch } from "../../store/store";
import { TodoListAppointment, TodoListInpection, TodoListTask, TodoSection } from "../../types/todolist";
import useRouter from "../../utils/hooks/useRouter";
import AppointmentCard from "./AppointmentCard";
import InspectionCard from "./InspectionCard";
import TaskCard from "./TaskCard";
import TodoListSorter, { SortOrder } from "./TodoListSorter";
import useApplyFilters from "./useApplyFilters";
import { shouldShowFilteredTotal } from "./utils";

interface SortableItem {
  sortingDate: string;
}

interface Entites {
  tasks: TodoListTask[];
  appointments: TodoListAppointment[];
  inspections: TodoListInpection[];
}

const TodoListCollapse: React.FC<{
  count?: { total: number; totalFiltered: number };
  title: string;
  tasks: TodoListTask[];
  inspections: TodoListInpection[];
  appointments: TodoListAppointment[];
  isOpenedByDefault: boolean;
  section: TodoSection;
  actionsMode: boolean;
}> = ({ title, tasks, inspections, isOpenedByDefault, appointments, count, section, actionsMode }): JSX.Element => {
  const [isCollapseOpen, setCollapseOpenStatus] = useState<boolean>(isOpenedByDefault);
  const [entities, setEntities] = useState<Entites>({
    tasks: tasks,
    appointments: appointments,
    inspections: inspections,
  });

  useEffect(() => {
    sortItems(SortOrder.DESC, { tasks, inspections, appointments });
  }, [tasks, inspections, appointments]);

  const { total, totalFiltered } = count || {};
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const searchTerm = useSelector((state: RootState) => state.todoList.searchTerm);
  const todoListStatusFilter = useSelector((state: RootState) => state.todoList.todoListStatusFilter);
  const sectionIsLoading = useSelector((state: RootState) => state.todoList.sectionIsLoading);
  const applyFilters = useApplyFilters();

  const handleFetchSection = () => {
    dispatch(fetchTodoListSection({ section, actionsMode }))
      .then(res => unwrapResult(res))
      .then(data => {
        if (data) {
          applyFilters({});
        }
      });
  };

  const sortByDate = (array: SortableItem[], order: SortOrder): SortableItem[] =>
    array.sort((a, b) => {
      const aDate = new Date(a.sortingDate).getTime();
      const bDate = new Date(b.sortingDate).getTime();
      return order === SortOrder.ASC ? aDate - bDate : bDate - aDate;
    });

  const sortItems = (order: SortOrder, entities: Entites) => {
    const sortedTasks = sortByDate([...entities.tasks], order);
    const sortedInspections = sortByDate([...entities.inspections], order);
    const sortedAppointments = sortByDate([...entities.appointments], order);

    setEntities({
      appointments: sortedAppointments as TodoListAppointment[],
      tasks: sortedTasks as TodoListTask[],
      inspections: sortedInspections as TodoListInpection[],
    });
  };

  const handleSortItems = (order: SortOrder) => {
    sortItems(order, entities);
  };

  const onCollapseButtonClick = useCallback(() => {
    if (!isCollapseOpen) {
      handleFetchSection();
    }
    setCollapseOpenStatus(!isCollapseOpen);
  }, [searchTerm, isCollapseOpen, section]);

  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    handleFetchSection();
  }, [searchTerm]);

  return (
    <div className="todo-list-collapse">
      <div className="collapsible">
        <div style={{ display: "flex" }}>
          {`${t(title)} (${
            shouldShowFilteredTotal(searchTerm, todoListStatusFilter, isCollapseOpen) ? totalFiltered + "/" : ""
          }${total})`}
          {isCollapseOpen && !sectionIsLoading[section] && <TodoListSorter onSort={handleSortItems} />}
        </div>
        <Space size={0.5}>
          {sectionIsLoading[section] && <Spin spinning />}
          <Button
            onClick={onCollapseButtonClick}
            type="link"
            shape="round"
            icon={isCollapseOpen ? <MinusOutlined /> : <PlusOutlined />}
          />
        </Space>
      </div>

      <div className={`content ${isCollapseOpen && !sectionIsLoading[section] ? "" : "hidden"}`}>
        {entities.tasks.map((x: TodoListTask, i: number) => (
          <TaskCard key={i} task={x} t={t} />
        ))}
        {entities.appointments.map((x: TodoListAppointment, i: number) => (
          <AppointmentCard key={i} x={x} t={t} />
        ))}
        {entities.inspections.map((x: TodoListInpection, i: number) => (
          <InspectionCard x={x} key={i} />
        ))}
      </div>
    </div>
  );
};

export default TodoListCollapse;
