import { unwrapResult } from "@reduxjs/toolkit";
import { Form, Input, Button, Row } from "antd";
import { Location } from "history";
import debounce from "lodash/debounce";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { routPaths } from "../../router";
import { hbApi } from "../../store/api";
import { checkResetPassword, forgotPassword, login, loginTfa, resetPassword } from "../../store/slices/user";
import { RootState, useAppDispatch } from "../../store/store";
import { HBEventName } from "../../types/analyticsTypes/HBEvent";
import { ResetPasswordModel, NewPassAccess } from "../../types/user";
import { LocalStorageKeys } from "../../types/utility";
import useInitTrackEvents from "../../utils/hooks/useInitTrackEvents";
import useLocalStorage from "../../utils/hooks/useLocalStorage";
import useRouter from "../../utils/hooks/useRouter";

interface LoginProps {
  customReferer?: string;
  darkBackground?: boolean;
  notCentered?: boolean;
  setCustomTitle: (title: string) => void;
}

interface TfaInfo {
  temporaryJwt: string;
  tfaCode: string;
}

enum MessageType {
  Error = "error",
  Info = "info",
}

const Login = ({ customReferer, darkBackground, notCentered, setCustomTitle }: LoginProps): JSX.Element => {
  const [username, setUsername] = useLocalStorage<string>(LocalStorageKeys.Username);
  const [email, setEmail] = useLocalStorage<string>(LocalStorageKeys.Email);
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [resetPasswordInfo, setResetPasswordInfo] = useState<ResetPasswordModel | null>();
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [tfaInfo, setTfaInfo] = useState<TfaInfo>({ temporaryJwt: "", tfaCode: "" });
  const dispatch = useAppDispatch();
  const { history, location } = useRouter<{ referer: Location }>();
  const referer = customReferer || location.state?.referer || "/actions";
  const jwt = useSelector((state: RootState) => state.user.jwt);
  const { settings, companySettings } = useSelector((state: RootState) => state.user);

  const query = new URLSearchParams(location.search);
  const key = query.get("key");

  const { t } = useTranslation();
  const { track } = useInitTrackEvents();

  useEffect(() => {
    const debouncedTrack = debounce(() => {
      track({ eventName: HBEventName.UserEnteredUserame });
      track({ eventName: HBEventName.UserEnteredPassword });
    }, 1000);

    if (username && password) {
      debouncedTrack();
    }

    return () => {
      debouncedTrack.cancel();
    };
  }, [username, password]);

  useEffect(() => {
    setCustomTitle("LoginHeader");
  }, []);

  const [backToLoginMessage, setBackToLoginMessage] = useState<{ message: string; type: MessageType } | null>();

  useEffect(() => {
    if (jwt && !key && !resetPasswordInfo) {
      if (companySettings.newPassAccess == NewPassAccess.NewPasDisabled) {
        history.push(routPaths.redirectToLegacy);
      } else {
        const returnUrl = query.get("returnUrl");
        history.push(returnUrl ?? referer);
      }
    }
  }, [jwt, resetPasswordInfo]);

  useEffect(() => {
    if (key) {
      dispatch(checkResetPassword(key))
        .then(unwrapResult)
        .then(res => {
          if (res) {
            setCustomTitle("ResetPasswordTitle");
            setResetPasswordInfo({ key: key, password: "", repeatPassword: "" });
          } else {
            setBackToLoginMessage({ message: "ExpiredUrl", type: MessageType.Error });
          }
        })
        .catch(e => {
          if (e.userError) {
            setBackToLoginMessage({ message: e.userError, type: MessageType.Error });
          }
          console.error(e);
        })
        .finally(() => {
          query.delete("key");
          query.delete("userId");
          history.replace({ search: query.toString() });
        });
    }
  }, [key]);

  const handleLogin = (values: { username: string; password: string }) => {
    if (!jwt) {
      dispatch(login({ userName: values.username, password: values.password, rememberMe: true }))
        .then(unwrapResult)
        .then(originalPromiseResult => {
          if (originalPromiseResult.data.token && originalPromiseResult.data.status === "VerificationCodeSent") {
            track({ eventName: HBEventName.Required2FA });
            setTfaInfo({ temporaryJwt: originalPromiseResult.data.token, tfaCode: "" });
            setErrorMessage("");
          } else {
            track({ eventName: HBEventName.LoginSuccessful });
          }
        })
        .catch(({ userError, data }) => {
          track({ eventName: HBEventName.LoginFail });
          if (data?.status === "UserMustChangePassword") {
            setResetPasswordInfo({ key: data.resetPasswordKey });
            setCustomTitle && setCustomTitle("SetPasswordTitle");
          } else if (userError) {
            setErrorMessage(userError);
          }
        });
    }
  };

  const handleResetPassword = (values: { password: string; repeatPassword: string }) => {
    if (values.password.length < 6 || values.repeatPassword.length < 6) {
      setErrorMessage("RequiredLength");
      return;
    }
    if (values.password !== values.repeatPassword) {
      setErrorMessage("ErrorPasswordNotMatch");
      return;
    }

    if (resetPasswordInfo && values.password === values.repeatPassword) {
      dispatch(resetPassword(resetPasswordInfo))
        .then(unwrapResult)
        .then(e => {
          if (e.userError) {
            setErrorMessage(e.userError);
          } else {
            setResetPasswordInfo(null);
            setCustomTitle("LoginHeader");
            setErrorMessage("");
          }
        })
        .catch(e => {
          if (e.userError) {
            setErrorMessage(e.userError);
          }
          console.error(e);
        });
    }
  };

  const handleForgotPassword = async (values: { username: string; email: string }) => {
    try {
      await dispatch(forgotPassword(values))
        .then(unwrapResult)
        .then(res => {
          if (res) {
            setIsForgotPassword(false);
            setBackToLoginMessage({ message: "ForgotPasswordSuccess", type: MessageType.Info });
            setErrorMessage("");
          } else {
            setErrorMessage(t("ErrorInvalidEmail"));
          }
        });
    } catch (error) {
      console.error(error);
      setErrorMessage(t("ErrorInvalidEmail"));
    }
  };

  const handleTfaSubmit = () => {
    if (!jwt) {
      dispatch(loginTfa(tfaInfo))
        .then(unwrapResult)
        .then(x => {
          track({ eventName: HBEventName.Passed2FA });
          track({ eventName: HBEventName.LoginSuccessful });
        })
        .catch(e => {
          track({ eventName: HBEventName.Failed2FA });
          if (e.userError) {
            setErrorMessage(e.userError);
          }
          console.error(e);
        });
    }
  };

  const handleInputChange = (setInput: React.Dispatch<React.SetStateAction<string>>) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => setInput(e.target.value);

  const renderLoginForm = () => (
    <Form
      className="login-wrapper"
      name="basic"
      initialValues={{ username: username || "", remember: true }}
      onFinish={handleLogin}
    >
      <Form.Item name="username" className="login-input" data-testid="usernameInput">
        <Input
          placeholder={t("AuthTypeUsername")}
          value={username || ""}
          onChange={handleInputChange(setUsername)}
          data-testid="login-username-input"
        />
      </Form.Item>

      <Form.Item className="password-input" data-testid="passwordInput" name="password">
        <Input.Password
          placeholder={t("FieldPassword")}
          value={password}
          onChange={handleInputChange(setPassword)}
          data-testid="login-password-input"
        />
      </Form.Item>
      <div
        className={`forgot-password forgot-password-${settings.direction} ${darkBackground ? "login-text-white" : ""}`}
        data-testid="forgot-password-link"
      >
        <span
          onClick={() => {
            setErrorMessage("");
            setCustomTitle("ForgotPasswordTitle");
            setIsForgotPassword(true);
          }}
        >
          {t("ForgotPasswordTitle")}
        </span>
      </div>
      <div className="login-error-message" data-testid="login-error-message">
        {errorMessage.includes(" ") ? errorMessage : t(errorMessage)}
      </div>
      <Row justify="center">
        <Button
          type="primary"
          disabled={!username || !password}
          className="button-submit"
          htmlType="submit"
          data-testid="login-submit-button"
        >
          {t("LoginTitle")}
        </Button>
        {errorMessage && (
          <div className={`tech-message-info ${darkBackground && "login-text-white"}`}>{t("ContactTechMessage")}</div>
        )}
      </Row>
    </Form>
  );

  const renderTfaForm = () => (
    <Form className="login-wrapper" name="basic" initialValues={{ remember: true }} onFinish={handleTfaSubmit}>
      <div className={`message-info ${darkBackground && "login-text-white"}`}>{t("TFAHeader")}</div>
      <Form.Item className="login-input" data-testid="tfaCodeInput" name="tfaCode">
        <Input
          placeholder={t("HintTfa")}
          onChange={a => setTfaInfo(prev => ({ temporaryJwt: prev.temporaryJwt, tfaCode: a.target.value }))}
          data-testid="tfa-code-input"
        />
      </Form.Item>
      <div className="login-error-message" data-testid="login-error-message">
        {errorMessage.includes(" ") ? errorMessage : t(errorMessage)}
      </div>
      <Row justify="center">
        <Button
          type="primary"
          disabled={!tfaInfo.tfaCode}
          className="button-submit"
          htmlType="submit"
          data-testid="tfa-submit-button"
        >
          {t("LoginTitle")}
        </Button>
      </Row>
    </Form>
  );

  const renderResetPasswordForm = () => (
    <Form className="login-wrapper" name="basic" initialValues={{ remember: true }} onFinish={handleResetPassword}>
      <Form.Item className="password-input" data-testid="passwordInput" name="password">
        <Input.Password
          placeholder={t("FieldPassword")}
          value={resetPasswordInfo?.password}
          onChange={e => setResetPasswordInfo({ ...resetPasswordInfo, password: e.target.value })}
          data-testid="reset-password-input"
        />
      </Form.Item>

      <Form.Item className="password-input" data-testid="passwordInput" name="repeatPassword">
        <Input.Password
          placeholder={t("FieldRepeatPassword")}
          value={resetPasswordInfo?.repeatPassword}
          onChange={e => setResetPasswordInfo({ ...resetPasswordInfo, repeatPassword: e.target.value })}
          data-testid="reset-password-repeat-input"
        />
      </Form.Item>
      <div className="login-error-message" data-testid="reset-password-error-message">
        {errorMessage.includes(" ") ? errorMessage : t(errorMessage)}
      </div>
      <Row justify="center">
        <Button
          type="primary"
          className="button-submit"
          htmlType="submit"
          data-testid="reset-password-submit-button"
          disabled={!resetPasswordInfo?.password || !resetPasswordInfo?.repeatPassword}
        >
          {t("Reset")}
        </Button>
      </Row>
    </Form>
  );

  const renderBackToLoginScreen = () => (
    <div className="login-wrapper">
      <div
        className={`login-${backToLoginMessage?.type || MessageType.Error}-message`}
        data-testid="reset-password-error-message"
      >
        {t(backToLoginMessage?.message || "")}
      </div>

      <Button
        type="primary"
        onClick={() => {
          setIsForgotPassword(false);
          setCustomTitle("LoginHeader");
          setBackToLoginMessage(null);
          setResetPasswordInfo(null);
        }}
        className="button-submit"
        data-testid="back-to-login-button"
      >
        {t("LoginTitle")}
      </Button>
    </div>
  );

  const renderForgotPasswordForm = () => (
    <Form
      initialValues={{
        username: username || "",
        email: email || "",
        remember: true,
      }}
      name="basic"
      onFinish={handleForgotPassword}
    >
      <Button
        onClick={() => {
          setErrorMessage("");
          setIsForgotPassword(false);
          setCustomTitle("LoginHeader");
        }}
        className={`back-button-forgot  ${darkBackground && "login-text-white"}`}
        data-testid="forgot-password-back-button"
        type="text"
        shape="round"
      >
        {t("OtpBack")}
      </Button>
      <Row justify="center">
        <div className={`message-info ${darkBackground && "login-text-white"}`}>{t("ForgotPasswordInfo")}</div>
        <Form.Item name="username" className="login-input" data-testid="usernameInput">
          <Input
            placeholder={t("AuthTypeUsername")}
            value={username}
            onChange={handleInputChange(setUsername)}
            data-testid="forgot-password-username-input"
          />
        </Form.Item>

        <Form.Item
          className="login-input"
          data-testid="emailInput"
          name="email"
          rules={[
            {
              validator: (_, value) => {
                if (/^\S+@\S+\.\S+$/.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t("IncorrectEmailEntered")));
              },
            },
          ]}
        >
          <Input
            placeholder={t("FieldEmail")}
            value={email}
            onChange={handleInputChange(setEmail)}
            data-testid="forgot-password-email-input"
          />
        </Form.Item>
        <div className="login-error-message" data-testid="login-error-message">
          {errorMessage.includes(" ") ? errorMessage : t(errorMessage)}
        </div>

        <Button
          type="primary"
          className="button-submit"
          htmlType="submit"
          data-testid="forgot-password-submit-button"
          disabled={!username || !email}
        >
          {t("ResetPasswordTitle")}
        </Button>
        <div className={`tech-message-info ${darkBackground && "login-text-white"}`}>{t("ContactTechMessage")}</div>
      </Row>
    </Form>
  );

  return (
    <Row className="login-wrapper" justify={notCentered ? "start" : "center"} align="middle">
      {!backToLoginMessage && !isForgotPassword && !resetPasswordInfo && !tfaInfo.temporaryJwt && renderLoginForm()}
      {tfaInfo.temporaryJwt && renderTfaForm()}
      {resetPasswordInfo && renderResetPasswordForm()}
      {isForgotPassword && renderForgotPasswordForm()}
      {backToLoginMessage && renderBackToLoginScreen()}
    </Row>
  );
};

export default Login;
