import useResizeObserver from "@react-hook/resize-observer";
import { Button } from "antd";
import { useLayoutEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useSelector } from "react-redux";
import { getApiUrl } from "../../store/api";
import { RootState } from "../../store/store";
import "./pdfviewmodal.less";

pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.js";

type PDFViewProps = {
  fileId: string;
  hideButtons?: boolean;
};
type onDocumentLoadSuccessArgs = {
  numPages: number;
};

enum PDFActions {
  MINUS = "-",
  PLUS = "+",
  ACTION100 = "100%",
  ACTION150 = "150%",
  ACTION75 = "75%",
}

const useWidth = (target: React.RefObject<HTMLDivElement>) => {
  const [width, setWidth] = useState<number>();

  useLayoutEffect(() => {
    if (target.current) setWidth(target.current.getBoundingClientRect().width);
  }, [target]);

  useResizeObserver(target, (entry: ResizeObserverEntry) => setWidth(entry.contentRect.width));
  return width;
};

const PDFViewComponent = ({ fileId, hideButtons }: PDFViewProps): JSX.Element => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const wrapperDiv = useRef<HTMLDivElement>(null);
  const width = useWidth(wrapperDiv);
  const { jwt } = useSelector((state: RootState) => state.user);
  const [scale, setScale] = useState<number>(1);

  const handleScale = (action: PDFActions) => () => {
    if (action === PDFActions.PLUS) {
      setScale(scale + 0.1);
    }
    if (action === PDFActions.MINUS) {
      setScale(scale - 0.1);
    }
    if (action === PDFActions.ACTION150) {
      setScale(1.75);
    }
    if (action === PDFActions.ACTION100) {
      setScale(1.5);
    }
    if (action === PDFActions.ACTION75) {
      setScale(1);
    }
  };

  const onDocumentLoadSuccess = ({ numPages }: onDocumentLoadSuccessArgs) => {
    setNumPages(numPages);
  };

  return (
    <div ref={wrapperDiv}>
      {!hideButtons && (
        <div className="pdf-scale-buttons-group">
          <div>
            <Button
              key={PDFActions.MINUS}
              className="pdf-action-button"
              type="primary"
              shape="round"
              onClick={handleScale(PDFActions.MINUS)}
            >
              {PDFActions.MINUS}
            </Button>
            <Button
              key={PDFActions.PLUS}
              className="pdf-action-button"
              type="primary"
              shape="round"
              onClick={handleScale(PDFActions.PLUS)}
            >
              {PDFActions.PLUS}
            </Button>
            <Button
              key={PDFActions.ACTION150}
              className="pdf-action-button"
              type="primary"
              shape="round"
              onClick={handleScale(PDFActions.ACTION150)}
            >
              {PDFActions.ACTION150}
            </Button>
            <Button
              key={PDFActions.ACTION100}
              className="pdf-action-button"
              type="primary"
              shape="round"
              onClick={handleScale(PDFActions.ACTION100)}
            >
              {PDFActions.ACTION100}
            </Button>
            <Button
              key={PDFActions.ACTION75}
              className="pdf-action-button"
              type="primary"
              shape="round"
              onClick={handleScale(PDFActions.ACTION75)}
            >
              {PDFActions.ACTION75}
            </Button>
          </div>
        </div>
      )}
      <Document
        file={`${getApiUrl()}/file/${fileId}`}
        options={{
          httpHeaders: {
            Authorization: `Bearer ${jwt}`,
          },
        }}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {Array.from(new Array(numPages), (_, index) => (
          <Page scale={scale} key={`page_${index + 1}`} pageNumber={index + 1} width={width} />
        ))}
      </Document>
    </div>
  );
};

export default PDFViewComponent;
