import { Space, Popover } from "antd";
import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import ChartMenuItem, { ChartMenuItemProperties } from "./ChartMenuItem";

import "./ChartMenu.less";

type ChartMenuProps = {
  options: ChartMenuItemProperties[];
  icon?: ReactNode;
};

export default function ChartMenu({ options, icon }: ChartMenuProps) {
  const isSU = useSelector((state: RootState) => state.user.settings.isSysAdmin);
  const userRole = useSelector((state: RootState) => state.user.settings.role);
  const getMenuItems = () => {
    let filteredOptions = options;
    if (!isSU) {
      filteredOptions = options.filter(
        o => !o.allowedUserRoles || (o.allowedUserRoles && o.allowedUserRoles.includes(userRole))
      );
    }
    return filteredOptions.map(option => <ChartMenuItem {...option} key={option.key} />);
  };

  const getMenu = () => {
    return (
      <Space id="chartMenu" direction="vertical">
        {getMenuItems()}
      </Space>
    );
  };

  return (
    <Popover content={getMenu()} placement="bottomRight" arrowPointAtCenter trigger="click">
      <a>{icon}</a>
    </Popover>
  );
}
