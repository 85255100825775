import { CubeProvider } from "@cubejs-client/react";
import useCubeApi from "../../../utils/hooks/useCubeApi";

import ChartsTable from "./ChartsTable";

export default function ChartsDownloaderPage() {
  const cubejsApi = useCubeApi({ crossProjects: undefined });
  return (
    <CubeProvider cubejsApi={cubejsApi}>
      <ChartsTable />
    </CubeProvider>
  );
}
