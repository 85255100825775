import { LeftOutlined } from "@ant-design/icons";
import { unwrapResult } from "@reduxjs/toolkit";
import { Col, Form, FormInstance, Row, message } from "antd";
import clsx from "clsx";
import React, { useRef } from "react";
import { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Prompt } from "react-router-dom";
import PageConfigurationContext from "../../context/pageContext";
import { ReactComponent as EditIcon } from "../../media/edit-icon.svg";
import { userSelectors } from "../../selectors";
import { useAppDispatch } from "../../store/store";
import { HBEventName } from "../../types/analyticsTypes/HBEvent";
import { CustomPropertyType } from "../../types/customProperty";
import { BaseEntityType } from "../../types/entityBase";
import { CategoryPage, ChangesConfirmation, SectionType } from "../../types/page";
import { AdditionalProps, NoValueKey } from "../../types/utility";
import { isRoleAdminLevel } from "../../utils/functions";
import useInitTrackEvents from "../../utils/hooks/useInitTrackEvents";
import useRouter from "../../utils/hooks/useRouter";
import { useUIErrorHandling } from "../../utils/hooks/useUIErrorHandling";
import { ConfirmationModal } from "../ConfirmationModal/ConfirmationModal";
import useFormManagmentEvents, { MobileEvent } from "../EmbeddedModal/useFormManagmentEvents";
import { EditingButtons } from "../HBComponents/CreateTaskSwtich/EditingButtons";
import CustomFieldsSection from "./components/CustomFieldsSection";
import SingleViewHeader from "./components/HeaderComponent";
import Section from "./components/SectionComponent";
import { ModalInfo, TProps } from "./types";

import "./singleViewCardMobileFirst.less";

type SingleViewContextType = {
  currentData: any;
  isGlobalEditForbidden: boolean;
  canEditCard: boolean;
  setIsCardEdited: (value: React.SetStateAction<boolean>) => void;
  summaryCardIcon: {
    name: string;
    alt: string;
    init?: boolean | undefined;
  } | null;
  setSummaryCardIcon: React.Dispatch<
    React.SetStateAction<{
      name: string;
      alt: string;
      init?: boolean | undefined;
    } | null>
  >;
  renderValue: (value: (entity: Record<string, unknown>) => string | null | ReactNode) => React.ReactNode;
  handleChange: (
    id: string,
    changesConfirmation?: ChangesConfirmation | null,
    manuallyChanged?: boolean,
    cleanOnChange?: string[]
  ) => (newValue: string | null | boolean | number) => void;
  isEdited: boolean;
  handleSetActiveTab: (tabKey: string) => void;
  isNewEntity?: boolean;
  data: Record<string, unknown> | null | undefined;
  form: FormInstance<any>;
};

export const SingleViewContext = React.createContext<SingleViewContextType | undefined>(undefined);
const TODO_LIST_PATH = "/todolist?mobile=true";

const SingleViewCardMobileFirst = ({
  isNewEntity,
  data,
  handleSetActiveTab,
  isEdited,
  handleSetIsEdited,
}: TProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { fireMobileEvent, setFormManagmentRef } = useFormManagmentEvents();
  const singleViewRef = useRef(null);
  const { history, location } = useRouter<{ prefillData: Record<string, unknown> }>();
  const [currentData, setCurrentData] = useState<Record<string, unknown> | null | undefined>(null);
  const { summaryCard, createNewEntity, updateEntity, id } = useContext(
    PageConfigurationContext
  ) as CategoryPage<BaseEntityType>;
  const [isCardEdited, setIsCardEdited] = useState(!!isNewEntity);
  const [navigationModalVisibility, setNavigationModalVisiblity] = useState(false);
  const { errorMessages, setErrors, handleClearErrors } = useUIErrorHandling();
  const [isGlobalEditForbidden, setIsGlobalEditForbidden] = useState(false);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [modalInfo, setModalInfo] = useState<ModalInfo | null>(null);
  const [manuallyChangedFields, addManuallyChangedFields] = useState<string[]>([]);
  const [form] = Form.useForm();
  const [cardValidationErrors, setCardValidationErrors] = useState<string[]>([]);
  const queryParams = new URLSearchParams(location.search);
  const todoListInParams = queryParams.get("todolist");

  const onFinishFailed = ({ errorFields }: { errorFields: { name: (string | number)[]; errors: string[] }[] }) => {
    if (errorFields.length > 0) {
      const errors = errorFields.map(field => {
        return `${field.name.join(".")}: ${field.errors.join(", ")}`;
      });
      setCardValidationErrors(errors);
    }
  };
  const user = useSelector(userSelectors.getCurrentUser);
  const { track } = useInitTrackEvents();
  const [summaryCardIcon, setSummaryCardIcon] = useState<{
    name: string;
    alt: string;
    init?: boolean;
  } | null>({ name: "10.png", alt: "10", init: true });

  const canEditSelectedData = useSelector(summaryCard?.canEditSelector || (() => []));

  useEffect(() => {
    setFormManagmentRef(singleViewRef);
  }, []);

  useEffect(() => {
    handleSetIsEdited(isCardEdited);
  }, [isCardEdited]);

  useEffect(() => {
    const navigationWarning = summaryCard.navigationWarning;
    if (
      location?.state?.prefillData &&
      navigationWarning &&
      (!navigationWarning.showConditionally ||
        (navigationWarning.showConditionally && navigationWarning.showConditionally("test")))
    ) {
      return setModalInfo({
        body: navigationWarning.addValueToBody ? `${t(navigationWarning.body)}` : t(navigationWarning.body),
        okText: navigationWarning.addValueToOkText ? `${t(navigationWarning.okText)}` : t(navigationWarning.okText),
        cancelText: t(navigationWarning.cancelText),
        onConfirm: () => {
          setModalInfo(null);
          navigationWarning.navigateOnOk && history.push(navigationWarning.navigateOnOk);
        },
        onCancel: () => {
          setModalInfo(null);
          navigationWarning.navigateOnCancel && history.push(navigationWarning.navigateOnCancel);
        },
      });
    }
  }, [summaryCard.navigationWarning]);

  useEffect(() => {
    setCurrentData(data);
    const prefillData = location?.state?.prefillData;
    if (prefillData) {
      for (const property in prefillData) {
        setCurrentData(prevData => ({ ...prevData, [property]: prefillData[property] }));
      }
    }
  }, [data]);

  useEffect(() => {
    setIsGlobalEditForbidden(false);
    if (summaryCard.globalEditForbidden && data && !isNewEntity) {
      setIsGlobalEditForbidden(summaryCard.globalEditForbidden(data));
    }
  }, [summaryCard.globalEditForbidden, data]);

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    }
  }, [data]);

  const handleBlockedNavigation = () => {
    if (!confirmedNavigation && !navigationModalVisibility) {
      setNavigationModalVisiblity(true);
      return false;
    }

    return true;
  };

  const handleModalClose = () => {
    setNavigationModalVisiblity(false);
  };

  const onSubmitChanges = async ({ additionTrigger }: { additionTrigger?: boolean }) => {
    if (!currentData) {
      return;
    }

    if (isNewEntity && createNewEntity) {
      return dispatch(
        createNewEntity({
          entity: { ...currentData, iconType: summaryCardIcon?.alt || null },
          additionTrigger: additionTrigger,
        })
      )
        .then(unwrapResult)
        .then(originalPromiseResult => {
          track({ eventName: HBEventName.CreateNew, data: { entity: currentData } });
          setIsCardEdited(false);
          history.push(`../${id}/${originalPromiseResult.id}/mobile`);
        })
        .catch(customError => {
          setErrors(customError);
        });
    }

    if (updateEntity) {
      dispatch(updateEntity({ ...currentData, iconType: summaryCardIcon?.alt || null }))
        .then(unwrapResult)
        .then(originalPromiseResult => {
          track({ eventName: HBEventName.SaveEntityChanges, data: { entity: currentData } });
          setIsCardEdited(false);
        })
        .catch(customError => {
          setErrors(customError);
        });
    }
  };

  const renderValue = useCallback(
    (value: (entity: Record<string, unknown>) => string | null | ReactNode) => {
      if (!currentData) {
        return null;
      }
      return value(currentData);
    },
    [currentData]
  );

  const onModalClick = (
    id: string,
    manuallyChanged: boolean,
    newValue: string | null | boolean | number,
    confirmed: boolean
  ) => () => {
    setModalInfo(null);

    if (confirmed) {
      handleChange(id, null, manuallyChanged)(newValue);
    }
  };

  const handleChange = (
    id: string,
    changesConfirmation?: ChangesConfirmation | null,
    manuallyChanged = true,
    cleanOnChange?: string[]
  ) => (newValue: string | null | boolean | number) => {
    if (currentData && currentData[id] === newValue) return;
    if (!manuallyChangedFields.includes(id) && manuallyChanged) addManuallyChangedFields(oldArray => [...oldArray, id]);

    if (
      changesConfirmation &&
      (!changesConfirmation.showConditionally ||
        (changesConfirmation.showConditionally && changesConfirmation.showConditionally(newValue)))
    ) {
      return setModalInfo({
        body: changesConfirmation.addValueToBody
          ? `${t(changesConfirmation.body)} ${newValue}`
          : t(changesConfirmation.body),
        okText: changesConfirmation.addValueToOkText
          ? `${t(changesConfirmation.okText)} ${newValue}`
          : t(changesConfirmation.okText),
        cancelText: t(changesConfirmation.cancelText),
        onConfirm: onModalClick(id, manuallyChanged, newValue, true),
        onCancel: onModalClick(id, manuallyChanged, newValue, false),
      });
    }

    return setCurrentData(current => {
      const customProperties = current ? (current["customPropertyValues"] as AdditionalProps[]) : null;

      if (!current) {
        return current;
      }

      const keys = Object.keys(current);
      const additionalPropsKeys = customProperties?.map(property => property.name) || null;

      if (!keys.includes(id) && !additionalPropsKeys?.includes(id)) {
        return current;
      }

      if (current[id] === newValue || Number(customProperties?.find(prop => prop.name === id)?.value) === newValue) {
        return current;
      }

      // Note: Case where a custom prop was changed
      if (additionalPropsKeys?.includes(id)) {
        setIsCardEdited(true);
        track({ eventName: HBEventName.EnterEditMode, data: { entity: currentData } });
        return {
          ...current,
          customPropertyValues: customProperties?.map(prop => {
            if (prop.name === id) {
              return {
                ...prop,
                value:
                  prop.type === "Dictionary" ? (newValue === NoValueKey ? "" : newValue ? newValue : "") : newValue,
              };
            }
            return prop;
          }),
        };
      }
      // Note: Case where a static prop was changed
      setIsCardEdited(true);
      track({ eventName: HBEventName.EnterEditMode, data: { entity: currentData } });

      //specifically logic for Dictionary. Todo: improve that process
      if (
        (Object as any).values(CustomPropertyType).includes(current.type) &&
        (id === "type" || id === "dictionaryId")
      ) {
        return { ...current, [id]: newValue, defaultValue: null };
      }

      if (cleanOnChange?.length) {
        let currentToModify = JSON.parse(JSON.stringify(current));

        cleanOnChange.forEach(x => (currentToModify[x] = null));

        return {
          ...currentToModify,
          [id]: newValue,
        };
      }

      return { ...current, [id]: newValue };
    });
  };

  const handleDependentFieldsValues = (changedValues: Partial<typeof data>) => {
    const dependentFields =
      summaryCard.dependentFields &&
      currentData &&
      summaryCard.dependentFields({ ...currentData, ...changedValues }, isNewEntity, manuallyChangedFields);
    if (!dependentFields) {
      return;
    }

    for (const property in changedValues) {
      const currentProp = dependentFields.find(f => f.parentId === property);
      if (currentProp) {
        handleChange(currentProp.id, undefined, false)(currentProp.defaultValue);
        form.setFieldsValue({
          [currentProp.id]: currentProp.defaultValue,
        });
      }
    }
  };

  const canEditCard =
    (summaryCard.canEdit && summaryCard.canEdit(user, currentData, canEditSelectedData)) ||
    isRoleAdminLevel(user.settings.role);

  const summaryFields = useMemo(() => {
    return isCardEdited
      ? []
      : Object.values(summaryCard.mobileSingleView?.summary || {}).map(field => ({ id: field?.id }));
  }, [isCardEdited, summaryCard]);

  const sectionsFields = useMemo(() => {
    return summaryCard.mobileSingleView?.sections.flatMap(section => section.fields) || [];
  }, [summaryCard]);

  const allFieldsInUse = useMemo(() => {
    return [...sectionsFields, ...summaryFields];
  }, [sectionsFields, summaryFields]);

  const allFieldInUseIds = useMemo(() => {
    return new Set(allFieldsInUse.map(x => x && x.id));
  }, [allFieldsInUse]);

  const allFields = useMemo(() => {
    return [...summaryCard.primaryFields, ...summaryCard.secondaryFields];
  }, [summaryCard.primaryFields, summaryCard.secondaryFields]);

  const fields = useMemo(() => {
    return allFields.filter(
      x =>
        (!x.isHidden || (typeof x.isHidden === "function" && currentData && !x.isHidden(currentData))) &&
        !allFieldInUseIds.has(x.id)
    );
  }, [summaryCard, currentData, allFieldInUseIds]);

  const handleDiscard = () => {
    setCurrentData(data);
    setIsCardEdited(false);
    form.resetFields();
    window.scrollTo(0, 0);
    todoListInParams ? history.push(TODO_LIST_PATH) : fireMobileEvent(MobileEvent.SingleViewBackToSearchEvent, {});
  };

  const handleCancel = useCallback(() => {
    isCardEdited ? handleBlockedNavigation() : handleDiscard();
  }, [isCardEdited, form, data]);

  return (
    <div ref={singleViewRef} className={clsx("single-mobile-first", isEdited && "edit-mode")}>
      <SingleViewContext.Provider
        value={{
          currentData,
          canEditCard,
          isGlobalEditForbidden,
          renderValue,
          handleChange,
          isEdited,
          handleSetActiveTab,
          isNewEntity,
          data,
          setIsCardEdited,
          summaryCardIcon,
          setSummaryCardIcon,
          form,
        }}
      >
        <Prompt when={isCardEdited} message={handleBlockedNavigation}></Prompt>
        <ConfirmationModal
          visible={navigationModalVisibility}
          confirmationType="navigateAway"
          confirmOkClick={handleDiscard}
          cancelClick={handleModalClose}
        />
        <ConfirmationModal
          visible={errorMessages.length > 0}
          confirmationType="mobileFirstCardValidationErrors"
          confirmOkClick={handleClearErrors}
          messages={errorMessages}
        />
        <ConfirmationModal
          visible={cardValidationErrors.length > 0}
          confirmationType="mobileFirstCardValidationErrors"
          confirmOkClick={() => setCardValidationErrors([])}
          messages={cardValidationErrors}
        />
        <ConfirmationModal
          visible={modalInfo !== null}
          message={modalInfo?.body || ""}
          okText={modalInfo?.okText || ""}
          cancelText={modalInfo?.cancelText || ""}
          confirmOkClick={modalInfo?.onConfirm || (() => undefined)}
          cancelClick={modalInfo?.onCancel || (() => undefined)}
        />
        <Form
          form={form}
          layout="vertical"
          onFinish={onSubmitChanges}
          onFinishFailed={onFinishFailed}
          requiredMark={false}
          validateTrigger={["onChange"]}
          onValuesChange={handleDependentFieldsValues}
        >
          <Row>
            <Row className="full-width">
              <Col className="fields-section full-width">
                <div className="upper-section">
                  <div onClick={handleCancel} className="back">
                    <LeftOutlined />
                    {t(`${todoListInParams ? "MobileBackToAgenda" : "MobileBackToSearch"}`)}
                  </div>
                  {(!isEdited && summaryCard.actionComponent && !isNewEntity && currentData && (
                    <Row>{summaryCard.actionComponent(currentData)}</Row>
                  )) ||
                    null}
                </div>

                <SingleViewHeader
                  editIcon={
                    !isCardEdited && !isGlobalEditForbidden && canEditCard ? (
                      <EditIcon className="edit-icon" onClick={() => setIsCardEdited(true)} />
                    ) : (
                      <></>
                    )
                  }
                />

                <Section title={SectionType.DetailsSection} fields={fields} />
                {summaryCard.mobileSingleView?.sections.map((section, index) => (
                  <Section key={index} fields={section.fields} title={SectionType[section.type]} />
                ))}

                <CustomFieldsSection />
              </Col>
            </Row>
          </Row>
          {isCardEdited && (
            <div style={{ width: "100%" }}>
              <EditingButtons isEditing={isEdited} handleCancel={handleCancel} />
            </div>
          )}
        </Form>
      </SingleViewContext.Provider>
    </div>
  );
};

export default SingleViewCardMobileFirst;
