import i18n from "i18next";
import HttpApi from "i18next-http-backend";
import { getApiUrl } from "./store/api";

i18n.use(HttpApi).init({
  // we init with resources
  backend: {
    loadPath: `${getApiUrl()}/Localization/{{lng}}`,
  },

  fallbackLng: "en-US",
  load: "currentOnly",
  // debug: process.env.REACT_APP_ENVIRONMENT === "development",

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ",",
  },

  react: {
    useSuspense: false,
    // wait: true,
  },
});

export default i18n;
