import { SubMenuConfig, SubMenuConfigItem } from "./types";

// Breadth-First Search iterative method for finding an active SubMenu config
export function isActiveConfig(config: SubMenuConfig, pageId: string): boolean {
  if (!config) return false;

  const queue: SubMenuConfigItem[] = [];
  queue.push(...config.items);

  while (queue.length > 0) {
    const node = queue.shift();
    if (!node) continue;

    if (node.id === pageId) return true;

    if (node.items && node.items.length) {
      queue.push(...node.items);
    }
  }

  return false;
}

const subMenuConfig: SubMenuConfig[] = [
  {
    module: "employee",
    items: [
      {
        id: "employee",
        label: "PluginEmployeeName",
        items: [
          {
            id: "employeeCustomProperty",
            label: "PluginCustomPropName",
            isVisibleByAdminOnly: true,
            items: [],
          },
        ],
      },
      {
        id: "orgUnit",
        label: "PluginOrgUnitName",
        items: [],
      },
    ],
  },
  {
    module: "equipment",
    items: [
      {
        id: "equipment",
        label: "PluginEquipmentName",
        items: [
          {
            id: "equipmentType",
            label: "PluginTypeName",
            isVisibleByAdminOnly: true,
            items: [],
          },
          {
            id: "equipmentCustomProperty",
            label: "PluginCustomPropName",
            isVisibleByAdminOnly: true,
            items: [],
          },
        ],
      },
      {
        id: "inspectionEquipment",
        label: "PluginInspectionName",
        items: [
          {
            id: "inspectionEquipmentType",
            label: "PluginTypeName",
            isVisibleByAdminOnly: true,
            items: [],
          },
          {
            id: "inspectionEquipmentCustomProperty",
            label: "PluginCustomPropName",
            isVisibleByAdminOnly: true,
            items: [],
          },
        ],
      },
    ],
  },
  {
    module: "location",
    items: [
      {
        id: "location",
        label: "PluginLocationName",
        items: [
          {
            id: "locationType",
            label: "PluginTypeName",
            isVisibleByAdminOnly: true,
            items: [],
          },
          {
            id: "locationCustomProperty",
            label: "PluginCustomPropName",
            isVisibleByAdminOnly: true,
            items: [],
          },
        ],
      },
      {
        id: "inspectionLocation",
        label: "PluginInspectionName",
        items: [
          {
            id: "inspectionLocationType",
            label: "PluginTypeName",
            isVisibleByAdminOnly: true,
            items: [],
          },
          {
            id: "inspectionLocationCustomProperty",
            label: "PluginCustomPropName",
            isVisibleByAdminOnly: true,
            items: [],
          },
        ],
      },
    ],
  },
  {
    module: "training",
    items: [
      {
        id: "training",
        label: "PluginTrainingName",
        items: [
          {
            id: "trainingCustomProperty",
            label: "PluginCustomPropName",
            isVisibleByAdminOnly: true,
            items: [],
          },
        ],
      },
      {
        id: "certification",
        label: "PluginCertificationName",
        items: [],
      },
    ],
  },
  {
    module: "actions",
    items: [
      {
        id: "actions",
        label: "TitleActions",
        items: [],
      },
      {
        id: "issueType",
        label: "IssueTypeResults",
        items: [],
      },
      {
        id: "recurrentActions",
        label: "TitleRecurringPlanning",
        items: [],
      },
    ],
  },
];

export default subMenuConfig;
