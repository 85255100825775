import dayjs, { Dayjs } from "dayjs";
import { RangeValue } from "rc-picker/lib/interface";
import { useEffect, useRef, useState } from "react";
import { DatePicker } from "../DatePicker/HBDatePicker";

const { RangePicker } = DatePicker;

type TProps = {
  onChange: (v: [string | null, string | null] | null) => void;
  value?: [string | null, string | null] | null;
  allowEmptyValue?: boolean;
  renderExtraFooter?: () => React.ReactNode;
  ranges?: Record<string, [Dayjs, Dayjs]>;
};

const HBRangeDatePicker = ({ onChange, value, allowEmptyValue, renderExtraFooter, ranges }: TProps): JSX.Element => {
  const formattedFrom = value && value[0] && dayjs(value[0]).isValid() ? dayjs(value[0]) : null;
  const formattedTo = value && value[1] && dayjs(value[1]).isValid() ? dayjs(value[1]) : null;

  const [inputValue, setInputValue] = useState<RangeValue<Dayjs>>([formattedFrom, formattedTo]);

  const initialRender = useRef(true);

  useEffect(() => {
    if (value === undefined) {
      setInputValue(null);
    }
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      if (value === null) {
        setInputValue(null);
      }
    }
  }, [value]);

  useEffect(() => {
    if (value === undefined) {
      setInputValue(null);
    }
  }, [value]);

  useEffect(() => {
    if (!inputValue || ((!inputValue[0] || !inputValue[1]) && !allowEmptyValue)) {
      onChange(null);
      return;
    }

    const timezoneOffset = dayjs().utcOffset();

    const formatted = [
      inputValue[0]
        ? inputValue[0].subtract(timezoneOffset, "minute").startOf("day").toISOString()
        : value
        ? value[0]
        : null,
      inputValue[1]
        ? inputValue[1].subtract(timezoneOffset, "minute").endOf("day").toISOString()
        : value
        ? value[1]
        : null,
    ] as [string, string];

    if (
      (formatted[0] && !formattedFrom?.isSame(formatted[0])) ||
      (formatted[1] && !formattedTo?.isSame(formatted[1]))
    ) {
      onChange(formatted);
    }
  }, [inputValue]);

  const handleChange = (values: RangeValue<Dayjs>) => setInputValue(values);
  return (
    <RangePicker
      value={inputValue}
      allowEmpty={[true, true]}
      renderExtraFooter={renderExtraFooter}
      onChange={handleChange}
      format={"DD/MM/YYYY"}
      ranges={ranges}
    />
  );
};

export default HBRangeDatePicker;
