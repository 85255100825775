import Dashboard from "../../../components/Dashboard/Dashboard";
import { ReactComponent as DashboardLogo } from "../../../media/sidebar/dashboard-icon.svg";
import { dashboardSelectors, pageSelectors } from "../../../selectors";
import { addNewChart } from "../../../store/slices/dashboard";
import { DashboardPage } from "../../../types/dashboard";
import { CustomPage } from "../../../types/page";
import { UserRole } from "../../../types/user";

export const EditDashboard = {
  id: "editDashboard",
  title: "EditDashboardButton",
  isDataOperation: false,
};

export const ScheduleReport = {
  id: "scheduleReport",
  title: "ScheduleReportButton",
  isDataOperation: false,
};

export const ResetDashboardLayout = {
  id: "resetDashboardLayout",
  title: "ResetDashboardLayoutButton",
  isDataOperation: false,
};

export const ExportAllCharts = {
  id: "exportAllCharts",
  title: "ExportAllChartsButton",
  isDataOperation: false,
};

const dashboardConfigPage = (): CustomPage<DashboardPage> => ({
  id: "dashboard",
  title: "SmartDashboard",
  PageIcon: DashboardLogo,
  lastUpdatedSelector: dashboardSelectors.dashboardLastUpdatedText,
  addButton: {
    label: "AddChart",
    action: addNewChart,
    enablingFor(userState) {
      return userState.settings.role === UserRole.SU;
    },
  },
  defaultView: "custom",
  isLoading: pageSelectors.isDashboardPageLoading,
  actions: [EditDashboard, ResetDashboardLayout, ExportAllCharts, ScheduleReport],
  singleViewActions: [EditDashboard, ResetDashboardLayout, ExportAllCharts, ScheduleReport],
  CustomView: Dashboard,
  primarySingleEntitySelector: dashboardSelectors.single,
});

export default dashboardConfigPage;
