// import "@cubejs-client/playground/lib/antd.min.css";
import { CopyOutlined, SelectOutlined, SyncOutlined } from "@ant-design/icons";
import { Query } from "@cubejs-client/core";
import { QueryBuilder } from "@cubejs-client/playground";
import { VizState } from "@cubejs-client/react";
import { Button, Checkbox, Col, Input, message, Space, Switch, Tooltip } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { SwitchChangeEventHandler } from "antd/lib/switch";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { updateStagedChart } from "../../../../../store/slices/dashboard";
import { fetchCubeToken } from "../../../../../store/slices/user";
import { RootState, useAppDispatch } from "../../../../../store/store";
import { CUBEJS_API_URL } from "../../../../../utils/hooks/useCubeApi";
import { ChartContext } from "../../HBChart";

import "./EditChart.less";
import EditQuery from "./EditQuery";

export default function QueryConfiguration() {
  const { chart } = useContext(ChartContext);
  const { cubeToken } = useSelector((state: RootState) => state.user);
  const stagedChart = useSelector((state: RootState) => state.dashboard.stagedChart);
  const [apiGet, setApiGet] = useState<boolean>(chart?.apiId !== undefined);

  const getAPIUniqueId = () => {
    if (chart) {
      if (chart.apiId) {
        return chart.apiId;
      } else if (chart.uniqueApiId) {
        return chart.uniqueApiId;
      }
    }
  };

  const [apiUniqueId, setApiUniqueId] = useState(
    chart?.apiId ? chart.apiId : chart?.uniqueApiId ? chart.uniqueApiId : ""
  );
  const [saveButtonAlert, setSaveButtonAlert] = useState(false);
  const dispatch = useAppDispatch();
  const [vizState, setVizState] = useState<VizState>({ query: chart?.query });
  const [stagedQuery, setStagedQuery] = useState<string>(JSON.stringify(stagedChart?.query || {}, null, 2));
  const [editorView, setEditorView] = useState(false);

  const onVizStateChanged = (changedVizState: VizState) => {
    if (changedVizState) {
      if (
        !isEqual(changedVizState.query, vizState.query) ||
        (changedVizState.chartType && !isEqual(changedVizState.chartType, vizState.chartType))
      ) {
        setSaveButtonAlert(true);
      } else {
        setSaveButtonAlert(false);
      }
      setVizState(changedVizState);
    }
  };
  const updateQuery = () => {
    var newurl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      `?query=${JSON.stringify(stagedChart?.query)}`;
    window.history.pushState({ path: newurl }, "", newurl);
  };

  useEffect(updateQuery, [stagedChart]);

  const onClick: React.MouseEventHandler<HTMLElement> = event => {
    if (editorView) {
      try {
        let parsedQuery = JSON.parse(stagedQuery);
        if (stagedChart) {
          const updatedFilters = calculateFiltersOnQueryChange(parsedQuery);
          dispatch(
            updateStagedChart({
              ...stagedChart,
              query: parsedQuery,
              filters: updatedFilters,
            })
          );
          setVizState({ ...vizState, query: parsedQuery });
          setSaveButtonAlert(false);
        }
      } catch (e: any) {
        message.error(e.message);
      }
    } else {
      if (stagedChart) {
        const updatedFilters = calculateFiltersOnQueryChange(vizState.query || {});
        dispatch(
          updateStagedChart({
            ...stagedChart,
            query: vizState.query || {},
            pivotConfig: vizState.pivotConfig,
            filters: updatedFilters,
          })
        );

        setSaveButtonAlert(false);
      }
    }
  };

  const calculateFiltersOnQueryChange = (query: Query) => {
    if (query && stagedChart) {
      let updatedFilters = cloneDeep(stagedChart?.filters);
      const timeDimension = query.timeDimensions ? query.timeDimensions[0] : null;
      if (timeDimension && updatedFilters) {
        Object.entries(stagedChart!.filters!).forEach(([k, v]) => {
          const splitted = k.split("-");
          if (splitted[1] === "0" && splitted[0] !== timeDimension.dimension && updatedFilters) {
            updatedFilters[`${timeDimension.dimension}-0`] = updatedFilters[k];
            delete updatedFilters[k];
          } else if (splitted[1] === "1" && splitted[0] !== timeDimension.dimension && updatedFilters) {
            updatedFilters[`${timeDimension.dimension}-1`] = updatedFilters[k];
            delete updatedFilters[k];
          }
        });
        return updatedFilters;
      }
    }
  };

  const onUpdateQuery = (query: string) => {
    setStagedQuery(query);
  };

  const onAllowApiGet: (e: CheckboxChangeEvent) => void = e => {
    setApiGet(e.target.checked);
  };
  const refreshToken: React.MouseEventHandler<HTMLElement> = event => {
    dispatch(fetchCubeToken());
  };
  const onSwitchQueryEditView: SwitchChangeEventHandler = (checked, event) => {
    setEditorView(checked);
    if (!checked) {
      updateQuery();
    }
  };

  return (
    <Col>
      <div className="queryEditorContainer">
        <div className="queryEditorHeader">
          <Space>
            <Switch checkedChildren="JSON" unCheckedChildren="Builder" onChange={onSwitchQueryEditView} />
            <Checkbox checked={apiGet} onChange={onAllowApiGet} />
            <div>API ID</div>
            <div>
              <Input
                className="queryEditorCopy"
                disabled
                defaultValue={apiGet ? apiUniqueId : ""}
                value={apiUniqueId}
              />
              <Tooltip title="copy">
                <Button disabled={!apiGet} icon={<CopyOutlined />} />
              </Tooltip>
            </div>
          </Space>
        </div>
        <div className="queryEditorButtons">
          <Button className="queryEditorConfigSelectButton" onClick={onClick} danger={saveButtonAlert} shape="circle">
            <Tooltip title="Select This Configuration">
              <SelectOutlined />
            </Tooltip>
          </Button>
          <Button shape="circle" title="refreshToken" onClick={refreshToken}>
            <Tooltip title="Refresh Cube Token">
              <SyncOutlined />
            </Tooltip>
          </Button>
        </div>
      </div>
      {editorView ? (
        <EditQuery onUpdateQuery={onUpdateQuery} query={JSON.stringify(stagedChart?.query, null, 2)} />
      ) : (
        <QueryBuilder
          apiUrl={CUBEJS_API_URL}
          token={cubeToken}
          onTokenPayloadChange={() => Promise.resolve<string>("tokenChanged")}
          onVizStateChanged={onVizStateChanged}
        />
      )}
    </Col>
  );
}
