import { Tabs, Layout as AntDLayout } from "antd";
import Layout from "../../pages/Layout/Layout";
import ChartsDownloaderPage from "./ChartsDownloader/ChartsDownloaderPage";
import DimensionBuilderPage from "./CubeDimensionBuilder/DimensionBuilderPage";
import CubePlayground from "./CubePlayground/CubePlayground";

const { TabPane } = Tabs;
const { Header } = AntDLayout;

enum CubePage {
  Playground = "Playground",
  DimensionsManager = "Dimensions Manager",
  ChartsDownloader = "Charts Downloader",
}

export default function CubeConfigurationPage() {
  const onTabSelected = () => {
    return;
  };

  return (
    <Layout isSideBarOnly={true} entityKey="cube">
      <div style={{ margin: "1rem", height: "100%" }}>
        <Header className="layout__header">
          <Tabs destroyInactiveTabPane defaultActiveKey={CubePage.Playground} onChange={onTabSelected}>
            <TabPane tab={CubePage.Playground} key={CubePage.Playground}>
              <CubePlayground />
            </TabPane>
            <TabPane tab={CubePage.DimensionsManager} key={CubePage.DimensionsManager}>
              <DimensionBuilderPage />
            </TabPane>
            <TabPane tab={CubePage.ChartsDownloader} key={CubePage.ChartsDownloader}>
              <ChartsDownloaderPage />
            </TabPane>
          </Tabs>
        </Header>
      </div>
    </Layout>
  );
}
