import { unwrapResult } from "@reduxjs/toolkit";
import { Spin } from "antd";
import { Location } from "history";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getRedirectToLegacy } from "../../store/slices/user";
import { RootState, useAppDispatch } from "../../store/store";
import useRouter from "../../utils/hooks/useRouter";

export default (): JSX.Element => {
  const { location } = useRouter<{ referer: Location }>();
  const urlSearchParams = new URLSearchParams(location.search);
  const dispatch = useAppDispatch();
  const returnUrl = urlSearchParams.get("returnUrl") ?? "actions";
  const lang = useSelector((state: RootState) => state.user.settings.lang);
  const jwt = useSelector((state: RootState) => state.user.jwt);

  useEffect(() => {
    if (jwt) {
      dispatch(getRedirectToLegacy({}))
        .then(res => unwrapResult(res))
        .then(data => {
          const vitreUrl = `${
            process.env.REACT_APP_VITRE_URL
          }/localRedirect?page=${returnUrl}&language=${lang}&key=${new URLSearchParams(
            new URL(data.returnUrl).search
          ).get("Key")}`;

          window.location.href = vitreUrl;
        })
        .catch(e => {
          console.error(e);
        });
    } else {
      window.location.href = `${process.env.REACT_APP_VITRE_URL}/login?returnUrl=${returnUrl}`;
    }
  }, [jwt]);

  return (
    <div className="full-login-container" style={{ height: "100vh" }}>
      <Spin />
    </div>
  );
};
