import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

export const USE_PAS_PRO_KEY = "usePASPRO";

const usePasProFlag = (): boolean => {
  const enablePasPro = useSelector((state: RootState) => state.user.companySettings.enablePasPro);
  const usePASPro = localStorage.getItem(USE_PAS_PRO_KEY) === "true";

  return enablePasPro || usePASPro;
};

export default usePasProFlag;
