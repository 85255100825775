import { Input, Button, Row } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./sso.less";
import { getApiUrl } from "../../store/api";
import { HBEventName } from "../../types/analyticsTypes/HBEvent";
import { LocalStorageKeys, ParamsKeys } from "../../types/utility";
import useInitTrackEvents from "../../utils/hooks/useInitTrackEvents";
import useLocalStorage from "../../utils/hooks/useLocalStorage";

const SSO = (): JSX.Element => {
  const [provider, setProvider] = useLocalStorage<string>(LocalStorageKeys.SSO);
  const { t } = useTranslation();
  const { track } = useInitTrackEvents();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const urlSearchParams = new URLSearchParams(location.search);

  const ssoNotFound = Boolean(
    urlSearchParams.get(ParamsKeys.SSO_NOT_FOUND) || urlSearchParams.get(ParamsKeys.SSO_USER_NOT_FOUND)
  );

  useEffect(() => {
    track({ eventName: HBEventName.SSOInititated });
    if (ssoNotFound) {
      setErrorMessage("ErrorContactAdmin");
    }
  }, []);

  const handleSubmitProvider = () => {
    window.location.href = `${getApiUrl()}/Account/${provider}`;
  };
  return (
    <Row align="middle">
      <div className="sso-container">
        <Input
          className="sso-input"
          value={provider}
          placeholder={t("FieldSSOCode")}
          onChange={a => setProvider(a.target.value)}
        />
        <div className="login-error-message" data-testid="sso-error-message">
          {t(errorMessage)}
        </div>
        <Button disabled={provider ? false : true} className="sso-submit" onClick={handleSubmitProvider}>
          {t("LoginTitle")}
        </Button>
      </div>
    </Row>
  );
};

export default SSO;
