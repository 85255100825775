import { CustomProperty, CustomPropertyType } from "../../../types/customProperty";
import { ColumnType, TOption } from "../../../types/page";
import { localizeText, renderBoolStatus } from "./utilities";

export const customPropertyTypeOptions: TOption[] = [
  {
    id: CustomPropertyType.Bool,
    label: "Bool",
  },
  {
    id: CustomPropertyType.Dictionary,
    label: "Dictionary",
  },
  {
    id: CustomPropertyType.Numeric,
    label: "Numeric",
  },
  {
    id: CustomPropertyType.String,
    label: "String",
  },
];

export const customPropertyTableColumns: ColumnType<CustomProperty>[] = [
  {
    id: "id",
    label: "ID",
    renderValue: entity => entity.id.toString(),
    sortable: false,
    optionsSelector: () => null,
    // hidden: true,
  },
  {
    id: "name",
    label: "FieldName",
    renderValue: entity => entity.name,
    optionsSelector: () => null,
    sortable: true,
  },
  {
    id: "sortOrder",
    label: "FieldSortOrder",
    renderValue: entity => entity.sortOrder?.toString(),
    optionsSelector: () => null,
    sortable: true,
  },
  {
    id: "defaultValue",
    label: "FieldDefaultValue",
    renderValue: entity => entity.defaultValue || "",
    optionsSelector: () => null,
    sortable: true,
  },
  {
    id: "actAsIdentifier",
    label: "FieldActAsIdentifier",
    renderValue: entity => renderBoolStatus(entity.actAsIdentifier),
    optionsSelector: () => null,
    sortable: true,
  },
  {
    id: "type",
    label: "FieldType",
    renderValue: entity => (entity.type ? localizeText(entity.type.toString()) : ""),
    optionsSelector: () =>
      customPropertyTypeOptions.map(option => ({
        id: option.id,
        label: localizeText(option.label),
      })),
    sortable: true,
  },
  {
    id: "dictionaryId",
    label: "FieldDictionaryId",
    renderValue: entity => entity.dictionaryId?.toString() || "",
    optionsSelector: () => null,
    sortable: true,
  },
];
