import { ConfigProvider } from "antd";
import { Suspense, useEffect, useState } from "react";
import { I18nextProvider } from "react-i18next";
import { useSelector } from "react-redux";
import FormManagmentModal from "./components/EmbeddedModal/FormManagmentModal";
import i18n from "./i18n";
import { ReactComponent as PasIcon } from "./media/pas-icon.svg";
import { isDevEnvironment } from "./pages/pageConfig/category/utilities";
import Router, { routPaths } from "./router";
import { fetchFullCertifications } from "./store/slices/certification";
import { fetchCompanyDictionaries } from "./store/slices/customProperty";
import { fetchFullEmployees } from "./store/slices/employee";
import { fetchFullEquipments } from "./store/slices/equipment";
import { fetchInspectionTypes } from "./store/slices/inspectionType";
import { fetchFullLocations } from "./store/slices/location";
import { fetchFullOrgUnits } from "./store/slices/orgUnit";
import { fetchUserContext, setAuthToken } from "./store/slices/user";
import { RootState, useAppDispatch } from "./store/store";
import { HBEventName } from "./types/analyticsTypes/HBEvent";
import { ExludedPages, LocalStorageKeys } from "./types/utility";
import useCompanyColors from "./utils/hooks/useCompanyColors";
import useInitAppUserSettings from "./utils/hooks/useInitAppUserSettings";
import useInitTrackEvents from "./utils/hooks/useInitTrackEvents";
import useInitUserTrackProperties from "./utils/hooks/useInitUserTrackProperties";
import useRouter from "./utils/hooks/useRouter";

import "./App.less";
export default function App() {
  const { locale, direction } = useInitAppUserSettings();
  const { track, Track } = useInitTrackEvents();
  useInitUserTrackProperties();
  const dispatch = useAppDispatch();
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(true);
  const jwt = useSelector((state: RootState) => state.user.jwt);
  const { loading: colorsLoading } = useCompanyColors();
  const { pathname } = useRouter();
  //remove after vitre migration
  const isVitreOrigin = window.location.origin.includes(`${process.env.REACT_APP_NEW_UI_ORIGIN_PART}`);
  const isExcludedPage = new RegExp(Object.values(ExludedPages).join("|")).test(pathname.toLocaleLowerCase());

  useEffect(() => {
    const storedJwt = localStorage.getItem(LocalStorageKeys.JWT);
    if (storedJwt && !jwt) {
      dispatch(setAuthToken(storedJwt));
      dispatch(fetchUserContext({ token: storedJwt }));
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [jwt]);

  useEffect(() => {
    // TODO: Instead of fetching the most frequently used entities on app load - specify the exact depndencies(entities) each of them needs and fetch them on load (use config files)
    if (jwt !== "" && !isExcludedPage && (isVitreOrigin || isDevEnvironment())) {
      dispatch(fetchCompanyDictionaries());
      dispatch(fetchFullEmployees());
      dispatch(fetchFullLocations());
      dispatch(fetchFullOrgUnits());
      dispatch(fetchFullCertifications());
      dispatch(fetchFullEquipments());
      dispatch(fetchInspectionTypes());
    }
  }, [jwt, isExcludedPage]);

  useEffect(() => {
    track({ eventName: HBEventName.AppLoad });
  }, []);

  if (
    isLoggedIn &&
    colorsLoading &&
    ![routPaths.login, routPaths.localRedirect, routPaths.landingPage].some(str => pathname.includes(str.split("/")[1]))
  ) {
    return (
      <div className="pas-icon-container">
        <PasIcon />
      </div>
    );
  }

  return (
    <Track>
      <ConfigProvider direction={direction} locale={locale}>
        <I18nextProvider i18n={i18n}>
          <FormManagmentModal />
          <Suspense fallback={<div />}>
            <Router />
          </Suspense>
        </I18nextProvider>
      </ConfigProvider>
    </Track>
  );
}
