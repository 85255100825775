import { Table, TableProps } from "antd";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { useVT } from "virtualizedtableforantd4";
import PageConfigurationContext from "../../../context/pageContext";
import { RootState, useAppDispatch } from "../../../store/store";
import { BaseEntityType } from "../../../types/entityBase";
import { CategoryPage } from "../../../types/page";

/**
 * Create an Ant table that is wrapped inside ResizeObserver
 * @param {RecordType} props takes Table props
 * @returns Table Component
 */
function VirtualListAntd<RecordType extends Record<string, unknown>>(props: TableProps<RecordType>) {
  const pageConfig = useContext(PageConfigurationContext) as CategoryPage<BaseEntityType>;
  const defaultPageSize = useSelector((state: RootState) => state.user.companySettings?.defaultPageSize);
  const { columns, scroll, dataSource } = props;
  const dispatch = useAppDispatch();
  const tableWidth = 0;
  // create a virtual table with specific height
  const [vt] = useVT(
    () => ({
      scroll: { y: "100%" },
      overscanRowCount: 0,
      onScroll: ({ isEnd, top }) => {
        if (dataSource?.length && dataSource?.length > 0 && isEnd && pageConfig.listView?.table.fetchPaginatedData) {
          dispatch(
            pageConfig.listView?.table.fetchPaginatedData({
              forceUpdate: false,
              pageSize: defaultPageSize,
            })
          );
        }
      },
      offset: 100,
    }),
    [pageConfig, dataSource]
  );

  const totalHeight = (dataSource?.length || 1) * 54; // cell height is 54

  // each column is assigned the width prop
  // the column width is set to be the equal size, except the last column
  const width = Math.floor(tableWidth / (columns?.length || 1));
  const mergedColumns = columns?.map((column, index) => ({
    ...column,
    // width
    width: totalHeight > (scroll?.y as number) && index === columns.length - 1 ? Math.max(0, width - 25) : width,
  }));

  return (
    <Table
      {...props}
      columns={mergedColumns}
      dataSource={dataSource}
      pagination={false}
      components={vt} // render the whole virtual table
    />
  );
}

export default VirtualListAntd;
