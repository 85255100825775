export enum JoinBy {
  Category = "Category",
  Appointment = "Appointment",
  Group = "Group",
}

export type DimensionJoin = {
  dimensionId?: number;
  joinedDimensionId?: number;
  joinBy: JoinBy;
};

export type CubeDimension = {
  id: number;
  name: string;
  joins: DimensionJoin[];
  questionIds: number[];
};

export interface CubeDimensionsState {
  dimensions: CubeDimension[];
  isLoading: boolean;
}

export type TDimensionResponse = CubeDimension[];
