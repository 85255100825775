import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import {
  InspectionTypeState,
  InspectionType,
  InspectionTypeSingleView,
  InspectionRecurrencyType,
  InspectionOwnershipType,
} from "../../types/inspectionType";
import { ExplicitAdditionalProps } from "../../types/utility";
import { hbApi, hbApiOptions } from "../api";
import { RootState } from "../store";
import { shouldUpdate } from "./common";

export const initialState: InspectionTypeState = {
  data: [],
  defaultCustomProperties: [],
  subData: {},
  isLoading: false,
  singleData: null,
  error: null,
  lastUpdated: dayjs().toISOString(),
};

const newInspectionType: InspectionType = {
  id: 0,
  name: "",
  recurrency: 0,
  recurrencyType: InspectionRecurrencyType.Daily,
  isReviewRequired: false,
  ownershipType: InspectionOwnershipType.EquipmentOwner,
  orgUnitId: null,
  reviewId: null,
  userId: null,
  iconType: "10",
};

export const fetchInspectionTypes = createAsyncThunk<
  { primaryData: InspectionType[]; defaultCustomProperties: ExplicitAdditionalProps[] },
  boolean | undefined,
  { state: RootState }
>(
  "@@INSPECTION_TYPE/FETCH",
  async (_, { getState }) => {
    const { user } = getState();
    const inspectionTypesResponse = await hbApi.get<InspectionType[]>("/InspectionType", hbApiOptions(user.jwt));

    return {
      primaryData: inspectionTypesResponse.data,
      defaultCustomProperties: [],
    };
  },
  {
    condition: (forceUpdate, { getState }) => {
      const { inspectionType } = getState();
      return shouldUpdate(inspectionType.lastUpdated, inspectionType.isLoading, forceUpdate);
    },
  }
);

export const fetchSingleInspectionType = createAsyncThunk<
  {
    singleData: InspectionTypeSingleView;
    subData: Record<string, never>;
    defaultCustomProperties: ExplicitAdditionalProps[];
  },
  string,
  { state: RootState }
>(
  "@@INSPECTION_TYPE/FETCH_SINGLE",
  async (id, { getState }) => {
    const { user } = getState();

    const inspectionType = await hbApi.get<InspectionTypeSingleView>(`/InspectionType/${id}`, hbApiOptions(user.jwt));

    return {
      singleData: inspectionType.data,
      subData: {},
      defaultCustomProperties: [],
    };
  },
  {
    condition: (_, { getState }) => {
      const { user } = getState();
      return !!user.jwt;
    },
  }
);

export const addInspectionType = createAsyncThunk<InspectionType, { entity: InspectionType }, { state: RootState }>(
  "@@INSPECTION_TYPE/ADD",
  async ({ entity }, { getState }) => {
    const { user } = getState();

    const response = await hbApi.post<InspectionType>("/InspectionType", entity, hbApiOptions(user.jwt));
    return response.data;
  },
  {
    condition: (_, { getState }) => {
      const { inspectionType, user } = getState();
      return !inspectionType.isLoading && !!user.jwt;
    },
  }
);

export const updateInspectionType = createAsyncThunk<InspectionType, InspectionType, { state: RootState }>(
  "@@INSPECTION_TYPE/UPDATE",
  async (entity, { getState }) => {
    const { user } = getState();
    const response = await hbApi.put<InspectionType>(`/InspectionType/${entity.id}`, entity, hbApiOptions(user.jwt));
    return response.data;
  },
  {
    condition: (_, { getState }) => {
      const { inspectionType, user } = getState();
      return !inspectionType.isLoading && !!user.jwt;
    },
  }
);

const slice = createSlice({
  name: "inspectionType",
  initialState,
  reducers: {
    createInspectionTypeTemplate: state => {
      state.singleData = newInspectionType;
    },
    clearInspectionTypeError: state => {
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchInspectionTypes.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSingleInspectionType.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addInspectionType.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateInspectionType.pending, state => {
        state.isLoading = true;
        state.error = null;
      })

      .addCase(fetchInspectionTypes.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(fetchSingleInspectionType.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(addInspectionType.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(updateInspectionType.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })

      .addCase(fetchInspectionTypes.fulfilled, (state, action) => {
        state.data = action.payload.primaryData;
        state.defaultCustomProperties = action.payload.defaultCustomProperties;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchSingleInspectionType.fulfilled, (state, action) => {
        state.singleData = action.payload.singleData;
        state.defaultCustomProperties = action.payload.defaultCustomProperties;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(addInspectionType.fulfilled, (state, action) => {
        state.singleData = action.payload;
        state.data = state.data ? [...state.data, action.payload] : [action.payload];
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateInspectionType.fulfilled, (state, action) => {
        state.singleData = action.payload;
        const updatedEntityIndex = state.data.findIndex(et => et.id === action.payload.id);
        state.data[updatedEntityIndex] = action.payload;
        state.isLoading = false;
        state.error = null;
      });
  },
});

export const { createInspectionTypeTemplate, clearInspectionTypeError } = slice.actions;
export default slice.reducer;
