import { LeftOutlined, RightOutlined, SearchOutlined, SyncOutlined } from "@ant-design/icons";
import { unwrapResult } from "@reduxjs/toolkit";
import { Spin, Input, DatePicker, Empty, Space, Button, Affix } from "antd";
import dayjs from "dayjs";
import moment, { Moment } from "moment";
import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import "./todolist.less";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { antdLocales } from "../../locales";
import { ReactComponent as OnlyMyIcon } from "../../media/agenda-only-my-icon.svg";
import { ReactComponent as ViewedByMeIcon } from "../../media/agenda-viewed-by-me-icon.svg";
import { fetchFullEmployees } from "../../store/slices/employee";
import {
  fetchInitialTodoList,
  fetchTodoListDate,
  filterTodolistByView,
  updateProcessingTask,
} from "../../store/slices/todolist";
import { RootState, useAppDispatch } from "../../store/store";
import { HBEventName } from "../../types/analyticsTypes/HBEvent";
import {
  TodoListSectioned,
  TodoListStatusFilter,
  TodoListViewFilter,
  TodoSection,
  TodoTotal,
} from "../../types/todolist";
import useDebounce from "../../utils/hooks/useDebounce";
import useInitTrackEvents from "../../utils/hooks/useInitTrackEvents";
import useIsMobile from "../../utils/hooks/useIsMobile";
import useMobilePullToRefreshData from "../../utils/hooks/useMobilePullToRefreshData";
import TodoListCollapse from "./TodoListCollapse";
import useApplyFilters from "./useApplyFilters";
import { getFormattedDate, getFormattedTime, serverDateFormat, todoDateFormat } from "./utils";

export type TodoListRefreshEventPayload = {
  actionId: string;
};

export type TodoListRefreshEvent = CustomEvent<TodoListRefreshEventPayload>;

export default function TodoListComponent() {
  const dispatch = useAppDispatch();
  const primaryColor = useSelector((state: RootState) => state.common.primaryColor);
  const { track } = useInitTrackEvents();
  const jwt = useSelector((state: RootState) => state.user.jwt);
  const direction = useSelector((state: RootState) => state.user.settings.direction);
  const locale = useSelector((state: RootState) => state.user.settings.lang);
  const userIsLoading = useSelector((state: RootState) => state.user.isLoading);
  const { filteredTodoList, isLoading, total, totalFiltered, todoListStatusFilter, todoListViewFilter } = useSelector(
    (state: RootState) => state.todoList
  );

  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const urlSearchParams = new URLSearchParams(location.search);

  const actionsMode = useSelector((state: RootState) => {
    const settingnActionsMode = state.user.companySettings.agendaActionsMode;
    if (settingnActionsMode) {
      return settingnActionsMode;
    } else {
      return Boolean(urlSearchParams.get("actionsMode"));
    }
  });
  const [datePickerVissible, setDatePickerVissible] = useState<boolean>(false);
  const [datePickerLable, setDatePickerLable] = useState<string>("Today");
  const [selectedDate, setSelectedDate] = useState<Moment>(moment());
  const [isToday, setIsToday] = useState<boolean>(true);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const applyFilters = useApplyFilters();

  useEffect(() => {
    if (jwt) {
      handleFetchInitialTodoList();
      if (isMobile) {
        dispatch(fetchFullEmployees());
      }
    }
  }, [jwt]);

  const firstUpdate = useRef(true);

  useLayoutEffect(() => {
    handleFetchTodoList();
  }, [selectedDate]);

  useEffect(() => {
    applyFilters({ searchTerm: debouncedSearchTerm });
  }, [debouncedSearchTerm]);

  const handleRefreshDataFromMobile = (event: Event) => {
    const actionId = (event as TodoListRefreshEvent).detail.actionId;
    if (actionId) {
      dispatch(updateProcessingTask(parseInt(actionId)));
      applyFilters({});
    } else {
      handleFetchTodoList();
    }
  };

  const handleFetchTodoList = () => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    const formattedDate = selectedDate.format(serverDateFormat);

    const isTodayL = formattedDate === moment().format(serverDateFormat);
    setIsToday(isTodayL);

    if (isTodayL) {
      setDatePickerLable("Today");
      handleFetchInitialTodoList(true);
    } else {
      setDatePickerLable(selectedDate.format(todoDateFormat));
      dispatch(fetchTodoListDate({ date: formattedDate, actionsMode: actionsMode }))
        .then(res => unwrapResult(res))
        .then(data => {
          if (data) {
            applyFilters({});
            endRefreshData();
          }
        });
    }
  };

  const handleFetchInitialTodoList = (mannualTriggered?: boolean) => {
    dispatch(fetchInitialTodoList({ mannualTriggered, actionsMode: actionsMode })).then(() => {
      applyFilters({});
      endRefreshData();
    });
  };

  const { endRefreshData, PullToRefreshOnWeb, PullToRefreshContextProvider } = useMobilePullToRefreshData({
    refreshHandler: handleRefreshDataFromMobile,
  });

  const onSearchChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const renderStatusFilter = () => {
    return (
      <Space direction="horizontal">
        {Object.entries(todoListStatusFilter).map(entry => (
          <Button
            key={entry[0]}
            type="primary"
            ghost={!entry[1]}
            size="small"
            shape="round"
            onClick={() => {
              applyFilters({ statusFilter: (entry[0] as unknown) as TodoListStatusFilter });
              track({ eventName: HBEventName.TodoListStatusFiltered, data: { filter: entry[0] } });
            }}
          >
            {t(entry[0])}
          </Button>
        ))}
      </Space>
    );
  };

  const renderViewFilters = () => {
    return (
      <Space direction="horizontal" size="small">
        <Button
          key="onlyMy"
          type="text"
          size="small"
          shape="circle"
          onClick={() => {
            const toggledViewFilter =
              todoListViewFilter === TodoListViewFilter.OnlyMy
                ? TodoListViewFilter.ViewedByMe
                : TodoListViewFilter.OnlyMy;
            dispatch(filterTodolistByView(toggledViewFilter));
            track({ eventName: HBEventName.TodoListViewFiltered, data: { filter: toggledViewFilter } });
            handleFetchTodoList();
          }}
        >
          <ViewedByMeIcon
            style={{ fill: todoListViewFilter === TodoListViewFilter.ViewedByMe ? primaryColor : "gray" }}
          />
        </Button>
        <Button
          key="onlyMy2"
          type="text"
          size="small"
          shape="circle"
          onClick={() => {
            const toggledViewFilter =
              todoListViewFilter === TodoListViewFilter.OnlyMy
                ? TodoListViewFilter.ViewedByMe
                : TodoListViewFilter.OnlyMy;
            dispatch(filterTodolistByView(toggledViewFilter));
            track({ eventName: HBEventName.TodoListViewFiltered, data: { filter: toggledViewFilter } });
            handleFetchTodoList();
          }}
        >
          <OnlyMyIcon style={{ fill: todoListViewFilter === TodoListViewFilter.OnlyMy ? primaryColor : "gray" }} />
        </Button>
      </Space>
    );
  };

  const handlePullToRefreshFromWeb = async () => handleFetchTodoList();

  return (
    <PullToRefreshContextProvider>
      <PullToRefreshOnWeb onRefresh={handlePullToRefreshFromWeb}>
        <div className={`todo-list ${direction}`} id="todo-list" ref={setContainer}>
          <Affix target={() => container}>
            <div className="todo-header-container">
              <div className="todo-date-picker">
                <div className="todo-date-picker-label">
                  <LeftOutlined
                    onClick={() => setSelectedDate(selectedDate.subtract(1, "days"))}
                    className="todo-date-picker-label-arrows"
                  />
                  <span onClick={() => setDatePickerVissible(true)}>{t(datePickerLable)}</span>
                  <RightOutlined
                    onClick={() => setSelectedDate(selectedDate.add(1, "days"))}
                    className="todo-date-picker-label-arrows"
                  />
                </div>
                <DatePicker
                  locale={antdLocales[locale]}
                  value={selectedDate}
                  placement={"bottomRight"}
                  open={datePickerVissible}
                  onOpenChange={setDatePickerVissible}
                  className="todo-hidden-date-picker"
                  onChange={date => {
                    if (date && date.format(todoDateFormat) !== selectedDate.format(todoDateFormat)) {
                      setSelectedDate(date);
                    }
                  }}
                />
              </div>
              <div className="filters-container">
                <div className="search-container-with-refresh">
                  <div className="search-bar">
                    <Input
                      className="search"
                      prefix={<SearchOutlined style={{ opacity: 0.5 }} />}
                      bordered={false}
                      id="todo-list-search"
                      placeholder={t("SearchButton")}
                      allowClear={true}
                      onChange={onSearchChanged}
                    />
                  </div>
                  {!isMobile && <Button type="text" icon={<SyncOutlined />} onClick={handleFetchTodoList} />}
                </div>
                <div className="todolist-filters-container">
                  <div className="status-filters">{renderStatusFilter()}</div>
                  <div className="view-filters">{renderViewFilters()}</div>
                </div>
              </div>
            </div>
          </Affix>
          <div className="todo-content">
            {!isLoading && !userIsLoading ? (
              <>
                {isToday ? (
                  <>
                    <TodoListCollapse
                      count={{ total: total.today, totalFiltered: totalFiltered.today }}
                      title={t("ExpiringToday")}
                      actionsMode={!!actionsMode}
                      tasks={
                        filteredTodoList?.today.tasks?.map(e => {
                          return {
                            ...e,
                            sortingDate: e.dueDate,
                            dueDate: dayjs(e.dueDate).isSame(e.startDate, "date")
                              ? dayjs(e.dueDate).isBefore(dayjs(), "date")
                                ? getFormattedDate(e.dueDate)
                                : getFormattedTime(e.dueDate)
                              : getFormattedDate(e.dueDate),
                            startDate: dayjs(e.startDate).isSame(e.dueDate, "date")
                              ? dayjs(e.dueDate).isBefore(dayjs(), "date")
                                ? ""
                                : getFormattedTime(e.startDate)
                              : dayjs(e.dueDate).isBefore(dayjs(), "date")
                              ? ""
                              : getFormattedDate(e.startDate),
                          };
                        }) ?? []
                      }
                      inspections={
                        filteredTodoList?.today.inspections.map(e => {
                          return {
                            ...e,
                            sortingDate: e.endPeriod,
                            endPeriod: t("Today"),
                          };
                        }) ?? []
                      }
                      appointments={
                        filteredTodoList?.today.appointments?.map(e => {
                          return {
                            ...e,
                            sortingDate: e.endDate,
                            endDate: dayjs(e.endDate).isSame(e.startDate, "date")
                              ? dayjs(e.endDate).isBefore(dayjs(), "date")
                                ? getFormattedDate(e.endDate)
                                : getFormattedTime(e.endDate)
                              : getFormattedDate(e.endDate),
                            startDate: dayjs(e.startDate).isSame(e.endDate, "date")
                              ? dayjs(e.startDate).isBefore(dayjs(), "date")
                                ? ""
                                : getFormattedTime(e.startDate)
                              : dayjs(e.startDate).isBefore(dayjs(), "date")
                              ? ""
                              : getFormattedDate(e.startDate),
                          };
                        }) ?? []
                      }
                      isOpenedByDefault={true}
                      section={TodoSection.Today}
                    />
                    <GetSectionComponent
                      total={total}
                      actionsMode={!!actionsMode}
                      totalFiltered={totalFiltered}
                      data={filteredTodoList}
                      section={TodoSection.Week}
                      title={t("ExpiringThisWeek")}
                      fetchSection
                    />
                    <GetSectionComponent
                      total={total}
                      actionsMode={!!actionsMode}
                      totalFiltered={totalFiltered}
                      data={filteredTodoList}
                      section={TodoSection.Month}
                      title={t("ExpiringThisMonth")}
                      fetchSection
                    />
                    <GetSectionComponent
                      total={total}
                      actionsMode={!!actionsMode}
                      totalFiltered={totalFiltered}
                      data={filteredTodoList}
                      section={TodoSection.Quarter}
                      title={t("ExpiringThisQuarter")}
                      fetchSection
                    />

                    <GetSectionComponent
                      total={total}
                      actionsMode={!!actionsMode}
                      totalFiltered={totalFiltered}
                      data={filteredTodoList}
                      section={TodoSection.Year}
                      title={t("ExpiringThisYear")}
                      fetchSection
                    />
                  </>
                ) : filteredTodoList?.today.tasks?.length ||
                  filteredTodoList?.today.appointments?.length ||
                  filteredTodoList?.today.inspections?.length ? (
                  <GetSectionComponent
                    total={total}
                    actionsMode={!!actionsMode}
                    totalFiltered={totalFiltered}
                    data={filteredTodoList}
                    section={TodoSection.Today}
                    title={"Records"}
                    fetchSection={false}
                  />
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("NoData")} />
                )}
              </>
            ) : (
              <div className="todo-list-spin">
                <Spin size="large" />
              </div>
            )}
          </div>
        </div>
      </PullToRefreshOnWeb>
    </PullToRefreshContextProvider>
  );
}

const GetSectionComponent = ({
  total,
  totalFiltered,
  data,
  section,
  title,
  fetchSection,
  actionsMode,
}: {
  total: TodoTotal;
  totalFiltered: TodoTotal;
  data: TodoListSectioned | null;
  section: TodoSection;
  fetchSection: boolean;
  title: string;
  actionsMode: boolean;
}) => {
  return (
    <TodoListCollapse
      title={title}
      section={section}
      actionsMode={actionsMode}
      count={{ total: total[section], totalFiltered: totalFiltered[section] }}
      tasks={
        data?.[section].tasks?.map(e => {
          return {
            ...e,
            sortingDate: e.dueDate,
            dueDate: getFormattedDate(e.dueDate),
            startDate: getFormattedDate(e.startDate),
          };
        }) ?? []
      }
      inspections={
        data?.[section].inspections?.map(e => {
          return {
            ...e,
            sortingDate: e.endPeriod,
            endPeriod: getFormattedDate(e.endPeriod),
          };
        }) ?? []
      }
      appointments={
        data?.[section].appointments?.map(e => {
          return {
            ...e,
            sortingDate: e.endDate,
            endDate: getFormattedDate(e.endDate),
            startDate: getFormattedDate(e.startDate),
          };
        }) ?? []
      }
      isOpenedByDefault={!fetchSection}
    />
  );
};
