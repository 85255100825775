import { Button, Col, List, Row, Space, Typography } from "antd";
import { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ReactComponent as AddColumnIcon } from "../../media/tableColumnActions/add-column.svg";
import { ReactComponent as RemoveColumnIcon } from "../../media/tableColumnActions/remove-column.svg";
import { ReactComponent as GroupIcon } from "../../media/tableColumnActions/select-group-icon.svg";
import { RootState } from "../../store/store";
import { ColumnType } from "../../types/page";
import { ArrayElement } from "../../utils/types";
import "./columnSelection.less";

type TProps = {
  allColumns: ColumnType<Record<string, unknown>>[];
  selectedColumns?: ColumnType<Record<string, unknown>>[];
  onSave: (columns: ColumnType<Record<string, unknown>>[]) => void;
  onDiscard?: (visible: boolean) => void;
};

export const ColumnSelection = ({ allColumns, onSave, onDiscard, selectedColumns = [] }: TProps): JSX.Element => {
  const { t } = useTranslation();
  const [availableColumns, setAvailableColumns] = useState<typeof allColumns>([]);
  const [stagedColumns, setStagedColumns] = useState<typeof allColumns>([]);

  useEffect(() => {
    if (allColumns.length > 0) {
      setStagedColumns(selectedColumns);
      const selectedKeys = selectedColumns.map(c => c.id);
      setAvailableColumns(allColumns.filter(c => !selectedKeys.includes(c.id)));
    }
  }, [allColumns]);

  const discardChanges = () => {
    setStagedColumns(selectedColumns);
    onDiscard && onDiscard(false);
  };

  const saveChanges = () => {
    onSave(stagedColumns);
  };

  const handleAddColumn = (column: ArrayElement<typeof allColumns>) => {
    setStagedColumns(prevSelectedColumns => {
      if (prevSelectedColumns.find(c => c.id === column.id)) {
        return [...prevSelectedColumns];
      }
      setAvailableColumns(prevAvailableColumns => prevAvailableColumns.filter(c => c.id !== column.id));
      return [...prevSelectedColumns, column];
    });
  };

  const handleRemoveColumn = (column: ArrayElement<typeof allColumns>) => {
    setStagedColumns(prevSelectedColumns => prevSelectedColumns.filter(c => c.id !== column.id));
    setAvailableColumns(prevAvailableColumns => [...prevAvailableColumns, column]);
  };

  const reorder = (list: typeof allColumns, startIndex: number, endIndex: number) => {
    const result = list.slice();
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(stagedColumns, result.source.index, result.destination.index);
    setStagedColumns(items);
  };

  return (
    <Row className="column-selection-modal">
      <Row className="title">{t("ManageColumns")}</Row>
      <DragDropContext onDragEnd={onDragEnd}>
        <Row className="column-wrapper">
          <Col span={12} className="column">
            <List
              size="small"
              header={<Typography.Text className="column__title">{t("Available")}</Typography.Text>}
              bordered
              className="column__list"
              dataSource={availableColumns}
              renderItem={item => (
                <Row className="row-wrapper">
                  <ColumnListItem column={item} action={handleAddColumn} type="add" />
                </Row>
              )}
            />
          </Col>

          <Col span={12} className="column">
            <Droppable droppableId={"in-use-columns"}>
              {providedParent => (
                <Row {...providedParent.droppableProps} ref={providedParent.innerRef} style={{ height: "100%" }}>
                  <List
                    size="small"
                    header={<Typography.Text className="column__title">{t("InUse")}</Typography.Text>}
                    bordered
                    className="column__list"
                    dataSource={stagedColumns}
                    renderItem={(item, index) => (
                      <Draggable draggableId={item.id} index={index} key={item.id}>
                        {provided => (
                          <Row
                            className="row-wrapper"
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <ColumnListItem column={item} action={handleRemoveColumn} type="remove" hasDragIcon />
                          </Row>
                        )}
                      </Draggable>
                    )}
                    itemLayout="horizontal"
                  />
                  {providedParent.placeholder}
                </Row>
              )}
            </Droppable>
          </Col>
        </Row>
      </DragDropContext>
      <Row className="control-buttons">
        <Button className="cancel" onClick={discardChanges}>
          {t("Discard")}
        </Button>
        <Button className="save" type="primary" onClick={saveChanges}>
          {t("SaveChanges")}
        </Button>
      </Row>
    </Row>
  );
};

type ColumnListItemProps = {
  type: "add" | "remove";
  action: (column: ColumnType<Record<string, unknown>>) => void;
  column: ColumnType<Record<string, unknown>>;
  hasDragIcon?: boolean;
};

const ColumnListItem = ({ column, action, type, hasDragIcon = false }: ColumnListItemProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const { t } = useTranslation();
  const { settings } = useSelector((state: RootState) => state.user);
  const generateActionIcon = () => {
    switch (type) {
      case "add":
        return <AddColumnIcon key={column.id} stroke="green" className="pointer" onClick={() => action(column)} />;
      case "remove":
        return <RemoveColumnIcon key={column.id} stroke="#D9042B" className="pointer" onClick={() => action(column)} />;
      default:
        return undefined;
    }
  };
  return (
    <List.Item
      className="row-wrapper__item"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="item-container">
        <div className="dragButton-header-container">
          {hasDragIcon && <GroupIcon />}
          {t(column.label)}
        </div>
        {isHovered ? [generateActionIcon()] : undefined}
      </div>
    </List.Item>
  );
};
