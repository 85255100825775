import { Row, Spin } from "antd";
import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageConfigurationContext from "../../context/pageContext";
import Layout from "../../pages/Layout/Layout";
import { BaseEntityType } from "../../types/entityBase";
import { CategoryPage } from "../../types/page";
import { SingleCard } from "./SingleCard";
import "./singleCard.less";

export const GroupView = () => {
  const { groupView, id, isLoading: isPageLoading } = useContext(
    PageConfigurationContext
  ) as CategoryPage<BaseEntityType>;
  const isLoading = useSelector(isPageLoading);
  const dispatch = useDispatch();
  const data = useSelector(groupView?.cardsSelector || (() => []));

  useEffect(() => {
    if (groupView) {
      dispatch(groupView.fetchCardThunk());
    }
  }, [id]);
  return (
    <Layout contentStyles={{ padding: 0 }} data={data} entityKey={id}>
      <Spin spinning={isLoading}>
        <Row className="groupView">
          {data && data.map((field, index) => <SingleCard key={index.toString()} data={field} />)}
        </Row>
      </Spin>
    </Layout>
  );
};
