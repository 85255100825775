import { Breadcrumb, Button, Typography } from "antd";
import { useContext } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import { HierarchicalState } from "../../../../../types/dashboard";
import { ROOT_MARKER } from "./HierarchicalDataControlContextProvider";

const { Title } = Typography;

export type HBHierarchicalDataControlContext = {
  hierarchicalState: HierarchicalState;
  pushValue: (value: string, depth: number, dimensionKey?: string) => void;
  goBack: (historyRecord: HierarchicalState) => void;
  valueHistory: HierarchicalState[];
};

export const HierarchicalDataControlContext = React.createContext<HBHierarchicalDataControlContext>({
  hierarchicalState: { depth: 0 },
  pushValue: (value: string) => {
    console.log("push value");
  },
  goBack: () => {
    console.log("go back");
  },
  valueHistory: [],
});

export default function HierarchicalDataControl() {
  const { valueHistory, goBack, hierarchicalState: hirarchialState } = useContext(HierarchicalDataControlContext);
  const { t } = useTranslation();
  const primaryColor = useSelector((state: RootState) => state.common.primaryColor);
  const direction = useSelector((state: RootState) => state.user.settings.direction);

  const getBreadCrumbs = () => {
    let breadcrubs = [
      <Breadcrumb.Item key={hirarchialState.value}>
        <Button type="link" shape="round" className="hirarchial-data-control-parent" style={{ color: primaryColor }}>
          {hirarchialState.value}
        </Button>
      </Breadcrumb.Item>,
    ].concat(
      valueHistory.map(item => (
        <Breadcrumb.Item key={item.value}>
          <Button
            type="text"
            shape="round"
            className="hirarchial-data-control-parent"
            onClick={() => {
              goBack(item);
            }}
          >
            {item.value === ROOT_MARKER || !item.value ? t("Root") : item.value}
          </Button>
        </Breadcrumb.Item>
      ))
    );

    if (direction === "ltr") {
      breadcrubs.reverse();
    }
    return breadcrubs;
  };
  if (valueHistory && valueHistory.length > 0) {
    return (
      <div className="hb-hirarchial-data-control">
        <Breadcrumb>{getBreadCrumbs()}</Breadcrumb>
      </div>
    );
  } else {
    return null;
  }
}
