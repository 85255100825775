import { Language } from "./user";
import { AuthStatus } from "./utility";

export interface LandingPageState {
  isLoading: boolean;
  error: null | string;
  landingPageJwt: string;
  landingPageInfo: LandingPageInfo | null;
  mode: Mode;
  notFound: boolean;
  notAllowed: boolean;
}

export type LandingPageInfo = {
  authType: LandingPageAuthType;
  colors: {
    backgroundColor: string;
    buttonCancelColor: string;
    buttonCancelHoverColor: string;
    buttonPrimaryColor: string;
    buttonPrimaryHoverColor: string;
    mainColor: string;
    mainHoverColor: string;
    navbarTopColor: string;
  };
  logo: string;
  image?: string;
  needAuthentication: boolean;
  surveyUrl: string;
  reviewName: string;
  defaultLanguage: Language;
  appointmentId: number;
  jwtToken: string;
};

export enum LandingPageAuthType {
  FullLogin = "FullLogin",
  EmployeeOTP = "EmployeeOTP",
  AnonymousOTP = "AnonymousOTP",
}

export enum Mode {
  Phone = "Phone",
  Code = "Code",
  IFrame = "IFrame",
}

export type LandingPageOtpResponse = {
  status: AuthStatus;
  token: string;
  message: string;
};
