import { createSelector } from "@reduxjs/toolkit";
import { Space, Checkbox } from "antd";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { debounce } from "throttle-debounce";
import PageConfigurationContext from "../../../context/pageContext";
import { addEmptyValueForFiltering } from "../../../pages/pageConfig/category/utilities";
import { pageSelectors } from "../../../selectors";
import { fetchFilterValues } from "../../../store/slices/filter";
import { RootState, useAppDispatch } from "../../../store/store";
import { CustomPropInfo } from "../../../types/customProperty";
import { CategoryId, FieldType, TOption } from "../../../types/page";
import useFilters, { FilterEntityType } from "../../../utils/hooks/useFilter";
import HBAutocomplete from "../../HBComponents/Autocomplete/HBAutocomplete";
import HBDatePicker from "../../HBComponents/DatePicker/HBDatePicker";
import HBRangeDatePicker from "../../HBComponents/RangeDatePicker/HBRangeDatePicker";
import { HBBooleanSelect } from "../../HBComponents/Select/HBBooleanSelect";
import { HBSelect } from "../../HBComponents/Select/HBSelect";

interface TProps {
  type: FieldType;
  label: string;
  id: string;
  optionSelector: (state: RootState) => TOption[] | null;
  filterOpen: boolean;
  entityKey: string;
  asyncFetchFilterOptionsEntityType?: FilterEntityType;
  asyncFetchFilterOptionsUseKeyValue?: boolean;
  propName?: string;
  defaultOpen?: boolean;
  className?: string;
  customPropInfo?: CustomPropInfo;
}

const FilterRenderer = ({
  type,
  id,
  optionSelector,
  label,
  filterOpen,
  entityKey,
  asyncFetchFilterOptionsEntityType,
  asyncFetchFilterOptionsUseKeyValue,
  propName,
  defaultOpen = true,
  className,
  customPropInfo,
}: TProps): JSX.Element | null => {
  const pageConfig = useContext(PageConfigurationContext);
  const pageId = pageConfig.id;
  const { onFilterChange, onFilterRemove } = useFilters(pageId, entityKey);
  const optionsSelector = createSelector(pageSelectors.stateSelector, optionSelector);
  const options = useSelector(
    asyncFetchFilterOptionsEntityType
      ? (state: RootState) => addEmptyValueForFiltering(state.filter.filterValues[pageId]?.values[id] || [])
      : optionsSelector
  );

  const isLoading = useSelector((state: RootState) => state.filter.filterValues[pageId]?.isLoading || false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  // Note: We need to get the values saved for the current filter id (it is always 1 value for 'autocomplete' and 2 values for 'dateRange')
  const value = useSelector((state: RootState) => {
    return state.filter.filters[pageId][entityKey]?.activeFilters?.filter(f => f.key === id).map(f => f.value) ?? [];
  }) as string[];
  const [dateValue, setDateValue] = useState<[string | null, string | null] | null | undefined>(null);
  const [isBlank, setIsBlank] = useState(false);

  const setQuery = useCallback(debounce(300, setSearchTerm), []);

  useEffect(() => {
    if (value.length === 0) {
      setIsBlank(false);
      setDateValue(null);
    }
  }, [value]);

  useEffect(() => {
    if (asyncFetchFilterOptionsEntityType) {
      dispatch(
        fetchFilterValues({
          entityKey,
          entityType: asyncFetchFilterOptionsEntityType,
          propName,
          startsWith: searchTerm,
          filterKey: id,
          pageId: entityKey as CategoryId,
          pageSize: 50,
          customPropInfo: customPropInfo,
          useKeyValue: asyncFetchFilterOptionsUseKeyValue,
        })
      );
    }
  }, [searchTerm, asyncFetchFilterOptionsEntityType]);

  const onFilterSearch = (value: string) => {
    setQuery(value);
  };

  const renderFilter = useMemo(() => {
    return () => {
      switch (type) {
        case "autocomplete":
          const filterAutocomplete = (value: string | null | number) => {
            let mappedIdToTextValue;
            if (id.slice(-2) === "Id") {
              mappedIdToTextValue = options?.filter(o => o.id === value)[0]?.label;
            }
            return onFilterChange({ key: id, value, filterType: "equals", label, textValue: mappedIdToTextValue });
          };

          return <HBAutocomplete onChange={filterAutocomplete} options={options!} value={value && value[0]} />;

        case "boolean":
          const filterBoolean = (value: boolean, textValue: string) => {
            return onFilterChange({ key: id, value, filterType: "equalsBool", label, textValue });
          };
          return (
            <HBBooleanSelect
              id={id}
              label={label}
              onChange={filterBoolean}
              options={options!}
              value={value}
              onDeselect={onFilterRemove}
              filterOpen={filterOpen}
              defaultOpen={defaultOpen}
            />
          );
        case "Dictionary":
        case "String":
        case "Bool":
        case "Numeric":
        case "multiSelect":
          const filterMultiSelect = (value: string | null | number, textValue: string, additionalInfo?: any) => {
            return onFilterChange({
              key: id,
              value,
              filterType: type === "Bool" || type === "String" || type === "Numeric" ? "includes" : "equals",
              label,
              textValue,
              additionalInfo: additionalInfo ?? customPropInfo,
            });
          };
          return (
            <HBSelect
              id={id}
              label={label}
              onSearch={onFilterSearch}
              onChange={filterMultiSelect}
              options={options!}
              value={value}
              loading={isLoading}
              onDeselect={onFilterRemove}
              defaultOpen={defaultOpen}
              open={filterOpen}
            />
          );
        case "date":
          const filterDatePicker = (value: string | null | number) =>
            onFilterChange({ key: id, value, filterType: "equals", label });
          return (
            <HBDatePicker
              onChange={filterDatePicker}
              value={null}
              renderExtraFooter={() => <Checkbox>{t("Blank")}</Checkbox>}
            />
          );

        case "dateRange":
          const filterDateRange = (
            value?: [string | null, string | null] | null,
            isBlank = false,
            setLocalState = true
          ) => {
            if (setLocalState) {
              setDateValue(value);
            }

            if (!value) {
              onFilterRemove(id);
              setIsBlank(isBlank);
              return;
            }

            if (value.length > 2) {
              return;
            }

            if (value[0]) {
              onFilterChange({
                key: id,
                value: value[0],
                filterType: "moreThan",
                label,
                fieldType: "dateRange",
                isBlank,
              });
            }

            if (value[1]) {
              onFilterChange({
                key: id,
                value: value[1],
                filterType: "lessThan",
                label,
                fieldType: "dateRange",
                isBlank,
              });
            }
          };

          const onIsBlankChanged = (event: any) => {
            setIsBlank(event.target.checked);
            filterDateRange(dateValue, event.target.checked);
          };

          return (
            <HBRangeDatePicker
              value={dateValue}
              onChange={filterDateRange}
              allowEmptyValue={true}
              renderExtraFooter={() => (
                <Checkbox checked={isBlank} onChange={onIsBlankChanged}>
                  {t("Blank")}
                </Checkbox>
              )}
            />
          );

        case "tags":
          const filterTags = (value: string | null | number, textValue: string) => {
            return onFilterChange({ key: id, value, filterType: "tags", label, textValue });
          };
          return (
            <HBSelect
              id={id}
              label={label}
              onChange={filterTags}
              options={options!}
              value={value}
              onDeselect={onFilterRemove}
              defaultOpen={defaultOpen}
              open={filterOpen}
            />
          );
        default:
          return null;
      }
    };
  }, [value, dateValue, isBlank]);

  return (
    <Space className={className} wrap={true} style={{ borderRadius: "10px" }}>
      {renderFilter()}
    </Space>
  );
};

export default FilterRenderer;
