import dayjs from "dayjs";
import { ReactComponent as DatePickerIcon } from "../../../../media/datePicker-icon.svg";
import { ReactComponent as LocationIcon } from "../../../../media/location-icon.svg";
import { locationEntitySelectors } from "../../../../selectors";
import { BaseField } from "../../../../types/page";
import { Training, TrainingStatus } from "../../../../types/training";
import { constructTableTree, fetchCurrentTrainingHasParticipants, fetchLocationById, localizeText } from "../utilities";
import { renderTrainingStatusOptionIcon, statusOptions } from "./trainingConfigPageData";

export const certificateIdField: BaseField<Training> = {
  id: "certificateId",
  title: "FieldCertificate",
  value: entity => entity.certificateId,
  type: "autocomplete",
  required: true,
  placeholder: "Choose",
  optionsSelector: state =>
    state.certification.subData.userCertifications?.map(l => ({
      id: l.id,
      label: l.name,
    })) ?? [],
  changesConfirmation: {
    body: "ChangeTrainingCertificateWarning",
    okText: "Change",
    cancelText: "Keep",
    addValueToBody: false,
    addValueToOkText: false,
    showConditionally: () => fetchCurrentTrainingHasParticipants(),
  },
};

export const statusField: BaseField<Training> = {
  id: "status",
  title: "FieldStatus",
  Icon: ({ value }) => renderTrainingStatusOptionIcon(value.status),
  value: e => e.status,
  localizedValue: e => localizeText(e.status),
  type: "dropdown",
  options: statusOptions,
  // placeholder: "active",
  optionsSelector: () => null,
  changesConfirmation: {
    body: "ConfirmCancelTraining",
    okText: "CancelTraining",
    cancelText: "KeepTraining",
    addValueToBody: false,
    addValueToOkText: false,
    showConditionally: value => (value === TrainingStatus.Canceled ? true : false),
  },
};

export const supplierField: BaseField<Training> = {
  id: "supplier",
  title: "Supplier",
  value: e => e.supplier,
  type: "input",
  placeholder: "Enter",
  required: false,
  optionsSelector: () => null,
};

export const externalIdField: BaseField<Training> = {
  id: "externalId",
  title: "ExternalID",
  value: e => e.externalId,
  type: "input",
  placeholder: "Enter",
  required: false,
  optionsSelector: () => null,
  enableMobileScanners: true,
};

export const plannedDateField: BaseField<Training> = {
  id: "plannedDate",
  Icon: DatePickerIcon,
  title: "TrainingDate",
  value: e => (e.plannedDate && dayjs(e.plannedDate).isValid() ? dayjs(e.plannedDate).format("DD/MM/YYYY") : null),
  type: "date",
  required: true,
  placeholder: "Undefined",

  optionsSelector: () => null,
};

export const completedDateField: BaseField<Training> = {
  id: "completedDate",
  Icon: DatePickerIcon,
  title: "TrainingDate",
  value: e =>
    e.completedDate && dayjs(e.completedDate).isValid() ? dayjs(e.completedDate).format("DD/MM/YYYY") : null,
  type: "date",
  required: true,
  placeholder: "Undefined",

  optionsSelector: () => null,
};

export const locationIdField: BaseField<Training> = {
  id: "locationId",
  title: "AppointmentLocationLink",
  Icon: LocationIcon,
  value: e => e.locationId,
  valueText: entity => fetchLocationById(entity.locationId),
  type: "treeselect",
  treeData: {
    treeDataSelector: locationEntitySelectors.basic,
    treeDataSet: dataSet =>
      constructTableTree({
        data: dataSet,
        itemValueKey: "id",
        itemLabelKey: "name",
      }),
  },
  placeholder: "Root",
  tabSwitchAction: {
    tabSwitch: true,
    action: () => "location",
  },
  optionsSelector: () => null,
  enableMobileScanners: true,
};

export const attendedParticipantsCountField: BaseField<Training> = {
  id: "attendedParticipentsCount",
  title: "FieldAttended",
  value: e => e.attendedParticipentsCount?.toString(),
  type: "text",
  placeholder: "0",
  optionsSelector: () => null,
};

export const participantsCountField: BaseField<Training> = {
  id: "participentsCount",
  title: "FieldParticipants",
  value: e =>
    (e.participentsCount ? e.participentsCount.toString() : e.employeeIds?.length) || e.participentsCount?.toString(),
  type: "text",
  placeholder: "0",
  optionsSelector: () => null,
};

export const primaryFields: BaseField<Training>[] = [certificateIdField, statusField];

export const secondaryFields: BaseField<Training>[] = [
  supplierField,
  externalIdField,
  plannedDateField,
  locationIdField,
  attendedParticipantsCountField,
  participantsCountField,
];
