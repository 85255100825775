import { EmployeeCertificate } from "./employee";
import { BaseEntityType } from "./entityBase";
import { Inspection } from "./equipment";
import { File } from "./files";
import { Training } from "./training";
import {
  AdditionalProps,
  EmployeeOrgUnitRelations,
  ExplicitAdditionalProps,
  HistoryLog,
  PrivilegeData,
  Role,
  Status,
} from "./utility";

export enum CertificationStatus {
  Active = "Active",
  Inactive = "Inactive",
}

export interface CertificationState {
  // data: Certification[];
  basicData: Certification[];
  groupViewData: GroupViewCertification[];
  listViewData: EmployeeCertificateListView[];
  isLoading: boolean;
  singleData: Certification | null;
  subData: {
    inspections: Inspection[];
    historyLog: HistoryLog[];
    accountable: PrivilegeData[];
    trainings: Partial<Training>[];
    files: File[];
    userCertifications?: Certification[];
  };
  error: string | null;
  defaultCustomProperties: ExplicitAdditionalProps[];
  lastUpdated: string;
  searchResults: EmployeeCertificateListView[];
  paginationInfo: {
    currentPage: number;
    count: number;
  };
}

export type CertificatePaginatedData = {
  data: EmployeeCertificateListView[];
  count: number;
};

export interface CertificateRelationsRequest extends EmployeeOrgUnitRelations {
  role: Role;
  certificateId: number | null;
}

export type CertificateOrgUnitEmployee = {
  externalId: string | undefined | null;
  status: Status | null;
  id: number | string;
  name: string;
  isExternal: boolean | null;
  staging?: boolean; // Is this a 'new' record (still not saved)
  activeId?: number | string; // The ID of the active counterpart of a staged new entity
  isOrgUnit?: boolean;
  isEmployee?: boolean;
  role: Role;
  certificateId: number | null;
};

// export type EntitiesWithCustomProperties = Location | Training | Employee | Equipment;

export type GroupViewCertification = {
  id: number;
  name: string;
  externalId: string | null;
  duration: number;
  supplier: string;
  ownerUserId: number | null;
  aboutToExpireDays: number;
  status: Status;
  total: number;
  active: number;
  aboutToExpire: number;
  planned: number;
  expired: number;
  isManual: boolean;
  iconType: string;
};

export type EmployeeCertificateListView = {
  employeeId: number;
  employeeName: string;
  employeeLocation: string;
  employeeExternalId: string | null;
  employeeOrgUnit: string | null;
} & EmployeeCertificate;

export type Certification = {
  id: number;
  name: string;
  externalId: string | null;
  duration: number;
  aboutToExpireDays: number;
  supplier: string;
  ownerUserId: number | null;
  status: Status;
  isManual: boolean;
  customPropertyValues?: AdditionalProps[];
  iconType: string;
};

export type ManualCertificate = {
  employeeId: number;
  certificateId: number;
  trainingDate: string;
  expirationDate: string;
};

export type CertificationPage = BaseEntityType & {
  entityData: {
    primaryData: Certification;
    // additionalData: null;
    // sharedData: {
    //   location: Location;
    // };
  };
  subData: {
    historyLog: HistoryLog[];
    inspections: Inspection[];
    files: File[];
  };
  groupViewData: GroupViewCertification;
  listViewData: EmployeeCertificateListView;
  tabs: PrivilegeData | HistoryLog | File | Partial<Training>;
  // secondary: Inspection | HistoryLog | Location;
};
