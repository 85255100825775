import dayjs from "dayjs";
import { ReactComponent as DatePickerIcon } from "../../../../media/datePicker-icon.svg";
import { ReactComponent as LocationIcon } from "../../../../media/location-icon.svg";
import { InspectionStatus, InspectionSingleView } from "../../../../types/inspection";
import { BaseField } from "../../../../types/page";
import { localizeText } from "../utilities";
import { renderInspectionStatusIcon } from "./inspectionConfigPageData";

type InspectionFieldType = BaseField<Record<string, unknown> & InspectionSingleView>;

export const inspectionTypeNameField: InspectionFieldType = {
  id: "inspectionTypeName",
  title: "FieldName",
  value: entity => entity.inspectionTypeName,
  type: "text",
  optionsSelector: () => null,
};

export const statusFieldInspection: InspectionFieldType = {
  id: "status",
  title: "FieldStatus",
  value: entity => (entity.status === InspectionStatus.Missing ? InspectionStatus.Expired : entity.status),
  localizedValue: entity =>
    entity.status === InspectionStatus.Missing ? localizeText(InspectionStatus.Expired) : localizeText(entity.status),
  type: "text",
  optionsSelector: () => null,
  Icon: ({ value }) => renderInspectionStatusIcon(value.status),
};

export const nameFieldInspection: InspectionFieldType = {
  id: "name",
  title: "",
  value: _entity =>
    localizeText(_entity.linkedEntity.linkedEntityType) +
    ": " +
    _entity.linkedEntity.id +
    " " +
    _entity.linkedEntity.name,
  type: "text",
  isHidden: () => true,
  optionsSelector: () => null,
};

// Secondary Fields
export const equipmentNameField: InspectionFieldType = {
  id: "equipmentName",
  title: "FieldEquipment",
  value: entity => (entity?.linkedEntity?.linkedEntityType === "Location" ? "None" : entity.linkedEntity?.name) || "",
  isHidden: entity => entity?.linkedEntity?.linkedEntityType === "Location" ?? false,
  isHiddenOnMobile: entity => entity?.linkedEntity?.linkedEntityType === "Location" ?? false,
  type: "text",
  optionsSelector: () => null,
};

export const locationField: InspectionFieldType = {
  id: "location",
  title: "PluginLocationName",
  value: entity =>
    (entity.linkedEntity?.linkedEntityType === "Location"
      ? entity.linkedEntity?.name
      : entity.linkedEntity?.location || "") || "",
  type: "text",
  isHiddenOnMobile: entity => entity?.linkedEntity?.linkedEntityType === "Equipment" ?? false,
  optionsSelector: () => null,
  Icon: LocationIcon,
};

export const recurrencyTypeField: InspectionFieldType = {
  id: "recurrencyType",
  title: "RecurrencyType",
  value: entity => entity.recurrencyType,
  localizedValue: entity => localizeText(entity.recurrencyType),
  type: "text",
  optionsSelector: () => null,
};

export const lastCheckField: InspectionFieldType = {
  id: "lastCheck",
  title: "PluginPropertyGroupCheck_LastCheck",
  value: entity => (dayjs(entity.lastCheck).isValid() ? dayjs(entity.lastCheck).format("DD/MM/YYYY") : null),
  type: "text",
  optionsSelector: () => null,
  Icon: DatePickerIcon,
};

export const nextCheckField: InspectionFieldType = {
  id: "nextCheck",
  title: "FieldExpiration",
  value: entity =>
    entity.status !== InspectionStatus.Pending
      ? dayjs(entity.expirationDate).isValid()
        ? dayjs(entity.expirationDate).format("DD/MM/YYYY")
        : null
      : null,
  type: "text",
  optionsSelector: () => null,
  Icon: DatePickerIcon,
};

export const primaryFields: InspectionFieldType[] = [
  inspectionTypeNameField,
  statusFieldInspection,
  nameFieldInspection,
];

export const secondaryFields: InspectionFieldType[] = [
  equipmentNameField,
  locationField,
  recurrencyTypeField,
  lastCheckField,
  nextCheckField,
];
