import { TimeDimensionGranularity } from "@cubejs-client/core";
import { Button, Popover, Space } from "antd";
import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { updateDateSlicer } from "../../../../../../store/slices/dashboard";
import { useAppDispatch } from "../../../../../../store/store";
import { ChartContext } from "../../../HBChart";

export type GranularityOption = {
  title: string;
  granularity: TimeDimensionGranularity;
};

export const granularityOptions: GranularityOption[] = [
  { title: "DashboardDateFilterDay", granularity: "day" },
  { title: "DashboardDateFilterWeek", granularity: "week" },
  { title: "DashboardDateFilterMonth", granularity: "month" },
  { title: "DashboardDateFilterQuarter", granularity: "quarter" },
  { title: "DashboardDateFilterYear", granularity: "year" },
];

export default function SliceByTimeDimension() {
  const { chart } = useContext(ChartContext);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const timeDimension = useMemo(() => {
    const queryType = (chart?.resultSet as any).queryType;
    const results = queryType === "compareDateRangeQuery" ? chart?.resultSet?.decompose()[0] : chart?.resultSet;
    if (results && results?.query().timeDimensions && results?.query().timeDimensions!.length > 0) {
      const timeDimension = results.query().timeDimensions![0];
      return {
        dimension: timeDimension,
        label: timeDimension.dimension || t("DashboardDateSlicer"),
      };
    } else if (chart?.query.timeDimensions && chart.query.timeDimensions.length > 0) {
      const timeDimension = chart.query.timeDimensions![0];
      return {
        dimension: timeDimension,
        label: timeDimension.dimension || "Date",
      };
    }
  }, [chart?.resultSet]);

  const onGranularityClicked: (
    granularity: TimeDimensionGranularity
  ) => React.MouseEventHandler<HTMLElement> = granularity => () => {
    if (timeDimension && chart) {
      dispatch(
        updateDateSlicer({ id: chart.id, timeDimension: timeDimension.dimension.dimension, granularity: granularity })
      );
    }
  };

  const granularitySelector = useMemo(
    () => (
      <Space>
        {granularityOptions.map(g => (
          <Button
            onClick={onGranularityClicked(g.granularity)}
            key={g.granularity}
            type={g.granularity === timeDimension?.dimension.granularity ? "primary" : "text"}
            shape="round"
          >
            {t(g.title)}
          </Button>
        ))}
      </Space>
    ),
    [timeDimension]
  );

  if (!timeDimension) return null;
  return (
    <Popover placement="right" content={granularitySelector}>
      <Button shape="round" type="text" onClick={e => e.preventDefault()}>
        {t(timeDimension.label)}
      </Button>
    </Popover>
  );
}
