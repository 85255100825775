import { useSelector } from "react-redux";
import FullLogin from "../../pages/Login/FullLogin";
import { RootState } from "../../store/store";
import { LandingPageAuthType } from "../../types/landingPage";
import useRouter from "../../utils/hooks/useRouter";
import OtpComponent from "../OtpComponent/OtpComponent";

export default function LandingPageLogin() {
  const { pathname } = useRouter<{ referer: Location }>();
  const landingPageKey = pathname.replace("/landingPage/", "");

  const { landingPageInfo } = useSelector((state: RootState) => state.landingPage);

  return (
    <>
      {landingPageInfo?.authType === LandingPageAuthType.EmployeeOTP ||
      landingPageInfo?.authType === LandingPageAuthType.AnonymousOTP ? (
        <OtpComponent
          landingPageKey={landingPageKey}
          backButtonWhite={!landingPageInfo.image}
          isLandingPage={true}
          customReferer={pathname}
        />
      ) : (
        <FullLogin
          darkBackground={landingPageInfo?.image ? false : true}
          isLandingPage={true}
          customReferer={pathname}
          notCentered={landingPageInfo?.image ? true : false}
        />
      )}
    </>
  );
}
