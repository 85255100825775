import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PageConfigurationContext from "../../context/pageContext";
import Layout from "../../pages/Layout/Layout";
import { filterSelector } from "../../selectors";
import { useAppDispatch } from "../../store/store";
import { BaseEntityType } from "../../types/entityBase";
import { CategoryPage } from "../../types/page";
import { pipe } from "../../utils/functions";
import useFilters from "../../utils/hooks/useFilter";
import ListViewVTable from "./ListViewVTable";

type TProps = {
  columnSelectionVisibility: boolean;
  handleColumnSelectionVisibility?: (visible: boolean) => void;
};

export const ListView = ({ columnSelectionVisibility, handleColumnSelectionVisibility }: TProps): JSX.Element => {
  const { id, listView, hasListView } = useContext(PageConfigurationContext) as CategoryPage<BaseEntityType>;

  const { filterData } = useFilters(id, id, true);
  // const data = useSelector(primaryEntitySelector);
  const data = useSelector(hasListView && listView?.table?.tableSelector ? listView?.table.tableSelector : () => []);
  const filters = useSelector(filterSelector(id, id));
  // TODO: This should be in the ListViewVTable component. Temporary brought here because it is currently needed in the HeaderBar for controlling visiblity of 'CreateTraining'
  const [selectedRows, setSelectedRows] = useState<typeof entitiesToDisplay>([]);
  const handleSetSelectedRows = (rows: typeof entitiesToDisplay) => {
    setSelectedRows(rows);
  };
  const dispatch = useAppDispatch();

  useEffect(() => {
    setSelectedRows([]);
  }, [filters]);

  const entitiesToDisplay = listView?.table.type === "paginatedTable" ? data : pipe(filterData)(data);

  useEffect(() => {
    if ((listView?.table.type === "table" || listView?.table.type === "tree") && listView.table?.fetchListViewData) {
      dispatch(listView?.table.fetchListViewData());
    }
  }, [location.pathname]);

  return (
    <Layout
      contentStyles={{ padding: 0 }}
      data={entitiesToDisplay}
      entityKey={id}
      handleColumnSelectionVisibility={handleColumnSelectionVisibility}
      selectedRows={selectedRows}
    >
      <ListViewVTable
        filteredData={entitiesToDisplay}
        fullData={data}
        columnSelectionVisibility={columnSelectionVisibility}
        handleSetSelectedRows={handleSetSelectedRows}
        selectedRows={selectedRows}
        handleColumnSelectionVisibility={handleColumnSelectionVisibility}
      />
    </Layout>
  );
};
