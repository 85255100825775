import { unwrapResult } from "@reduxjs/toolkit";
import { Button, Row } from "antd";
import debounce from "lodash/debounce";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput, { CountryData } from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useSelector } from "react-redux";
import VerificationInput from "react-verification-input";
import { routPaths } from "../../router";
import { changeMode, landingPageOtp } from "../../store/slices/landingPage";
import { changeOtpMode, otp } from "../../store/slices/user";
import { RootState, useAppDispatch } from "../../store/store";
import { HBEventName } from "../../types/analyticsTypes/HBEvent";
import { Mode } from "../../types/landingPage";
import { NewPassAccess } from "../../types/user";
import { LocalStorageKeys } from "../../types/utility";
import useInitTrackEvents from "../../utils/hooks/useInitTrackEvents";
import useLocalStorage from "../../utils/hooks/useLocalStorage";
import useRouter from "../../utils/hooks/useRouter";
import "./otpComponent.less";

interface TProps {
  isLandingPage?: boolean;
  backButtonWhite?: boolean;
  landingPageKey?: string;
  customReferer?: string;
}

const ALLOWED_COUNTRIES = process.env.REACT_APP_ALLOWED_COUNTRY_CODES?.split(",");
const RESEND_CODE_TIME = 30;

const OtpComponent = ({ isLandingPage, backButtonWhite, landingPageKey, customReferer }: TProps) => {
  const [phone, setPhone] = useLocalStorage<string>(LocalStorageKeys.Phone);
  const [otpCode, setOtpCode] = useState<string | undefined>();
  const [otpMode, setOtpMode] = useState<boolean>(false);
  const { history, location } = useRouter<{ referer: Location }>();
  const referer = customReferer || location.state?.referer || "/equipment";
  const [message, setMessage] = useState<string>("");
  const jwt = useSelector((state: RootState) => state.user.jwt);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [timer, setTimer] = useState<number>(RESEND_CODE_TIME);
  const companySettings = useSelector((state: RootState) => state.user.companySettings);

  const { track } = useInitTrackEvents();

  useEffect(() => {
    if (jwt) {
      if (companySettings.newPassAccess == NewPassAccess.NewPasDisabled) {
        history.push(routPaths.redirectToLegacy);
      } else {
        const query = new URLSearchParams(location.search);
        const returnUrl = query.get("returnUrl");
        history.push(returnUrl ?? referer);
      }
    }
  }, [jwt]);

  useEffect(() => {
    const debouncedTrack = debounce(() => {
      track({ eventName: HBEventName.UserEnteredPhoneOTP });
    }, 1000);

    if (phone) {
      debouncedTrack();
    }

    return () => {
      debouncedTrack.cancel();
    };
  }, [phone]);

  const onSubmit = async () => {
    if (phone) {
      const phoneNumber = phone.replace(/\D/g, "");

      if (isLandingPage) {
        dispatch(
          landingPageOtp(
            otpMode
              ? { OtpCode: otpCode, Phone: phoneNumber, Key: landingPageKey }
              : { Phone: phoneNumber, Key: landingPageKey }
          )
        )
          .then(unwrapResult)
          .then(res => {
            if (res) {
              if (isLandingPage) {
                dispatch(changeMode(Mode.Code));
              }
              if (otpMode) {
                track({ eventName: HBEventName.LoginSuccessful });
              }
              setOtpMode(true);
            }
          })
          .catch(e => {
            track({ eventName: HBEventName.LoginFail });
            setMessage(e.userError);
          });
      } else {
        dispatch(otp(otpMode ? { OtpCode: otpCode, Phone: phoneNumber } : { Phone: phoneNumber }))
          .then(unwrapResult)
          .then(res => {
            if (res) {
              //change in implementing whole flow for SU and admins on entering otp
              if (res.data.status === "VerificationUsernameRequired") {
                setMessage("ErrorUserAdminShouldEnterUsernamePassword");
              }
              setTimer(RESEND_CODE_TIME);
              dispatch(changeOtpMode(Mode.Code));
              setOtpMode(true);
            }
          })
          .catch(e => setMessage(e.userError));
      }
    }
  };

  const onBack = () => {
    if (isLandingPage) {
      dispatch(changeMode(Mode.Phone));
    } else {
      dispatch(changeOtpMode(Mode.Phone));
    }

    setOtpMode(false);
    setMessage("");
  };

  const handleChange: (
    value: string,
    data: any | CountryData,
    event: React.ChangeEvent<HTMLInputElement>,
    formattedValue: string
  ) => void = (value, data) => {
    let phoneNumber = value.substring(data.dialCode.length, value.length);
    if (phoneNumber.startsWith("0")) {
      setPhone(data.dialCode + phoneNumber.substring(1, phoneNumber.length));
    } else {
      setPhone(value);
    }
  };

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer(prevTimer => prevTimer - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [timer]);

  const handleResend = () => {
    setTimer(RESEND_CODE_TIME);
    setMessage("");
    dispatch(otp({ Phone: phone.replace(/\D/g, "") }))
      .then(unwrapResult)
      .catch(e => setMessage(e.userError));
  };

  return (
    <Row align="middle">
      {otpMode ? (
        <div className="otp-container">
          <Button
            onClick={onBack}
            className={`back-button-forgot back-button ${backButtonWhite ? "text-white" : "text-black"}`}
            data-testid="forgot-password-back-button"
            type="text"
            size="small"
            shape="round"
          >
            {t("OtpBack")}
          </Button>
          <span className={`code-sent-message ${backButtonWhite ? "text-white" : "text-black"}`}>
            {t("OneTimeCodeSentToTheNumber0").replace("{0}", phone)}
          </span>
          <VerificationInput
            onChange={otp => setOtpCode(otp)}
            validChars="0-9"
            autoFocus
            inputProps={{ autoComplete: "one-time-code", inputMode: "numeric" }}
            classNames={{
              character: "otpInput",
              characterFilled: "otpInput",
              characterSelected: "otpInput-selected",
              container: "otpContainer",
            }}
          />
          <span
            className={`otp-message ${
              phone && !message?.includes(phone)
                ? "error-otp-message"
                : ` ${backButtonWhite ? "text-white" : "text-black"}`
            } `}
          >
            {t(message)}
          </span>
          <Button
            className={`resend-code-message ${backButtonWhite ? "white" : "black"} ${
              timer > 0 ? "" : phone ? "" : "disabled"
            }`}
            onClick={timer === 0 && phone ? handleResend : undefined}
            type="text"
            shape="round"
            disabled={timer > 0}
          >
            {timer > 0
              ? `${t("OtpReSendResendCanBeDoneLater").replace(/\d{2}/, timer.toString())}`
              : t("OtpDidntSendResend")}
          </Button>
          <Button disabled={phone ? false : true} className="otp-submit otp-submit-code" onClick={onSubmit}>
            {t("SubmitCodeButton")}
          </Button>
        </div>
      ) : (
        <div className="otp-container">
          <PhoneInput
            containerClass="phone-input-container"
            country={"il"}
            onlyCountries={ALLOWED_COUNTRIES}
            inputClass="otp-input"
            dropdownClass="HB-dropdown-wrapper"
            placeholder={t("EnterYourPhone")}
            onChange={handleChange}
            value={phone}
          />
          <Button disabled={phone ? false : true} className="otp-submit otp-submit-phone" onClick={onSubmit}>
            {t("SendCodeButton")}
          </Button>
        </div>
      )}
    </Row>
  );
};

export default OtpComponent;
