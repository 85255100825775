import { ReactNode } from "react";

export const getAlignment = (direction: "rtl" | "ltr") => {
  if (direction === "ltr") {
    return { offset: [-5, 80] };
  } else {
    return { offset: [-5, -90] };
  }
};

export enum MenuItemType {
  Menu,
  Action,
}

export type ProfileMenuItem = {
  title: string;
  icon: ReactNode;
  type?: MenuItemType;
  content?: ReactNode;
  disabled?: boolean;
  action?: any;
};
