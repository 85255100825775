import { TableColumn, Query } from "@cubejs-client/core";
import { Button, message, Modal, Space, Table, Typography, Input, Empty } from "antd";
import { ColumnType } from "antd/lib/table";
import isEqual from "lodash/isEqual";
import { ChangeEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ExportData } from "../../../actions/menuActions";
import usePagedCubeQuery from "../../../utils/hooks/usePagedCubeQuery";
import useRenewCubeQuery from "../../../utils/hooks/useRenewCubeQuery";
import TimeSelector from "../../Map/Legend/TimeSelector";

const { TextArea } = Input;
const { Title } = Typography;

export default function ChartsTable() {
  const [query, setQuery] = useState<Query | undefined>(undefined);
  const [selectedQuery, setSelectedQuery] = useState<Query>({});
  const [userQuery, setUserQuery] = useState<string>("");
  const [isDownloadModalOpened, setIsDownloadModalOpened] = useState<boolean>(false);
  const { t } = useTranslation();
  const { resultSet: charts, isLoading } = useRenewCubeQuery<any>({
    query: {
      dimensions: ["Dashboard.name", "DashboardCharts.name", "DashboardCharts.query"],
      timeDimensions: [],
      order: {
        "DashboardCharts.name": "asc",
      },
    },
  });
  const { resultSet, error, loading } = usePagedCubeQuery({
    query: query,
    getAllPages: true,
  });
  const onDownloadChart = () => {
    if (prettify()) {
      setQuery(selectedQuery);
      message.open({ key: "downloadQuery", content: "Query download in progress ...", type: "loading", duration: 0 });
      setIsDownloadModalOpened(false);
    }
  };

  useEffect(() => {
    if ((resultSet && resultSet != null) || (isEqual(query, selectedQuery) && resultSet)) {
      const template = resultSet.tableColumns().map(column => {
        const template = {
          type: "Header",
          property: column.dataIndex,
          value: column.title,
        };

        return template;
      });

      template.push({
        type: "Option",
        property: "RTL",
        value: "true",
      });

      let today = new Date();
      let filename = `data-exported-${today.toLocaleString().split(",")[0].replaceAll(".", "-")}.xlsx`;

      ExportData(resultSet.tablePivot(), template, filename).then(() => message.destroy("downloadQuery"));
      setQuery(undefined);
    } else if (error && !loading) {
      message.destroy("downloadQuery");
      message.error(`Query encoutered an error: ${error}`, 10);
    }
  }, [resultSet]);

  const generateColumns: (columns: TableColumn[] | undefined) => ColumnType<any>[] = columns => {
    const genColumns: ColumnType<any>[] = columns ? [...columns] : [];
    genColumns.map(column => {
      const index = column.dataIndex?.toString();
      column.colSpan = 1;
      if (index && index !== "DashboardCharts.query") {
        column.fixed = true;
        column.width = "30rem";
        column.filters = charts?.rawData().map(x => {
          return { text: x[index!], value: x[index!] };
        });
        column.onFilter = (value, record) => {
          return record[index!] === value;
        };
        column.filterMultiple = true;
        column.sorter = (a, b) => {
          return a[index!].length - b[index!].length;
        };
        column.sortDirections = ["descend", "ascend"];
      }
    });
    genColumns.push({
      title: "Actions",
      render: (value, record, index) => {
        return (
          <Button
            onClick={() => {
              setSelectedQuery(JSON.parse(record["DashboardCharts.query"]));
              setUserQuery(getPrettyQuery(JSON.parse(record["DashboardCharts.query"])));
              setIsDownloadModalOpened(true);
            }}
          >
            Download
          </Button>
        );
      },
    });
    return genColumns;
  };

  const onTimeSelected = (range: any) => {
    if (userQuery) {
      let transformedQuery: Query = { ...JSON.parse(userQuery) };
      transformedQuery.timeDimensions?.forEach(dim => (range ? (dim.dateRange = range) : (dim.dateRange = undefined)));
      setUserQuery(getPrettyQuery(transformedQuery));
    }
  };

  const onUserQueryChange: ChangeEventHandler<any> = e => {
    setUserQuery(e.target.value);
  };

  const getPrettyQuery: (query: Query | string) => string = query => {
    return JSON.stringify(query, null, 2);
  };
  const prettify = () => {
    try {
      setUserQuery(getPrettyQuery(JSON.parse(userQuery)));
      setSelectedQuery(JSON.parse(userQuery));
      return true;
    } catch (exception) {
      message.error("Input is not a valid json!");
      return false;
    }
  };

  return (
    <>
      <Table
        locale={{
          emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("NoData")} />,
          triggerDesc: t("SortDesc"),
          triggerAsc: t("SortAsc"),
          cancelSort: t("SortCancel"),
        }}
        loading={!charts && isLoading}
        columns={generateColumns(charts?.tableColumns())}
        dataSource={charts?.rawData()}
      />
      <Modal
        title="Download Query"
        destroyOnClose={true}
        open={isDownloadModalOpened}
        maskClosable={false}
        onCancel={() => {
          setIsDownloadModalOpened(false);
        }}
        onOk={onDownloadChart}
      >
        <Space direction="vertical">
          <Title level={3}>Query</Title>
          <Button onClick={prettify}>Prettify</Button>
          <TextArea
            style={{ whiteSpace: "pre-line", overflow: "scroll", height: "40rem", width: "30rem" }}
            value={userQuery}
            onChange={onUserQueryChange}
            placeholder="please insert query as json"
          />
          <Title style={{ marginTop: "3rem" }} level={3}>
            Select Time
          </Title>
          <TimeSelector onChangeRange={onTimeSelected} />
        </Space>
      </Modal>
    </>
  );
}
