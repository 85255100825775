import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { setPrimaryColor } from "../../store/slices/common";
import { RootState, useAppDispatch } from "../../store/store";

export const applyTheme = async (varname: string, color: string) => {
  window.less.modifyVars({ [varname]: color });
};

export const DEFAULT_COMPANY_COLOR = "#7f2a90";

const useCompanyColors = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const mainColor = useSelector((state: RootState) => state.user.companySettings.colors.mainColor);
  const jwt = useSelector((state: RootState) => state.user.jwt);

  useEffect(() => {
    const setColor = async () => {
      if (jwt) {
        if (mainColor) {
          setLoading(true);
          await dispatch(setPrimaryColor(mainColor));
          await applyTheme("@primary-color", mainColor);
          setLoading(false);
        }
      }
    };
    setColor();
  }, [mainColor, jwt]);

  return { loading };
};

export default useCompanyColors;
