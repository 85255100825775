import { Button, Space } from "antd";
import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { resetSlicers, updateChartSlicers } from "../../../../../../store/slices/dashboard";
import { useAppDispatch } from "../../../../../../store/store";
import { ChartContext } from "../../../HBChart";
import SliceByTimeDimension from "./SliceByTimeDimension";

export default function SliceByDimensions() {
  const { chart } = useContext(ChartContext);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const onSlicerClicked: (dimension: string) => React.MouseEventHandler<HTMLElement> = dimension => () => {
    if (chart) {
      dispatch(updateChartSlicers({ id: chart.id, dimensions: [dimension] }));
    }
  };

  const onDefaultClicked: React.MouseEventHandler<HTMLElement> = () => {
    if (chart) {
      dispatch(resetSlicers({ id: chart.id }));
    }
  };

  const userDimension = useMemo(
    () =>
      chart?.userDimensions?.map(dimension => (
        <Button shape="round" type="text" onClick={onSlicerClicked(dimension.dimension)} key={dimension.dimension}>
          {dimension.label}
        </Button>
      )),
    [chart?.userDimensions]
  );

  return (
    <Space direction="vertical">
      {chart?.userFilters?.find(uf => uf.type === 1) && <SliceByTimeDimension />}
      {userDimension}
      <Button shape="round" type="text" onClick={onDefaultClicked}>
        {t("DashboardSlicerDefault")}
      </Button>
    </Space>
  );
}
