import { Badge } from "antd";
import dayjs from "dayjs";
import { certificationSelectors } from "../../../../selectors";
import {
  deleteNewAccountableEntryTemplate,
  fillNewAccountableEntryTemplate,
  updateNewAccountableEntryRole,
} from "../../../../store/slices/certification";
import { addEntityPrivilege, updateEntityPrivilegeRole } from "../../../../store/slices/privileges";
import {
  CertificateOrgUnitEmployee,
  Certification,
  CertificationStatus,
  EmployeeCertificateListView,
} from "../../../../types/certification";
import { ColumnType, ColumnWithDifferentCells, TOption } from "../../../../types/page";
import { Training } from "../../../../types/training";
import { CertificateTraineeStatus, PrivilegeData, PrivilegedEntityType, Role } from "../../../../types/utility";
import { AccountabilityOptions, getCellSearchResultColumns } from "../common";
import { renderTrainingStatusOptionIcon, statusOptions as trainingStatus } from "../training/trainingConfigPageData";
import { localizeText, renderBoolStatus, renderTableStatus } from "../utilities";

const statusOptions: TOption[] = [
  {
    id: CertificationStatus.Active,
    label: "Active",
    icon: <Badge color="#02D924" />,
  },
  {
    id: CertificationStatus.Inactive,
    label: "Inactive",
    icon: <Badge color="#B7B7B7" />,
  },
];

const certificateStatusOptions: TOption[] = [
  {
    id: CertificateTraineeStatus.Active,
    label: CertificateTraineeStatus.Active,
  },
  {
    id: CertificateTraineeStatus.Expired,
    label: CertificateTraineeStatus.Expired,
  },
  {
    id: CertificateTraineeStatus.Pending,
    label: CertificateTraineeStatus.Pending,
  },

  {
    id: CertificateTraineeStatus.IsNoLongerNeeded,
    label: CertificateTraineeStatus.IsNoLongerNeeded,
  },
];

export const certificationTableColumns: ColumnType<Certification>[] = [
  {
    id: "name",
    label: "FieldName",
    renderValue: value => value.name,
    sortable: true,
    optionsSelector: () => null,
    width: 100,
  },
  {
    id: "externalId",
    label: "ExternalID",
    // TODO: Change once we know from where we can get the organizations/team
    renderValue: value => value.externalId,
    optionsSelector: () => null,
    // filterType: "autocomplete",
    sortable: true,
    width: 100,
  },
  {
    id: "supplier",
    label: "Supplier",
    renderValue: value => value.supplier,
    optionsSelector: () => null,
    sortable: true,
    width: 100,
  },
  {
    id: "duration",
    label: "FieldDuration",
    optionsSelector: () => null,
    renderValue: value => `${value.duration} days`,
    sortable: true,
    width: 100,
  },
  {
    id: "status",
    label: "FieldStatus",
    renderValue: () => null,
    optionsSelector: () =>
      statusOptions.map(l => ({
        id: l.id,
        label: l.label,
      })),
    width: 100,
    valueIcon: value => {
      // TODO: Check if other colors need to be added
      return renderTableStatus(value.status);
    },

    sortable: true,
    // width: 100,
  },
];

export const employeesTabColumns: ColumnWithDifferentCells<PrivilegeData>[] = [
  {
    id: "name",
    label: "FieldName",
    renderValue: value => value.name || "",
    sortable: false,
    filterType: "multiSelect",
    cellType: e => (e.staging ? "search" : "text"),
    cellSearchSelector: certificationSelectors.allCertificateRelations,
    cellSearchResultColumns: getCellSearchResultColumns<CertificateOrgUnitEmployee>(["name", "externalId"]),
    cellSearchKeys: ["name"],
    cellSearchRecordClick: fillNewAccountableEntryTemplate,
    optionsSelector: () => null,
    width: 100,
    primaryColumn: e => ({
      navigationTarget: e.userId ? `/employee/${e.id}` : `/orgUnit/${e.orgUnitId}`,
    }),
  },
  {
    id: "externalId",
    label: "ExternalID",
    renderValue: value => value.externalId || "",
    sortable: false,
    filterType: "multiSelect",
    cellType: e => (e.staging ? "search" : "text"),
    cellSearchSelector: certificationSelectors.allCertificateRelations,
    cellSearchResultColumns: getCellSearchResultColumns<CertificateOrgUnitEmployee>(["name", "externalId"]),
    cellSearchKeys: ["externalId"],
    cellSearchRecordClick: fillNewAccountableEntryTemplate,
    optionsSelector: () => null,
    width: 100,
  },
  {
    id: "status",
    label: "FieldStatus",
    renderValue: value => (value.status ? localizeText(value.status.toString()) : ""),
    sortable: false,
    optionsSelector: () => null,
    width: 100,
  },
  {
    id: "isExternal",
    label: "External",
    renderValue: value => (value.isExternal !== null ? renderBoolStatus(value.isExternal) : ""),
    sortable: false,
    optionsSelector: () => null,
    width: 100,
  },
  {
    id: "role",
    label: "Accountability",
    renderValue: e => (e.role ? localizeText(e.role.toString()) : ""),
    cellType: () => "dropdown",
    cellDropdownOptions: () =>
      AccountabilityOptions.map(ao => ({
        id: ao.id,
        label: localizeText(ao.label),
      })),
    cellDropdownOnChange: (e, newValue) =>
      e.staging
        ? updateNewAccountableEntryRole({ entity: e, newValue: newValue as Role })
        : updateEntityPrivilegeRole({
            entity: e,
            role: newValue as Role,
            id: null,
            entityType: PrivilegedEntityType.Certificate,
          }),
    sortable: false,
    optionsSelector: () => null,
    width: 100,
  },
  {
    id: "action" as keyof PrivilegeData,
    label: "FieldAction",
    renderValue: () => null,
    sortable: false,
    optionsSelector: () => null,
    cellType: () => "buttonActions",
    width: 100,
    stagingActions: [
      {
        label: "Add",
        className: "hb-primary-button",
        action: entity =>
          addEntityPrivilege({ entity: entity, entityType: PrivilegedEntityType.Certificate, id: null }),
      },
      {
        label: "Cancel",
        className: "hb-tertiary-button",
        action: entity => deleteNewAccountableEntryTemplate(entity),
      },
    ],
  },
];

export const trainingsTabColumns: ColumnWithDifferentCells<Partial<Training>>[] = [
  {
    id: "plannedDate",
    label: "PlannedDate",
    renderValue: value => (value.plannedDate ? dayjs(value.plannedDate).format("DD/MM/YYYY") : null),
    sortable: false,
    optionsSelector: () => null,
    width: 100,
  },
  {
    id: "participentsCount",
    label: "Trainees",
    renderValue: value => value?.participentsCount?.toString() || "",
    // optionsSelector: trainingSelectors.uniqueTrainingParticipantsCount,
    optionsSelector: () => null,
    // filterType: "multiSelect",
    sortable: true,
    width: 100,
  },
  {
    id: "attendedParticipentsCount",
    label: "FieldAttendees",
    renderValue: value => value?.attendedParticipentsCount?.toString() || "",
    // optionsSelector: trainingSelectors.uniqueTrainingAttendees,
    optionsSelector: () => null,
    // filterType: "multiSelect",
    sortable: true,
    width: 100,
  },
  {
    id: "status",
    label: "FieldStatus",
    valueIcon: e => renderTrainingStatusOptionIcon(e.status!),
    renderValue: value =>
      trainingStatus.find(o => o.id === value.status)
        ? localizeText(trainingStatus.filter(o => o.id === value.status)[0].label)
        : null,
    // renderValue: value => statusOptions.find(o => o.id === value.status)?.label || null,
    sortable: true,
    // filterType: "multiSelect",
    // optionsSelector: () =>
    //   statusOptions.map(l => ({
    //     id: l.id,
    //     label: l.label,
    //   })),
    optionsSelector: () => null,
    width: 100,
  },
];

export const employeeCertificateTableColumns: ColumnType<EmployeeCertificateListView>[] = [
  {
    id: "employeeName",
    label: "EmployeeName",
    renderValue: value => value.employeeName,
    sortable: true,
    filterType: "multiSelect",
    propName: "displayName",
    valueType: "string",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "User",
    primaryColumn: e => ({
      navigationTarget: `/employee/${e.employeeId}`,
    }),
  },
  {
    id: "employeeLocation",
    label: "AppointmentLocationLink",
    renderValue: value => value.employeeLocation,
    propName: "name",
    valueType: "string",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "Location",
    filterType: "multiSelect",
    sortable: true,
  },
  {
    id: "employeeExternalId",
    label: "ExternalID",
    renderValue: value => value.employeeExternalId,
    propName: "externalId",
    valueType: "string",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "User",
    filterType: "multiSelect",
    sortable: true,
  },
  {
    id: "employeeOrgUnit",
    label: "FieldOrgUnit",
    renderValue: value => value.employeeOrgUnit,
    propName: "name",
    valueType: "string",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "OrgUnit",
    filterType: "multiSelect",
    sortable: true,
  },
  {
    id: "certificateName",
    label: "FieldCertificate",
    renderValue: value => value.certificateName,
    filterType: "multiSelect",
    propName: "name",
    valueType: "string",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "Certificate",
    sortable: true,
  },
  {
    id: "certificateExpirationDate",
    label: "FieldExpirationDate",
    renderValue: value =>
      value.certificateExpirationDate ? dayjs(value.certificateExpirationDate).format("DD/MM/YYYY") : null,
    valueIcon: value => {
      if (value.certificateExpirationDate) {
        const expDate = dayjs(value.certificateExpirationDate);
        const dayDiff = expDate.diff(dayjs(), "days");
        return <Badge color={dayDiff >= 30 ? "green" : "red"} />;
      }
    },
    filterType: "dateRange",
    sortable: true,
    optionsSelector: () => null,
  },
  {
    id: "nextTrainingSessionDate",
    label: "FieldNextTrainingSession",
    renderValue: value =>
      value.nextTrainingSessionDate
        ? dayjs(value.nextTrainingSessionDate).format("DD/MM/YYYY")
        : localizeText("Undefined"),
    filterType: "dateRange",
    sortable: true,
    optionsSelector: () => null,
  },
  {
    id: "status",
    label: "FieldStatus",
    filterType: "multiSelect",
    optionsSelector: () =>
      certificateStatusOptions.map(l => ({
        id: l.id,
        label: localizeText(l.label),
      })),
    // width: 100,
    renderValue: e => localizeText(e.status),
    sortable: true,
  },
];
