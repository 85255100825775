import { DownSquareOutlined } from "@ant-design/icons";
import { createSelector } from "@reduxjs/toolkit";
import { Row, TreeSelect, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { TreeData } from "../../../pages/pageConfig/category/utilities";
import { pageSelectors } from "../../../selectors";
import { RootState } from "../../../store/store";
import "./treeSelect.less";

type TProps = {
  onChange: (v: string | null | number) => void;
  value?: string | null | number;
  title?: string | undefined; // Translatable name of the field (type/name/email/etc.)
  treeData?: {
    treeDataSelector: (state: RootState) => Record<string, unknown>[];
    treeDataSet: (dataSet: Record<string, unknown>[], currentEntityId?: string) => TreeData[];
    isNullableField?: boolean;
  };
  label: string | number | null;
  onCardEdited?: (value: boolean) => void;
};

export const HBTreeSelect: React.FC<TProps> = ({
  onChange,
  value,
  treeData,
  title,
  label,
  onCardEdited,
}): JSX.Element => {
  const optionsSelector = createSelector(pageSelectors.stateSelector, treeData?.treeDataSelector || (() => null));
  const data = useSelector(optionsSelector);
  const { id: currentEntityId } = useParams<{ id: string }>();
  const [currentValue, setCurrentValue] = useState<{ value: string | number; label: string } | null>(null);
  const [treeDataSet, setTreeDataSet] = useState<TreeData[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (value) {
      setCurrentValue({ value, label: label?.toString() || "" });
    }
  }, [value, label]);

  useEffect(() => {
    if (data && data.length && treeData) {
      setTreeDataSet(treeData.treeDataSet(data, currentEntityId));
    }
  }, [data]);

  const handleChange = (value: { value: number | string; label: string }) => {
    onCardEdited?.(true);
    setCurrentValue(value);
  };

  const onBlur = () => {
    if (currentValue) onChange(currentValue.value);
  };

  return (
    <Row className="HB-treeselect-row">
      {title && <Typography.Text ellipsis title={title}>{`${title}: `}</Typography.Text>}
      <TreeSelect
        className="HB-treeselect"
        showSearch
        treeNodeFilterProp="label"
        value={currentValue || undefined}
        popupClassName="HB-treeselect__dropdown"
        treeData={treeDataSet}
        placeholder={t("PleaseSelect")}
        treeDefaultExpandAll
        treeDefaultExpandedKeys={[`${value}`]}
        size="middle"
        dropdownMatchSelectWidth={false}
        onChange={handleChange}
        labelInValue
        switcherIcon={<DownSquareOutlined />}
        onBlur={onBlur}
      />
    </Row>
  );
};

// const traverseTree = (value: string | number | undefined | null, treeDataSet: TreeData[]): TreeData | undefined => {
//   if (!value) {
//     return undefined;
//   }
//   let found: TreeData | undefined;
//   for (let i = 0; i < treeDataSet.length; i++) {
//     if (treeDataSet[i].value === value) {
//       return treeDataSet[i];
//     } else if (Array.isArray(treeDataSet[i].children)) {
//       found = traverseTree(value, treeDataSet[i].children);
//       if (found) {
//         return found;
//       }
//     }
//   }
//   return undefined;
// };
