import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { DrillDownOption } from "./DrillDownConfigurationPanel";

type OrderDimensionItemProps = {
  item: DrillDownOption;
  onSelectedChanged: () => void;
};

export default function DrillDownConfigItem({ item, onSelectedChanged }: OrderDimensionItemProps) {
  const onSelectedChange: (e: CheckboxChangeEvent) => void = e => {
    item.selected = !item.selected;
    onSelectedChanged();
  };
  return (
    <div id="drillDownConfigItem">
      <div>{item.title}</div>
      <Checkbox defaultChecked={item.selected} onChange={onSelectedChange} />
    </div>
  );
}
