import { Row, Space, Typography } from "antd";
import "./buildInfo.less";
import { getApiUrl } from "../../store/api";
const {
  buildId,
  buildNumber,
  buildDefinition,
  buildReason,
  branchName,
  buildTime,
  sourceVersion,
  sourceVersionMessage,
} = require("../../build.json");

const modifiedMessage = sourceVersionMessage.substring(0, sourceVersionMessage.indexOf("\n")) || sourceVersionMessage;

export const BuildInfo = (): JSX.Element => {
  return (
    <Row className="build-info">
      {/* <Space> */}
      <Typography.Text ellipsis>{`Branch Name: ${branchName}`}</Typography.Text>
      <Typography.Text ellipsis>{`ID: ${buildId}`}</Typography.Text>
      <Typography.Text ellipsis>{`Number: ${buildNumber}`}</Typography.Text>
      <Typography.Text ellipsis>{`Definition: ${buildDefinition}`}</Typography.Text>
      <Typography.Text ellipsis>{`Reason: ${buildReason}`}</Typography.Text>
      <Typography.Text ellipsis>{`Date: ${buildTime}`}</Typography.Text>
      <Typography.Text ellipsis>{`Version: ${sourceVersion}`}</Typography.Text>
      <Typography.Text ellipsis>{`Message: ${modifiedMessage}`}</Typography.Text>
      <Typography.Text ellipsis>{`Api: ${getApiUrl()}`}</Typography.Text>
      {/* </Space> */}
    </Row>
  );
};
