import { Badge } from "antd";
import dayjs from "dayjs";
import {
  ColumnManagementAction,
  CreateTrainingFromEmpCertificates,
  ExportAction,
  GetFiltersUrl,
} from "../../../../actions/menuActions";
import { SystemActions } from "../../../../consts/listViewActions";
import { ReactComponent as Logo } from "../../../../media/certification-page-icon.svg";
import { certificationSelectors, pageSelectors } from "../../../../selectors";
import {
  addCertification,
  createCertificationTemplate,
  fetchSingleCertification,
  updateCertification,
  fetchCertificateCards,
  createNewAccountableEntryTemplate,
  removeFiles,
  uploadFile,
  fetchCertificateTrainings,
  clearCertificationError,
  fetchPaginatedCertificate,
  searchCertification,
  resetCurrentPage,
  resetSearchResults,
} from "../../../../store/slices/certification";
import { setFileInfoToOpen } from "../../../../store/slices/common";
import { fetchHistoryLog } from "../../../../store/slices/commonThunks";
import { addEmployeeManualCertificate } from "../../../../store/slices/employee";
import { resetActiveFiltersAndAddNew } from "../../../../store/slices/filter";
import { deleteMultipleEntityPrivileges, getEntityPrivileges } from "../../../../store/slices/privileges";
import store from "../../../../store/store";
import { CertificationPage, EmployeeCertificateListView, ManualCertificate } from "../../../../types/certification";
import { BaseFile } from "../../../../types/files";
import { CategoryPage, SectionType } from "../../../../types/page";
import { PrivilegeData, PrivilegedEntityType, TableAddButtonType } from "../../../../types/utility";
import { filesTableColumns, historyLogTableColumns } from "../common";
import { localizeText, prepareDynamicColumns, removeColumnFilters, userIsCetificateOwner } from "../utilities";
import {
  employeeCertificateTableColumns,
  employeesTabColumns,
  trainingsTabColumns,
} from "./certificationConfigPageData";
import {
  certificateIdModalField,
  durationField,
  employeeIdModalField,
  expirationDateModalField,
  externalIdFieldCert,
  isManualField,
  nameField,
  ownerUserIdField,
  primaryFields,
  secondaryFields,
  trainingDateTabModalField,
} from "./fields";

const certificationConfigPage = (): CategoryPage<CertificationPage> => ({
  id: "certification",
  title: "PluginCertificationName",
  entityEndpoint: "/Certificate",
  PageIcon: Logo,
  hasGroupView: true,
  primaryErrorSelector: certificationSelectors.error,
  clearError: clearCertificationError,
  hasListView: true,
  intializeDataActions: [],
  lastUpdatedSelector: certificationSelectors.certificationLastUpdatedText,
  breadcrumbSingleValue: e => {
    return {
      id: e.id?.toString(),
      label: e.name,
    };
  },

  addButton: {
    label: "AddCertificate",
    action: () => console.log("Create new certificate"),
    addButtonActions: [
      {
        isDataOperation: false,
        id: SystemActions.AddNew,
        title: "AddCertificate",
      },
      {
        isDataOperation: false,
        id: SystemActions.CallModal,
        title: "AddOrRenew",
      },
    ],
    addButtonModal: {
      addNewByDefault: true,
      type: TableAddButtonType.AddModal,
      visible: userIsCetificateOwner(),
      addModalInputFields: {
        primaryFields: [certificateIdModalField, employeeIdModalField],
        secondaryFields: [trainingDateTabModalField, expirationDateModalField],
      },
      addRecordModalAction: e => addEmployeeManualCertificate((e as unknown) as ManualCertificate),
    },
  },
  summaryCard: {
    primaryFields: primaryFields,
    secondaryFields: secondaryFields,

    mobileSingleView: {
      summary: {
        icon: Logo,
        title: nameField,
        id: externalIdFieldCert,
        mainContextRelation: isManualField,
      },
      sections: [
        {
          type: SectionType.ContextSection,
          fields: [],
        },
        {
          type: SectionType.DatesSection,
          fields: [durationField],
        },
      ],
    },
  },

  tabsPanel: [
    {
      key: "accountable",
      label: "Accountable",
      tabSelector: certificationSelectors.certificateAccountableRelations,
      tabDataThunk: id => getEntityPrivileges({ id: +id, entityType: PrivilegedEntityType.Certificate }),
      columns: removeColumnFilters(employeesTabColumns) as [],
      rowSelection: true,
      type: "table",
      addButton: {
        action: createNewAccountableEntryTemplate,
        label: "AddEmployee",
        type: TableAddButtonType.AddInline,
      },
      generateDynamicColumns: (props, data) => [],
      customPropertiesSelector: pageSelectors.nullableCustomPropertiesSelector,
      dropdownButtonActions: [
        {
          label: "DeleteBulk",
          action: entities =>
            deleteMultipleEntityPrivileges({
              entity: entities as PrivilegeData[],
              entityType: PrivilegedEntityType.Certificate,
              id: null,
            }),
          type: "bulk",
          changesConfirmation: {
            body: "ConfirmEntitiesRemoval",
            okText: "Remove",
            cancelText: "Keep",
            addValueToBody: false,
            addValueToOkText: false,
          },
        },
      ],
    },
    {
      key: "trainings",
      label: "Trainings",
      tabSelector: certificationSelectors.certificationTrainings,
      tabDataThunk: id => fetchCertificateTrainings(+id),
      columns: removeColumnFilters(trainingsTabColumns) as [],
      rowSelection: true,
      type: "table",
      generateDynamicColumns: (props, data) => [],
      customPropertiesSelector: pageSelectors.nullableCustomPropertiesSelector,
    },
    {
      key: "files",
      label: "Files",
      tabSelector: certificationSelectors.files,
      columns: removeColumnFilters(filesTableColumns("certification")) as [],
      rowSelection: true,
      addButton: {
        action: createNewAccountableEntryTemplate,
        label: "AddFile",
        type: TableAddButtonType.UploadFile,
        uploadFileAction: uploadFile,
      },
      type: "table",
      generateDynamicColumns: (props, data) => [],
      customPropertiesSelector: pageSelectors.nullableCustomPropertiesSelector,
      onRowClick: fileInfo => store.dispatch(setFileInfoToOpen(fileInfo as BaseFile)),
      dropdownButtonActions: [
        {
          label: localizeText("DeleteBulk"),
          action: entities => removeFiles(entities.map(x => x.id) as number[]),
          type: "bulk",
          changesConfirmation: {
            body: "Removing the files could not be reversible, Are you should you wish to remove the files?",
            okText: "Yes I wish to remove the files",
            cancelText: "Keep the files",
          },
        },
      ],
    },
    {
      key: "historyLog",
      label: "HistoryLog",
      tabDataThunk: id => fetchHistoryLog({ entityId: +id, entityType: "Certificate" }),
      tabSelector: certificationSelectors.certificationHistoryLog,
      columns: removeColumnFilters(historyLogTableColumns) as [],
      rowSelection: true,
      type: "table",
      generateDynamicColumns: (props, data) => [],
      customPropertiesSelector: pageSelectors.nullableCustomPropertiesSelector,
    },
  ],
  // listSearch: {
  //   columns: pickSpecificColumns<Certification>(certificationTableColumns, "name", "externalId"),
  //   keys: ["name", "externalId"],
  // },
  listView: {
    table: {
      tableSelector: certificationSelectors.allEmployeeCertificates,
      fetchPaginatedData: fetchPaginatedCertificate,
      searchResultsSelector: certificationSelectors.certificationSearchResults,
      possibleResultsSelector: certificationSelectors.certificationPossibleResults,
      columns: employeeCertificateTableColumns, // TODO: should be changed with employeeCertificateTableColumns
      rowSelection: true,
      defaultColumnKeys: [
        "employeeName",
        "employeeExternalId",
        "employeeLocation",
        "certificateName",
        "certificateExpirationDate",
        "nextTrainingSessionDate",
        "status",
      ],
      disableNavigation: true,
      type: "paginatedTable",
      searchPaginatedData: searchCertification,
      resetSearchResult: resetSearchResults,
      resetCurrentPage: resetCurrentPage,
      // defaultColumnKeys: ["serialNumber", "name", "typeId", "locationId", "expirationData", "status"],
    },

    generateDynamicColumns: (props, data) => prepareDynamicColumns<EmployeeCertificateListView>(props, data),
  },
  // primaryEntitySelector: certificationSelectors.all,
  defaultView: "group",
  groupView: {
    fetchCardThunk: fetchCertificateCards,
    cardsSelector: certificationSelectors.allCertificateCards,
    CardIcon: Logo,
    cardButtons: {
      primaryButton: {
        title: "CreateTraining",
        link: e => ({
          path: "/training/new",
          prefillData: { certificateId: e.id },
        }),
      },
      dropdownButtons: [],
    },
    totalCountField: {
      id: "total",
      title: "FieldTotal",
      value: e => e.total,
      action: entity => ({
        action: () =>
          resetActiveFiltersAndAddNew([
            {
              entityKey: "certification",
              pageId: "certification",
              filter: {
                filterType: "equals",
                key: "certificateId",
                label: "Certificate",
                textValue: entity.name,
                value: entity.id,
                fieldType: "text",
              },
            },
          ]),
        link: {
          path: "/certification",
          state: { viewType: "list" },
        },
      }),
    },
    primaryFields: [
      {
        id: "name",
        title: "FieldName",
        value: e => e.name,
      },
    ],
    secondaryFields: [
      {
        id: "active",
        title: "FieldActive",
        value: e => e.active,
        icon: () => <Badge color="#02D924" />,
        placeholder: "Enter",
        action: entity => ({
          action: () =>
            resetActiveFiltersAndAddNew([
              {
                entityKey: "certification",
                pageId: "certification",
                filter: {
                  filterType: "equals",
                  key: "status",
                  label: "Status",
                  textValue: "Active",
                  value: "Active",
                  fieldType: "text",
                },
              },
              {
                entityKey: "certification",
                pageId: "certification",
                filter: {
                  filterType: "equals",
                  key: "certificateId",
                  label: "Certificate",
                  textValue: entity.name,
                  value: entity.id,
                  fieldType: "text",
                },
              },
            ]),
          link: {
            path: "/certification",
            state: { viewType: "list" },
          },
        }),
      },
      {
        id: "aboutToExpire",
        title: "AboutToExpire",
        value: e => e.aboutToExpire,
        icon: () => <Badge color="#FF8515" />,
        placeholder: "Enter",
        action: entity => ({
          action: () =>
            resetActiveFiltersAndAddNew([
              {
                entityKey: "certification",
                pageId: "certification",
                filter: {
                  filterType: "moreThan",
                  key: "certificateExpirationDate",
                  label: "Expiration Date",
                  textValue: dayjs().toISOString(),
                  value: dayjs().toISOString(),
                  fieldType: "dateRange",
                },
              },
              {
                entityKey: "certification",
                pageId: "certification",
                filter: {
                  filterType: "lessThan",
                  key: "certificateExpirationDate",
                  label: "Expiration Date",
                  textValue: dayjs().toISOString(),
                  value: dayjs()
                    .add(entity.aboutToExpireDays || 60, "day")
                    .toISOString(),
                  fieldType: "dateRange",
                },
              },
              {
                entityKey: "certification",
                pageId: "certification",
                filter: {
                  filterType: "equals",
                  key: "certificateId",
                  label: "Certificate",
                  textValue: entity.name,
                  value: entity.id,
                  fieldType: "text",
                },
              },
              {
                entityKey: "certification",
                pageId: "certification",
                filter: {
                  filterType: "equals",
                  key: "nextTrainingSessionDate",
                  label: "Next Training Session",
                  textValue: "Undefined",
                  value: null,
                  fieldType: "text",
                },
              },
            ]),
          link: {
            path: "/certification",
            state: { viewType: "list" },
          },
        }),
      },
      {
        id: "planned",
        title: "Planned",
        value: e => e.planned,
        icon: () => <Badge color="#B7B7B7" />,
        placeholder: "Enter",
        action: entity => ({
          action: () =>
            resetActiveFiltersAndAddNew([
              {
                entityKey: "certification",
                pageId: "certification",
                filter: {
                  filterType: "equals",
                  key: "status",
                  label: "Status",
                  textValue: "Pending",
                  value: "Pending",
                  fieldType: "text",
                },
              },
              {
                entityKey: "certification",
                pageId: "certification",
                filter: {
                  filterType: "equals",
                  key: "certificateId",
                  label: "Certificate",
                  textValue: entity.name,
                  value: entity.id,
                  fieldType: "text",
                },
              },
            ]),
          link: {
            path: "/certification",
            state: { viewType: "list" },
          },
        }),
      },
      {
        id: "expired",
        title: "Expired",
        value: e => e.expired,
        icon: () => <Badge color="#D9042B" />,
        placeholder: "Enter",
        action: entity => ({
          action: () =>
            resetActiveFiltersAndAddNew([
              {
                entityKey: "certification",
                pageId: "certification",
                filter: {
                  filterType: "equals",
                  key: "status",
                  label: "Status",
                  textValue: "Expired",
                  value: "Expired",
                  fieldType: "text",
                },
              },
              {
                entityKey: "certification",
                pageId: "certification",
                filter: {
                  filterType: "equals",
                  key: "certificateId",
                  label: "Certificate",
                  textValue: entity.name,
                  value: entity.id,
                  fieldType: "text",
                },
              },
              // {
              //   entityKey: "certification",
              //   pageId: "certification",
              //   filter: {
              //     filterType: "equals",
              //     key: "nextTrainingSessionDate",
              //     label: "Next Training Session",
              //     textValue: "Undefined",
              //     value: null,
              //     fieldType: "text",
              //   },
              // },
            ]),
          link: {
            path: "/certification",
            state: { viewType: "list" },
          },
        }),
      },
    ],
  },
  isLoading: pageSelectors.isCertificationPageLoading,
  primarySingleEntitySelector: certificationSelectors.single,
  customPropertiesSelector: certificationSelectors.certificationCustomProperties,
  fetchSingle: fetchSingleCertification,
  createNewEntityTemplate: createCertificationTemplate,
  createNewEntity: addCertification,
  updateEntity: updateCertification,
  listViewActions: [ColumnManagementAction, ExportAction, CreateTrainingFromEmpCertificates, GetFiltersUrl],
  singleViewActions: [],
});

export default certificationConfigPage;
