import { createAsyncThunk, createSlice, PayloadAction, nanoid } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { CustomError } from "../../pages/pageConfig/category/utilities";
import { Employee } from "../../types/employee";
import { File, FileUploadResponse } from "../../types/files";
import { OrgUnit, OrgUnitState } from "../../types/orgUnit";
import { Status, ExplicitAdditionalProps, Role, PrivilegeData } from "../../types/utility";
import { hbApi, hbApiOptions } from "../api";
import { RootState } from "../store";
import { shouldUpdate } from "./common";
import { fetchHistoryLog } from "./commonThunks";
import {
  addEntityPrivilege,
  createNewPrivilegeTemplate,
  deleteEntityPrivilege,
  deleteMultipleEntityPrivileges,
  deleteNewPrivilegeEntryTemplate,
  fillNewPrivilegeEntryTemplate,
  getEntityPrivileges,
  updateEntityPrivilegeRole,
  updateNewPrivilegeEntryRole,
} from "./privileges";

function adjustOUStatus(status: Status): Status {
  return status === Status.Inactive ? Status.InActive : status;
}

export const initialState: OrgUnitState = {
  data: [],
  basicData: [],
  isLoading: false,
  subData: {
    employees: [],
    files: [],
    accountable: [],
    historyLog: [],
  },
  singleData: null,
  error: null,
  defaultCustomProperties: [],
  lastUpdated: dayjs().toISOString(),
};

export const newOrgUnit: OrgUnit = {
  id: 0,
  managerUserId: null,
  parentId: null,
  externalId: null,
  status: Status.Active,
  name: "",
};

export const fetchOrgUnits = createAsyncThunk<
  { primaryData: OrgUnit[]; defaultCustomProperties: ExplicitAdditionalProps[] },
  boolean | undefined,
  { state: RootState }
>(
  "@@ORG_UNIT/FETCH",
  async (_, { getState }) => {
    const { user } = getState();
    const response = await hbApi.get<OrgUnit[]>("/OrgUnit", hbApiOptions(user.jwt));
    return {
      primaryData: response.data.map(item => {
        return {
          ...item,
        };
      }),
      defaultCustomProperties: [],
    };
  },
  {
    condition: (forceUpdate, { getState }) => {
      const { orgUnit } = getState();
      return shouldUpdate(orgUnit.lastUpdated, orgUnit.isLoading, forceUpdate);
    },
  }
);

export const fetchFullOrgUnits = createAsyncThunk<{ basicData: OrgUnit[] }, void, { state: RootState }>(
  "@@ORG_UNIT/FETCH_BASIC",
  async (_, { getState }) => {
    const { user } = getState();
    const response = await hbApi.get<OrgUnit[]>("/OrgUnit/full", hbApiOptions(user.jwt));
    return {
      basicData: response.status === 200 ? response.data : [],
    };
  }
);

export const fetchSingleOrgUnit = createAsyncThunk<
  {
    singleData: OrgUnit;
    subData: { employees: Employee[]; files: File[] };
    defaultCustomProperties: ExplicitAdditionalProps[];
  },
  string,
  { state: RootState }
>("@@SINGLE_ORG_UNIT/FETCH", async (id, { getState, rejectWithValue }) => {
  const { user } = getState();

  try {
    const cardDataResponse = await hbApi.get<OrgUnit>(`/OrgUnit/${id}`, hbApiOptions(user.jwt));
    const employeesResponse = await hbApi.get<Employee[]>(`/Employee?orgUnitIds=${id}`, hbApiOptions(user.jwt));
    const filesResponse = await hbApi.get<File[]>(`/File?entityType=OU&entityId=${id}`, hbApiOptions(user.jwt));

    return {
      singleData: {
        ...cardDataResponse.data,
        status: adjustOUStatus(cardDataResponse.data.status),
      },
      subData: {
        employees: employeesResponse.data,
        files: filesResponse.data,
      },
      defaultCustomProperties: [],
    };
  } catch (e) {
    if (e.status === 400 || e.status === 404) {
      return rejectWithValue(e.data);
    }
    throw new CustomError(e.message || e.Message);
  }
});

export const addOrgUnit = createAsyncThunk<OrgUnit, { entity: OrgUnit }, { state: RootState }>(
  "@@ORG_UNIT/ADD",
  async ({ entity }, { getState, rejectWithValue }) => {
    const { user } = getState();

    try {
      const response = await hbApi.post<OrgUnit>("/OrgUnit", entity, hbApiOptions(user.jwt));
      return {
        ...response.data,
        status: adjustOUStatus(response.data.status),
      };
    } catch (e) {
      if (e.status === 400) {
        return rejectWithValue(e.data);
      }
      throw new CustomError(e.message || e.Message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { orgUnit, user } = getState();
      return !orgUnit.isLoading && !!user.jwt;
    },
  }
);

export const patchOrgUnitParentId = createAsyncThunk<
  OrgUnit,
  { objectId: number; newValue: number },
  { state: RootState }
>(
  "@@ORG_UNIT/PATCH_PARENT_ID",
  async ({ objectId, newValue }, { getState, rejectWithValue }) => {
    const { user } = getState();

    try {
      const response = await hbApi.patch<OrgUnit>(
        `/OrgUnit/${objectId}`,
        [{ path: "/parentId", op: "replace", value: newValue }],
        hbApiOptions(user.jwt)
      );
      return response.data;
    } catch (e) {
      if (e.status === 400) {
        return rejectWithValue(e.data);
      }
      throw new CustomError(e.message || e.Message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { orgUnit, user } = getState();
      return !orgUnit.isLoading && !!user.jwt;
    },
  }
);

export const updateOrgUnit = createAsyncThunk<OrgUnit, OrgUnit, { state: RootState }>(
  "@@ORG_UNIT/UPDATE",
  async (entity, { getState, rejectWithValue }) => {
    const { user } = getState();

    try {
      const response = await hbApi.put<OrgUnit>(`/OrgUnit/${entity.id}`, entity, hbApiOptions(user.jwt));
      return {
        ...response.data,
        status: adjustOUStatus(response.data.status),
      };
    } catch (e) {
      if (e.status === 400) {
        return rejectWithValue(e.data);
      }
      throw new CustomError(e.message || e.Message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { orgUnit, user } = getState();
      return !orgUnit.isLoading && !!user.jwt;
    },
  }
);

export const removeFiles = createAsyncThunk<number[], number[], { state: RootState }>(
  "@@ORG_UNIT/REMOVE_FILE",
  async (ids, { getState }) => {
    const { user } = getState();
    await hbApi.delete<File[]>("/File", hbApiOptions(user.jwt, ids));

    return ids;
  }
);

export const uploadFile = createAsyncThunk<File[] | null, { file: any; expiration: string }, { state: RootState }>(
  "@@ORG_UNIT/UPLOAD_FILE",
  async ({ file, expiration }, { getState }) => {
    const { user, orgUnit } = getState();
    const formData = new FormData();
    formData.append("files[]", file);

    try {
      const fileEntity = await hbApi.post<FileUploadResponse>(
        `/File/upload?entityType=OU&entityId=${orgUnit?.singleData?.id || 0}&expirationDate=${expiration}`,
        formData,
        hbApiOptions(user.jwt)
      );

      return fileEntity.data?.data;
    } catch (e) {
      throw new CustomError(e.message || e.Message);
    }
  }
);

export const deleteBulkEmployees = createAsyncThunk<Employee[], Employee[], { state: RootState }>(
  "@@ORG_UNIT/DELETE_BULK_EMPLOYEES",
  async (entity, { getState, rejectWithValue }) => {
    const { orgUnit, user } = getState();

    const rootOrgUnit = orgUnit.data.find(ou => ou.parentId === null);
    const additionalData = {
      employeeIds: entity.map(e => e.id),
    };

    try {
      if (additionalData.employeeIds.length && rootOrgUnit) {
        // TODO: make a proper bulk delete instead of multiple calls
        for (const id of additionalData.employeeIds) {
          await hbApi.patch<Employee>(
            `/Employee/${id}`,
            [{ path: "/orgUnitId", op: "replace", value: rootOrgUnit.id }],
            hbApiOptions(user.jwt)
          );
        }
      }
    } catch (e) {
      if (e.status === 400) {
        return rejectWithValue(e.data);
      }
      throw new CustomError(e.message || e.Message);
    }
    return entity;
  },
  {
    condition: (_, { getState }) => {
      const { orgUnit, user } = getState();
      return !orgUnit.isLoading && !!user.jwt;
    },
  }
);

export const slice = createSlice({
  name: "orgUnit",
  initialState,
  // Note: User reducers only for synchronous logic
  reducers: {
    createOrgUnitTemplate: state => {
      state.singleData = newOrgUnit;
      state.subData.employees = [];
      state.subData.files = [];
    },
    clearOrgUnitError: state => {
      state.error = null;
    },
    updateParentId: {
      prepare: (payload: number) => ({ payload }),
      reducer: (state, action: PayloadAction<number>) => {
        if (state.singleData) {
          state.singleData = { ...state.singleData, parentId: action.payload };
        }
      },
    },
    createNewAccountableEntryTemplate: createNewPrivilegeTemplate,
    fillNewAccountableEntryTemplate: {
      prepare: (payload: { row: PrivilegeData; targetEntity: Record<string, unknown> }) => ({ payload }),
      reducer: fillNewPrivilegeEntryTemplate,
    },
    updateNewAccountableEntryRole: {
      prepare: (payload: { entity: PrivilegeData; newValue: Role }) => ({ payload }),
      reducer: updateNewPrivilegeEntryRole,
    },
    deleteNewAccountableEntryTemplate: {
      prepare: (payload: PrivilegeData) => ({ payload }),
      reducer: deleteNewPrivilegeEntryTemplate,
    },
  },
  // Note: User reducers only for asynchronous logic with AsyncThunk
  extraReducers: builder => {
    builder
      // Note - Pending:
      .addCase(fetchOrgUnits.pending, state => {
        state.isLoading = true;
        state.data = [];
        state.error = null;
      })
      .addCase(fetchFullOrgUnits.pending, (state, action) => {
        state.isLoading = true;
        state.basicData = [];
        state.error = null;
      })
      .addCase(fetchSingleOrgUnit.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      // Add
      .addCase(addOrgUnit.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      // Update
      .addCase(updateOrgUnit.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      // Patch Parent ID
      .addCase(patchOrgUnitParentId.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(uploadFile.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(removeFiles.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteBulkEmployees.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getEntityPrivileges.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
      })
      // .addCase(getOrgUnitRelations.pending, state => {
      //   state.isLoading = true;
      //   state.error = null;
      // })
      // .addCase(changeRelationRole.pending, state => {
      //   state.isLoading = true;
      //   state.error = null;
      // })
      // .addCase(addOrgUnitEmployee.pending, state => {
      //   state.isLoading = true;
      //   state.error = null;
      // })
      // .addCase(addOrgUnitOrgUnit.pending, state => {
      //   state.isLoading = true;
      //   state.error = null;
      // })
      // .addCase(deleteOrgUnitRelation.pending, state => {
      //   state.isLoading = true;
      //   state.error = null;
      // })
      // .addCase(deleteBulkRelations.pending, state => {
      //   state.isLoading = true;
      //   state.error = null;
      // })

      // Note - Rejected:
      .addCase(fetchOrgUnits.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(fetchFullOrgUnits.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(fetchSingleOrgUnit.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
        state.singleData = undefined;
      })
      // Add
      .addCase(addOrgUnit.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      // Update
      .addCase(updateOrgUnit.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      // Patch Parent ID
      .addCase(patchOrgUnitParentId.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(uploadFile.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(removeFiles.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(deleteBulkEmployees.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(getEntityPrivileges.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      // Note - Fulfilled:
      .addCase(fetchOrgUnits.fulfilled, (state, action) => {
        state.data = action.payload.primaryData;
        state.defaultCustomProperties = action.payload.defaultCustomProperties;
        state.error = null;
        state.isLoading = false;
      })
      .addCase(fetchFullOrgUnits.fulfilled, (state, action) => {
        state.basicData = action.payload.basicData;
        state.error = null;
        state.isLoading = false;
      })
      .addCase(fetchSingleOrgUnit.fulfilled, (state, action) => {
        state.singleData = action.payload.singleData;
        state.subData.employees = action.payload.subData.employees;
        state.subData.files = action.payload.subData.files;
        state.defaultCustomProperties = action.payload.defaultCustomProperties;
        state.subData.accountable = [];
        state.error = null;
        state.isLoading = false;
      })
      // Add
      .addCase(addOrgUnit.fulfilled, (state, action) => {
        state.singleData = action.payload;
        state.data = state.data ? [...state.data, action.payload] : [action.payload];
        state.basicData = state.basicData ? [...state.basicData, action.payload] : [action.payload];
        state.isLoading = false;
        state.error = null;
      })
      // Update
      .addCase(updateOrgUnit.fulfilled, (state, action) => {
        state.singleData = action.payload;
        const updatedEntityIndex = state.data.findIndex(ou => ou.id === action.payload.id);
        state.data[updatedEntityIndex] = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      // Patch Parent ID
      .addCase(patchOrgUnitParentId.fulfilled, (state, action) => {
        state.singleData = action.payload;
        const updatedEntityIndex = state.data.findIndex(ou => ou.id === action.payload.id);
        state.data[updatedEntityIndex].parentId = action.payload.parentId;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(uploadFile.fulfilled, (state, action) => {
        const filesArray = [...state.subData.files];

        if (action.payload && action.payload.length) filesArray.unshift(action.payload[0]);
        state.subData.files = filesArray;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(removeFiles.fulfilled, (state, action) => {
        state.subData.files = state.subData.files.filter(x => !action.payload.includes(x.id));
        state.isLoading = false;
        state.error = null;
      })
      .addCase(deleteBulkEmployees.fulfilled, (state, action) => {
        const employeesToRemove = action.payload.map(e => e.id);
        state.subData.employees = state.subData.employees.filter(e => !employeesToRemove.includes(e.id));
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchHistoryLog.fulfilled, (state, action) => {
        state.subData.historyLog = action.payload
          ? action.payload.map(r => ({ ...r, id: nanoid(), timeStamp: dayjs(r.timeStamp).format("DD/MM/YYYY") }))
          : [];
        state.error = null;
      })
      .addCase(getEntityPrivileges.fulfilled, (state, action) => {
        state.subData.accountable = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateEntityPrivilegeRole.fulfilled, (state, action) => {
        const updatedEntityIndex = state.subData.accountable.findIndex(r => r.id === action.payload.id);
        const managerDeleted = action.payload.oldRole === "Manager" && action.payload.role !== "Manager";
        state.subData.accountable[updatedEntityIndex].role = action.payload.role;
        if (managerDeleted && state.singleData) {
          state.singleData.managerUserId = null;
        }
        // state.isLoading = false;
        state.error = null;
      })
      .addCase(addEntityPrivilege.fulfilled, (state, action) => {
        state.subData.accountable = state.subData.accountable.filter(r => r.id !== action.payload.id);
        state.subData.accountable.unshift({
          ...action.payload,
          id: action.payload.id!,
          staging: false,
        });
        // state.isLoading = false;
        state.error = null;
      })
      .addCase(deleteEntityPrivilege.fulfilled, (state, action) => {
        state.subData.accountable = state.subData.accountable.filter(r => r.id !== action.payload.id);
        // state.isLoading = false;
        state.error = null;
      })
      .addCase(deleteMultipleEntityPrivileges.fulfilled, (state, action) => {
        const relationsToRemove = action.payload.map(r => r.id);
        state.subData.accountable = state.subData.accountable.filter(r => !relationsToRemove.includes(r.id));
        const managerDeleted = action.payload.find(r => r.role === "Manager");
        if (managerDeleted && state.singleData) {
          state.singleData.managerUserId = null;
        }
        // state.isLoading = false;
        state.error = null;
      });
  },
});

export const {
  createOrgUnitTemplate,
  clearOrgUnitError,
  updateParentId,
  createNewAccountableEntryTemplate,
  fillNewAccountableEntryTemplate,
  updateNewAccountableEntryRole,
  deleteNewAccountableEntryTemplate,
} = slice.actions;
export default slice.reducer;
