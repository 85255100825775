/**
 * This class properties describe where the positions of all questions from the details group are
 */
export default class QuestionPositions {
  public static readonly ProjectName: number = 1;
  public static readonly ReviewName: number = 2;
  public static readonly PersonName: number = 3;
  public static readonly CreatedAtDate: number = 4;
  public static readonly CreatedAtTime: number = 5;
}
