import { Select, Space, Typography } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";

const { Text } = Typography;
type SelectDimensionProps = {
  value: string | undefined;
  currentDimensionId: number | undefined;
  onChange: (value: string) => void;
};
export default function SelectDimension({ value, onChange, currentDimensionId }: SelectDimensionProps) {
  const { dimensions } = useSelector((state: RootState) => state.cubeDimensions);
  const getOptions = () => {
    return dimensions
      .filter(d => d.id !== currentDimensionId)
      .map(dimension => {
        return {
          label: dimension.name,
          value: dimension.id,
          key: dimension.id,
        };
      });
  };
  return (
    <Space direction="horizontal">
      <Text>Dimension</Text>
      <Select
        placeholder="please select dimension"
        options={getOptions()}
        onChange={onChange}
        value={value}
        style={{ width: "20rem" }}
      />
    </Space>
  );
}
