import { SelectOutlined } from "@ant-design/icons";
import "./newTabComponent.less";
import { useState } from "react";

type TProps = {
  href: string;
  value?: string;
  component?: JSX.Element;
};
const NewTabComponent = ({ href, value, component }: TProps): JSX.Element => {
  const [showNewTabIcon, setShowNewTabIcon] = useState<boolean>(false);

  return (
    <div
      onMouseEnter={() => setShowNewTabIcon(true)}
      onMouseLeave={() => setShowNewTabIcon(false)}
      className="newtabicon-wrapper"
    >
      <span>{value}</span>
      {component}
      {showNewTabIcon && (
        <a className="newtabicon" target="_blank" rel="noreferrer" href={`${window.location.origin}${href}`}>
          <SelectOutlined rotate={90} type="export" onClick={e => e.stopPropagation()} />
        </a>
      )}
    </div>
  );
};

export default NewTabComponent;
