import { unwrapResult } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  LTR_DATE_FORMAT,
  RTL_DATE_FORMAT,
  TIME_FORMAT,
  mapDataToState,
  handleI18nFields,
  transformI18nField,
} from "../../review-viewer/MapDataToState";
import {
  I18N_FIELDS,
  ISummaryControl,
  TApiQuestionType,
  TBranding,
  TExecutiveSummaryItem,
  TInitialFormattedState,
  TLabels,
} from "../../review-viewer/types";
import { getSasLinks } from "../../store/slices/common";
import { removeAuthToken } from "../../store/slices/user";
import { RootState, useAppDispatch } from "../../store/store";
import { Language } from "../../types/user";
import { LocalStorageKeys } from "../../types/utility";
import useRouter from "../../utils/hooks/useRouter";

interface FullPdfData {
  summaryControls: ISummaryControl[];
  isRightToLeft: boolean;
  modelData: TInitialFormattedState;
  brandingData: TBranding;
  translations: TLabels;
  submittedDate: string;
  score: string;
  executiveSummary: TExecutiveSummaryItem[];
}

const getLogoUrlFromData = (logoUrl: string) => {
  return new URL(logoUrl).pathname.substring(1);
};

const getFullSizeImagesPaths = (questions: TApiQuestionType[], companyLogo: string, projectLogo: string) => {
  const extractedPaths: string[] = [];
  questions.forEach((question: TApiQuestionType) =>
    question.selectedAnswers.forEach(answer => {
      if (answer.image) {
        const images = answer.image.split(",");
        images.forEach((image: string) => {
          const multiPartImage: string = image.split("|")[0];
          extractedPaths.push(multiPartImage);
        });
      }
    })
  );

  if (companyLogo) {
    extractedPaths.push(getLogoUrlFromData(companyLogo));
  }
  if (projectLogo) {
    extractedPaths.push(getLogoUrlFromData(projectLogo));
  }

  return extractedPaths;
};

const getQuestionsWithSasLinks = (
  questions: TApiQuestionType[],
  imagesWithSasLinks: {
    path: string;
    tokenizedUrl: string;
  }[]
) => {
  return questions.map((question: TApiQuestionType) => {
    return {
      ...question,
      selectedAnswers: question.selectedAnswers.map(answer => {
        const images = answer.image
          ? answer.image
              .split(",")
              .map((img: string) => imagesWithSasLinks.find(x => x.path === img.split("|")[0])?.tokenizedUrl)
              .join(",")
          : null;
        return {
          ...answer,
          ...(answer.image && { image: images }),
        };
      }),
    };
  });
};

// this method use for transform old structure to new one. its need to be for already created live pdf records
// old version contains summaryControlInfo and summaryTableData
// in new version move both to summaryControls
const consolidateSummaryControls = (jsonData: any) => {
  return jsonData.summaryControlInfo && jsonData.summaryTableData?.length
    ? [{ ...jsonData.summaryControlInfo, tableData: jsonData.summaryTableData[0] }]
    : jsonData.summaryControls;
};

type UseLivePdfParams = {
  livePDFId?: string;
};

export const useLivePdf = ({ livePDFId }: UseLivePdfParams) => {
  const { params } = useRouter();
  const guidPdfData = (params as any).id;
  const jwt = useSelector((state: RootState) => state.user.jwt);
  const lang = useSelector((state: RootState) => state.user.settings.lang);
  const direction = useSelector((state: RootState) => state.user.settings.direction);
  const companyId = useSelector((state: RootState) => state.user.settings.companyId);
  const [loading, setLoading] = useState<boolean>(true);
  const [review, setReview] = useState<FullPdfData>();
  const [isMount, setIsMount] = useState(true);
  const router = useRouter();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const getPdfData = async () => {
      try {
        //getting link to blob
        const pdfId = livePDFId || guidPdfData;
        if (pdfId) {
          dispatch(getSasLinks([`c${companyId}/survey-history/${pdfId}.json`]))
            .then(res => unwrapResult(res))
            .then(async data => {
              if (data[0].tokenizedUrl) {
                //extracting data from blob
                await fetch(data[0].tokenizedUrl)
                  .then(response => response.json())
                  .then(async (jsonData: any) => {
                    const questions = jsonData.reviewResult.questions;
                    const extractedPaths = getFullSizeImagesPaths(
                      questions,
                      jsonData.branding.company.logoUrl,
                      jsonData.branding.project.logoUrl
                    );

                    transformI18nField(jsonData.branding.pdf, I18N_FIELDS.ADRESS_TEXT, lang);

                    dispatch(getSasLinks(extractedPaths))
                      .then(res => unwrapResult(res))
                      .then(imagesWithSasLinks => {
                        setReview({
                          ...jsonData,
                          brandingData: {
                            ...jsonData.branding,
                            company: jsonData.branding.company.logoUrl
                              ? {
                                  ...jsonData.branding.company,
                                  logoUrl: imagesWithSasLinks.find(
                                    x => x.path === getLogoUrlFromData(jsonData.branding.company.logoUrl)
                                  )?.tokenizedUrl,
                                }
                              : jsonData.branding.company,
                            project: jsonData.branding.project.logoUrl
                              ? {
                                  ...jsonData.branding.project,
                                  logoUrl: imagesWithSasLinks.find(
                                    x => x.path === getLogoUrlFromData(jsonData.branding.project.logoUrl)
                                  )?.tokenizedUrl,
                                }
                              : jsonData.branding.project,
                          },
                          modelData: mapDataToState(
                            true,
                            handleI18nFields(
                              {
                                ...jsonData.reviewResult,
                                isRightToLeft: [Language.ar, Language["he-IL"]].includes(lang) ? true : false,
                                appointmentInfo: jsonData.appointmentInfo,
                                actionId: jsonData.reviewResult.actionId,
                                questions: getQuestionsWithSasLinks(questions, imagesWithSasLinks),
                              },
                              lang
                            ),
                            {
                              ...jsonData.branding,
                              company: { ...jsonData.branding, timeZone: dayjs.tz.guess() },
                            },
                            jsonData.branding.company.dateFormat
                              ? jsonData.branding.company.dateFormat.toUpperCase()
                              : direction === "rtl"
                              ? RTL_DATE_FORMAT
                              : LTR_DATE_FORMAT,
                            jsonData.branding.company?.timeFormat || TIME_FORMAT,
                            lang
                          ),
                          summaryControls: consolidateSummaryControls(jsonData),
                        } as FullPdfData);
                        setLoading(false);
                      });
                  });
              }
            });
        }
      } catch (error) {
        if (error?.toString().includes("401")) {
          dispatch(removeAuthToken());
        }

        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    //todo: remove on login/logout will be merged
    if (!localStorage.getItem(LocalStorageKeys.JWT)) {
      router.push("/login");
    }
    if (jwt) {
      getPdfData();
    }
  }, [jwt]);
  //todo: remove on login/logout will be merged
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
    } else if (!jwt) {
      router.push("/login");
    }
  }, [jwt]);

  return { review, loading };
};
