import { Button, Space } from "antd";
import { useTranslation } from "react-i18next";
import { ColumnAction } from "../../../types/page";
import "./hbButtons.less";

type TProps = {
  actions: ColumnAction<Record<string, unknown>>[];
  row: Record<string, unknown>;
  onClick: (columnAction: ColumnAction<Record<string, unknown>>, entity: Record<string, unknown>) => void;
};

export const HBTableCellButtons = ({ actions, row, onClick }: TProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Space size={5}>
      {actions?.map((action, index) => (
        <Button
          key={index.toString()}
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
            onClick(action, row);
          }}
          className={action.className ? action.className : ""}
        >
          {t(action.conditionalLabel ? action.conditionalLabel(row) : action.label)}
        </Button>
      ))}
    </Space>
  );
};
