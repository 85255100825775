import { unwrapResult } from "@reduxjs/toolkit";
import { Spin } from "antd";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getRedirectToLegacy } from "../../store/slices/user";
import { RootState, useAppDispatch } from "../../store/store";
import useRouter from "../../utils/hooks/useRouter";

export default (): JSX.Element => {
  const { location } = useRouter<{ referer: Location }>();
  const urlSearchParams = new URLSearchParams(location.search);
  const dispatch = useAppDispatch();
  const { jwt } = useSelector((state: RootState) => state.user);
  const returnUrl = urlSearchParams.get("returnUrl");
  useEffect(() => {
    if (jwt) {
      dispatch(getRedirectToLegacy({ returnUrl: returnUrl }))
        .then(res => unwrapResult(res))
        .then(data => (window.location.href = data.returnUrl))
        .catch(e => {
          console.error("Get key error: ", e);
        });
    }
  }, [jwt]);

  return (
    <div className="full-login-container" style={{ height: "100vh" }}>
      <Spin />
    </div>
  );
};
