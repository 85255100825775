import { Form } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { BaseField } from "../../../types/page";

type TProps = {
  input: BaseField<Record<string, unknown>>;
  children: React.ReactNode;
  label?: boolean;
  labelDots?: boolean;
};

const HbFormItem = ({ input, children, label, labelDots }: TProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Form.Item
      name={input.id}
      rules={[
        { required: input.required, message: t("ErrorFieldRequired") },
        ...(input.type === "email" ? [{ type: input.type, message: t("InputCorrectEmailError") }] : []),
        ...(input.validationRules?.length ? input.validationRules : []),
      ]}
      label={input.title && label && `${t(input.title)}${labelDots ? ":" : ""}`}
      className={`${input.id} form-item ${input.type}`}
    >
      {children}
    </Form.Item>
  );
};

export default HbFormItem;
