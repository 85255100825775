import { Input, InputNumber, InputRef, Row, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import "./hbInput.less";
import ScannerComponent from "../ScannerComponent/ScannerComponent";
import HBTextArea from "./HBTextArea";

type TProps = {
  onChange: (v: string | null | number) => void; // Change handler for the new value;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  value: string | null | number; // Current value;
  conditionalFocus?: boolean;
  title?: string;
  type?: string;
  isPassword?: boolean;
  inputType?: InputType; // Prop for input type
  additionalAction?: (v: boolean) => void;
  enableMobileScanners?: boolean;
  disabled?: boolean;
};

//extend the InputType enum to include the new input type
export enum InputType {
  Text = "text",
  Password = "password",
  Email = "email",
}

const HBInput = ({
  onChange,
  onFocus,
  value,
  conditionalFocus,
  title,
  type,
  inputType = InputType.Text, // Default to "text" input type
  additionalAction,
  enableMobileScanners,
  disabled,
}: TProps): JSX.Element => {
  const [inputValue, setInputValue] = useState(value);
  const { companySettings, settings } = useSelector((state: RootState) => state.user);
  const [shouldFocusOnMobile, setShouldFocusOnMobile] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const inputNumberRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    onChange(inputValue);
    if (shouldFocusOnMobile) {
      setShouldFocusOnMobile(false);
    }
  };

  const handleTextAreaBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    onChange(inputValue);
  };

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleClickMobile = () => {
    setShouldFocusOnMobile(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | number | string | null) => {
    if (e && typeof e === "object" && e.hasOwnProperty("target")) {
      setInputValue(e.target.value);
    } else if (e === null || typeof e === "number" || typeof e === "string") {
      setInputValue(e);
    }
    additionalAction?.(true);
  };

  return type === "number" ? (
    <Row>
      {title && <Typography.Text ellipsis title={title}>{`${title}: `}</Typography.Text>}
      <InputNumber
        onBlur={handleBlur}
        ref={inputNumberRef}
        className="HB-input input-numeric"
        defaultValue={value || 0}
        onChange={handleChange}
        value={inputValue ? inputValue : 0}
        autoFocus={conditionalFocus}
        //controls={false} //requires updating antd to ^4.17.0
      ></InputNumber>
    </Row>
  ) : type === "textArea" ? (
    <Row>
      {title && <Typography.Text ellipsis title={title}>{`${title}: `}</Typography.Text>}
      <HBTextArea
        onBlur={handleTextAreaBlur}
        ref={inputRef}
        defaultValue={value || ""}
        onChange={handleChange}
        value={inputValue ? inputValue : ""}
        autoFocus={conditionalFocus}
        disabled={disabled}
      />
    </Row>
  ) : type === "phoneInput" ? (
    <Row>
      {title && <Typography.Text ellipsis title={title}>{`${title}: `}</Typography.Text>}
      <PhoneInput
        onBlur={handleBlur}
        onClick={handleClickMobile}
        inputProps={{ autoFocus: shouldFocusOnMobile }}
        containerClass={settings.direction === "rtl" ? "phone-input-rtl" : ""}
        inputClass="HB-input"
        dropdownClass="HB-dropdown-wrapper"
        onChange={args => args && handleChange("+" + args)}
        value={
          inputValue ? inputValue.toString() : companySettings.phoneCountryCode ? companySettings.phoneCountryCode : ""
        }
      />
    </Row>
  ) : (
    <Row>
      {title && <Typography.Text ellipsis title={title}>{`${title}: `}</Typography.Text>}
      <Input
        ref={inputRef}
        onBlur={handleBlur}
        onFocus={onFocus}
        onClick={handleClick}
        className="HB-input"
        defaultValue={value || ""}
        onChange={handleChange}
        value={inputValue || ""}
        type={inputType}
        suffix={enableMobileScanners && <ScannerComponent onScan={setInputValue} />}
      />
    </Row>
  );
};

export default HBInput;
