import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Breadcrumb, Space } from "antd";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import PageConfigurationContext from "../../../context/pageContext";
import { RootState, useAppDispatch } from "../../../store/store";
import { BaseEntityType } from "../../../types/entityBase";
import { CategoryPage, TOption } from "../../../types/page";
import useRouter from "../../../utils/hooks/useRouter";
import "./hbBreadcrumbs.less";
import { handleFetchPaginatedData } from "../PaginatedTable/PaginatedTable";

type TProps = {
  singleEntity?: Record<string, unknown> | null;
  customPage?: TOption;
};

const breadcrumbNameMap = (
  entity: TOption = { id: "", label: "" },
  rootLocationLabel: string,
  rootLocationId: string
): Record<string, string> => {
  if (entity.label?.trim() && entity.id) {
    return {
      [`/${rootLocationId}`]: rootLocationLabel,
      [`/${rootLocationId}/${entity.id}`]: entity.label,
    };
  }
  /* TODO: Currently this covers the 'New' case but it is pretty ambiguous.There should be better way to handle it. (e.g. additional prop?) */
  return {
    [`/${rootLocationId}`]: rootLocationLabel,
    [`/${rootLocationId}/new`]: "CreateNew",
  };
};

const HBBreadcrumbs = ({ singleEntity, customPage }: TProps): JSX.Element | null => {
  const pageConfig = useContext(PageConfigurationContext) as CategoryPage<BaseEntityType>;
  const { t } = useTranslation();
  const { id: rootLocationId, title: rootLocationLabel } = pageConfig;
  const { location, history } = useRouter();
  const paths = location.pathname.split("/").filter(l => l);
  const dispatch = useAppDispatch();

  const direction = useSelector((state: RootState) => state.user.settings.direction);

  const goBack = () => {
    history.goBack();
  };

  const getSinglePageName = () => {
    if (singleEntity) {
      if (pageConfig.breadcrumbSingleValue) {
        return pageConfig.breadcrumbSingleValue(singleEntity);
      }
    } else {
      return customPage;
    }
  };

  const singlePageName = getSinglePageName();

  const renderBackArrow = () => {
    return direction === "rtl" ? (
      <RightOutlined style={{ color: "#333435" }} onClick={goBack} />
    ) : (
      <LeftOutlined style={{ color: "#333435" }} onClick={goBack} />
    );
  };

  const breadcrumbItems = paths.map((_, index) => {
    const url = `/${paths.slice(0, index + 1).join("/")}`;
    return (
      <Breadcrumb.Item key={url} className="HB-breadcrumbs__item">
        <NavLink
          exact
          activeClassName="HB-breadcrumbs__item--active"
          onClick={() => handleFetchPaginatedData(pageConfig, dispatch)}
          to={url}
        >
          {t(breadcrumbNameMap(singlePageName, t(rootLocationLabel), rootLocationId)[url])}
        </NavLink>
      </Breadcrumb.Item>
    );
  });

  return (
    <Space size={10} className="HB-breadcrumbs">
      {renderBackArrow()}
      <Breadcrumb>{breadcrumbItems}</Breadcrumb>
    </Space>
  );
};

export default HBBreadcrumbs;
