import { ResultSet } from "@cubejs-client/core";
import { Result } from "antd";
import "chart.js/auto";
import { Chart as ChartJS } from "chart.js";
import { MatrixController, MatrixElement } from "chartjs-chart-matrix";
import ChartAnnotationPlugin from "chartjs-plugin-annotation";
import ChartDataLabels from "chartjs-plugin-datalabels";
import chartTrendline from "chartjs-plugin-trendline";
import React, { useContext } from "react";
import { Chart } from "react-chartjs-2";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import tinycolor from "tinycolor2";
import { RootState } from "../../../../store/store";
import useLocalizeDimensions, { CUBE_NO_VALUE } from "../../Localization/useLocalizeDimensions";
import { ChartContext } from "../HBChart";
import { labelColor, commaify, hirachialColumns } from "../Helpers";

ChartJS.register(ChartAnnotationPlugin);
ChartJS.register(ChartDataLabels);
ChartJS.register(chartTrendline);
ChartJS.register(MatrixController, MatrixElement);

type HeatMapProps = {
  data: ResultSet | undefined;
  grouping: string;
  showNumber: boolean;
  showPrecent: boolean;
  onClick: (values: string[], axis: "x" | "y") => (event: any) => void;
};

const HeatMap = React.forwardRef<ChartJSOrUndefined, HeatMapProps>(
  ({ data, grouping, showNumber, showPrecent, onClick }, graphRef) => {
    const { chart } = useContext(ChartContext);
    const { t } = useTranslation();
    const { localizeDimension, formatSeriesLabel, formatCategoryLabel } = useLocalizeDimensions();
    const primaryColor = useSelector((state: RootState) => state.common.primaryColor);
    const language = useSelector((state: RootState) => state.user.settings.lang);

    const xColumn = data?.tableColumns()[0];
    const yColumn = data?.tableColumns()[1];
    const valueColumnKey = data?.tableColumns()[2]?.key;
    const categries = data?.categories({ x: [xColumn!.key], y: [yColumn!.key, valueColumnKey!] }).map((c: any) => {
      return formatCategoryLabel(data, c.x);
    });
    const yValues = data?.tablePivot().map(row => row[yColumn!.key] || CUBE_NO_VALUE);
    const xValues = data?.tablePivot().map(row => formatCategoryLabel(data, row[xColumn!.key] as string));

    const chartJsData: (resultSet: any) => any = function (resultSet: ResultSet<any>) {
      if (resultSet && xColumn && yColumn && valueColumnKey) {
        const lengthOfYAxis = resultSet.series({ x: [xColumn.key], y: [yColumn.key, valueColumnKey] }).length;
        const lengthOfXAxis = resultSet.categories({ x: [xColumn.key], y: [yColumn.key, valueColumnKey] }).length;
        return {
          datasets: [
            {
              label: resultSet.tableColumns()[2].title,
              data: resultSet.tablePivot().map(row => {
                let modified: any = {};
                modified["v"] = row[valueColumnKey];
                modified["x"] = formatCategoryLabel(resultSet, row[xColumn.key] as string);
                modified["y"] = row[yColumn.key] || CUBE_NO_VALUE;
                return modified;
              }),
              backgroundColor(context: any) {
                const slices = chart?.annotations ? [...chart.annotations] : undefined;
                slices?.sort((a, b) => a.value - b.value);
                const value = context.dataset.data[context.dataIndex]?.v;
                const color =
                  slices?.find((slice, index, obj) => {
                    if (value) {
                      if (index === 0) {
                        return value <= slice.value;
                      } else if (index === slices.length - 1) {
                        return (value > slices[index - 1].value && value <= slice.value) || value >= slice.value;
                      } else {
                        return value > slices[index - 1].value && value <= slice.value;
                      }
                    }
                  })?.color || primaryColor;
                return tinycolor(color).toRgbString();
              },
              //   borderColor(context: any) {  1  ,   5   ,  10 ,  20
              //     const value = context.dataset.data[context.dataIndex].v;
              //     const alpha = (value - 5) / 40;
              //     return tinycolor(primaryColor).setAlpha(alpha).toRgbString();
              //   },
              borderWidth: 0,
              width: ({ chart }: any) => (chart.chartArea || {}).width / lengthOfXAxis - 1,
              height: ({ chart }: any) => (chart.chartArea || {}).height / lengthOfYAxis - 1,
            },
          ],
        };
      }
    };

    const getChartPlugins = () => {
      const chartPlugins = [];
      if (showNumber) {
        chartPlugins.push(ChartDataLabels);
      }
      chartPlugins.push(ChartAnnotationPlugin);
      chartPlugins.push(chartTrendline);
      return chartPlugins;
    };

    const getChartOptions = (resultSet?: ResultSet<any>) => {
      if (resultSet && xColumn && yColumn && valueColumnKey) {
        return {
          plugins: {
            legend: {
              labels: {
                usePointStyle: true,
              },
              position: "bottom",
            },
            datalabels: {
              color: labelColor,
              clamp: true,
              display: true,
              font: {
                weight: "bold",
              },
              formatter: commaify,
              align: "center",
              offset: 0,
              tension: 0.1,
              textAlign: "center",
            },
          },
          responsive: true,
          interaction: {
            mode: "point" as const,
            axis: "xy",
            intersect: false,
          },
          layout: {
            padding: 30,
          },
          indexAxis: chart?.chartAxis,
          maintainAspectRatio: false,
          locale: language,
          scales: {
            x: {
              type: "category",
              labels: categries,
              ticks: {
                display: true,
              },
              grid: {
                display: true,
                offset: true,
              },
            },
            y: {
              type: "category",
              offset: true,
              ticks: {
                display: true,
              },
              grid: {
                display: true,
                offset: true,
              },
            },
          },
        };
      }
    };

    if (!valueColumnKey) {
      return (
        <div className="chartTypeNotSupported">
          <Result status="warning" title={t("DashboardUnsupportedChartTypeTitle")} />
        </div>
      );
    }
    const calculatedData = chartJsData(data);
    if (!calculatedData) return null;
    return (
      <Chart
        plugins={getChartPlugins()}
        options={getChartOptions(data) as any}
        type="matrix"
        ref={graphRef}
        data={calculatedData}
        onClick={onClick(
          (yColumn?.key &&
          chart?.hierarchicalNavigationState?.dimensionKey &&
          yColumn.key === chart?.hierarchicalNavigationState.dimensionKey
            ? yValues
            : xValues) as string[],
          yColumn?.key &&
            chart?.hierarchicalNavigationState?.dimensionKey &&
            yColumn.key === chart?.hierarchicalNavigationState.dimensionKey
            ? "y"
            : "x"
        )}
      />
    );
  }
);

export default HeatMap;
