import { LocalizedDimension } from "./useLocalizeDimensions";

export const appointmentStatus: LocalizedDimension = {
  dimension: "Appointment.AppointmentStatus",
  values: {
    Draft: "AppointmentStatus_Draft",
    Canceled: "AppointmentStatus_Canceled",
    Processing: "AppointmentStatus_Proccessing",
    Sent: "AppointmentStatus_Sent",
    "Partially Completed": "AppointmentStatus_PartiallyCompleted",
    Completed: "AppointmentStatus_Completed",
  },
};
