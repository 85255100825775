import { CloseCircleOutlined } from "@ant-design/icons";
import { Badge, Button, Card, Divider, Space } from "antd";
import cloneDeep from "lodash/cloneDeep";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { DimensionJoin, JoinBy } from "../../../../types/cubeDimensions";
import SelectDimension from "./SelectDimension";
import SelectJoinType from "./SelectJoinType";

type SelectJoinsProps = {
  value: DimensionJoin[] | undefined;
  currentDimensionId: number | undefined;
  onChange: (selectedValues: DimensionJoin[]) => void;
};
export default function SelectJoins({ value, onChange, currentDimensionId }: SelectJoinsProps) {
  const { dimensions } = useSelector((state: RootState) => state.cubeDimensions);
  const [selectedJoins, setselectedJoins] = useState<DimensionJoin[]>(value ? value : []);
  const [changed, setchanged] = useState<boolean>(false);
  const onChangeType: (
    dimensionJoinIndex: number
  ) => (selectedType: any) => void = dimensionJoinIndex => selectedType => {
    let modifiedJoins = cloneDeep(selectedJoins);
    modifiedJoins[dimensionJoinIndex].joinBy = selectedType;
    setselectedJoins(modifiedJoins);
    setchanged(true);
  };

  const onChangeDimension: (
    dimensionJoinIndex: number
  ) => (selectedDimension: any) => void = dimensionJoinIndex => selectedDimension => {
    let modifiedJoins = cloneDeep(selectedJoins);
    modifiedJoins[dimensionJoinIndex]["joinedDimensionId"] = selectedDimension;
    setselectedJoins(modifiedJoins);
    setchanged(true);
  };

  useEffect(() => {
    if (changed) onChange(selectedJoins);
  }, [selectedJoins]);

  const onAddDimensionClicked = () => {
    setselectedJoins(selectedJoins.concat({ joinBy: JoinBy.Appointment }));
    setchanged(true);
  };

  const onRemoveDimension: (joinIndex: number) => () => void = joinIndex => () => {
    setselectedJoins(selectedJoins.filter((value, index) => index !== joinIndex));
    setchanged(true);
  };

  return (
    <Space direction="vertical" align="center" className="joins-container">
      <Space direction="vertical" wrap={true}>
        {selectedJoins.map((join, index) => (
          <div key={join.joinedDimensionId} style={{ marginTop: "1rem" }}>
            <Badge
              title="remove dimension"
              count={
                <div className="dimension-join-badge">
                  <CloseCircleOutlined onClick={onRemoveDimension(index)} style={{ color: "darkred" }} />
                </div>
              }
            >
              <Card key={join.joinedDimensionId} size="small">
                <SelectDimension
                  currentDimensionId={currentDimensionId || undefined}
                  onChange={onChangeDimension(index)}
                  value={dimensions.find(d => d.id === join?.joinedDimensionId)?.name}
                />
                <Divider />
                <SelectJoinType onChange={onChangeType(index)} value={join?.joinBy} />
              </Card>
            </Badge>
          </div>
        ))}
      </Space>
      <Button style={{ marginTop: "1rem" }} size="small" shape="circle" onClick={onAddDimensionClicked}>
        +
      </Button>
    </Space>
  );
}
