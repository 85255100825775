import dayjs from "dayjs";
import { InspectionStatus } from "../../types/inspection";
import {
  TodoList,
  TodoListAppointment,
  TodoListInpection,
  TodoListStatusFilter,
  TodoListStatusFilterState,
  TodoListTask,
} from "../../types/todolist";
import { TodoListCardStatus } from "../../types/utility";

export const modifyCardStatusForInspection = (inspections: TodoListInpection[]) => {
  return inspections.map(inspection => {
    let cardStatus: TodoListCardStatus = TodoListCardStatus.Close;
    switch (inspection.status) {
      case InspectionStatus.Pending:
        cardStatus = TodoListCardStatus.Open;
        break;
      case InspectionStatus.Passed:
      case InspectionStatus.Failed:
      case InspectionStatus.PassedWithConditions:
      case InspectionStatus.Expired:
        cardStatus = TodoListCardStatus.Close;
    }
    return { ...inspection, cardStatus };
  });
};

export const shouldShowFilteredTotal = (
  searchTerm: string,
  statusFilterState: TodoListStatusFilterState,
  isCollapseOpened: boolean
) => {
  return (
    isCollapseOpened &&
    (searchTerm.length || Object.values(statusFilterState).filter(state => state === true).length > 0)
  );
};

export const filterTodoListByStatus = (status: string, cardStatus?: TodoListCardStatus) => {
  if (cardStatus) {
    switch (status) {
      case "LateOnly": {
        return [TodoListCardStatus.Late].includes(cardStatus);
      }
      case "PendingOnly":
        return [
          TodoListCardStatus.Open,
          TodoListCardStatus.Late,
          TodoListCardStatus.AwaitForApproval,
          TodoListCardStatus.SentForApproval,
        ].includes(cardStatus);
      case "ShowAll":
        return true;
    }
  }
  return false;
};

export const filterInspectionsBySearchTerm = (array: TodoListInpection[], searchValue: string) => {
  const searchTerm = searchValue.toLowerCase();
  return array.filter(
    x =>
      (x.locationExternalId && x.locationExternalId.toLowerCase().includes(searchTerm)) ||
      (x.mappedEntityName && x.mappedEntityName.toLowerCase().includes(searchTerm)) ||
      (x.locationName && x.locationName.toLowerCase().includes(searchTerm)) ||
      (x.mappedEntityExternalId && x.mappedEntityExternalId.toLowerCase().includes(searchTerm))
  );
};

export const filterTasksBySearchTerm = (array: TodoListTask[], searchValue: string) => {
  const searchTerm = searchValue.toLowerCase();
  return array?.filter(
    x =>
      (x.id && x.id.toString().toLowerCase().includes(searchTerm)) ||
      (x.title && x.title.toLowerCase().includes(searchTerm))
  );
};

export const filterAppointmentsBySearchTerm = (array: TodoListAppointment[], searchValue: string) => {
  const searchTerm = searchValue.toLowerCase();
  return array?.filter(
    x =>
      (x.id && x.id.toString().toLowerCase().includes(searchTerm)) ||
      (x.title && x.title.toLowerCase().includes(searchTerm))
  );
};

export const getFormattedDate = (date: string) => {
  const dateObj = dayjs(date);
  const currentYear = dayjs().year();
  const formatString = dateObj.year() === currentYear ? "MMM D, HH:mm" : "MMM D YYYY, HH:mm";
  return dateObj.format(formatString);
};

export const getFormattedTime = (date: string) => dayjs(date).format("HH:mm");

export const todoDateFormat = "MMM D, YYYY";

export const serverDateFormat = "YYYY-MM-DD";
