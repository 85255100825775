import { Query, BinaryFilter, UnaryFilter } from "@cubejs-client/core";
import { CubeContext } from "@cubejs-client/react";
import { Result, Spin } from "antd";
import isEmpty from "lodash/isEmpty";
import React from "react";
import { useContext, useEffect, useState } from "react";
import { getElementAtEvent } from "react-chartjs-2";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { updateChartResultSet } from "../../../../store/slices/dashboard";
import { RootState, useAppDispatch } from "../../../../store/store";
import { HBChartType } from "../../../../types/dashboard";
import { HierarchicalDataControlContext } from "../ChartOptions/HirarchialDataControl/HierarchicalDataControl";
import { ChartContext } from "../HBChart";
import { hirachialColumns } from "../Helpers";
import {
  dimensionCubeKey,
  dimensionName,
  dimensionsManipulation,
  filtersManipulation,
  manipulateHirarchialDrillDownQuery,
  ordersManipulation,
} from "../Helpers/QueryHelpers";
import Graph from "./Graph";
import HeatMap from "./HeatMap";
import NumberCard from "./NumberCard/NumberCard";
import TableChart from "./Table/TableChart";

const ChartContainer = React.forwardRef<ChartJSOrUndefined>((_, graphRef) => {
  const { cubejsApi } = useContext(CubeContext);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { chart } = useContext(ChartContext);
  const { pushValue, hierarchicalState: hierarchicalState } = useContext(HierarchicalDataControlContext);
  const [currentQuery, setCurrentQuery] = useState<Query>();
  const timezone = useSelector((state: RootState) => state.user.personalSettings.timeZone);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();

  const onChartClicked = (values: string[], axis: "x" | "y") => (event: any) => {
    if (chart?.useHierarchicalNavigation) {
      if (graphRef) {
        const points = getElementAtEvent((graphRef as any).current, event);
        if (chart?.resultSet && points?.length > 0) {
          const clickedElementIndex = (points as any)[0][axis === "x" ? "index" : "datasetIndex"];
          if (values && chart?.pivotConfig?.y && currentQuery) {
            // matches the cleicked element to the right value
            const yValue = values[clickedElementIndex];
            const dimensionFromPivotConfig = chart?.pivotConfig[axis];
            const yDimensionKey = dimensionFromPivotConfig?.[0];
            if (
              yValue &&
              yDimensionKey &&
              hirachialColumns.includes(dimensionCubeKey(yDimensionKey)) &&
              pushValue &&
              hierarchicalState
            ) {
              // pushes the next tree level based on the depth if existing
              const depthFilter = currentQuery.filters?.find(filter => {
                const f = filter as BinaryFilter | UnaryFilter;
                return (
                  f.member &&
                  dimensionCubeKey(f.member) === dimensionCubeKey(yDimensionKey) &&
                  dimensionName(f.member) === "depth"
                );
              }) as BinaryFilter | UnaryFilter;
              let depth = 1;
              if (depthFilter && depthFilter.values) {
                depth = parseInt(depthFilter.values[0]) + 1;
              }
              pushValue(yValue, depth, yDimensionKey);
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    if (currentQuery && chart && hierarchicalState) {
      let query: Query = { ...currentQuery, timezone };
      queryManipulation(query);
      setCurrentQuery(query);
      executeQuery(query);
    }
  }, [hierarchicalState]);

  const queryManipulation = (query: Query) => {
    if (chart?.dimensions) {
      dimensionsManipulation(query, chart);
    }
    if (chart?.filters) {
      filtersManipulation(query, chart);
    }
    if (chart?.orders) {
      ordersManipulation(query, chart);
    }
    if (chart?.useHierarchicalNavigation && hierarchicalState) {
      manipulateHirarchialDrillDownQuery(
        query,
        chart,
        hierarchicalState.depth,
        hierarchicalState.value,
        hierarchicalState.dimensionKey
      );
    }
    return query;
  };

  const executeQuery = (query: Query) => {
    if (chart?.query && !isEmpty(chart.query)) {
      setLoading(true);
      setError(undefined);
      cubejsApi
        .load(query)
        .then(resultSet => {
          dispatch(updateChartResultSet({ id: chart.id, resultSet: resultSet }));
          setLoading(false);
        })
        .catch(reason => {
          setError(reason.message);
          console.error(reason);
        });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    let query: Query = { ...chart?.query, timezone };
    queryManipulation(query);
    setCurrentQuery(query);
    executeQuery(query);
  }, [chart?.query, chart?.filters, chart?.orders, chart?.dimensions]);

  const renderChart = () => {
    if (error) {
      return (
        <div className="chartContainerLoadingError">
          <Result
            status="warning"
            title={t("DashboardChartLoadingErrorTitle")}
            subTitle={t("DashboardChartLoadingErrorTitle")}
          />
        </div>
      );
    }
    if (isEmpty(chart?.query)) {
      return (
        <div className="chartContainerEmptyChart">
          <Result status="info" title={t("DashboardNewChartTitle")} subTitle={t("DashboardNewChartSubTitle")} />
        </div>
      );
    }
    if (chart?.drillDownModeEnabled) {
      return <TableChart data={chart.resultSet} drillDownMode />;
    }
    switch (chart?.chartType) {
      case HBChartType.line:
      case HBChartType.bar:
      case HBChartType.pie:
        return (
          <Graph
            ref={graphRef}
            grouping={chart.barGrouping}
            type={chart.chartType}
            data={chart.resultSet}
            showNumber={chart.showNumber}
            showPrecent={chart.showPercent}
            onClick={onChartClicked}
          />
        );

      case HBChartType.heatmap:
        return (
          <HeatMap
            ref={graphRef}
            grouping={chart.barGrouping}
            data={chart.resultSet}
            showNumber={chart.showNumber}
            showPrecent={chart.showPercent}
            onClick={onChartClicked}
          />
        );
      case HBChartType.table:
        return <TableChart data={chart.resultSet} />;
      case HBChartType.number:
        return <NumberCard data={chart.resultSet} />;
      default:
        return (
          <div className="chartTypeNotSupported">
            <Result status="warning" title={t("DashboardUnsupportedChartTypeTitle")} />
          </div>
        );
    }
  };
  if ((loading && !error) || !chart) {
    return (
      <div className="chartContainerLoading">
        <Spin spinning />
      </div>
    );
  } else {
    return <div className="chartContainer">{renderChart()}</div>;
  }
});

export default ChartContainer;
