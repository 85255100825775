import { Select, Row, Typography, Col, Button } from "antd";
import { LabeledValue } from "antd/lib/select";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TOption } from "../../../types/page";
import { FilterValueType } from "../../../utils/hooks/useFilter";
import "./hbSelect.less";

type TProps = {
  /**
   * List of available options to choose from
   */
  options: TOption[];
  onChange: (value: boolean, textValue: string) => void;
  onDeselect: (key: string, value?: FilterValueType) => void;
  label: string;
  /**
   * Currently selected values coming from the active filter corresponding to this id.
   */
  value?: string[]; // Note: It should be boolean[]
  /**
   * locationId/typeId/orgUnitId/etc.
   */
  id: string;
  filterOpen: boolean;
  defaultOpen?: boolean;
};

export const HBBooleanSelect = ({
  options,
  onChange,
  value,
  onDeselect,
  id,
  label,
  filterOpen,
  defaultOpen = true,
}: TProps): JSX.Element => {
  const { t } = useTranslation();
  const optionsMap = options?.map(o => ({ id: o.id, value: o.label }));
  const [valuesWithLabels, setValuesWithLabels] = useState<LabeledValue[]>([]);

  // Note: that's a workaround for focusing the input on load. (Doesn't work without the setTimeout)
  const callbackRef = useCallback(
    inputElement => {
      if (filterOpen && inputElement) {
        setTimeout(() => {
          inputElement.focus();
        }, 1);
      }
    },
    [filterOpen]
  );

  useEffect(() => {
    if (value) {
      const selectedValues = optionsMap.filter(option => {
        const foundValue = value.find(val => (val as any) === !!option.id);
        if (typeof foundValue === "boolean") {
          return true;
        }
      });
      setValuesWithLabels(
        selectedValues.map(value => ({
          value: value.value,
          label: value.value,
        }))
      );
    }
  }, [value]);

  const handleOnDeselect = (v: LabeledValue, o: any) => {
    onDeselect(id, !!o.id);
  };

  const handleOnSelect = (v: LabeledValue, option: any) => {
    if (option) {
      onChange(!!option.id, option.value);
    }
  };

  return (
    <Row className="HBSelect-container">
      <Row justify="space-between" align="middle">
        <Col>
          <Typography.Text className="HBSelect-container__title">{t("Filter")}</Typography.Text>
        </Col>
        <Col>
          {value && value.length > 0 && (
            <Button type="text" danger onClick={() => onDeselect(id)} className="HBSelect-container__clear">
              {t("ClearFilters")}
            </Button>
          )}
        </Col>
      </Row>
      <Row>
        <Typography.Text className="HBSelect-container__filter-label">{t(label)}</Typography.Text>
      </Row>
      <Select
        ref={callbackRef}
        autoFocus
        defaultOpen={defaultOpen}
        mode="multiple"
        showAction={["focus"]}
        style={{ width: "20rem" }}
        placeholder={t("SearchButton")}
        onSelect={handleOnSelect}
        onDeselect={handleOnDeselect}
        options={optionsMap}
        labelInValue
        listHeight={120}
        maxTagCount={1}
        dropdownStyle={{ height: "20rem" }}
        popupClassName="HBSelect-dropdown"
        value={(valuesWithLabels.length > 0 && valuesWithLabels) || undefined}
      />
    </Row>
  );
};
