import { createAsyncThunk } from "@reduxjs/toolkit";
import { HistoryLog } from "../../types/utility";
import { hbApi, hbApiOptions } from "../api";
import { RootState } from "../store";

export const fetchHistoryLog = createAsyncThunk<
  HistoryLog[],
  { entityType: string; entityId: number },
  { state: RootState }
>("@@FETCH_HISTORY_LOG", async ({ entityType, entityId }, { getState }) => {
  const { user } = getState();
  const response = await hbApi.get<HistoryLog[]>(`/HistoryLog/${entityType}/${entityId}`, hbApiOptions(user.jwt));

  return response.data;
});
