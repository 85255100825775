import React from "react";
import { useSelector } from "react-redux";
import useFitText from "use-fit-text";
import { RootState } from "../../../../../store/store";

import "./NumberCard.less";

export enum CaretDirection {
  Down,
  Up,
}
type NumericFitTextProps = {
  text?: string;
  caretDirection?: CaretDirection;
  weight?: number;
};

export default function NumericFitText({ text, caretDirection, weight }: NumericFitTextProps) {
  const { fontSize, ref } = useFitText({ minFontSize: 30, maxFontSize: 3000, resolution: 5 });
  const primaryColor = useSelector((state: RootState) => state.common.primaryColor);
  return (
    <div
      className="numeric-fit-text"
      ref={ref}
      style={{
        fontSize,
        fontWeight: weight || 150,
        color: caretDirection !== undefined ? (caretDirection === CaretDirection.Down ? "red" : "green") : primaryColor,
      }}
    >
      <div className="caret-size">{`${
        caretDirection !== undefined ? (caretDirection === CaretDirection.Down ? "▼" : "▲") : ""
      }`}</div>
      {text}
    </div>
  );
}
