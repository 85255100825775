import { Badge } from "antd";
import dayjs from "dayjs";
import { trainingSelectors } from "../../../../selectors";
import { addEntityPrivilege, updateEntityPrivilegeRole } from "../../../../store/slices/privileges";
import {
  addTrainingTrainee,
  changeTraineeStatus,
  deleteNewParticipantEntryTemplate,
  deleteNewTraineeEntryTemplate,
  fillNewParticipantTemplate,
  fillNewTraineeTemplate,
  updateNewParticipantEntryRole,
  updateNewTraineeEntryStatus,
} from "../../../../store/slices/training";
import { ColumnType, ColumnWithDifferentCells, TOption } from "../../../../types/page";
import { Training, TrainingStatus, TrainingTrainee } from "../../../../types/training";
import { PrivilegeData, PrivilegedEntityType, Role, TraineeStatus } from "../../../../types/utility";
import { numericOptions } from "../../../../utils/functions";
import { AccountabilityOptions, getCellSearchResultColumns, TraineeStatusOptions } from "../common";
import { localizeText, renderBoolStatus } from "../utilities";

export const statusOptions: TOption[] = [
  {
    id: TrainingStatus.Passed,
    label: "Passed",
    icon: <Badge color="#00ECEC" />,
  },
  {
    id: TrainingStatus.Canceled,
    label: "Canceled",
    icon: <Badge color="red" />,
  },
  {
    id: TrainingStatus.InProgress,
    label: "InProgress",
    icon: <Badge color="orange" />,
  },
  {
    id: TrainingStatus.Planned,
    label: "Planned",
    icon: <Badge color="yellow" />,
  },
];

export const renderTrainingStatusOptionIcon = (status: TrainingStatus): React.ReactElement | null => {
  switch (status) {
    case TrainingStatus.Passed:
      return <Badge color="#00ECEC" />;

    case TrainingStatus.Canceled:
      return <Badge color="red" />;

    case TrainingStatus.InProgress:
      return <Badge color="orange" />;

    case TrainingStatus.Planned:
      return <Badge color="yellow" />;

    default:
      return null;
  }
};

export const trainingTableColumns: ColumnType<Training>[] = [
  {
    id: "plannedDate",
    label: "PlannedDate",
    optionsSelector: () => null,
    renderValue: value => (value.plannedDate ? dayjs(value.plannedDate).format("DD/MM/YYYY") : null),
    sortable: true,
    filterType: "dateRange",
    width: 100,
  },
  {
    id: "certificateId",
    label: "FieldCertificate",
    // TODO: Change once we know from where we can get the organizations/team
    renderValue: value => value.certificateName,
    valueType: "string",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "Certificate",
    asyncFetchFilterOptionsUseKeyValue: true,
    propName: "name",
    filterType: "multiSelect",
    sortable: true,
    width: 100,
  },
  {
    id: "supplier",
    label: "Supplier",
    renderValue: value => value.supplier,
    valueType: "string",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "Training",
    filterType: "multiSelect",
    sortable: true,
    width: 100,
  },
  {
    id: "externalId",
    label: "ExternalID",
    renderValue: value => value.externalId,
    valueType: "string",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "Training",
    filterType: "multiSelect",
    sortable: true,
    width: 100,
  },
  {
    id: "attendedParticipantsCount",
    label: "FieldAttendees",
    renderValue: value => value.attendedParticipantsCount?.toString() ?? "",
    optionsSelector: numericOptions,
    filterType: "multiSelect",
    sortable: true,
    width: 60,
  },
  {
    id: "participantsCount",
    label: "FieldTrainee",
    renderValue: value => value.participantsCount?.toString() ?? "",
    optionsSelector: numericOptions,
    filterType: "multiSelect",
    sortable: true,
    width: 60,
  },
  {
    id: "locationId",
    label: "AppointmentLocationLink",
    renderValue: value => value.locationName,
    sortable: true,
    filterType: "multiSelect",
    valueType: "string",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "Location",
    asyncFetchFilterOptionsUseKeyValue: true,
    propName: "name",
    width: 100,
  },
  {
    id: "status",
    label: "FieldStatus",
    valueIcon: e => renderTrainingStatusOptionIcon(e.status),
    renderValue: value =>
      statusOptions.find(o => o.id === value.status)
        ? localizeText(statusOptions.filter(o => o.id === value.status)[0].label)
        : null,
    sortable: true,
    filterType: "multiSelect",
    optionsSelector: () =>
      statusOptions.map(l => ({
        id: l.id,
        label: localizeText(l.label),
      })),
    width: 100,
  },
];

export const traineeTabColumns: ColumnWithDifferentCells<TrainingTrainee>[] = [
  {
    id: "name",
    label: "FieldName",
    renderValue: value => value.name || "",
    sortable: false,
    filterType: "multiSelect",
    cellType: e => (e.staging ? "search" : "text"),
    cellSearchSelector: trainingSelectors.allTrainingEmployees,
    cellSearchResultColumns: getCellSearchResultColumns(["name", "phone"]),
    cellSearchKeys: ["name"],
    cellSearchRecordClick: fillNewTraineeTemplate,
    optionsSelector: () => null,
    width: 100,
    primaryColumn: e => ({
      navigationTarget: `/employee/${e.id}`,
    }),
  },
  {
    id: "externalId",
    label: "ExternalID",
    renderValue: value => value.externalId || "",
    sortable: false,
    filterType: "multiSelect",
    cellType: e => (e.staging ? "search" : "text"),
    cellSearchSelector: trainingSelectors.allTrainingEmployees,
    cellSearchResultColumns: getCellSearchResultColumns(["name", "externalId"]),
    cellSearchKeys: ["externalId"],
    cellSearchRecordClick: fillNewTraineeTemplate,
    optionsSelector: () => null,
    width: 100,
  },
  {
    id: "isExternal",
    label: "External",
    renderValue: value => (value.isExternal !== null ? renderBoolStatus(value.isExternal) : ""),
    sortable: false,
    optionsSelector: () => null,
    width: 100,
  },
  {
    id: "status",
    label: "FieldStatus",
    renderValue: e => (e.status ? localizeText(e.status.toString()) : ""),
    cellType: () => "dropdown",
    cellDropdownOptions: () =>
      TraineeStatusOptions.map(tso => ({
        id: tso.id,
        label: localizeText(tso.label),
      })),
    cellDropdownOnChange: (e, newValue) =>
      e.staging
        ? updateNewTraineeEntryStatus({ entity: e, newValue: newValue as TraineeStatus })
        : changeTraineeStatus({ entity: e, newValue: newValue as TraineeStatus }), // TODO: Implement a thunk for updating the role of a urgUnit/employee relation
    sortable: false,
    optionsSelector: () => null,
    width: 100,
  },
  {
    id: "action" as keyof TrainingTrainee,
    label: "FieldAction",
    renderValue: () => null,
    sortable: false,
    optionsSelector: () => null,
    cellType: () => "buttonActions",
    width: 100,
    stagingActions: [
      {
        label: "Add",
        className: "hb-primary-button",
        action: entity => addTrainingTrainee(entity),
      },
      {
        label: "Cancel",
        className: "hb-tertiary-button",
        action: entity => deleteNewTraineeEntryTemplate(entity),
      },
    ],
  },
];

export const accountableTabColumns: ColumnWithDifferentCells<PrivilegeData>[] = [
  {
    id: "name",
    label: "FieldName",
    renderValue: value => value.name || "",
    sortable: false,
    filterType: "multiSelect",
    cellType: e => (e.staging ? "search" : "text"),
    cellSearchSelector: trainingSelectors.allTrainingEmployeesAsAccountable,
    cellSearchResultColumns: getCellSearchResultColumns(["name", "externalId"]),
    cellSearchKeys: ["name"],
    cellSearchRecordClick: fillNewParticipantTemplate,
    optionsSelector: () => null,
    width: 100,
    primaryColumn: e => ({
      navigationTarget: e.userId ? `/employee/${e.userId}` : `/orgUnit/${e.orgUnitId}`,
    }),
  },
  {
    id: "externalId",
    label: "ExternalID",
    renderValue: value => value.externalId || "",
    sortable: false,
    filterType: "multiSelect",
    cellType: e => (e.staging ? "search" : "text"),
    cellSearchSelector: trainingSelectors.allTrainingEmployeesAsAccountable,
    cellSearchResultColumns: getCellSearchResultColumns(["name", "externalId"]),
    cellSearchKeys: ["externalId"],
    cellSearchRecordClick: fillNewParticipantTemplate,
    optionsSelector: () => null,
    width: 100,
  },
  {
    id: "isExternal",
    label: "External",
    renderValue: value => (value.isExternal !== null ? renderBoolStatus(value.isExternal) : ""),
    sortable: false,
    optionsSelector: () => null,
    width: 100,
  },
  {
    id: "status",
    label: "FieldStatus",
    renderValue: value => (value.status ? localizeText(value.status.toString()) : ""),
    sortable: false,
    optionsSelector: () => null,
    width: 100,
  },
  {
    id: "role",
    label: "Accountability",
    renderValue: e => (e.role ? localizeText(e.role.toString()) : ""),
    cellType: () => "dropdown",
    cellDropdownOptions: () =>
      AccountabilityOptions.map(ao => ({
        id: ao.id,
        label: localizeText(ao.label),
      })),
    cellDropdownOnChange: (e, newValue) =>
      e.staging
        ? updateNewParticipantEntryRole({ entity: e, newValue: newValue as Role })
        : updateEntityPrivilegeRole({
            entity: e,
            role: newValue as Role,
            id: null,
            entityType: PrivilegedEntityType.Training,
          }),
    sortable: false,
    optionsSelector: () => null,
    width: 100,
  },
  {
    id: "action" as keyof PrivilegeData,
    label: "FieldAction",
    renderValue: () => null,
    sortable: false,
    optionsSelector: () => null,
    cellType: () => "buttonActions",
    width: 100,
    stagingActions: [
      {
        label: "Add",
        className: "hb-primary-button",
        action: entity => addEntityPrivilege({ entity: entity, entityType: PrivilegedEntityType.Training, id: null }),
      },
      {
        label: "Cancel",
        className: "hb-tertiary-button",
        action: entity => deleteNewParticipantEntryTemplate(entity),
      },
    ],
    // buttonWithDropdownActions: {
    //   primaryButton: {
    //     label: "Action Button",
    //     action: entity => console.log("clicked primary button"),
    //   },
    //   dropdownButtons: [
    //     {
    //       label: "Delete Relation",
    //       className: "hb-primary-button",
    //       action: entity => deleteParticipantRelation(entity),
    //       changesConfirmation: {
    //         body: "Are you sure you wish to remove this entity?",
    //         okText: "Remove",
    //         cancelText: "Keep",
    //         addValueToBody: false,
    //         addValueToOkText: false,
    //       },
    //     },
    //   ],
    // },
  },
];
