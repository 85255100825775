import { Modal, Button } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UploadModalType } from "../../../types/utility";
import HBInput from "../Input/HBInput";

type TProps = {
  isVisible: boolean;
  addComment: (value: string) => void;
  closeModal: () => void;
  uploadModalType: UploadModalType;
};

export const HbAddCommentModal = ({ isVisible, addComment, closeModal, uploadModalType }: TProps): JSX.Element => {
  const { t } = useTranslation();
  const [comment, setComment] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onCommentChanged = (value: string | null | number) => {
    setComment(value?.toString() || "");
  };

  useEffect(() => {
    if (!isVisible) {
      setComment("");
      setIsLoading(false);
    }
  }, [isVisible]);

  const onSave = () => {
    setIsLoading(true);
    addComment(comment);
  };

  return (
    <Modal title={t("AddComment")} open={isVisible} footer={null} onCancel={closeModal}>
      {uploadModalType === UploadModalType.AddComment && (
        <div className="upload-modal-comment">
          <span>{t("Comment")}</span>
          <span>
            <HBInput onChange={onCommentChanged} value={comment} type={"textArea"} />
          </span>
        </div>
      )}
      {uploadModalType === UploadModalType.AddComment && (
        <div className="upload-modal-action-button">
          <Button onClick={onSave} loading={isLoading}>
            {t("Save")}
          </Button>
        </div>
      )}
    </Modal>
  );
};
