import { UpOutlined, DownOutlined } from "@ant-design/icons";
import React, { useState } from "react";

interface TodoListSorterProps {
  onSort: (order: SortOrder) => void;
}

export enum SortOrder {
  ASC = "asc",
  DESC = "desc",
}

const TodoListSorter: React.FC<TodoListSorterProps> = ({ onSort }) => {
  const [sortState, setSortState] = useState<SortOrder>(SortOrder.DESC);

  const handleSortChange = (sortState: SortOrder) => {
    const nextOrder = sortState === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
    setSortState(nextOrder);
    onSort(nextOrder);
  };

  const renderIcon = (mode: SortOrder) => {
    return (
      <div className="todolist-sorter" onClick={() => handleSortChange(sortState)}>
        <UpOutlined className={`icon ${mode === SortOrder.ASC ? "" : SortOrder.ASC}`} />
        <DownOutlined className={`icon ${mode === SortOrder.DESC ? "" : SortOrder.DESC}`} />
      </div>
    );
  };

  return renderIcon(sortState);
};

export default TodoListSorter;
