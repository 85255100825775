import { SerializedError } from "@reduxjs/toolkit";
import { InspectionRecurrencyType, InspectionStatus } from "./inspection";
import { AppointmentStatus, TaskPriority, TodoListCardStatus } from "./utility";

export interface TodolistState {
  todoList: TodoListSectioned;
  filteredTodoList: TodoListSectioned;
  isLoading: boolean;
  sectionIsLoading: TodoListIsLoadingPerSction;
  error: SerializedError | string | null;
  todoListLoaded: boolean;
  total: TodoTotal;
  totalFiltered: TodoTotal;
  searchTerm: string;
  todoListStatusFilter: TodoListStatusFilterState;
  todoListViewFilter: TodoListViewFilter;
}
export type TodoListStatusFilterState = { [key in TodoListStatusFilter]?: boolean };
export type TodoListIsLoadingPerSction = { [key in TodoSection]?: boolean };

export enum TodoListStatusFilter {
  PendingOnly = "PendingOnly",
  LateOnly = "LateOnly",
  ShowAll = "ShowAll",
}

export enum TodoListViewFilter {
  OnlyMy = "OnlyMy",
  ViewedByMe = "ViewedByMe",
}

export type TodoList = {
  inspections: TodoListInpection[];
  appointments: TodoListAppointment[];
  tasks: TodoListTask[];
};

export type TodoListInpection = {
  id: number;
  inspectionTypeName: string;
  mappedEntityName: string;
  mappedEntityExternalId: string;
  reviewId?: number;
  expirationDate: string;
  endPeriod: string;
  status: InspectionStatus;
  recurrencyType: InspectionRecurrencyType;
  locationExternalId: string;
  locationName: string;
  cardStatus?: TodoListCardStatus;
  inspectionId?: number;
  linkedEntityType: InspectionLinkedEntityType;
  sortingDate: string;
};

export const enum InspectionLinkedEntityType {
  Equipment = "Equipment",
  Location = "Location",
}

export type TodoListAppointment = {
  id: number;
  title: string;
  startDate: string;
  endDate: string;
  status: AppointmentStatus;
  cardStatus?: TodoListCardStatus;
  assignedToUser?: number;
  recurrency: string | null;
  sortingDate: string;
};

export type TodoListTask = {
  id: number;
  startDate: string;
  completedDate?: string | null;
  dueDate: string;
  title: string;
  cardStatus?: TodoListCardStatus;
  generatedByAppointmentId?: number;
  canBeCompleted?: boolean;
  projectId?: number | null;
  reviewIds?: number[] | null;
  createdById: number | null;
  createdByName: string | null;
  priority: TaskPriority;
  sortingDate: string;
};

export type TodoListSectioned = {
  today: TodoList;
  week: TodoList;
  month: TodoList;
  quarter: TodoList;
  year: TodoList;
};

export type TodoTotal = {
  today: number;
  week: number;
  month: number;
  quarter: number;
  year: number;
};

export enum TodoSection {
  Today = "today",
  Week = "week",
  Month = "month",
  Quarter = "quarter",
  Year = "year",
}

export const InitialTodoObj = {
  today: {
    inspections: [],
    appointments: [],
    tasks: [],
  },
  week: {
    inspections: [],
    appointments: [],
    tasks: [],
  },
  month: {
    inspections: [],
    appointments: [],
    tasks: [],
  },
  quarter: {
    inspections: [],
    appointments: [],
    tasks: [],
  },
  year: {
    inspections: [],
    appointments: [],
    tasks: [],
  },
};
