export enum CubeReview {
  id = "Review.id",
  name = "Review.name",
  active = "Review.active",
}

export enum CubeReviewCategory {
  id = "ReviewCategory.id",
  name = "ReviewCategory.name",
  active = "ReviewCategory.active",
}

export enum CubeReviewQuestion {
  id = "ReviewQuestion.id",
  text = "ReviewQuestion.text",
  active = "ReviewQuestion.active",
}
