import { Children, ReactNode, cloneElement, isValidElement, useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, RouteComponentProps, RouteProps } from "react-router-dom";
import PageConfigurationContext from "../context/pageContext";
import { ReactComponent as PasIcon } from "../media/pas-icon.svg";
import { isDevEnvironment } from "../pages/pageConfig/category/utilities";
import { getPageByCategoryId } from "../pages/pageConfig/categoryPageConfig";
import { RootState } from "../store/store";
import { CategoryId } from "../types/page";
import { UserRole } from "../types/user";
import { LocalStorageKeys } from "../types/utility";
import useRouter from "../utils/hooks/useRouter";
import { VitreModalComponent } from "./Vitre/VitreModalComponent";

type TProps = RouteProps & {
  privateRoute?: boolean;
  type?: "category" | "generic";
  allowedUserRoles?: UserRole[];
};

type TParams = { category?: CategoryId };

export default ({
  children,
  privateRoute,
  type = "generic",
  allowedUserRoles,
  location,
  ...rest
}: TProps): JSX.Element => {
  const settings = useSelector((state: RootState) => state.user.settings);
  const jwt = useSelector((state: RootState) => state.user.jwt);
  const storedJwt = localStorage.getItem(LocalStorageKeys.JWT);
  const router = useRouter();
  const isVitreOrigin = window.location.origin.includes(`${process.env.REACT_APP_NEW_UI_ORIGIN_PART}`);

  useEffect(() => {
    if (jwt === "" && storedJwt) {
      return;
    }

    if (!jwt && !storedJwt && privateRoute) {
      const returnUrl = `${location?.pathname.replace(/^\/|\/$/g, "")}${location?.search}`;
      if (!isVitreOrigin) {
        router.push(`/login${returnUrl ? `?returnUrl=${returnUrl}` : ""}`);
        //remove after vitre migration
      } else {
        window.location.href = `${process.env.REACT_APP_NEW_PAS_URL}/vitreEntrance?returnUrl=${returnUrl}`;
      }
    }
  }, [jwt, storedJwt, privateRoute]);

  if (!jwt && !storedJwt && privateRoute) {
    return (
      <div className="pas-icon-container">
        <PasIcon />
      </div>
    );
  }

  if (
    settings.role !== UserRole.None &&
    allowedUserRoles &&
    allowedUserRoles?.length > 0 &&
    !allowedUserRoles?.includes(settings.role)
  ) {
    return <Redirect to={{ pathname: "/notAllowed", state: { referer: location } }} />;
  }

  if (type === "generic") {
    return <Route {...rest}>{children}</Route>;
  }

  return (
    <Route
      {...rest}
      render={(props: RouteComponentProps<TParams>): ReactNode => {
        const categoryId = props.match.params.category;

        if (!categoryId) {
          props.history.push("/error");
          return;
        }
        const currentPage = getPageByCategoryId(categoryId);

        if (!currentPage) {
          props.history.push("/error");
          return;
        }

        if (
          settings.role !== UserRole.None &&
          currentPage.allowedUserRoles &&
          currentPage.allowedUserRoles.length &&
          !currentPage.allowedUserRoles.includes(settings.role)
        ) {
          props.history.push("/notAllowed");
          return;
        }

        const childrenWithProps = Children.map(children, child => {
          if (isValidElement(child)) {
            return cloneElement(child, props);
          }
          return child;
        });

        return (
          <>
            <PageConfigurationContext.Provider value={currentPage}>
              {childrenWithProps}
            </PageConfigurationContext.Provider>
            {!isDevEnvironment() &&
              privateRoute &&
              !window.location.origin.includes(`${process.env.REACT_APP_NEW_UI_ORIGIN_PART}`) && (
                <VitreModalComponent />
              )}
          </>
        );
      }}
    />
  );
};
