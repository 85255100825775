import { Row, Space, TimePicker } from "antd";
import generatePicker from "antd/es/date-picker/generatePicker";
import dayjs, { Dayjs } from "dayjs";
import moment, { Moment } from "moment";
import dateFnsGenerateConfig from "rc-picker/lib/generate/dayjs";
import "antd/lib/date-picker/style/index";
import React, { useEffect, useRef, useState, useCallback } from "react";
import "./hbDatePicker.less";
import { serverDateFormat } from "../../../pages/TodoList/utils";
import { HBEventName } from "../../../types/analyticsTypes/HBEvent";
import useInitTrackEvents from "../../../utils/hooks/useInitTrackEvents";

export const DatePicker = generatePicker<Dayjs>(dateFnsGenerateConfig);

type TProps = {
  onChange: (v: string | null) => void;
  value: string | number | null;
  icon?: string | null | React.ReactNode;
  disabledDate?: (date: Dayjs) => boolean;
  noWrapperStyle?: boolean;
  renderExtraFooter?: () => React.ReactNode;
};

const DATE_FORMAT_WITH_TIME = "DD/MM/YYYY HH:mm";
const DATE_FORMAT_WITHOUT_TIME = "DD/MM/YYYY";

const HBDatePicker: React.FC<TProps> = ({
  onChange,
  value,
  children,
  icon,
  disabledDate,
  noWrapperStyle,
  renderExtraFooter,
}): JSX.Element => {
  const DATE_FORMAT = dayjs(value, DATE_FORMAT_WITH_TIME).isValid() ? DATE_FORMAT_WITH_TIME : DATE_FORMAT_WITHOUT_TIME;

  const formattedValue = value ? dayjs(value, DATE_FORMAT) : undefined;

  const { track } = useInitTrackEvents();

  useEffect(() => {
    if (value) {
      if (inputValue && inputValue.isSame(dayjs(value, DATE_FORMAT))) {
        return;
      }
      setInputValue(dayjs(value, DATE_FORMAT));
    }
  }, [value]);

  const [inputValue, setInputValue] = useState(formattedValue);

  const previousInputValue = useRef(inputValue);

  useEffect(() => {
    if (!inputValue || !formattedValue) {
      if (inputValue !== formattedValue) {
        onChange(inputValue ? inputValue.format() : null);
      }
      return;
    }

    if (dayjs(inputValue).isValid() && inputValue !== previousInputValue.current) {
      const formatted = inputValue.format();

      onChange(formatted);
    }

    previousInputValue.current = inputValue;
  }, [inputValue]);

  const handleChange = useCallback(
    (value: Dayjs | null) => {
      track({ eventName: HBEventName.DateChange });
      onChange(value ? dayjs(value).utc().format() : null);
    },
    [onChange]
  );

  const handleDisabledDates = useCallback(
    (current: Dayjs) => {
      if (disabledDate) {
        return disabledDate(current);
      }
      return false;
    },
    [disabledDate]
  );

  return (
    <Row className={noWrapperStyle ? "" : "HB-datePicker-wrapper"}>
      <Space className="space-first-item-align-center" size={5}>
        {icon && icon}
        {children}
        <DatePicker
          showTime={dayjs(value, DATE_FORMAT_WITH_TIME).isValid() ? { format: "HH:mm" } : undefined}
          bordered={false}
          defaultValue={dayjs(inputValue).isValid() ? inputValue : dayjs()}
          onChange={handleChange}
          value={formattedValue}
          format={DATE_FORMAT}
          className="HB-datePicker-wrapper__value"
          disabledDate={handleDisabledDates}
          renderExtraFooter={renderExtraFooter}
        />
      </Space>
    </Row>
  );
};

export default HBDatePicker;
