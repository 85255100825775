import "@cubejs-client/playground/lib/antd.min.css";
import { QueryBuilder } from "@cubejs-client/playground";
import { CubeContext } from "@cubejs-client/react";
import { Button, Space, Input, Modal, Typography, message } from "antd";
import { useState, useContext } from "react";
import { ExportData } from "../../../actions/menuActions";
import useRouter from "../../../utils/hooks/useRouter";

const { TextArea } = Input;
const { Title } = Typography;

export default function QueryBuilderPage({ apiUrl, token }) {
  const { history, location } = useRouter();
  const [userQuery, setUserQuery] = useState("");
  const [vizStateQuery, setVizStateQuery] = useState({});
  const [showUserQueryModal, setShowUserQueryModal] = useState(false);
  const onVizStateChanged = vizState => {
    setVizStateQuery(vizState.query);
  };
  const { cubejsApi } = useContext(CubeContext);

  const onButtonClick = () => {
    cubejsApi.load(vizStateQuery).then(resultSet => {
      message.loading({ content: "Downloading Query As Excel..", duration: 0, key: "queryLoading" });
      if (resultSet !== null) {
        const template = resultSet.tableColumns().map(column => {
          const template = {
            type: "Header",
            property: column.dataIndex,
            value: column.title,
          };

          return template;
        });

        template.push({
          type: "Option",
          property: "RTL",
          value: "true",
        });

        let today = new Date();
        let filename = `data-exported-${today.toLocaleString().split(",")[0].replaceAll(".", "-")}.xlsx`;

        ExportData(resultSet.tablePivot(), template, filename).then(() => message.destroy("queryLoading"));
      }
    });
  };

  const onOpenUserQueryModal = () => {
    setShowUserQueryModal(!showUserQueryModal);
  };
  const onCancelUserQueryModal = () => {
    setShowUserQueryModal(false);
    setUserQuery("");
  };

  const onUpdateQuery = () => {
    if (prettify()) {
      history.push({ pathname: location.pathname, search: `?query=${userQuery}` });
      window.location.reload();
      setUserQuery(null);
      setShowUserQueryModal(false);
    }
  };

  const onUserQueryChange = e => {
    setUserQuery(e.target.value);
  };

  const prettify = () => {
    let parsedValue = userQuery;
    if (parsedValue !== "") {
      try {
        parsedValue = JSON.parse(userQuery);
        setUserQuery(JSON.stringify(parsedValue, null, 2));
      } catch (exception) {
        message.error("Input is not a valid json!");
        return false;
      }
    } else {
      setUserQuery("");
    }

    return true;
  };

  return (
    <div>
      <Space
        direction="horizontal"
        style={{
          marginBottom: "1rem",
        }}
      >
        <Button onClick={onButtonClick}>Download File</Button>
        <Button onClick={onOpenUserQueryModal}>Set Query</Button>
      </Space>
      <Modal open={showUserQueryModal} destroyOnClose onOk={onUpdateQuery} onCancel={onCancelUserQueryModal}>
        <Title level={3}>Set Query</Title>
        <Button onClick={prettify} style={{ marginBottom: "1rem" }}>
          Prettify
        </Button>
        <TextArea
          style={{ whiteSpace: "pre-line", overflow: "scroll", height: "50rem" }}
          onChange={onUserQueryChange}
          value={userQuery}
          placeholder="please insert query as json"
        />
      </Modal>
      <QueryBuilder apiUrl={apiUrl} token={token} onVizStateChanged={onVizStateChanged} />
    </div>
  );
}
