import { Select } from "antd";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import tinycolor from "tinycolor2";
import { setHeatmapValue, addLayer } from "../../../store/slices/map";
import { RootState, useAppDispatch } from "../../../store/store";
import { CubeAppointment } from "../../../types/cubeTypes/Appointment";
import { CubeTasks } from "../../../types/cubeTypes/Task";

const { Option } = Select;

const sample = [
  {
    step: 0,
    alpha: 0,
    color: "rgba(255, 102, 148, 0)",
  },
  {
    step: 0.33,
    alpha: 0.2,
    color: "rgba(255, 102, 148, 0.2)",
  },
  {
    step: 0.66,
    alpha: 1,
    color: "#FFF4E0",
  },
  {
    step: 1,
    alpha: 1,
    color: "#FF6694",
  },
];

export const heatmapOptions = [
  {
    dimension: CubeAppointment.answersDefect,
    display: "Answers With Defects",
    weight: [
      [0, 2],
      [7, 3],
      [50, 7],
    ],
  },
  {
    dimension: CubeAppointment.ansewrsCriticalDefect,
    display: "Answers With Critical Defects",
    weight: [
      [1, 0],
      [7, 1],
      [50, 7],
    ],
  },
  {
    dimension: CubeAppointment.score,
    display: "Score",
    weight: [
      [-5, -100],
      [0, -5],
      [10, 0],
      [50, 10],
      [100, 50],
    ],
  },
  {
    dimension: CubeTasks.taskDelay,
    //parser: (x) => parseInt(x),
    display: "Task Delay",
    weight: [
      [-2, -100],
      [0, -2],
      [1, 0],
      [5, 1],
      [100, 5],
    ],
  },
  {
    dimension: CubeAppointment.numOfTasks,
    //parser: (x) => parseInt(x),
    display: "Task Count",
    weight: [
      [1, 0],
      [2, 1],
      [50, 2],
    ],
  },
];

export default function HeatmapControl() {
  const dispatch = useAppDispatch();
  const { heatmapValue } = useSelector((state: RootState) => state.map);
  const handleHeatmapValueSelected: (value: number) => void = value => {
    dispatch(setHeatmapValue(heatmapOptions[value]));
  };
  const primaryColor = useSelector((state: RootState) => state.common.primaryColor);

  const getHeatmapMatcher = () => {
    const colors = tinycolor(primaryColor).monochromatic(sample.length).reverse();
    sample.forEach((sampleStep, index) => (sampleStep.color = colors[index].setAlpha(sampleStep.alpha).toRgbString()));

    return [
      "interpolate",
      ["linear"],
      ["heatmap-density"],
      ...sample.reduce<any[]>((acc, item) => {
        acc.push(item.step, item.color);
        return acc;
      }, []),
    ];
  };

  useEffect(() => {
    dispatch(
      addLayer({
        layer: {
          id: "heatmap",
          type: "heatmap",
          paint: {
            "heatmap-intensity": {
              stops: [
                [11, 1],
                [15, 3],
              ],
            },
            "heatmap-radius": {
              stops: [
                [14, 20],
                [30, 30],
                [50, 40],
              ],
            },
            "heatmap-weight": {
              property: "value",
              type: "exponential",
              stops: heatmapValue.weight,
            },
            "heatmap-color": getHeatmapMatcher(),
            "heatmap-opacity": {
              default: 1,
              stops: [
                [14, 1],
                [17, 0.5],
                [22, 0],
              ],
            },
          },
          source: "appointments",
        },
      })
    );
  }, []);
  return (
    <Select style={{ width: "100%" }} defaultValue={0} onChange={handleHeatmapValueSelected}>
      {heatmapOptions.map((item, i) => (
        <Option key={i} value={i}>
          {item.display}
        </Option>
      ))}
    </Select>
  );
}
