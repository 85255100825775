import isEqual from "lodash/isEqual";
import { useEffect, useRef } from "react";

export function useDeepCompareEffect(callback: () => void, dependencies: any[]) {
  const firstRenderRef = useRef(true);
  const dependenciesRef = useRef(dependencies);

  if (!isEqual(dependencies, dependenciesRef.current)) {
    dependenciesRef.current = dependencies;
  }

  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      return;
    }

    return callback();
  }, [dependenciesRef.current]);
}
