import { SettingOutlined } from "@ant-design/icons";
import { nanoid, unwrapResult } from "@reduxjs/toolkit";
import { Button, Popover, Space } from "antd";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ReactComponent as CompanySettingsIcon } from "../../media/company-settings.svg";
import { ReactComponent as SettingsIcon } from "../../media/settings.svg";
import { getRedirectToLegacy } from "../../store/slices/user";
import { RootState, useAppDispatch } from "../../store/store";
import { MenuItemType, ProfileMenuItem, getAlignment } from "./utility";

import "./ProfileMenu.less";

export default function CompanySettings() {
  const dispatch = useAppDispatch();
  const settings = useSelector((state: RootState) => state.user.settings);
  const { t } = useTranslation();

  const companySettingsMenuItems: ProfileMenuItem[] = [
    {
      title: "MenuCompanySettings",
      icon: <CompanySettingsIcon />,
      type: MenuItemType.Action,
      action: () => {
        dispatch(getRedirectToLegacy({ returnUrl: "companysettings", triggerLoader: false }))
          .then(res => unwrapResult(res))
          .then(data => window.open(data.returnUrl, "_blank"))
          .catch(e => {
            console.error("Get key error: ", e);
          });
      },
    },
  ];

  const getMenuItem: (item: ProfileMenuItem) => ReactNode = item => {
    return (
      <Button
        onClick={item.action ? item.action : null}
        disabled={item.disabled}
        type="text"
        className="menu-button"
        key={nanoid()}
      >
        <div id="icon-container">{item.icon}</div>
        {t(item.title)}
      </Button>
    );
  };

  return (
    <Popover
      style={{ cursor: "pointer" }}
      // align={getAlignment(settings.direction)}
      placement={settings.direction === "ltr" ? "rightBottom" : "leftTop"}
      content={
        <Space direction="vertical" className="profile-menu-item" wrap={true}>
          {companySettingsMenuItems
            .filter(item => !item.disabled)
            .map(item => {
              if (item.type === MenuItemType.Menu) {
                return (
                  <Popover
                    align={getAlignment(settings.direction)}
                    placement={settings.direction === "ltr" ? "rightBottom" : "leftTop"}
                    key={item.title}
                    trigger="hover"
                    content={item.content}
                  >
                    {getMenuItem(item)}
                  </Popover>
                );
              } else {
                return getMenuItem(item);
              }
            })}
        </Space>
      }
    >
      <SettingsIcon />
    </Popover>
  );
}
