import { PlusOutlined } from "@ant-design/icons";
import { Tag, Input, Tooltip, AutoComplete, Row, Typography } from "antd";
import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import "antd/dist/antd.css";
import "./index.less";
import { localizeText } from "../../pages/pageConfig/category/utilities";
import { TOption } from "../../types/page";

type AutocompleteOptions = {
  id: string | number;
  value: string;
};

interface TagsState {
  tags: string[];
  options: AutocompleteOptions[];
  inputVisible: boolean;
  inputValue: string;
  editInputIndex: number;
  editInputValue: string;
}
interface TagsComponentProps {
  onChange: (v: string | null | boolean | number) => void;
  initialTags: string | null;
  options?: TOption[] | null;
  title?: string;
}
const TagsComponent: FC<TagsComponentProps> = ({ initialTags, onChange, options, title }) => {
  const [state, setState] = useState<TagsState>({
    tags: [],
    options: [],
    inputVisible: false,
    inputValue: "",
    editInputIndex: -1,
    editInputValue: "",
  });
  const inputRef = useRef<any>(null);

  useEffect(() => {
    setState(state => ({
      ...state,
      tags: initialTags ? initialTags.split(",") : [],
      options: options
        ? options.map(tag => ({
            id: tag.id ?? "",
            value: tag.label,
          }))
        : [],
    }));
  }, []);

  useEffect(() => {
    if (initialTags === state.tags.join(",") && !initialTags) {
      return;
    }
  }, [state.tags]);

  const handleClose = (removedTag: string) => {
    const tags = state.tags.filter(tag => tag !== removedTag);
    setState(state => ({ ...state, tags }));
  };

  const handleSelect = (tag: string) => {
    let { tags } = state;
    if (tag && !tags.includes(tag)) {
      tags = [...tags, tag];
    }
    setState(state => ({
      ...state,
      tags,
      inputVisible: true,
      inputValue: "",
    }));
  };

  const showInput = () => {
    setState(state => ({ ...state, inputVisible: true }));
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setState(state => ({ ...state, inputValue: e.target.value }));
  };

  const handleInputConfirm = () => {
    const { inputValue } = state;
    let { tags } = state;
    if (inputValue && tags.indexOf(inputValue) === -1) {
      tags = [...tags, inputValue];
    }
    setState(state => ({
      ...state,
      tags,
      inputVisible: false,
      inputValue: "",
    }));

    onChange([...tags, inputValue].filter(Boolean).join(","));
  };

  return (
    <Row>
      {title && <Typography.Text ellipsis title={title}>{`${title}: `}</Typography.Text>}
      <div className="tags-container">
        {state.tags.map(tag => {
          const isLongTag = tag.length > 20;

          const tagElem = (
            <Tag className="edit-tag" key={tag} closable={true} onClose={() => handleClose(tag)}>
              <span>{isLongTag ? `${tag.slice(0, 20)}...` : tag}</span>
            </Tag>
          );
          return isLongTag ? (
            <Tooltip title={tag} key={tag}>
              {tagElem}
            </Tooltip>
          ) : (
            tagElem
          );
        })}
        {state.inputVisible && (
          <AutoComplete
            filterOption={(inputValue, option) => option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
            options={state.options}
            style={{ width: "78px" }}
            onSelect={(tag: string) => {
              handleSelect(tag);
            }}
            value={state.inputValue}
            ref={inputRef}
          >
            <Input
              ref={input => input && input.focus()}
              type="text"
              size="small"
              value={state.inputValue}
              onChange={handleInputChange}
              onBlur={handleInputConfirm}
              onPressEnter={handleInputConfirm}
            />
          </AutoComplete>
        )}
        {!state.inputVisible && (
          <Tag style={{ color: "red !important" }} className="site-tag-plus" onClick={showInput}>
            <PlusOutlined /> {localizeText("NewTag")}
          </Tag>
        )}
      </div>
    </Row>
  );
};

export default TagsComponent;
