import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const useLegacyBaseUrl = () => {
  const language = useSelector((state: RootState) => state.user.settings.lang);

  return `${process.env.REACT_APP_OLD_PAS_URL}/${language}`;
};

export default useLegacyBaseUrl;
