import { BarcodeOutlined, WifiOutlined } from "@ant-design/icons";
import { useEffect, useRef, memo, useCallback } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import useFormManagmentEvents, { MobileEvent } from "../../EmbeddedModal/useFormManagmentEvents";

import "./scannerComponent.less";

const ScannerComponent = memo(
  ({ onScan, className }: { onScan: (value: string | number) => void; className?: string }) => {
    const { fireMobileEvent, setFormManagmentRef } = useFormManagmentEvents();
    const isClickedRef = useRef(false);
    const scannerRef = useRef(null);
    const primaryColor = useSelector((state: RootState) => state.common.primaryColor);

    useEffect(() => {
      setFormManagmentRef(scannerRef);
    }, []);

    useEffect(() => {
      const handleScan = (data: string) => {
        if (data && isClickedRef.current) {
          onScan(data);
          isClickedRef.current = false;
        }
      };
      const handleMessage = (event: Event) => {
        const messageEvent = event as any;

        if (messageEvent.detail && messageEvent.detail.key === MobileEvent.ScannerValueFromMobile) {
          handleScan(messageEvent.detail.value);
        }
      };

      window.addEventListener((MobileEvent.ScannerValueFromMobile as unknown) as keyof WindowEventMap, handleMessage);

      return () => {
        window.removeEventListener(
          (MobileEvent.ScannerValueFromMobile as unknown) as keyof WindowEventMap,
          handleMessage
        );
      };
    }, []);

    const handleBarcodeClick = useCallback(
      e => {
        fireMobileEvent(MobileEvent.CallBarcodeScanner, {});
      },
      [fireMobileEvent]
    );

    const handleNfcClick = useCallback(
      e => {
        fireMobileEvent(MobileEvent.CallNfcReader, {});
      },
      [fireMobileEvent]
    );

    return (
      <div onClick={() => (isClickedRef.current = true)} ref={scannerRef} className={`scanner-component ${className}`}>
        <WifiOutlined style={{ color: primaryColor, transform: "rotate(90deg)" }} onClick={handleNfcClick} />
        <BarcodeOutlined style={{ color: primaryColor }} onClick={handleBarcodeClick} />
      </div>
    );
  }
);

export default ScannerComponent;
