import tinycolor from "tinycolor2";

const SPIN_FACTOR = 30;

const generateColorsForCharts = (numberOfRecords: number, companyColor: string) => {
  const companyTinyColor = tinycolor(companyColor);
  const colorsToCreate = numberOfRecords > 5 ? numberOfRecords : 6;
  const chartColors = companyTinyColor.analogous(colorsToCreate, colorsToCreate);
  let spinColor = 0;
  return chartColors.map((value, index) => {
    if (index !== 0 && value.toHexString() === chartColors[0].toHexString()) {
      const newValue = tinycolor.mix(value, tinycolor("#00b5c3").spin(spinColor));
      spinColor = spinColor + SPIN_FACTOR;
      return newValue;
    } else {
      return value;
    }
  });
};

export { generateColorsForCharts };
