import { Employee } from "./employee";
import { Equipment } from "./equipment";
import { OrgUnit } from "./orgUnit";
import { EmployeeRole } from "./user";

export enum MigrationStatus {
  BlobRunning = "Blob Running",
  Running = "Running",
  Success = "Success",
  Failed = "Failed",
  NotExecuted = "Not Executed",
}

export type CompanyMigration = {
  id: number;
  sourceCompanyId: number;
  destinationCompanyId: number;
  createdByUserId: number;
  createdDate: string;
  completedDate: string;
  isSuccess: boolean;
  errorDescription: string;
  isRollback: boolean;
  isRunning: boolean;
  destinationOrgUnitId: number;
  isBlobRunning: boolean;
};

export type CompanyMigrationJobs = {
  migrationId: number;
  hangfireJobs: number[];
};

export type EntitiesDuplicationsToolsResponse = {
  employees: Employee[];
  equipments: Equipment[];
  orgUnits: OrgUnit[];
};

export type RunMigrationResponse = {
  jobs: number[];
  migrationId: number;
};

export enum CompanyUserStatus {
  Active = "Active",
  Inactive = "Inactive",
}

export type CompanyUser = {
  id: number;
  username: string;
  orgUnitId: number;
  externalId: string;
  displayName: string;
  phone: string;
  email: string;
  rank: string;
  position: number;
  locationId: number;
  expirationDate: string;
  jobTitle: string;
  isActive: boolean;
  isExternal: boolean;
  isUser: boolean;
  employeeRole: EmployeeRole;
  status: CompanyUserStatus;
};

export type CompanyMigrationSelection = {
  sourceCompanyId?: number;
  destinationCompanyId?: number;
  isRollback?: boolean;
  destinationOrgUnitId?: number;
};

export interface CompanyMigrationState {
  migrations: CompanyMigration[];
  migrationHangfireJobs: CompanyMigrationJobs[];
  tools: {
    companyUsersInDestination: CompanyUser[] | undefined;
    duplicateEntities: EntitiesDuplicationsToolsResponse | undefined;
    blobMigration: number | undefined;
  };
  destinationCompanyOrgUnits: OrgUnit[];
  isLoading: boolean;
}
