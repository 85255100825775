import { Query, CubejsApi, ResultSet } from "@cubejs-client/core";
import { CubeContext } from "@cubejs-client/react";
import cloneDeep from "lodash/cloneDeep";
import { useContext, useEffect, useState } from "react";

type UseCubeQueryOptions = {
  /**
   * A `CubejsApi` instance to use. Taken from the context if the param is not passed
   */
  cubejsApi?: CubejsApi;
  /**
   * Query execution will be skipped when `skip` is set to `true`. You can use this flag to avoid sending incomplete queries.
   */
  skip?: boolean;
  /**
   * Use continuous fetch behavior. See [Real-Time Data Fetch](real-time-data-fetch)
   */
  subscribe?: boolean;
  /**
   * When `true` the resultSet will be reset to `null` first
   */
  resetResultSetOnChange?: boolean;
};

type UsePagedCubeQueryProps = {
  query: Query | undefined;
  options?: UseCubeQueryOptions;
  /**
   * When `true` gets all the pages before returning the result set
   */
  getAllPages?: boolean;
  /**
   * page size to use on query
   */
  pageSize?: number;
};

const MAX_COUNT_OF_ERRORS = 10;
const PAGE_SIZE = 2000;

export type NextPageIterator = {
  next?: Promise<NextPageIterator> | null;
  resultSet?: ResultSet;
};

const usePagedCubeQuery = ({ query, options, getAllPages, pageSize }: UsePagedCubeQueryProps) => {
  const { cubejsApi } = useContext(CubeContext);
  const selectedPageSize = pageSize || PAGE_SIZE;
  const [numberOfErors, setNumberOfErors] = useState<number>(MAX_COUNT_OF_ERRORS);
  const [lastRunQuery, setLastRunQuery] = useState<Query | undefined>();
  const [res, setRes] = useState<ResultSet | undefined>();
  const [runQuery, setRunQuery] = useState<boolean>(true);
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const modifyPage: (q: Query | undefined, page: number) => Query | undefined = (q, page) => {
    let tmpQuery = cloneDeep(query);
    tmpQuery!.offset = page * selectedPageSize;
    tmpQuery!.limit = selectedPageSize;
    return tmpQuery;
  };

  const createIterator: (page: number, query: Query) => Promise<NextPageIterator> = (page, query) => {
    if (query && Object.keys(query).length > 0) {
      const queryToExecute = page === 0 ? modifyPage(query, page) : query;
      return (options?.cubejsApi || cubejsApi)
        .load(queryToExecute!, options)
        .then(results => {
          if (results.rawData().length == selectedPageSize) {
            return {
              resultSet: results,
              next: createIterator(page + 1, modifyPage(queryToExecute, page)!),
            };
          }
          return { resultSet: results, next: null };
        })
        .catch(error => {
          if (error === "invalid Token" && numberOfErors > 0) {
            setNumberOfErors(numberOfErors - 1);
            return createIterator(page, query);
          } else {
            setError(error);
            return { next: null };
          }
        });
    }
    return Promise.reject("query is empty");
  };

  const loadAllData: (loader: Promise<NextPageIterator>) => Promise<ResultSet> = async loader => {
    {
      const { resultSet, next: loadMore } = await loader;
      if (resultSet && loadMore) {
        const result = await loadAllData(loadMore);
        const fututreOffset = result.serialize().loadResponse.results[0].query.offset;
        const currentOffset = resultSet.serialize().loadResponse.results[0].query.offset;
        if (fututreOffset! > currentOffset!) {
          const combined = resultSet?.serialize();
          combined.loadResponse.results[0].data.push(...result.serialize().loadResponse.results[0].data);
          return ResultSet.deserialize(combined);
        }
        return result;
      }
      return resultSet!;
    }
  };

  const fetchQuery = async () => {
    if (getAllPages) {
      if (options?.resetResultSetOnChange) {
        setRes(undefined);
      }
      setLoadingStatus(true);
      loadAllData(createIterator(0, query!)).then(res => {
        setRes(res);
        setLoadingStatus(false);
      });
    }
  };

  useEffect(() => {
    setLoadingStatus(true);
    fetchQuery();
  }, [JSON.stringify(query)]);

  if (getAllPages) {
    if (runQuery) {
      return { next: null, loading: loadingStatus, resultSet: res, refetch: fetchQuery, error };
    } else {
      return {
        next: null,
        loading: loadingStatus,
        resultSet: cloneDeep(res),
        refetch: fetchQuery,
        error,
      };
    }
  }

  return { next: createIterator(0, query!) };
};

export default usePagedCubeQuery;
