import { Badge } from "antd";
import dayjs from "dayjs";
import { employeeSelectors, equipmentSelectors } from "../../../../selectors";
import {
  deleteNewAccountableEntryTemplate,
  fillNewAccountableEntryTemplate,
  updateNewAccountableEntryRole,
} from "../../../../store/slices/equipment";
import { addEntityPrivilege, updateEntityPrivilegeRole } from "../../../../store/slices/privileges";
import { Equipment, EquipmentStatus } from "../../../../types/equipment";
import { Inspection, InspectionStatus } from "../../../../types/inspection";
import { ColumnType, ColumnWithDifferentCells, TOption } from "../../../../types/page";
import { PrivilegeData, PrivilegedEntityType, Role } from "../../../../types/utility";
import { AccountabilityOptions, getCellSearchResultColumns } from "../common";
import {
  addEmptyValueForFiltering,
  fetchEqTypeById,
  fetchLocationById,
  localizeText,
  renderBoolStatus,
  renderTableStatus,
} from "../utilities";

export const fetchAllStatuses = (): TOption[] => {
  return eqStatusOptions.map<TOption>(x => ({ id: x.id, label: localizeText(x.label) }));
};

export const eqStatusOptions: TOption[] = [
  {
    id: EquipmentStatus.Active,
    label: "Active",
    icon: <Badge color="#02D924" />,
  },
  {
    id: EquipmentStatus.InActive,
    label: "InActive",
    icon: <Badge color="#B7B7B7" />,
  },
  {
    id: EquipmentStatus.Broken,
    label: "Broken",
    icon: <Badge color="#D9042B" />,
  },
];

export const equipmentsTableColumns: ColumnType<Equipment>[] = [
  {
    id: "serialNumber",
    label: "FieldSerialNumber",
    renderValue: value => value.serialNumber,
    sortable: false,
    width: 100,
    filterType: "multiSelect",
    valueType: "string",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "Equipment",
  },
  {
    id: "name",
    label: "FieldName",
    renderValue: value => value.name,
    sortable: true,
    width: 100,
    filterType: "multiSelect",
    valueType: "string",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "Equipment",
  },
  {
    id: "locationId",
    // FIXME: Find a better way
    renderValue: value => fetchLocationById(value.locationId!),
    label: "AppointmentLocationLink",
    sortable: true,
    width: 100,
    filterType: "multiSelect",
    propName: "name",
    valueType: "string",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "Location",
    asyncFetchFilterOptionsUseKeyValue: true,
  },
  {
    id: "typeName",
    label: "FieldType",
    width: 100,
    // FIXME: DOES NOT WORK on initial load
    renderValue: value => value.typeName,
    sortable: true,

    propName: "name",
    valueType: "string",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "EquipmentType",
    filterType: "multiSelect",
  },
  {
    id: "expiration",
    width: 100,
    label: "FieldExpiration",
    optionsSelector: () => null,
    renderValue: value => (dayjs(value.expiration).isValid() ? dayjs(value.expiration).format("DD/MM/YYYY") : ""),
    valueIcon: value => {
      const expDate = dayjs(value.expiration);
      const isValidDate = expDate.isValid();
      const dayDiff = expDate.diff(dayjs(), "days");
      return isValidDate ? <Badge color={dayDiff >= 30 ? "green" : "red"} /> : null;
    },
    sortable: true,
    filterType: "dateRange",
  },
  {
    id: "status",
    label: "FieldStatus",
    filterType: "multiSelect",
    width: 50,
    optionsSelector: fetchAllStatuses,
    // renderValue: value => EquipmentStatus[value.status] || EquipmentStatus.Broken,
    renderValue: () => null, // Note: We render the value together with the icon when we want to show a tag
    valueIcon: value => {
      // TODO: Check if other colors need to be added
      return renderTableStatus(value.status);
    },
    sortable: true,
  },
];

export const employeesTabColumns: ColumnWithDifferentCells<PrivilegeData>[] = [
  {
    id: "name",
    label: "FieldName",
    renderValue: value => value.name || "",
    sortable: false,
    filterType: "multiSelect",
    cellType: e => (e.staging ? "search" : "text"),
    cellSearchSelector: equipmentSelectors.allEquipmentRelations,
    cellSearchResultColumns: getCellSearchResultColumns(["name", "externalId"]),
    cellSearchKeys: ["name"],
    cellSearchRecordClick: fillNewAccountableEntryTemplate,
    optionsSelector: () => null,
    width: 100,
    primaryColumn: e => ({
      navigationTarget: e.userId ? `/employee/${e.userId}` : `/orgUnit/${e.orgUnitId}`,
    }),
  },
  {
    id: "externalId",
    label: "ExternalID",
    renderValue: value => value.externalId || "",
    sortable: false,
    filterType: "multiSelect",
    cellType: e => (e.staging ? "search" : "text"),
    cellSearchSelector: equipmentSelectors.allEquipmentRelations,
    cellSearchResultColumns: getCellSearchResultColumns(["name", "externalId"]),
    cellSearchKeys: ["externalId"],
    cellSearchRecordClick: fillNewAccountableEntryTemplate,
    optionsSelector: employeeSelectors.allEmployeeExternalIDs,
    width: 100,
  },
  {
    id: "status",
    label: "FieldStatus",
    renderValue: value => (value.status ? localizeText(value.status) : ""),
    sortable: false,
    optionsSelector: () => null,
    width: 100,
  },
  {
    id: "isExternal",
    label: "External",
    renderValue: value => (value.isExternal !== null ? renderBoolStatus(value.isExternal) : ""),
    sortable: false,
    optionsSelector: () => null,
    width: 100,
  },
  {
    id: "role",
    label: "Accountability",
    renderValue: e => (e.role ? localizeText(e.role.toString()) : ""),
    cellType: () => "dropdown",
    cellDropdownOptions: () =>
      AccountabilityOptions.map(ao => ({
        id: ao.id,
        label: localizeText(ao.label),
      })),
    cellDropdownOnChange: (e, newValue) =>
      e.staging
        ? updateNewAccountableEntryRole({ entity: e, newValue: newValue as Role })
        : updateEntityPrivilegeRole({
            entity: e,
            role: newValue as Role,
            id: null,
            entityType: PrivilegedEntityType.Equipment,
          }),
    sortable: false,
    optionsSelector: () => null,
    width: 100,
  },
  {
    id: "action" as keyof PrivilegeData,
    label: "FieldAction",
    renderValue: () => null,
    sortable: false,
    optionsSelector: () => null,
    cellType: () => "buttonActions",
    width: 100,
    stagingActions: [
      {
        label: "Add",
        className: "hb-primary-button",
        action: entity => addEntityPrivilege({ entity: entity, entityType: PrivilegedEntityType.Equipment, id: null }),
      },
      {
        label: "Cancel",
        className: "hb-tertiary-button",
        action: entity => deleteNewAccountableEntryTemplate(entity),
      },
    ],
    // buttonWithDropdownActions: {
    //   primaryButton: {
    //     label: "Action Button",
    //     action: entity => console.log("clicked primary button"),
    //   },
    //   dropdownButtons: [
    //     {
    //       label: "Delete Relation",
    //       className: "hb-primary-button",
    //       action: entity => deleteEquipmentRelation(entity), // TODO: Implement a thunk for deleting a LocationOrgUnit relation which would be based on 'staging'
    //       changesConfirmation: {
    //         body: "Are you sure you wish to remove this entity?",
    //         okText: "Remove",
    //         cancelText: "Keep",
    //         addValueToBody: false,
    //         addValueToOkText: false,
    //       },
    //     },
    //   ],
    // },
  },
];

export const inspectionTableColumns: ColumnWithDifferentCells<Inspection>[] = [
  {
    id: "inspectionTypeId",
    label: "FieldType",
    renderValue: value => value.inspectionTypeName,
    sortable: true,
    optionsSelector: _state => null,
    primaryColumn: e => ({
      navigationTarget: `/inspectionEquipment/${e.id}`,
    }),
  },
  {
    id: "recurrencyType",
    label: "RecurrencyType",
    renderValue: value => localizeText(value.recurrencyType),
    sortable: true,
    optionsSelector: () => null,
  },
  {
    id: "inspectionDate",
    label: "PluginPropertyGroupCheck_LastCheck",
    renderValue: value => (dayjs(value.lastCheck).isValid() ? dayjs(value.lastCheck).format("DD/MM/YYYY") : null),
    sortable: true,
    optionsSelector: () => null,
  },
  {
    id: "expirationDate",
    label: "FieldExpiration",
    renderValue: value =>
      dayjs(value.expirationDate).isValid() ? dayjs(value.expirationDate).format("DD/MM/YYYY") : null,
    sortable: false,
    optionsSelector: () => null,
    valueIcon: value => {
      const expDate = dayjs(value.expirationDate);

      if (!expDate) return null;

      const isValidDate = expDate.isValid();
      const isExpired = expDate.isBefore(dayjs(), "day");

      return isValidDate ? <Badge color={!isExpired ? "green" : "red"} /> : null;
    },
  },
  {
    id: "status",
    label: "FieldStatus",
    renderValue: entity =>
      entity.status === InspectionStatus.Missing ? localizeText(InspectionStatus.Expired) : localizeText(entity.status),
    sortable: false,
    optionsSelector: () => null,
  },
];
