import { InputRef } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";

type TProps = {
  value: string | null | number;
  ref?: React.RefObject<InputRef>;
  defaultValue?: string | null | number;
  autoFocus?: boolean;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | number | string | null) => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
};

const HBTextArea = ({ value, ref, defaultValue, autoFocus, disabled, onChange, onBlur }: TProps): JSX.Element => {
  return (
    <TextArea
      onBlur={onBlur}
      ref={ref}
      className="HB-input"
      defaultValue={defaultValue || ""}
      onChange={onChange}
      value={value ? value : ""}
      autoFocus={autoFocus}
      autoSize={{ minRows: 3, maxRows: 5 }}
      disabled={disabled}
    />
  );
};

export default HBTextArea;
