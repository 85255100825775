import { BinaryFilter, UnaryFilter } from "@cubejs-client/core";
import React, { useContext, useEffect, useState } from "react";
import { HierarchicalState } from "../../../../../types/dashboard";
import { ChartContext } from "../../HBChart";
import { hirachialColumns } from "../../Helpers";
import { HierarchicalDataControlContext } from "./HierarchicalDataControl";

export const ROOT_MARKER = "R%%T";

export const HierarchicalDataControlContextProvider: React.FC = ({ children }) => {
  const { chart } = useContext(ChartContext);
  const [hierarchicalState, setHierarchicalState] = useState<HierarchicalState>(
    chart?.hierarchicalNavigationState
      ? (chart.hierarchicalNavigationState as HierarchicalState)
      : {
          value:
            (chart?.query?.filters?.find(filter => {
              const f = filter as BinaryFilter | UnaryFilter;
              if (f.member) {
                return hirachialColumns.includes(f.member.split(".")[0]) && f.member.split(".")[1] === "name";
              }
              return false;
            }) as BinaryFilter | UnaryFilter)?.values?.[0] || ROOT_MARKER,
          depth: parseInt(
            (chart?.query?.filters?.find(filter => {
              const f = filter as BinaryFilter | UnaryFilter;
              if (f.member) {
                return hirachialColumns.includes(f.member.split(".")[0]) && f.member.split(".")[1] === "depth";
              }
              return false;
            }) as BinaryFilter | UnaryFilter)?.values?.[0] || "0"
          ),
        }
  );
  const [valueHistory, setValueHistory] = useState<HierarchicalState[]>([]);

  useEffect(() => {
    setHierarchicalState(chart?.hierarchicalNavigationState as HierarchicalState);
  }, [chart?.hierarchicalNavigationState]);

  const pushValue = (value: string, depth: number, dimensionKey?: string) => {
    setValueHistory([hierarchicalState].concat(valueHistory));
    setHierarchicalState({ value: value, depth, dimensionKey: dimensionKey || hierarchicalState.dimensionKey });
  };

  const goBack = (historyRecord: HierarchicalState) => {
    setHierarchicalState(historyRecord);
    setValueHistory([...valueHistory].splice(valueHistory.indexOf(historyRecord) + 1));
  };

  return (
    <HierarchicalDataControlContext.Provider
      value={{ hierarchicalState: hierarchicalState, pushValue, goBack, valueHistory }}
    >
      {children}
    </HierarchicalDataControlContext.Provider>
  );
};
