import { QueryBuilder } from "@cubejs-client/react";
import { Select, Space } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { updateStagedChart } from "../../../../../store/slices/dashboard";
import { RootState, useAppDispatch } from "../../../../../store/store";
import useCubeApi from "../../../../../utils/hooks/useCubeApi";

export default function SlicersConfigurationPanel() {
  const stagedChart = useSelector((state: RootState) => state.dashboard.stagedChart);
  const [selectedSlicers, setSelectedSlicers] = useState(stagedChart?.userDimensions);
  const cubejsApi = useCubeApi({});
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (stagedChart) {
      dispatch(updateStagedChart({ ...stagedChart, userDimensions: selectedSlicers }));
    }
  }, [selectedSlicers]);

  return (
    <Space direction="horizontal">
      <div>Slicers</div>
      <Space direction="vertical">
        <QueryBuilder
          cubejsApi={cubejsApi}
          render={({ dimensions, availableDimensions }) =>
            availableDimensions && (
              <Select
                mode="tags"
                className="dimensionsSelector"
                value={selectedSlicers?.map(s => s.dimension)}
                onSelect={(dimension: string, option: any) =>
                  setSelectedSlicers(selectedSlicers?.concat({ dimension: dimension, label: option.label }))
                }
                onDeselect={(dimension: string) =>
                  setSelectedSlicers(selectedSlicers?.filter(s => s.dimension !== dimension))
                }
                showSearch
                optionFilterProp="label"
                filterOption={(input, option) =>
                  ((option?.label as string) ?? "").toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  ((optionA?.label as string) ?? "")
                    .toLowerCase()
                    .localeCompare(((optionB?.label as string) ?? "").toLowerCase())
                }
                options={availableDimensions.map(d => ({ value: d.name, label: d.title }))}
              />
            )
          }
        />
      </Space>
    </Space>
  );
}
