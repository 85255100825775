import { ResultSet } from "@cubejs-client/core";
import { Result } from "antd";
import { useTranslation } from "react-i18next";
import { roundNumber } from "../../Helpers";

import NumericFitText, { CaretDirection } from "./NumericFitText";
import "./NumberCard.less";

type CardProps = {
  data: ResultSet | undefined;
};

export default function NumberCard({ data }: CardProps) {
  const { t } = useTranslation();

  const calculateColorAndArrowType = (resultsTotal: number[]) => {
    if (resultsTotal.length >= 2) {
      if (resultsTotal[0] < resultsTotal[1]) {
        return CaretDirection.Down;
      } else if (resultsTotal[0] > resultsTotal[1]) {
        return CaretDirection.Up;
      }
    }
    return undefined;
  };

  if (!data) {
    return <Result status="warning" title={t("DashboardUnsupportedChartTypeTitle")} />;
  }

  if ((data as any)?.queryType === "compareDateRangeQuery") {
    const totals = data
      ?.decompose()
      .map(resultSet => roundNumber(parseFloat(resultSet.totalRow()[resultSet.seriesNames()[0].key])));
    if (!totals) {
      return <Result status="warning" title={t("DashboardUnsupportedChartTypeTitle")} />;
    }

    return (
      <div className="card-container">
        <div className="main-period-number">
          <NumericFitText text={totals && totals[0].toLocaleString()} weight={300} />
        </div>
        <div className="secondary-period-number">
          <NumericFitText
            text={totals && totals[1].toLocaleString()}
            caretDirection={calculateColorAndArrowType(totals)}
          />
        </div>
      </div>
    );
  }
  const total = data?.totalRow();
  return (
    <div className="card-container">
      <NumericFitText
        text={total && data && roundNumber(parseFloat(total[data.seriesNames()[0].key])).toLocaleString()}
      />
    </div>
  );
}
