import { useContext, useEffect, useState } from "react";
import { View } from "../../components/CategoryHeader/types";
import { GroupView } from "../../components/GroupView/GroupVIew";
import { ListView } from "../../components/ListViewVTable/ListView";
import PageConfigurationContext from "../../context/pageContext";
import { HBEventName } from "../../types/analyticsTypes/HBEvent";
import { BaseEntityType } from "../../types/entityBase";
import { CategoryPage, CustomPage } from "../../types/page";
import { BuildInfo } from "../../utils/components/BuildInfo";
import useInitTrackEvents from "../../utils/hooks/useInitTrackEvents";
import useLoadInitalData from "../../utils/hooks/useLoadInitalData";
import useRouter from "../../utils/hooks/useRouter";
import { isDevEnvironment } from "../pageConfig/category/utilities";
import CategoryErrorModal from "./CategoryErrorModal";

const Categories = (): JSX.Element => {
  useLoadInitalData();
  const { location } = useRouter<{ viewType: View }>();
  const pageConfig = useContext(PageConfigurationContext);
  const [currentView, setCurrentView] = useState<View>();
  const [{ columnSelectionVisibility, exportVisibility }, setNewState] = useState({
    columnSelectionVisibility: false,
    exportVisibility: false,
  });
  const { track } = useInitTrackEvents();

  useEffect(() => {
    const viewType = location?.state?.viewType || pageConfig.defaultView || "list";
    track({ eventName: HBEventName.ScreenView, data: { presentation: viewType, name: pageConfig.title } });
    setCurrentView(viewType);
  }, [pageConfig, location]);

  const handleColumnSelectionVisibility = (visibility: boolean) => {
    setNewState({ columnSelectionVisibility: visibility, exportVisibility: exportVisibility });
  };

  const getView = () => {
    switch (currentView) {
      case "group": {
        const { hasGroupView } = pageConfig as CategoryPage<BaseEntityType>;

        return (
          hasGroupView && (
            <>
              <CategoryErrorModal />
              <GroupView />
            </>
          )
        );
      }
      case "list": {
        const { hasListView } = pageConfig as CategoryPage<BaseEntityType>;

        return (
          <>
            {hasListView && (
              <>
                <CategoryErrorModal />
                <ListView
                  columnSelectionVisibility={columnSelectionVisibility}
                  handleColumnSelectionVisibility={handleColumnSelectionVisibility}
                />
              </>
            )}
          </>
        );
      }
      case "custom": {
        const { CustomView, id } = pageConfig as CustomPage<BaseEntityType>;
        if (CustomView !== undefined) {
          return <CustomView />;
        }
        break;
      }
      default: {
        const { hasGroupView, hasListView } = pageConfig as CategoryPage<BaseEntityType>;
        if (hasGroupView && !hasListView) {
          return (
            <>
              <CategoryErrorModal />
              <GroupView />
            </>
          );
        }
      }
    }
  };

  return (
    <>
      {getView()}
      {isDevEnvironment() && <BuildInfo />}
    </>
  );
};

export default Categories;
