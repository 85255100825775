import { AutoComplete } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { TOption } from "../../../types/page";
import "./hbAutocomplete.less";
import ScannerComponent from "../ScannerComponent/ScannerComponent";

type TProps = {
  options: TOption[];
  onChange: (v: string | null | number) => void;
  value?: string | null | number;
  searchById?: boolean;
  dropdownAlign?: any;
  localizeValue?: boolean;
  enableMobileScanners?: boolean;
  onCardEdited?: (value: boolean) => void;
};

const HBAutocomplete: React.FC<TProps> = ({
  options,
  onChange,
  value,
  children,
  searchById,
  dropdownAlign,
  localizeValue = false,
  enableMobileScanners,
  onCardEdited,
}): JSX.Element => {
  const [currentValue, setCurrentValue] = useState<TOption | null>(null);
  const [autocompleteOptions, setAutocompleteOptions] = useState<{ value: string | number; label: string }[]>();
  const { t } = useTranslation();

  useEffect(() => {
    setAutocompleteOptions(
      options?.map(o => ({
        value: o.id || "",
        label: searchById ? `${t(o.label)} (id: ${o.id})` : t(o.label),
      }))
    );
  }, [options]);

  const propChangeRef = useRef(false);

  useEffect(() => {
    if (!value) {
      propChangeRef.current = true;
      setCurrentValue(null);
    } else {
      setCurrentValue((value && options.find(o => o.id === value)) || null);
    }
  }, [value]);

  const handleChange = (value: string | number) => {
    onCardEdited?.(true);
    if (!value) {
      return setCurrentValue(null);
    }

    const foundValue = options.find(o => o.id === (localizeValue ? value : Number(value)));

    if (!foundValue) {
      return setCurrentValue(null);
    }
    return setCurrentValue(foundValue);
  };

  const handleSearch = (value: string) => {
    const filtered = options
      .filter(
        o =>
          o.label.toUpperCase().includes(value.toUpperCase().trim()) ||
          (searchById && o.id && o.id.toString() === value)
      )
      .map(item => ({ value: item.id ?? "", label: searchById ? `${item.label} (id: ${item.id})` : item.label }));

    setAutocompleteOptions(filtered);
  };

  return (
    <>
      {children}
      <div className="HB-autocomplete-wrapper">
        <AutoComplete
          dropdownAlign={dropdownAlign}
          className={`HB-autocomplete ${enableMobileScanners ? "HB-autocomplete-with-scanner" : ""}`}
          onBlur={() => onChange(currentValue?.id ?? null)}
          onChange={handleChange}
          value={localizeValue ? t(currentValue?.label ?? "") : currentValue?.label}
          onSearch={handleSearch}
          options={autocompleteOptions}
          allowClear={true}
        />
        {enableMobileScanners && <ScannerComponent onScan={handleChange} />}
      </div>
    </>
  );
};

export default HBAutocomplete;
