// NOTE: Might not need it - import queryString from "query-string";
import { useMemo } from "react";

import { useParams, useLocation, useHistory, useRouteMatch } from "react-router-dom";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function useRouter<T>() {
  const params = useParams<T>();
  const location = useLocation<T>();
  const history = useHistory();
  const match = useRouteMatch();

  return useMemo(
    () => ({
      push: history.push,
      replace: history.replace,
      pathname: location.pathname,
      params: {
        // Note: might not need it - ...queryString.parse(location.search),
        ...params,
      },
      match,
      location,
      history,
    }),
    [params, match, location, history]
  );
}

export default useRouter;
