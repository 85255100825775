import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import useInitTrackEvents from "./useInitTrackEvents";

const useInitUserTrackProperties = () => {
  const { identify, setProperty, optOutTracking, optInTracking, hasOptedInTracking } = useInitTrackEvents();
  const companyId = useSelector((state: RootState) => state.user.settings.companyId);
  const isTracked = useSelector((state: RootState) => state.user.companySettings.isTracked);
  const userId = useSelector((state: RootState) => state.user.id);

  useEffect(() => {
    if (!isTracked) {
      optOutTracking();
    } else {
      if (userId && !hasOptedInTracking()) {
        optInTracking();
        identify(`${userId}`);
      }
    }
  }, [isTracked, userId]);

  useEffect(() => {
    if (companyId) {
      setProperty({ company: companyId.toString() });
    }
  }, [companyId]);
};

export default useInitUserTrackProperties;
