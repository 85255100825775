import mixpanel from "mixpanel-browser";
import { AnalyticsWrapper } from "../../../types/analyticsTypes/AnalyticsWrapper";
import { HBEvent } from "../../../types/analyticsTypes/HBEvent";

class mixpanelWrapper<T extends HBEvent> implements AnalyticsWrapper<T> {
  constructor() {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN ?? "", { debug: false });
  }
  hasOptedInTracking: () => boolean = () => {
    return mixpanel.has_opted_in_tracking();
  };
  optInTracking: () => void = () => {
    mixpanel.opt_in_tracking();
  };
  optOutTracking: () => void = () => {
    mixpanel.opt_out_tracking({ delete_user: false });
  };
  setProperty: (property: Record<string, string>) => void = property => {
    mixpanel.people.set(property);
  };
  identify: (userId: string) => void = userId => {
    mixpanel.identify(userId);
  };
  dispatch: (data: T) => void = data => {
    mixpanel.track(data.eventName, data.data);
  };
}

export default mixpanelWrapper;
