// import "@cubejs-client/playground/lib/antd.min.css";
import { Col, Tabs } from "antd";

import "./EditChart.less";
import QueryConfiguration from "./QueryConfiguration";
import SlicersConfiguration from "./SlicersConfiguration";
import VizualizationConfig from "./VizualizationConfig";

export default function EditChart() {
  const tabs = [
    {
      label: "Query",
      key: "queryConfig",
      children: <QueryConfiguration />,
    },
    {
      label: "Visualization",
      key: "visualizationConfig",
      children: <VizualizationConfig />,
    },
    {
      label: "Slicers",
      key: "slicersConfig",
      children: <SlicersConfiguration />,
    },
  ];

  const onTabChange: (activeKey: string) => void = activeKey => {
    if (activeKey !== "queryConfig") {
      var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;
      window.history.pushState({ path: newurl }, "", newurl);
    }
  };

  return (
    <Col>
      <Tabs type="card" defaultActiveKey="queryConfig" items={tabs} onChange={onTabChange} />
    </Col>
  );
}
