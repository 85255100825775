import Fuse from "fuse.js";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { debounce } from "throttle-debounce";
import PageConfigurationContext from "../../context/pageContext";
import { useAppDispatch } from "../../store/store";
import { BaseEntityType } from "../../types/entityBase";
import { CategoryPage } from "../../types/page";
import { isNumeric } from "../functions";
import { AppThunk } from "../types";

// type TOptions = Fuse.IFuseOptions<T> & { limit: number };

export function useFuse<T extends Record<string, unknown>>(
  list: T[],
  options: Fuse.IFuseOptions<T> & {
    limit?: number;
    fetchOptions?: AppThunk<T, { filters?: string; page?: number } | undefined>;
  }
) {
  // defining our query state in there directly
  const [query, updateQuery] = useState("");
  const [hits, setHits] = useState<Fuse.FuseResult<T>[]>();
  const pageConfig = useContext(PageConfigurationContext) as CategoryPage<BaseEntityType>;
  const dispatch = useAppDispatch();
  const paginatedResults = useSelector(
    pageConfig.hasListView && pageConfig.listView?.table?.searchResultsSelector
      ? pageConfig.listView?.table.searchResultsSelector
      : () => null
  );

  // removing custom options from Fuse options object
  // NOTE: `limit` is actually a `fuse.search` option, but we merge all options for convenience
  const { ...fuseOptions } = options;

  // let's memoize the fuse instance for performances
  const fuse = useMemo(() => new Fuse<T>(list, fuseOptions), [list, fuseOptions]);
  //   const data = fuse.getIndex()

  useEffect(() => {
    if (paginatedResults) setHits(paginatedResults.map(t => ({ item: t as T, refIndex: t.id as number })));
  }, [paginatedResults]);

  const setFuseResults = useCallback(() => setHits(fuse.search(query, { limit: options.limit! })), [query, fuse]);

  // memoize results whenever the query or options change
  useEffect(
    // if query is empty and `matchAllOnEmptyQuery` is `true` then return all list
    // NOTE: we remap the results to match the return structure of `fuse.search()`
    () => {
      if (query && query.length >= 3) {
        if (options.fetchOptions) {
          dispatch(
            options.fetchOptions({
              page: 1,
              filters: `(${pageConfig.listSearch?.columns
                .filter(x => x.id && pageConfig.listSearch?.keys?.includes(x.id))
                .reduce<string[]>((acc, param) => {
                  if (param.valueType === "number") {
                    if (isNumeric(query)) {
                      acc.push(`${param.id}=${query}`);
                    }
                  } else {
                    acc.push(`${param.id}=*${query}`);
                  }
                  return acc;
                }, [])
                .join("|")})`,
            })
          );
        } else {
          setFuseResults();
        }
      } else {
        setHits([]);
      }
    },
    //   !query && matchAllOnEmptyQuery
    //     ? fuse
    //         .getIndex()
    //         .docs.slice(0, limit)
    //         .map((item: any, refIndex: any) => ({ item, refIndex }))
    //     : fuse.search(query, { limit }),
    [query]
  );

  // debounce updateQuery and rename it `setQuery` so it's transparent
  const setQuery = useCallback(debounce(300, updateQuery), []);

  // pass a handling helper to speed up implementation
  const onSearch = useCallback(e => setQuery(e.target?.value.trim()), [setQuery]);

  // still returning `setQuery` for custom handler implementations
  return {
    hits,
    onSearch,
    query,
    setQuery,
  };
}
