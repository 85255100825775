import {
  Checkbox,
  DatePicker,
  Divider,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Radio,
  Select,
  Space,
  TimePicker,
} from "antd";
import FormItem from "antd/es/form/FormItem";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RRule } from "rrule";
import { localizeText } from "../../../pages/pageConfig/category/utilities";
import { RootState } from "../../../store/store";
import {
  CountOptions,
  LocalizedSpecificTimeRule,
  MonthlyRadioOptions,
  RecurrenceRule,
  RepeatOptions,
  SpecificTimeDay,
  SpecificTimeRule,
  Weekdays,
  convertObjectToRuleString,
} from "../../../types/dashboard";

import "./ScheduleReportModal.less";

export function rruleToHumanText(rruleStr: string) {
  const RRulePrefix = "RRule_";

  try {
    const rrule = RRule.fromString(rruleStr);

    const words = rrule
      .toText()
      .split(/\s+|,/)
      .map(x => {
        // Remove parentheses
        x = x.replace(/[()]/g, "");

        if (/^\d/.test(x)) {
          // Remove non-digit character
          x = x.replace(/\D+$/, "");
        } else if (isNaN(Number(x)) && !/[,\d]/.test(x)) {
          x = localizeText(`${RRulePrefix}${x.charAt(0).toUpperCase() + x.slice(1)}`);
        }
        return x;
      });

    const result = words.join(" ");

    return result;
  } catch (error) {
    return rruleStr;
  }
}

const RRuleComponent = ({
  onChange,
  fieldName,
  form,
}: {
  onChange?: (v: string | null | boolean | number) => void; // The change handler for the new value coming from the input
  fieldName: string; // The current value
  form: FormInstance<any>;
}) => {
  const { t } = useTranslation();
  const recurrenceRule = Form.useWatch<RecurrenceRule>(fieldName, form);
  const [monthlyRadio, setMonthlyRadio] = useState(MonthlyRadioOptions.OnDay);
  const [countSelectionValue, setCountSelectionValue] = useState(CountOptions.Never);
  const firstDayOfWeek = useSelector((state: RootState) => state.user.companySettings.firstDayOfWeek);

  useEffect(() => {
    const formValue = form.getFieldValue("recurrencyRule");

    if (onChange) {
      const rruleStringValue = recurrenceRule && convertObjectToRuleString(recurrenceRule, firstDayOfWeek);

      if (
        rruleStringValue &&
        !rruleStringValue.includes("undefined") &&
        rruleStringValue.replace("RRULE:", "") !== formValue
      ) {
        onChange(rruleStringValue.replace("RRULE:", ""));
      }
    }
  }, [recurrenceRule]);

  useEffect(() => {
    if (recurrenceRule) {
      setMonthlyRadio(
        recurrenceRule[MonthlyRadioOptions.OnSpecificTime]
          ? MonthlyRadioOptions.OnSpecificTime
          : MonthlyRadioOptions.OnDay
      );
      if (recurrenceRule.until) {
        setCountSelectionValue(CountOptions.OnDate);
      } else if (recurrenceRule.endAfter) {
        setCountSelectionValue(CountOptions.After);
      } else {
        setCountSelectionValue(CountOptions.Never);
      }
    }
  }, [recurrenceRule]);

  const repeatOptions = useMemo(
    () =>
      Object.entries(RepeatOptions)
        .filter(option => {
          return isNaN(parseInt(option[0]));
        })
        .map(option => {
          return { label: t(option[0]), value: option[1] };
        }),
    []
  );

  const specificTimeRuleOptions = useMemo(
    () =>
      Object.entries(LocalizedSpecificTimeRule)
        .filter(option => isNaN(parseInt(option[0])))
        .map(option => {
          const value = Object.entries(SpecificTimeRule)
            .filter(rule => isNaN(parseInt(rule[0])))
            .find(rule => rule[0] === option[0]);
          return {
            label: t(option[1]),
            value: value ? value[1] : option[1],
          };
        }),
    []
  );

  const weekdayOptions: { label: string; value: Weekdays }[] = useMemo(
    () =>
      Object.entries(Weekdays)
        .filter(option => isNaN(parseInt(option[0])))
        .map(option => {
          return { label: t(option[1]), value: option[1] as Weekdays };
        }),
    []
  );

  const specificTimeDayOptions: { label: string; value: SpecificTimeDay | Weekdays }[] = useMemo(
    () =>
      Object.entries(SpecificTimeDay)
        .filter(option => isNaN(parseInt(option[0])))
        .map(option => {
          return { label: t(option[1]), value: option[1] as SpecificTimeDay | Weekdays };
        })
        .concat(weekdayOptions),
    []
  );

  const countOptions = [
    { label: t("ScheduleReportNever"), value: CountOptions.Never },
    { label: t("ScheduleReportAfter"), value: CountOptions.After },
    { label: t("ScheduleReportOnDate"), value: CountOptions.OnDate },
  ];

  const getRepeatForm = (repeat: RepeatOptions) => {
    switch (repeat) {
      case RepeatOptions.Daily: {
        return (
          <Form.Item name={["recurrenceRule", "amount"]} label={t("ScheduleReportEvery")} rules={[{ required: true }]}>
            <InputNumber min={1} formatter={value => `${value} ${t("ScheduleReportdays")}`} />
          </Form.Item>
        );
      }
      case RepeatOptions.Weekly: {
        return (
          <>
            <Form.Item
              name={["recurrenceRule", "amount"]}
              label={t("ScheduleReportEvery")}
              rules={[{ required: true }]}
            >
              <InputNumber min={1} formatter={value => `${value} ${t("ScheduleReportweeks")}`} />
            </Form.Item>
            <Form.Item name={["recurrenceRule", "weekdays"]} rules={[{ required: true }]}>
              <Checkbox.Group options={weekdayOptions} />
            </Form.Item>
          </>
        );
      }
      case RepeatOptions.Monthly: {
        return (
          <>
            <Form.Item
              name={["recurrenceRule", "amount"]}
              label={t("ScheduleReportEvery")}
              rules={[{ required: true }]}
            >
              <InputNumber min={1} formatter={value => `${value} ${t("ScheduleReportdays")}`} />
            </Form.Item>
            <Form.Item>
              <Radio.Group
                value={monthlyRadio}
                onChange={e => setMonthlyRadio(e.target.value)}
                defaultValue={monthlyRadio}
              >
                <Space direction="vertical">
                  <Radio value={MonthlyRadioOptions.OnDay}>
                    <Form.Item
                      name={["recurrenceRule", MonthlyRadioOptions.OnDay]}
                      label={t("ScheduleReportOnDay")}
                      rules={[{ required: monthlyRadio === MonthlyRadioOptions.OnDay }]}
                    >
                      <InputNumber min={1} disabled={monthlyRadio !== MonthlyRadioOptions.OnDay} max={31} />
                    </Form.Item>
                  </Radio>
                  <Radio value={MonthlyRadioOptions.OnSpecificTime}>
                    <div
                      style={{
                        display: "flex",
                        flexFlow: "row",
                        justifyContent: "space-around",
                        gap: 8,
                        height: "5rem",
                      }}
                    >
                      <Form.Item
                        name={["recurrenceRule", MonthlyRadioOptions.OnSpecificTime, "rule"]}
                        label={t("ScheduleReportOnThe")}
                        rules={[{ required: monthlyRadio === MonthlyRadioOptions.OnSpecificTime }]}
                      >
                        <Select
                          disabled={monthlyRadio !== MonthlyRadioOptions.OnSpecificTime}
                          options={specificTimeRuleOptions}
                          style={{ width: "10rem" }}
                        />
                      </Form.Item>
                      <Form.Item
                        name={["recurrenceRule", MonthlyRadioOptions.OnSpecificTime, "day"]}
                        rules={[{ required: monthlyRadio === MonthlyRadioOptions.OnSpecificTime }]}
                      >
                        <Select
                          disabled={monthlyRadio !== MonthlyRadioOptions.OnSpecificTime}
                          options={specificTimeDayOptions}
                          style={{ width: "13rem" }}
                        />
                      </Form.Item>
                    </div>
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </>
        );
      }
    }
  };

  const getCountOptionItem = () => {
    switch (countSelectionValue) {
      case CountOptions.Never:
        return null;
      case CountOptions.After:
        return (
          <Form.Item name={["recurrenceRule", "endAfter"]} rules={[{ required: true }]}>
            <InputNumber min={1} formatter={value => `${value} ${t("ScheduleReportdays")}`} />
          </Form.Item>
        );
      case CountOptions.OnDate:
        return (
          <Form.Item name={["recurrenceRule", "until"]} rules={[{ required: true }]}>
            <DatePicker />
          </Form.Item>
        );
    }
  };

  return (
    <>
      <Input.Group>
        <Form.Item name={["recurrenceRule", "repeat"]} label={t("ScheduleReportRepeat")} rules={[{ required: true }]}>
          <Select style={{ width: "15rem", borderRadius: "10px" }} options={repeatOptions} />
        </Form.Item>
        {getRepeatForm(recurrenceRule?.repeat)}
      </Input.Group>
      {/* <Divider /> */}
      <FormItem label={t("ScheduleReportEnd")}>
        <Space align="baseline">
          <Select
            options={countOptions}
            value={countSelectionValue}
            onChange={value => {
              setCountSelectionValue(value);
            }}
          />
          {getCountOptionItem()}
        </Space>
      </FormItem>
    </>
  );
};

export default RRuleComponent;
