import dayjs from "dayjs";
import { rruleToHumanText } from "../../../../components/Dashboard/ScheduledReport/SheduleComponent";
import { recurrentActionsSelectors } from "../../../../selectors";
import { ColumnType } from "../../../../types/page";
import { RecurrentAction } from "../../../../types/recurrentAction";
import { renderBoolStatus } from "../utilities";

export const recurrentActionTableColumns: ColumnType<RecurrentAction>[] = [
  {
    id: "id",
    label: "ID",
    renderValue: value => value.id.toString(),
    sortable: false,
    optionsSelector: recurrentActionsSelectors.uniqueRecurrentActionIds,
    width: 100,
    filterType: "multiSelect",
  },
  {
    id: "title",
    label: "FieldTitle",
    renderValue: value => value.title,
    sortable: true,
    optionsSelector: recurrentActionsSelectors.uniqueRecurrentActionTitle,
    filterType: "multiSelect",
  },
  {
    id: "responsibleUserName",
    label: "FieldResponsible",
    renderValue: value => value.responsibleUserName,
    sortable: true,
    optionsSelector: recurrentActionsSelectors.uniqueRecurrentActionResponsible,
    filterType: "multiSelect",
  },
  {
    id: "projectName",
    label: "FieldProject",
    renderValue: value => value.projectName,
    sortable: true,
    optionsSelector: recurrentActionsSelectors.uniqueRecurrentActionProject,
    filterType: "multiSelect",
  },
  {
    id: "startDate",
    label: "FieldCreateDate",
    renderValue: value => (value.startDate ? dayjs(value.startDate).format("DD/MM/YYYY HH:mm") : null),
    filterType: "dateRange",
    optionsSelector: () => null,
    sortable: true,
  },
  {
    id: "recurrencyRule",
    label: "FieldRecurrencyRule",
    renderValue: value => rruleToHumanText(value.recurrencyRule ?? ""),
    sortable: true,
    optionsSelector: recurrentActionsSelectors.uniqueRecurrentActionRecurrencyRule,
    filterType: "multiSelect",
  },
  {
    id: "recurrencyRuleEndDate",
    label: "FieldRecurrencyRuleEndDate",
    renderValue: value =>
      value.recurrencyRuleEndDate ? dayjs(value.recurrencyRuleEndDate).format("DD/MM/YYYY HH:mm") : null,
    filterType: "dateRange",
    optionsSelector: () => null,
    sortable: true,
  },
  {
    id: "recurrencyRuleOccurrences",
    label: "FieldOccurrencesCount",
    renderValue: value => (value.recurrencyRuleOccurrences ? value.recurrencyRuleOccurrences.toString() : ""),
    sortable: true,
    optionsSelector: recurrentActionsSelectors.uniqueRecurrentActionOccurrencesCount,
    filterType: "multiSelect",
  },
  {
    id: "isActive",
    label: "FieldIsActive",
    renderValue: value => (value.isActive !== null ? renderBoolStatus(value.isActive) : ""),
    sortable: false,
    optionsSelector: () => null,
    width: 100,
  },
];
