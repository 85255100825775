import { Space } from "antd";
import { useSelector } from "react-redux";

import { ReactComponent as Arrow } from "../../media/message-arrow.svg";
import { RootState } from "../../store/store";

import Layout from "../Layout/Layout";

import "./NotFoundPage.less";

export default function NotFoundPage() {
  const primaryColor = useSelector((state: RootState) => state.common.primaryColor);
  return (
    <Layout isSideBarOnly={true} entityKey="notAllowed">
      <Space className="not-allowed-container" direction="vertical" align="center">
        <Space size={1} direction="horizontal" align="baseline">
          <Arrow className="not-allowed-page-arrow" fill={primaryColor} />
          <div className="not-allowed-title">
            {"The entity you are trying to access cannot be found.\n Please contact your System Admin"}
          </div>
        </Space>
      </Space>
    </Layout>
  );
}
