import { useContext } from "react";
import PageConfigurationContext from "../../../context/pageContext";
import Categories from "../Categories";
import CategorySingle from "./CategorySingle";
import CategorySingleMobileFirst from "./CategorySingleMobileFirst";

type CategoryWrapperProps = {
  isNewEntity?: boolean;
  isMobile?: boolean;
};

export default function CategoryWrapper({ isNewEntity, isMobile }: CategoryWrapperProps) {
  const pageConfig = useContext(PageConfigurationContext);

  if (pageConfig.defaultView === "custom") {
    return <Categories />;
  } else {
    return isMobile ? (
      <CategorySingleMobileFirst isNewEntity={isNewEntity} />
    ) : (
      <CategorySingle isNewEntity={isNewEntity} />
    );
  }
}
