import { Space } from "antd";
import ChartFilterMenu from "./Menus/ChartFilterMenu";
import ChartOptionsMenu from "./Menus/ChartOptionsMenu";
import ChartVisualMenu from "./Menus/ChartVisualMenu";

export default function ChartOptions() {
  return (
    <Space size={5}>
      <ChartVisualMenu />
      <ChartFilterMenu />
      <ChartOptionsMenu />
    </Space>
  );
}
