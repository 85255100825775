import { unwrapResult } from "@reduxjs/toolkit";
import { Avatar, Button, Popover, Image, Spin } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getFile } from "../../store/slices/common";
import { setCompanyLogoInternalUrl, updateUserContext } from "../../store/slices/user";
import { RootState, useAppDispatch } from "../../store/store";
import { initials } from "../../utils/functions";
import CompanyMenu from "./CompanyMenu";
import "./CompanyMenu.less";

export default function CompanyButton() {
  const dispatch = useAppDispatch();
  const companyId = useSelector((state: RootState) => state.user.settings.companyId);
  const direction = useSelector((state: RootState) => state.user.settings.direction);
  const availableCompanies = useSelector((state: RootState) => state.user.settings.availableComapnies);
  const changeUserContextInProgress = useSelector((state: RootState) => state.user.changeUserContextInProgress);
  const { companySettings, logoInternalUrl, isLoading } = useSelector((state: RootState) => state.user);

  const getCompanyName = () => {
    const companyName = availableCompanies.find(company => company.id === companyId)?.name || "";
    return initials(companyName).toUpperCase();
  };

  useEffect(() => {
    if (isNaN(companyId)) {
      dispatch(updateUserContext({ companyId: availableCompanies[0].id }));
    }
  }, [companyId]);

  const getAlignment = () => {
    if (direction === "ltr") {
      return { points: ["bl", "tr"], offset: [5, 15] };
    } else {
      return { points: ["br", "tl"], offset: [-5, 15] };
    }
  };

  useEffect(() => {
    if (!changeUserContextInProgress && !logoInternalUrl) {
      if (companySettings.logo) {
        dispatch(getFile(`${companySettings.logo}`))
          .then(response => unwrapResult(response))
          .then(blob => {
            var file = new Blob([blob], { type: "arraybuffer" });
            const imageObjectURL = URL.createObjectURL(file);
            dispatch(setCompanyLogoInternalUrl(imageObjectURL));
          })
          .catch(e => {
            dispatch(setCompanyLogoInternalUrl(undefined)); //handle default icon
          });
      }
    }
  }, [companySettings, changeUserContextInProgress, logoInternalUrl]);

  const companyLogoIsTransparent = () => {
    return companySettings.logo.endsWith(".png") || companySettings.logo.endsWith(".ico");
  };

  const getDefaultCompanyLogo = () => {
    return (
      <Button shape="circle" key="avatar" type="text" className="company-button">
        <div className="company-button-wrapper-vitre">
          <Image src={"/media/vitre-v.png"} preview={false} style={{ maxHeight: "100%", maxWidth: "100%" }} />
        </div>
      </Button>
    );
  };

  const getCompanyLogoComponent = () => {
    if (logoInternalUrl) {
      if (companyLogoIsTransparent()) {
        return (
          <Button className="company-logo" type="text">
            <div className="company-button-wrapper">
              <Image src={logoInternalUrl} preview={false} style={{ maxHeight: "100%", maxWidth: "100%" }} />
            </div>
          </Button>
        );
      } else {
        return (
          <Avatar
            className="company-logo"
            size={45}
            src={
              <Image
                src={logoInternalUrl}
                preview={false}
                style={{ height: "6rem", width: "6rem", objectFit: "cover" }}
              />
            }
          />
        );
      }
    } else {
      return getDefaultCompanyLogo();
    }
  };

  const companyLogoCallback = () => {
    dispatch(setCompanyLogoInternalUrl(undefined));
  };

  const fallbackCompanyLogo = () => {
    return (
      <div className="company-logo">
        <div className="company-button-spinner-wrapper">
          <Spin spinning className="spinner" />
        </div>
      </div>
    );
  };

  if (isLoading || changeUserContextInProgress) return fallbackCompanyLogo();

  if (availableCompanies.length === 0) return getCompanyLogoComponent();
  return (
    <Popover
      align={getAlignment()}
      placement={direction === "ltr" ? "rightBottom" : "leftTop"}
      content={<CompanyMenu onChangeCompanyCallback={companyLogoCallback} />}
    >
      {getCompanyLogoComponent()}
    </Popover>
  );
}
