import { createSelector } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { TreeData } from "../../pages/pageConfig/category/utilities";
import { pageSelectors } from "../../selectors";
import { RootState } from "../../store/store";
import { FieldType, TOption } from "../../types/page";

export interface UseLabelAndValueCheckProps {
  fieldValue: string | null | number; // The current value
  type: FieldType;
  options?: TOption[];
  optionSelector: (state: RootState) => TOption[] | null;
  treeData?: {
    treeDataSelector: (state: RootState) => Record<string, unknown>[];
    treeDataSet: (dataSet: Record<string, unknown>[]) => TreeData[];
  };

  currentData?: Record<string, unknown> | null;
  selector?: (state: RootState) => Record<string, unknown>[];
  basedOnDependensiesOptions?: (currentData: unknown, selectedDependentData: unknown[] | null) => TOption[] | null;
  localizedValue?: string | number | null;
}
export const useLabelAndValueCheck = ({
  fieldValue,
  type,
  options,
  optionSelector,
  treeData,
  currentData,
  selector,
  basedOnDependensiesOptions,
  localizedValue,
}: UseLabelAndValueCheckProps) => {
  const [valueCheck, setValueCheck] = useState<string | number | null>(fieldValue);
  const [label, setLabel] = useState<string | number | null>(fieldValue);
  const { t } = useTranslation();
  const optionsSelector = createSelector(pageSelectors.stateSelector, optionSelector);
  const dependentDataSelector = createSelector(pageSelectors.stateSelector, selector ? selector : () => null);
  const selectedDependentData = useSelector(dependentDataSelector);
  const autoCompleteOptions = useSelector(optionsSelector);
  const treeDataSet = useSelector(treeData?.treeDataSelector || (() => null));

  useEffect(() => {
    if (type === "dropdown" || type === "autocomplete" || type === "Dictionary") {
      const currentOption = (options ||
        autoCompleteOptions ||
        (basedOnDependensiesOptions && basedOnDependensiesOptions(currentData, selectedDependentData)))!.find(
        o => o.id === fieldValue
      );
      setValueCheck(currentOption?.id || fieldValue);
      return setLabel(localizedValue || currentOption?.label || null);
    }

    if (type === "treeselect" && treeDataSet) {
      const currentOption = treeDataSet!.find(o => o.id === fieldValue);
      setValueCheck(fieldValue);
      return setLabel((currentOption?.name as string) || null);
    }

    if (type === "boolean" && typeof fieldValue === "boolean") {
      setValueCheck(+fieldValue);
      const label = (options || autoCompleteOptions)!.find(o => o.id === +fieldValue)?.label;
      const localizedLabel = label ? t(label.toString()) : fieldValue;
      return setLabel(localizedLabel);
    }

    setLabel(localizedValue || fieldValue);

    return setValueCheck(fieldValue);
  }, [fieldValue, autoCompleteOptions, options, treeData]);

  return { label, valueCheck };
};
