import { DownloadOutlined, EyeOutlined } from "@ant-design/icons";
import { unwrapResult } from "@reduxjs/toolkit";
import { Space, Button, Modal, Divider } from "antd";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactToPrint from "react-to-print";
import LivePDF from "../../components/LivePDF/LivePDF";
import PDFViewComponent from "../../components/PDFViewModal/PDFViewComponent";
import { getFile } from "../../store/slices/common";
import { useAppDispatch } from "../../store/store";

import "./PDFActions.less";
import useIsMobile from "../../utils/hooks/useIsMobile";

type PDFActionsParams = {
  pdfLink?: string;
  fileLink?: string;
  title?: string;
  primaryButton?: boolean;
  buttonTitle?: string;
  textOnly?: boolean;
  additionalAction?: () => void;
  className?: string;
};

export default function PDFActions({
  pdfLink,
  fileLink,
  primaryButton,
  buttonTitle,
  textOnly,
  additionalAction,
  className,
}: PDFActionsParams) {
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const componnentRef = useRef(null);

  const handleDownloadPDF = () => {
    if (fileLink) {
      dispatch(getFile(fileLink))
        .then(response => unwrapResult(response))
        .then(response => {
          var file = new Blob([response], { type: "application/pdf" });
          const fileURL = window.URL.createObjectURL(file);
          window.open(fileURL);
        });
    }
  };

  const getDownloadButton = () => {
    //temp disable on mobile
    if (isMobile) return null;
    if (fileLink && !pdfLink) {
      return (
        <Button className="btn" type="primary" shape="round" onClick={handleDownloadPDF} icon={<DownloadOutlined />}>
          {t("DownloadPDF")}
        </Button>
      );
    } else {
      return (
        <ReactToPrint
          trigger={() => (
            <Button className="btn" type="primary" shape="round" icon={<DownloadOutlined />}>
              {t("DownloadPDF")}
            </Button>
          )}
          content={() => componnentRef.current}
        />
      );
    }
  };

  return (
    <div id="pdfActions" className="pointer" onClick={e => e.stopPropagation()}>
      <Space size={0} split={<Divider type="vertical" />}>
        {textOnly ? (
          <span
            onClick={() => {
              setIsModalOpen(!isModalOpen);
              additionalAction && additionalAction();
            }}
          >
            {t("ViewPDF")}
          </span>
        ) : (
          <Button
            onClick={() => {
              setIsModalOpen(!isModalOpen);
            }}
            type={primaryButton ? "primary" : "text"}
            shape="round"
            className={primaryButton ? "btn btn-white-color" : "btn btn-primary-color"}
            icon={primaryButton ? <EyeOutlined style={{ color: "white" }} /> : null}
          >
            {buttonTitle ? t(buttonTitle) : t("ViewPDF")}
          </Button>
        )}
      </Space>

      <Modal
        width="75rem"
        className={`view-pdf-modal ${className}`}
        maskClosable={false}
        open={isModalOpen}
        destroyOnClose={true}
        closable={false}
        onCancel={() => {
          setIsModalOpen(!isModalOpen);
        }}
        footer={
          <div className="pdf-actions-footer">
            {getDownloadButton()}
            <Button
              shape="round"
              className="btn"
              onClick={() => {
                setIsModalOpen(!isModalOpen);
              }}
            >
              {t("Close")}
            </Button>
          </div>
        }
      >
        {fileLink && !pdfLink ? (
          <PDFViewComponent fileId={fileLink} />
        ) : (
          <LivePDF ref={componnentRef} livePDFId={pdfLink} />
        )}
      </Modal>
    </div>
  );
}
