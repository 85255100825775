import { Row, Typography } from "antd";
import { ReactComponent as ErrorIcon } from "../../media/error-circle.svg";
import { ReactComponent as WarningIcon } from "../../media/warning-circle.svg";
import { localizeText } from "../../pages/pageConfig/category/utilities";
import { NavigationModalTexts } from "./types";

const modalNavigationConfirmation = (): NavigationModalTexts => ({
  title: localizeText("ExitWithoutSaving"),
  body: localizeText("SaveChangesPopupText"),
  footer: localizeText("LeavePagewithoutSaveQuestion"),
  cancelText: localizeText("Stay"),
  okText: localizeText("Exit"),
});
const modalNotValidParent = (): NavigationModalTexts => ({
  // title: "UNSAVED WORK",
  Image: WarningIcon,
  title: localizeText("PayAttention"),
  body: localizeText("NotValidParent"),
  okText: localizeText("Okay"),
});

const modalCardValidationErrors = (messages?: string[]): NavigationModalTexts => ({
  // title: "UNSAVED WORK",
  Image: WarningIcon,
  title: localizeText("PayAttention"),
  body: (
    <Typography.Paragraph>
      {messages?.map((message, i) => {
        return (
          <Row key={i.toString()}>
            <Typography.Text>{localizeText(message)}</Typography.Text>
          </Row>
        );
      })}
    </Typography.Paragraph>
  ),
  okText: localizeText("Okay"),
});

const modalOperationFailedError = (messages?: string[]): NavigationModalTexts => ({
  Image: ErrorIcon,
  title: localizeText("OperationFailed"),
  body: (
    <Typography.Paragraph>
      {messages?.map((message, i) => {
        return (
          <Row key={i.toString()}>
            <Typography.Text>{localizeText(message)}</Typography.Text>
          </Row>
        );
      })}
    </Typography.Paragraph>
  ),
  okText: localizeText("Okay"),
});

const notification = (messages?: string[]): NavigationModalTexts => ({
  Image: messages && messages.length > 2 && messages[2] == "error" ? ErrorIcon : WarningIcon,
  title: messages && messages.length > 0 ? messages[0] : "Confirmation",
  body: messages && messages.length > 1 ? localizeText(messages[1]) : "",
  okText: localizeText("Okay"),
});

export const modalTexts = {
  navigateAway: modalNavigationConfirmation,
  notValidParent: modalNotValidParent,
  cardValidationErrors: modalCardValidationErrors,
  operationFailed: modalOperationFailedError,
  notification: notification,
  mobileFirstCardValidationErrors: modalCardValidationErrors,
};
