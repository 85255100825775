import { ExportAction, ColumnManagementAction, GetFiltersUrl } from "../../../../actions/menuActions";
import { ReactComponent as PageIcon } from "../../../../media/sidebar/tasks.svg";
import { issueTypeSelectors, pageSelectors } from "../../../../selectors";
import {
  addIssueType,
  clearIssueTypeError,
  createIssueTypeTemplate,
  fetchIssueTypes,
  fetchSingleIssueType,
  updateIssueType,
  updateIssueTypeParentId,
  updateParentId,
} from "../../../../store/slices/issueType";
import { IssueType, IssueTypePage } from "../../../../types/issueType";
import { CategoryPage, SectionType } from "../../../../types/page";
import { contextActionsTableColumns } from "../common";
import { pickSpecificColumns, prepareDynamicColumns, removeColumnFilters } from "../utilities";
import { externalIdField, nameFiled, parentIdField, primaryFields, secondaryFields, statusField } from "./fields";
import { issueTypeTableColumns } from "./issueTypeConfigPageData";

const issueTypeConfigPage = (): CategoryPage<IssueTypePage> => ({
  id: "issueType",
  title: "IssueTypeResults",
  entityEndpoint: "/issueType",
  PageIcon: PageIcon,
  hasGroupView: false,
  hasListView: true,
  primaryErrorSelector: issueTypeSelectors.error,
  isLoading: pageSelectors.isIssueTypePageLoading,
  lastUpdatedSelector: issueTypeSelectors.issueTypeLastUpdatedText,
  primarySingleEntitySelector: issueTypeSelectors.single,
  customPropertiesSelector: issueTypeSelectors.issueTypeCustomProperties,
  fetchSingle: fetchSingleIssueType,
  intializeDataActions: [fetchIssueTypes],
  clearError: clearIssueTypeError,
  createNewEntityTemplate: createIssueTypeTemplate,
  createNewEntity: addIssueType,
  listViewActions: [ColumnManagementAction, ExportAction, GetFiltersUrl],
  updateEntity: updateIssueType,
  breadcrumbSingleValue: e => {
    return { id: e.id?.toString(), label: e.name };
  },
  addButton: {
    label: "AddIssueType",
    action: () => console.log("Create new IT"),
  },
  summaryCard: {
    remotelyUpdatedFieldIDs: ["parentId"],
    cardInformationalMessasge: e => {
      return e.reviewId === null ? "This is the root, and it can not be edited" : "";
    },
    primaryFields: primaryFields,
    secondaryFields: secondaryFields,
    mobileSingleView: {
      summary: {
        icon: PageIcon,
        title: nameFiled,
        id: externalIdField,
        status: statusField,
        mainContextRelation: parentIdField,
      },
      sections: [
        {
          type: SectionType.ContextSection,
          fields: [],
        },
        {
          type: SectionType.DatesSection,
          fields: [],
        },
      ],
    },
  },
  listView: {
    table: {
      tableSelector: issueTypeSelectors.all,
      fetchListViewData: fetchIssueTypes,
      columns: issueTypeTableColumns,
      rowSelection: true,
      defaultColumnKeys: ["id", "name", "externalId", "parentId", "status"],
      disableNavigation: false,
      type: "tree",
    },
    generateDynamicColumns: (props, data) => prepareDynamicColumns<IssueType>(props, data),
  },
  tabsPanel: [
    {
      key: "actions",
      label: "FieldActions",
      tabSelector: issueTypeSelectors.actions,
      columns: removeColumnFilters(contextActionsTableColumns()),
      rowSelection: true,
      type: "table",
      generateDynamicColumns: (props, data) => [],
      customPropertiesSelector: pageSelectors.nullableCustomPropertiesSelector,
    },
    {
      key: "issueType",
      label: "ParentIssueType",
      tabSelector: issueTypeSelectors.all,
      columns: removeColumnFilters(issueTypeTableColumns) as [],
      rowSelection: true,
      type: "tree",
      changeNewEntityParent: updateParentId,
      changeExistingEntityParent: updateIssueTypeParentId,
      selectedKeyId: "parentId",
      generateDynamicColumns: (props, data) => removeColumnFilters(prepareDynamicColumns<IssueType>(props, data)),
      customPropertiesSelector: issueTypeSelectors.issueTypeCustomProperties,
    },
  ],
  listSearch: {
    columns: pickSpecificColumns<IssueType>(issueTypeTableColumns, "name", "externalId"),
    keys: ["name", "externalId"],
  },
});

export default issueTypeConfigPage;
