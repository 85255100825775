import { useContext, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import PageConfigurationContext from "../../context/pageContext";
import { RootState, useAppDispatch } from "../../store/store";
import useFilters from "./useFilter";

const useLoadInitalData = () => {
  const dispatch = useAppDispatch();
  const pageConfig = useContext(PageConfigurationContext);
  const { filtersReady } = useFilters(pageConfig.id, pageConfig.id, true);
  const jwt = useSelector((state: RootState) => state.user.jwt);
  const jwtRef = useRef(jwt);
  const initialLoadRef = useRef(true);
  const isLoading = useSelector(pageConfig.isLoading);

  useEffect(() => {
    const prevJwt = jwtRef.current;
    const isJwtChanged = jwt !== prevJwt;

    if ((initialLoadRef.current && !isLoading) || isJwtChanged) {
      if (pageConfig?.intializeDataActions && filtersReady) {
        pageConfig.intializeDataActions.forEach(action => dispatch(action(isJwtChanged)));
        jwtRef.current = jwt;
        if (initialLoadRef.current && !isLoading) {
          initialLoadRef.current = false;
        }
      }
    }
  }, [jwt, isLoading, pageConfig, filtersReady, dispatch, initialLoadRef]);

  useEffect(() => {
    if (!initialLoadRef.current) {
      initialLoadRef.current = true;
    }
  }, [pageConfig.id]);
};

export default useLoadInitalData;
