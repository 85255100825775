import { Select, Space, Typography } from "antd";
import { DefaultOptionType } from "antd/lib/select";

import { JoinBy } from "../../../../types/cubeDimensions";

const { Text } = Typography;
type SelectJoinTypeProps = {
  value: string;
  onChange: (value: string) => void;
};
export default function SelectJoinType({ value, onChange }: SelectJoinTypeProps) {
  const getOptions: () => DefaultOptionType[] = () => {
    return Object.values(JoinBy).map(v => {
      return { label: v, value: v };
    });
  };
  return (
    <Space direction="horizontal">
      <Text>Join Type</Text>
      <Select placeholder="please select join type" options={getOptions()} onChange={onChange} value={value} />
    </Space>
  );
}
