import { RecurrenceRule } from "./dashboard";
import { BaseEntityType } from "./entityBase";
import { TOption } from "./page";
import { ExplicitAdditionalProps } from "./utility";

export type Tag = {
  id: number;
  name: string;
};
export interface RecurrentActionState {
  data: RecurrentAction[];
  isLoading: boolean;
  singleData: RecurrentAction | null | undefined;
  subData: any;
  error: string | null;
  defaultCustomProperties: ExplicitAdditionalProps[];
  lastUpdated: string;
}

export type RecurrentAction = Record<string, unknown> & {
  id: number;
  title: string;
  responsibleUserId: number | null;
  responsibleUserName: string;
  projectId: number | null;
  projectName: string;
  isActive: boolean;
  startDate: string;
  recurrencyRule: string | null;
  recurrencyRuleEndDate: string | null;
  description: string | null;
  durationHours: number | null;
  followUpReviewId: number | null;
  employeeId: number | null;
  equipmentId: number | null;
  locationId: number | null;
  issueTypeId: number | string | null;
  recurrencyRuleOccurrences: number | null;
  isFormBased: boolean;
  recurrencyRuleEndType: RecurrencyRuleEndType;
  recurrenceRule: RecurrenceRule;
};

export type RecurrentActionPage = BaseEntityType & {
  entityData: {
    primaryData: RecurrentAction;
  };
  subData: any;
  listViewData: RecurrentAction;
};

export enum RecurrencyRuleEndType {
  Date = "Date",
  Never = "Never",
  OccurrencesCount = "OccurrencesCount",
}

export const RecurrencyRuleEndTypeOptions: TOption[] = [
  {
    id: RecurrencyRuleEndType.Date,
    label: RecurrencyRuleEndType.Date,
  },
  {
    id: RecurrencyRuleEndType.Never,
    label: RecurrencyRuleEndType.Never,
  },
  {
    id: RecurrencyRuleEndType.OccurrencesCount,
    label: RecurrencyRuleEndType.OccurrencesCount,
  },
];
