import { MoreOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Menu, Popover, Row, Typography } from "antd";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import toNumber from "lodash/toNumber";
import { MenuInfo } from "rc-menu/lib/interface";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import TimeAgo from "timeago-react";
import * as timeago from "timeago.js";
import ar from "timeago.js/lib/lang/ar";
import en_US from "timeago.js/lib/lang/en_US";
import es_Es from "timeago.js/lib/lang/es";
import fr from "timeago.js/lib/lang/fr";
import he from "timeago.js/lib/lang/he";
import ru from "timeago.js/lib/lang/ru";
import uk from "timeago.js/lib/lang/uk";
import { AddButtonActions, SystemActions } from "../../consts/listViewActions";
import PageConfigurationContext from "../../context/pageContext";
import { ReactComponent as RefreshIcon } from "../../media/header-refresh-icon.svg";
import { getAlignment } from "../../pages/ProfileMenu/utility";
import { userSelectors } from "../../selectors";
import { setFillAForm } from "../../store/slices/common";
import { RootState, useAppDispatch } from "../../store/store";
import { BaseEntityType } from "../../types/entityBase";
import { CategoryPage } from "../../types/page";
import { Language } from "../../types/user";
import { TableAddButtonType } from "../../types/utility";
import usePasProFlag from "../../utils/hooks/usePasProFlag";
import useRouter from "../../utils/hooks/useRouter";
import { ConfirmationModal } from "../ConfirmationModal/ConfirmationModal";
import { HBAddTableRecordModal } from "../HBComponents/AddTableRecordModal/HBAddTableRecordModal";
import { QuicklyCreateAppointment } from "../QuickCreateAppointmentModal/QuicklyCreateAppointment";
import HeaderSubMenu from "./HeaderSubMenu";
import subMenuConfig, { isActiveConfig } from "./subMenuConfig";
import { View } from "./types";
import "./headerBar.less";

dayjs.extend(relativeTime);

const { Title, Text } = Typography;

type TProps = {
  handleColumnSelectionVisibility?: (visible: boolean) => void;
  selectedRows?: Record<string, unknown>[];
  addButtonPathAction?: boolean;
  customRefreshAction?: () => void;
};

// const demoDropDown = [{ title: "Item 1" }, { title: "Item 2" }, { title: "Item 3" }];

export const HeaderBar = ({
  handleColumnSelectionVisibility,
  selectedRows,
  addButtonPathAction,
  customRefreshAction,
}: TProps): JSX.Element => {
  const {
    title,
    PageIcon,
    addButton,
    id,
    actions,
    listViewActions,
    singleViewActions,
    lastUpdatedSelector,
    groupView,
    listView,
    hasListView,
    hasGroupView,
    fetchSingle,
    tabsPanel,
    primarySingleEntitySelector,
  } = useContext(PageConfigurationContext) as CategoryPage<BaseEntityType>;
  const { history, location } = useRouter<{ viewType: View }>();
  const dispatch = useAppDispatch();
  const lastUpdatedText = useSelector(lastUpdatedSelector);
  const [enableAddButton, setEnableAddButton] = useState(false);
  const [modalInfo, setModalInfo] = useState<{ body: string; okText: string; cancelText?: string } | null>(null);
  const { t } = useTranslation();
  const [isQuicklyCreateAppointmentModalVisible, setQuicklyCreateAppointmentModalVisible] = useState<boolean>(false);
  const { id: itemId } = useParams<{ id: string }>();
  const urlSearchParams = new URLSearchParams(location.search);
  const isMobile = Boolean(urlSearchParams.get("mobile"));

  const isPASProEnabled = usePasProFlag();
  const defaultPageSize = useSelector((state: RootState) => state.user.companySettings.defaultPageSize);
  const lang = useSelector((state: RootState) => state.user.settings.lang);
  const direction = useSelector((state: RootState) => state.user.settings.direction);
  const userState = useSelector(userSelectors.getCurrentUser);
  const jwt = useSelector((state: RootState) => state.user.jwt);
  const primaryColor = useSelector((state: RootState) => state.common.primaryColor);
  const singleViewEntity = useSelector(primarySingleEntitySelector);
  const [lastUpdatedDate, setLastUpdated] = useState(lastUpdatedText);
  const [isActionsMenuOpen, setIsActionsMenuOpen] = useState(false);
  const [isAddButtonActionsMenuOpen, setIsAddButtonActionsMenuOpen] = useState(false);
  const [isActionModalVisible, setActiondModalVisibility] = useState(false);
  const rootLocation = `/${id}`;
  const subMenuData = subMenuConfig.find(x => isActiveConfig(x, id));
  const getPageActions = () => {
    if (location.pathname === rootLocation) {
      if (listViewActions) {
        return listViewActions.filter(x => (x.stateBaseAccess ? x.stateBaseAccess(user) : true));
      } else {
        return actions;
      }
    }
    return (
      singleViewActions &&
      singleViewActions.filter(x => (x.stateBaseAccess ? x.stateBaseAccess(user, singleViewEntity) : true))
    );
  };
  const user = useSelector((state: RootState) => state.user);
  timeago.register(Language.ar, ar);
  timeago.register(Language["en-US"], en_US);
  timeago.register(Language["he-IL"], he);
  timeago.register(Language["uk-UA"], uk);
  timeago.register(Language["ru-RU"], ru);
  timeago.register(Language["es-ES"], es_Es);
  timeago.register(Language["fr-FR"], fr);

  useEffect(() => {
    if (addButton?.enablingFor === undefined) setEnableAddButton(true);
    else {
      setEnableAddButton(addButton.enablingFor(userState));
    }
  }, [userState]);

  useEffect(() => {
    if (lastUpdatedText) {
      setLastUpdated(lastUpdatedText);
    }
  }, [lastUpdatedText]);
  const handleMenuClick = (e: MenuInfo, isAddNewMenu?: boolean) => {
    const idx = toNumber(e.key);
    const actions = (isAddNewMenu ? addButton?.addButtonActions : getPageActions())?.filter(
      a => !a.canAccess || a.canAccess()
    );

    if (isAddNewMenu) {
      setIsAddButtonActionsMenuOpen(false);
    } else {
      setIsActionsMenuOpen(false);
    }

    if (actions && actions.length > idx) {
      // This action exists -> others are just for preview -> this should be removed once the demo menu is removed
      const action = actions[idx];
      if (action.action) {
        return action.action(action, jwt, selectedRows, t, dispatch);
      }
      if (action.link && selectedRows) {
        const linkData = action.link(selectedRows);
        let link = linkData.path;
        if (!link.startsWith("/")) {
          link = `/${id}/${link}`;
        }
        if (linkData.warningMessage) {
          return setModalInfo(linkData.warningMessage);
        }
        return history.push({
          pathname: link,
          state: { prefillData: linkData.prefillData },
        });
      }
      if (action.showColumnManagement && handleColumnSelectionVisibility) {
        return handleColumnSelectionVisibility(action.showColumnManagement());
      }
      return console.warn("Menu action with no link or function: ", action);
    }
  };

  const quicklyCreateAppointment = () => {
    if (isPASProEnabled) {
      dispatch(setFillAForm(true));
    } else {
      setQuicklyCreateAppointmentModalVisible(!isQuicklyCreateAppointmentModalVisible);
    }
  };

  const getQuicklyCreateAppointment = addButton?.addButtonActions?.find(
    mi => mi.id == AddButtonActions.QuickCreateAppointment
  );

  if (getQuicklyCreateAppointment) {
    getQuicklyCreateAppointment.action = quicklyCreateAppointment;
  }

  const handleAddNew = () => {
    if (addButtonPathAction) {
      history.push(`..${rootLocation}/new`);
    } else {
      if (addButton?.action) dispatch(addButton?.action);
    }
  };

  const handleOpenActionModal = () => {
    setActiondModalVisibility(true);
  };

  const setAction = (actionId: string, action: () => void) => {
    const actionItem = addButton?.addButtonActions?.find(mi => mi.id === actionId);
    if (actionItem) {
      actionItem.action = action;
    }
  };

  setAction(SystemActions.AddNew, handleAddNew);
  setAction(SystemActions.CallModal, handleOpenActionModal);

  const handleDataRefetch = () => {
    if (customRefreshAction) {
      customRefreshAction();
      return;
    }
    if (location.pathname === rootLocation) {
      if (hasListView && listView?.table.type === "paginatedTable" && listView?.table.fetchPaginatedData) {
        dispatch(listView?.table.fetchPaginatedData({ forceUpdate: true, page: 1, pageSize: defaultPageSize }));
      } else if (hasListView && listView?.table.fetchListViewData) {
        dispatch(listView?.table.fetchListViewData(true));
      }
      if (hasGroupView && groupView) {
        dispatch(groupView?.fetchCardThunk(true));
      }
    } else if (itemId) {
      dispatch(fetchSingle(itemId)).then(res =>
        tabsPanel.forEach(x => x.tabDataThunk && x.key === "accountable" && dispatch(x.tabDataThunk(itemId)))
      );
    }
  };

  const pageActions = getPageActions();

  const menuActions =
    pageActions && pageActions.length ? (
      <Menu selectable={false} onClick={handleMenuClick}>
        {pageActions
          .filter(a => !a.canAccess || a.canAccess())
          .map((action, index) => {
            return (
              <Menu.Item
                key={index}
                disabled={
                  action.id === SystemActions.CreateTrainingWithEmployees || action.id === SystemActions.SetActionStatus
                    ? selectedRows && !selectedRows.length
                    : false
                }
              >
                {action.content ? (
                  <Popover
                    align={getAlignment(direction)}
                    placement={direction === "ltr" ? "rightBottom" : "leftTop"}
                    key={action.title}
                    trigger="hover"
                    content={action.content}
                  >
                    {t(action.title)}
                  </Popover>
                ) : (
                  t(action.title)
                )}
              </Menu.Item>
            );
          })}
      </Menu>
    ) : null;

  const addButtonActions =
    addButton && addButton.addButtonActions?.length ? (
      <Menu selectable={false} onClick={menuInfo => handleMenuClick(menuInfo, true)}>
        {addButton.addButtonActions
          .filter(a => !a.canAccess || a.canAccess())
          .map((action, index) => {
            return <Menu.Item key={index}>{t(action.title)}</Menu.Item>;
          })}
      </Menu>
    ) : null;

  return (
    <Row justify="space-between" align="middle">
      <ConfirmationModal
        visible={modalInfo !== null}
        message={(modalInfo?.body && t(modalInfo.body)) || ""}
        okText={(modalInfo?.okText && t(modalInfo.okText)) || ""}
        cancelText={(modalInfo?.cancelText && t(modalInfo.cancelText)) || ""}
        confirmOkClick={() => setModalInfo(null)}
      />
      {addButton?.addButtonModal?.type === TableAddButtonType.AddModal && (
        <HBAddTableRecordModal
          isVisible={isActionModalVisible}
          fields={addButton?.addButtonModal?.addModalInputFields}
          closeModal={() => setActiondModalVisibility(false)}
          onSave={addButton?.addButtonModal?.addRecordModalAction}
        ></HBAddTableRecordModal>
      )}
      <QuicklyCreateAppointment
        cancelClick={() => setQuicklyCreateAppointmentModalVisible(false)}
        visible={isQuicklyCreateAppointmentModalVisible}
      />
      <Col className="left-side-col" style={{ minHeight: "64px" }}>
        <Row align="middle" style={{ minHeight: "64px" }}>
          <PageIcon fill={primaryColor} />
          <Title className="page-title" data-testid="header">
            {subMenuData ? <HeaderSubMenu items={subMenuData.items} /> : t(title)}
          </Title>
        </Row>
      </Col>
      <Col className="right-side-col">
        <Row align="middle">
          <Text className="noselect last-updated-text">
            {t("LastUpdated")} &nbsp;
            <TimeAgo locale={lang} datetime={lastUpdatedDate} />
          </Text>
          <RefreshIcon style={{ cursor: "pointer" }} onClick={handleDataRefetch} />
          {!isMobile && (
            <>
              {addButton &&
                enableAddButton &&
                (addButton.isMenu || addButton?.addButtonModal?.type === TableAddButtonType.AddModal ? (
                  <Popover
                    content={addButtonActions}
                    open={isAddButtonActionsMenuOpen}
                    onOpenChange={visible => {
                      setIsAddButtonActionsMenuOpen(visible);
                    }}
                    trigger={["click"]}
                  >
                    <Button className="add-category" type="primary">
                      {t(addButton.label)}
                      <PlusOutlined />
                    </Button>
                  </Popover>
                ) : (
                  <Button className="add-category" type="primary" onClick={handleAddNew}>
                    {t(addButton.label)}
                    <PlusOutlined />
                  </Button>
                ))}
              {menuActions && (
                <Popover
                  content={menuActions}
                  open={isActionsMenuOpen}
                  onOpenChange={visible => {
                    setIsActionsMenuOpen(!isActionsMenuOpen);
                  }}
                  trigger={["click"]}
                >
                  <Button data-dest="moreActionsMenu" className="dropdown-button" type="text" shape="round">
                    <MoreOutlined className="dropdown-button__icon" />
                  </Button>
                </Popover>
              )}
            </>
          )}
        </Row>
      </Col>
      <Divider style={{ margin: "0 0 1rem 0" }} />
    </Row>
  );
};
