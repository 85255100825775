import { Collapse, Input, InputNumber, Select, Space, Switch } from "antd";
import { SwitchChangeEventHandler } from "antd/lib/switch";
import { useSelector } from "react-redux";
import { updateStagedChart } from "../../../../../store/slices/dashboard";
import { RootState, useAppDispatch } from "../../../../../store/store";
import { HBChartType } from "../../../../../types/dashboard";
import Annotations from "../VizualizationsMenu/Annotations";
import LabelColoring from "../VizualizationsMenu/LabelColoring";

const { Panel } = Collapse;

export default function VizualizationConfig() {
  const stagedChart = useSelector((state: RootState) => state.dashboard.stagedChart);
  const dispatch = useAppDispatch();
  const onGroupItems: SwitchChangeEventHandler = checked => {
    if (stagedChart) {
      dispatch(updateStagedChart({ ...stagedChart, barGrouping: checked ? "stack" : "dodge" }));
    }
  };

  const onSetHirarchialNavigation: SwitchChangeEventHandler = checked => {
    if (stagedChart) {
      dispatch(updateStagedChart({ ...stagedChart, useHierarchicalNavigation: checked }));
    }
  };

  const onSetHirarchialNavigationValueChanged: React.ChangeEventHandler<HTMLInputElement> = event => {
    if (stagedChart) {
      dispatch(
        updateStagedChart({
          ...stagedChart,
          hierarchicalNavigationState: {
            ...stagedChart.hierarchicalNavigationState,
            value: event.target.value,
          },
        })
      );
    }
  };

  const onSetHirarchialNavigationDimensionChanged = (value: string) => {
    if (stagedChart) {
      dispatch(
        updateStagedChart({
          ...stagedChart,
          hierarchicalNavigationState: {
            ...stagedChart.hierarchicalNavigationState,
            dimensionKey: value,
          },
        })
      );
    }
  };

  const onChangeHeriarchialInitialValueDepth: (value: number | null) => void = value => {
    if (stagedChart) {
      dispatch(
        updateStagedChart({
          ...stagedChart,
          hierarchicalNavigationState: {
            ...stagedChart.hierarchicalNavigationState,
            depth: value as number | undefined,
          },
        })
      );
    }
  };

  const onFlipChart: SwitchChangeEventHandler = checked => {
    if (stagedChart) {
      dispatch(updateStagedChart({ ...stagedChart, chartAxis: checked ? "x" : "y" }));
    }
  };
  const onChartTypeChanged: (value: HBChartType) => void = value => {
    if (stagedChart) {
      dispatch(updateStagedChart({ ...stagedChart, chartType: value }));
    }
  };
  return (
    <Space direction="vertical" size={10}>
      <Space size={20}>
        <Space direction="horizontal">
          <div>Chart Type</div>
          <Select
            style={{ width: "10rem" }}
            onChange={onChartTypeChanged}
            value={stagedChart?.chartType}
            options={Object.values(Object.values(HBChartType).map(v => ({ value: v, label: v })))}
          />
        </Space>
        <div>
          <div>Group Items</div>
          <Switch
            onChange={onGroupItems}
            defaultChecked={stagedChart?.barGrouping === "stack"}
            checkedChildren=""
            unCheckedChildren=""
          />
        </div>
        <div>
          <div>Chart Main Axis</div>
          <Switch
            onChange={onFlipChart}
            defaultChecked={stagedChart?.chartAxis === "x"}
            checkedChildren="x"
            unCheckedChildren="y"
          />
        </div>
        <Space>
          <div>
            <div>Hirachial Navigation</div>
            <Switch
              onChange={onSetHirarchialNavigation}
              defaultChecked={stagedChart?.useHierarchicalNavigation}
              checkedChildren=""
              unCheckedChildren=""
            />
          </div>
          {stagedChart?.useHierarchicalNavigation && (
            <Space>
              <div>
                <div>Depth</div>
                <InputNumber<number>
                  value={stagedChart.hierarchicalNavigationState?.depth}
                  onChange={onChangeHeriarchialInitialValueDepth}
                />
              </div>
              <div>
                <div>Value</div>
                <Input
                  value={stagedChart.hierarchicalNavigationState?.value}
                  onChange={onSetHirarchialNavigationValueChanged}
                />
              </div>
              <div>
                <div>Dimension</div>
                <Select
                  value={stagedChart.hierarchicalNavigationState?.dimensionKey}
                  onSelect={onSetHirarchialNavigationDimensionChanged}
                  options={stagedChart.pivotConfig?.x
                    ?.map(dimension => ({ label: dimension, key: dimension, value: dimension }))
                    .concat(
                      stagedChart.pivotConfig?.y?.map(dimension => ({
                        label: dimension,
                        key: dimension,
                        value: dimension,
                      })) || []
                    )}
                />
              </div>
            </Space>
          )}
        </Space>
      </Space>
      <Collapse style={{ width: "50rem" }}>
        <Panel key="1" header="LabelColoring">
          <LabelColoring />
        </Panel>
      </Collapse>
      <Collapse style={{ width: "50rem" }}>
        <Panel key="1" header="Annotations">
          <Annotations />
        </Panel>
      </Collapse>
    </Space>
  );
}
