import { unwrapResult } from "@reduxjs/toolkit";
import { Modal, Button, Spin } from "antd";
import { useEffect, useState } from "react";
import "./vitreModalComponent.less";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getRedirectToLegacy } from "../../store/slices/user";
import { RootState, useAppDispatch } from "../../store/store";
import { LocalStorageKeys } from "../../types/utility";

const isEncoded = (uri: string) => {
  try {
    return uri !== decodeURIComponent(uri);
  } catch (e) {
    return false;
  }
};

export const VitreModalComponent = () => {
  const [visible] = useState(true);
  const direction = useSelector((state: RootState) => state.user.settings.direction);
  const lang = useSelector((state: RootState) => state.user.settings.lang);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isVitreEntered = localStorage.getItem(LocalStorageKeys.VITRE_ENTERED);

  useEffect(() => {
    if (isVitreEntered) {
      handleVitreEntrance();
    }
  }, [isVitreEntered]);

  const handleVitreEntrance = () => {
    dispatch(getRedirectToLegacy({}))
      .then(res => unwrapResult(res))
      .then(data => {
        let searchParams = window.location.search;
        if (isEncoded(searchParams)) {
          searchParams = decodeURIComponent(searchParams);
        }
        const params = new URLSearchParams(searchParams);
        const lg = params.get("lg");
        const returnUrl = params.get("returnUrl");

        const lgParam = returnUrl ? searchParams.split("lg=")[1] : lg;

        const vitreUrl = `${process.env.REACT_APP_VITRE_URL}/localRedirect?page=${
          lgParam ? lgParam : window.location.pathname.replace(/^\//, "")
        }&language=${lang}&key=${new URLSearchParams(new URL(data.returnUrl).search).get("Key")}`;

        localStorage.setItem(LocalStorageKeys.VITRE_ENTERED, "true");
        window.location.href = vitreUrl;
      })
      .catch(e => {
        console.error(e);
      });
  };

  return (
    <>
      {isVitreEntered ? (
        <div
          className="full-login-container"
          style={{ background: "white", position: "fixed", top: 0, left: 0, right: 0, bottom: 0 }}
        >
          <Spin />
        </div>
      ) : (
        <Modal closable={false} className={`dark-mask-${direction}`} open={visible} centered footer={null} title={null}>
          <div className="modal-content">
            <div className="inner-circle">
              <img src="/media/vitre.png" alt="inner circle" className="inner-circle-logo" />
            </div>
            <div className="cycle-container">
              <div className="video-container">
                <video autoPlay loop muted>
                  <source src="/media/vitre-background.mp4" type="video/mp4" />
                </video>
                <div className="mask" />
              </div>
            </div>
            <div style={{ marginLeft: "4rem", marginTop: "1rem", width: "42rem" }} className="text-content">
              <h1 className="modal-title">{t("VitreModalTitle")}</h1>
              <pre className="modal-text">
                {`${t("PasIsNowVitreText")}

    ${t("VitreIsText")}`}
              </pre>
              <Button className="vitre-button" type="primary" onClick={handleVitreEntrance}>
                <span>{t("EntranceVitreButton")}</span>
                <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1.61733 9.65855H16.8934L13.881 12.8722C13.6402 13.1291 13.6402 13.5505 13.881 13.8074C14.1217 14.0642 14.5106 14.0642 14.7514 13.8074L18.8194 9.46757C19.0602 9.21073 19.0602 8.79585 18.8194 8.53902L14.7576 4.19262C14.5168 3.93579 14.1279 3.93579 13.8872 4.19262C13.6464 4.44946 13.6464 4.86434 13.8872 5.12117L16.8934 8.34146H1.61733C1.27781 8.34146 1.00002 8.6378 1.00002 9C1.00002 9.3622 1.27781 9.65855 1.61733 9.65855Z"
                    fill="white"
                    stroke="white"
                  />
                </svg>
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
