export enum CubeAppointment {
  id = "Appointment.id",
  title = "Appointment.title",
  latx = "Appointment.latx",
  longy = "Appointment.longy",
  date = "Appointment.date",
  numOfTasks = "Appointment.numOfTasks",
  score = "Appointment.score",
  ansewrsCriticalDefect = "Appointment.ansewrsCriticalDefect",
  answersDefect = "Appointment.ansewrsDefect",
  count = "Appointment.count",
}
