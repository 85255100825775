import { Locale } from "antd/lib/locale-provider";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import timezone from "dayjs/plugin/timezone";
import updateLocale from "dayjs/plugin/updateLocale";
import utc from "dayjs/plugin/utc";
import weekOfYear from "dayjs/plugin/weekOfYear";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import i18n from "../../i18n";
import { antdLocales, DAY_OF_WEEK, dayjsLocales } from "../../locales";
import { fetchCompanySettings, setUserLanguage, setUserTimezone } from "../../store/slices/user";
import { RootState, useAppDispatch } from "../../store/store";
import { LocalStorageKeys, ParamsKeys } from "../../types/utility";
import { getDeviceLang } from "../functions";
import useRouter from "./useRouter";

dayjs.extend(quarterOfYear);
dayjs.extend(advancedFormat);
dayjs.extend(updateLocale);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(weekOfYear);
dayjs.extend(localizedFormat);

const useInitAppUserSettings = () => {
  const dispatch = useAppDispatch();
  const jwt = useSelector((state: RootState) => state.user.jwt);
  const lang = useSelector((state: RootState) => state.user.settings.lang);
  const router = useRouter();
  const queryParams = new URLSearchParams(router.location.search);
  const userLanguage = useSelector((state: RootState) => state.user.personalSettings.language);
  const direction = useSelector((state: RootState) => state.user.settings.direction);
  const companySettings = useSelector((state: RootState) => state.user.companySettings);
  const [locale, setLocale] = useState<Locale>();

  useEffect(() => {
    dispatch(setUserTimezone(dayjs.tz.guess()));
  }, []);

  useEffect(() => {
    if (jwt) {
      (async () => {
        let language = queryParams.get(ParamsKeys.LANG) || localStorage.getItem(LocalStorageKeys.LANG);

        if (queryParams.has(ParamsKeys.LANG)) {
          localStorage.setItem(LocalStorageKeys.LANG, language || "");
          queryParams.delete(ParamsKeys.LANG);
          const newUrl = queryParams.toString()
            ? `${window.location.pathname}?${queryParams.toString()}`
            : window.location.pathname;
          router.history.replace(newUrl);
        }
        if (!language) {
          language = getDeviceLang();
          dispatch(setUserLanguage({ language, updateSettings: false }));
        }
        dispatch(setUserLanguage({ language, updateSettings: false }));
        dispatch(fetchCompanySettings({ jwt }));
      })();
    }
  }, [jwt]);

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
      setLocale(antdLocales[lang]);
      dayjs.locale(dayjsLocales[lang]);
      dayjs.updateLocale(antdLocales[lang], { weekStart: DAY_OF_WEEK[companySettings.firstDayOfWeek] });
    }
  }, [lang, userLanguage]);

  return { locale, direction };
};

export default useInitAppUserSettings;
