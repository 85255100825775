import useRouter from "./useRouter";
import useWindowDimensions from "./useWindowDimensions";

const MOBILE_BREAKPOINT = 767;

const useIsMobile = (): boolean => {
  const { location } = useRouter();
  const { width } = useWindowDimensions();
  const urlSearchParams = new URLSearchParams(location.search);
  const isMobile = width < MOBILE_BREAKPOINT || Boolean(urlSearchParams.get("mobile"));

  return isMobile;
};

export default useIsMobile;
