import { DownOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import PageConfigurationContext from "../../context/pageContext";
import { isAdmin } from "../../pages/pageConfig/category/utilities";
import useRouter from "../../utils/hooks/useRouter";
import { SubMenuConfigItem } from "./types";

import "./headerBar.less";

type TProps = {
  items: SubMenuConfigItem[];
};

const { SubMenu } = Menu;

const HeaderSubMenu = ({ items }: TProps): JSX.Element => {
  const { id } = useContext(PageConfigurationContext);
  const { history } = useRouter<{ viewType: null }>();
  const { t } = useTranslation();

  const onSubMenuItemClicked = (item: { key: string }) => {
    history.push(`/${item.key}`);
  };

  const isSubMenuItemVisible = (item: SubMenuConfigItem) => {
    return !item.isVisibleByAdminOnly || isAdmin();
  };

  const getSubMenuItemTitle = (item: SubMenuConfigItem) => {
    const selectedSubItem = item.items?.find(ch => ch.id === id);
    const title = selectedSubItem ? `${t(item.label)}: ${t(selectedSubItem.label)}` : t(item.label);

    return (
      <>
        {title}
        <DownOutlined className="ml-3 mr-0" />
      </>
    );
  };

  return (
    <Menu
      disabledOverflow={true}
      className="pas-horizontal-menu"
      selectedKeys={[id]}
      mode="horizontal"
      triggerSubMenuAction="click"
    >
      {items.map((item, index) => {
        if (!isSubMenuItemVisible(item)) {
          return null;
        }
        const visibleChildren = item.items && item.items.length ? item.items.filter(x => isSubMenuItemVisible(x)) : [];

        return visibleChildren.length ? (
          <SubMenu
            key={`SubMenu_${index}`}
            className="pas-horizontal-menu-item"
            popupClassName="pas-horizontal-sub-menu"
            title={getSubMenuItemTitle(item)}
            disabled={item.disabled}
          >
            <Menu.Item
              key={item.id}
              className="pas-sub-menu-item"
              disabled={item.disabled}
              onClick={onSubMenuItemClicked}
            >
              {t(item.label)}
            </Menu.Item>
            {visibleChildren.map(ch => {
              return (
                isSubMenuItemVisible(ch) && (
                  <Menu.Item
                    key={ch.id}
                    className="pas-sub-menu-item"
                    disabled={ch.disabled}
                    onClick={onSubMenuItemClicked}
                  >
                    {t(ch.label)}
                  </Menu.Item>
                )
              );
            })}
          </SubMenu>
        ) : (
          <Menu.Item
            key={item.id}
            className="pas-horizontal-menu-item"
            disabled={item.disabled}
            onClick={onSubMenuItemClicked}
          >
            {t(item.label)}
          </Menu.Item>
        );
      })}
    </Menu>
  );
};

export default HeaderSubMenu;
