import { useEffect } from "react";
import useRouter from "../../utils/hooks/useRouter";

export default function RootPath() {
  const router = useRouter();

  useEffect(() => {
    router.push("/actions");
  }, []);
  return <></>;
}
