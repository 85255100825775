import { Modal } from "antd";
import { inspectionTypeSelectors } from "../../../selectors";
import {
  addInspectionTypeLocationTypeRelation,
  changeLocalStatus,
  deleteNewLocTypeInspTypeRelationEntryTemplate,
  fillNewLocTypeInspTypeRelationEntryTemplate,
  updateLocTypeInspTypeRelationStatus,
} from "../../../store/slices/locationType";
import store from "../../../store/store";
import { EqLocTypeInspTypeRelationStatus } from "../../../types/equipmentType";
import { LocTypeInspTypeRelation, LocationType } from "../../../types/locationType";
import { ColumnType, ColumnWithDifferentCells } from "../../../types/page";
import { EqTypeInspTypeRelationStatusOptions, getCellSearchResultColumns } from "./common";
import { fetchEmployeeById, fetchInspectionTypeById, fetchOUById, localizeText, renderBoolStatus } from "./utilities";

export const locationTypeTableColumns: ColumnType<LocationType>[] = [
  {
    id: "id",
    label: "ID",
    renderValue: entity => entity.id.toString(),
    sortable: false,
    optionsSelector: () => null,
    // hidden: true,
  },
  {
    id: "name",
    label: "FieldName",
    renderValue: entity => entity.name,
    optionsSelector: () => null,
    sortable: true,
  },
];

export const locTypeInspTypeRelationTableColumns: ColumnWithDifferentCells<LocTypeInspTypeRelation>[] = [
  {
    id: "id",
    label: "ID",
    renderValue: entity => entity.inspectionTypeId?.toString() ?? "",
    sortable: false,
    optionsSelector: () => null,
    primaryColumn: entity => ({
      navigationTarget: `/inspectionLocationType/${entity.inspectionTypeId}`,
    }),
  },
  {
    id: "name",
    label: "FieldName",
    renderValue: entity => fetchInspectionTypeById(entity.inspectionTypeId)?.name ?? "",
    sortable: false,
    optionsSelector: () => null,
    cellType: e => (e.staging && typeof e.id === "string" ? "search" : "text"),
    cellSearchSelector: inspectionTypeSelectors.all as any,
    cellSearchResultColumns: getCellSearchResultColumns(["name"]),
    cellSearchKeys: ["name"],
    cellSearchRecordClick: fillNewLocTypeInspTypeRelationEntryTemplate,
    width: 100,
    primaryColumn: e => ({
      navigationTarget: `/inspectionLocationType/${e.inspectionTypeId}`,
    }),
  },
  {
    id: "recurrencyType",
    label: "FieldRecurrencyType",
    renderValue: entity => localizeText(fetchInspectionTypeById(entity.inspectionTypeId)?.recurrencyType ?? ""),
    optionsSelector: () => null,
    sortable: false,
  },
  {
    id: "isReviewRequired",
    label: "FieldIsReviewRequired",
    renderValue: entity =>
      renderBoolStatus(fetchInspectionTypeById(entity.inspectionTypeId)?.isReviewRequired ?? false),
    optionsSelector: () => null,
    sortable: true,
  },
  {
    id: "user",
    label: "FieldUser",
    renderValue: entity => fetchEmployeeById(fetchInspectionTypeById(entity.inspectionTypeId)?.userId),
    optionsSelector: () => null,
    sortable: true,
  },
  {
    id: "orgUnit",
    label: "FieldOrgUnit",
    renderValue: entity => fetchOUById(fetchInspectionTypeById(entity.inspectionTypeId)?.orgUnitId),
    optionsSelector: () => null,
    sortable: true,
  },
  {
    id: "reviewId",
    label: "FieldReviewId",
    renderValue: entity => fetchInspectionTypeById(entity.inspectionTypeId)?.reviewId?.toString() ?? null,
    optionsSelector: () => null,
    sortable: true,
  },
  {
    id: "status",
    width: 300,
    label: "FieldStatus",
    renderValue: entity => entity.status,
    sortable: false,
    filterType: "multiSelect",
    cellType: entity => "dropdown",
    cellDropdownOptions: entity =>
      EqTypeInspTypeRelationStatusOptions.map(ao => ({
        id: ao.id,
        label: localizeText(ao.label),
      })),
    cellDropdownOnChange: (entity, newStatus) =>
      changeLocalStatus({ entity, status: newStatus as EqLocTypeInspTypeRelationStatus }),
    optionsSelector: state =>
      Array.from(new Set(state.inspection.singleData?.checkpoints.map(x => x.status))).map(l => ({
        id: l,
        label: localizeText(l),
      })),
  },
  {
    id: "action" as keyof LocTypeInspTypeRelation,
    label: "FieldAction",
    renderValue: () => null,
    sortable: false,
    optionsSelector: () => null,
    cellType: () => "buttonActions",
    width: 100,
    stagingActions: [
      {
        label: "Add",
        className: "hb-primary-button",
        action: entity => {
          if (typeof entity.id === "string") {
            return addInspectionTypeLocationTypeRelation(entity);
          } else {
            Modal.confirm({
              content: localizeText(
                entity.status === EqLocTypeInspTypeRelationStatus.CreatedByMistake
                  ? "EqTypeInspTypeRegretConfirmationMessage"
                  : "EqTypeInspTypeNoLongerNeededConfirmationMessage"
              ),
              onOk: async () => {
                store.dispatch(updateLocTypeInspTypeRelationStatus(entity));
              },
              closable: true,
              maskClosable: true,
            });
          }
        },
      },
      {
        label: "Cancel",
        className: "hb-tertiary-button",
        action: entity => deleteNewLocTypeInspTypeRelationEntryTemplate(entity),
      },
    ],
  },
];
