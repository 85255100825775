import { SyncOutlined, ArrowDownOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { PullToRefresh } from "antd-mobile";
import { FC, useEffect, useState, PropsWithChildren, useContext } from "react";
import { useTranslation } from "react-i18next";

import {
  PullToRefreshContext,
  PullToRefreshOnWebProps,
} from "../components/PullToRefreshFromOnWeb/PullToRefreshContext";
import { PullToRefreshContextProvider } from "../components/PullToRefreshFromOnWeb/PullToRefreshContextProvider";
import useIsMobile from "./useIsMobile";

type UseMobilePullToRefreshDataProps = {
  refreshHandler: (event: Event) => void;
};

type UseMobilePullToRefreshDataReturn = {
  endRefreshData: () => void;
  PullToRefreshOnWeb: FC<PropsWithChildren<PullToRefreshOnWebProps>>;
  PullToRefreshContextProvider: React.FC;
};
const OLD_REFRESH_DATA_EVENT_NAME = "refreshAgendaData"; //Remove when fully migradted
const REFRESH_DATA_EVENT_NAME = "refreshWebPageData";
const REFRESH_DATA_END_EVENT_NAME = "refreshWebPageDataEnd";

const PullToRefreshOnWeb: FC<Omit<PullToRefreshOnWebProps, "disabled">> = props => {
  const { disabled } = useContext(PullToRefreshContext);
  const { t } = useTranslation();
  return (
    <PullToRefresh
      disabled={disabled}
      onRefresh={props.onRefresh}
      refreshingText={<Spin tip={t("PullToRefresh")} spinning />}
      completeText={null}
      canReleaseText={<Spin tip={t("PullToRefresh")} indicator={<SyncOutlined style={{ fontSize: 24 }} />} />}
      pullingText={<Spin tip={t("PullToRefresh")} indicator={<ArrowDownOutlined style={{ fontSize: 24 }} />} />}
    >
      {props.children}
    </PullToRefresh>
  );
};

const useMobilePullToRefreshData = ({
  refreshHandler,
}: UseMobilePullToRefreshDataProps): UseMobilePullToRefreshDataReturn => {
  const isMobile = useIsMobile();
  const [isFlutterInAppWebViewReady, setIsFlutterInAppWebViewReady] = useState((window as any).flutter_inappwebview);

  useEffect(() => {
    if (isMobile) {
      window.addEventListener(REFRESH_DATA_EVENT_NAME, refreshHandler);
      window.addEventListener(OLD_REFRESH_DATA_EVENT_NAME, refreshHandler);
    }
    return () => {
      if (isMobile) {
        window.removeEventListener(REFRESH_DATA_EVENT_NAME, refreshHandler);
        window.removeEventListener(OLD_REFRESH_DATA_EVENT_NAME, refreshHandler);
      }
    };
  });

  const endRefreshData = () => {
    // if (isMobile) (window as any).flutter_inappwebview.callHandler(REFRESH_DATA_END_EVENT_NAME, null);
  };

  return { endRefreshData, PullToRefreshOnWeb, PullToRefreshContextProvider };
};

export default useMobilePullToRefreshData;
