import { Menu } from "antd";
import MenuItem from "antd/lib/menu/MenuItem";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { setUserLanguage } from "../../../store/slices/user";
import { RootState, useAppDispatch } from "../../../store/store";
import { HBEventName } from "../../../types/analyticsTypes/HBEvent";
import { Language } from "../../../types/user";
import { LocalStorageKeys } from "../../../types/utility";
import useInitTrackEvents from "../../../utils/hooks/useInitTrackEvents";

import "./LanguagePicker.less";

export const availableLanguages = [
  {
    code: Language["he-IL"],
    title: "עברית",
  },
  {
    code: Language["ar"],
    title: "عربي",
  },
  {
    code: Language["en-US"],
    title: "English",
  },
  {
    code: Language["es-ES"],
    title: "Español",
  },
  {
    code: Language["ru-RU"],
    title: "Русский",
  },
  {
    code: Language["uk-UA"],
    title: "Українська",
  },
  {
    code: Language["fr-FR"],
    title: "Français",
  },
];

const LanguagePicker = ({
  isLandingPage,
  isLandingPageBlack,
  defaultLanguage,
}: {
  isLandingPage?: boolean;
  isLandingPageBlack?: boolean;
  defaultLanguage?: Language;
}) => {
  const dispatch = useAppDispatch();
  const lang = useSelector((state: RootState) => state.user.settings.lang);
  const jwt = useSelector((state: RootState) => state.user.jwt);
  const { track } = useInitTrackEvents();
  const { t } = useTranslation();
  const [localLang, setLocalLang] = useState<Language | null>(null);

  useEffect(() => {
    if (defaultLanguage) {
      dispatch(setUserLanguage({ language: defaultLanguage, updateSettings: false }));
    }
  }, [defaultLanguage]);

  useEffect(() => {
    if (localLang && jwt && isLandingPage) {
      dispatch(setUserLanguage({ language: localLang || defaultLanguage }));
    }
  }, [jwt]);

  const contextMenuLanguages = isLandingPage ? availableLanguages.slice(0, 2) : [];
  const dropdownMenuLanguages = isLandingPage ? availableLanguages.slice(2) : availableLanguages;

  const onChangeLanguage: (language: Language) => void = language => {
    setLocalLang(language);
    dispatch(setUserLanguage({ language }));

    localStorage.setItem(LocalStorageKeys.LANG, language);

    track({ eventName: HBEventName.SaveEntityChanges, data: { lang: language } });
  };

  return (
    <Menu
      selectedKeys={[lang]}
      className={`${isLandingPage && "landing"} ${
        isLandingPage ? (isLandingPageBlack ? "landing-black" : "landing-white") : "regular-black"
      } language-menu`}
    >
      {isLandingPage ? (
        <>
          {contextMenuLanguages.map((lang, i) => {
            return (
              <MenuItem className="language-menu-item" key={lang.code} onClick={() => onChangeLanguage(lang.code)}>
                {lang.title}
              </MenuItem>
            );
          })}
          <Menu.SubMenu title={t("MoreLanguages")}>
            {dropdownMenuLanguages.map((lang, i) => {
              return (
                <MenuItem className="language-menu-item" key={lang.code} onClick={() => onChangeLanguage(lang.code)}>
                  {lang.title}
                </MenuItem>
              );
            })}
          </Menu.SubMenu>
        </>
      ) : (
        <>
          {availableLanguages.map((lang, i) => {
            return (
              <MenuItem className="language-menu-item" key={lang.code} onClick={() => onChangeLanguage(lang.code)}>
                {lang.title}
              </MenuItem>
            );
          })}
        </>
      )}
    </Menu>
  );
};

export default LanguagePicker;
