import {
  AlignCenterOutlined,
  BorderInnerOutlined,
  OrderedListOutlined,
  PauseOutlined,
  PercentageOutlined,
  PieChartFilled,
  SmallDashOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import { useContext, useState } from "react";
import { flipChartAxis, updateShowAllLabels } from "../../../../../store/slices/dashboard";
import { useAppDispatch } from "../../../../../store/store";
import { ChartContext } from "../../HBChart";
import { commafiyTooltip, commaify, precentageTooltip, precetageLabels } from "../../Helpers";
import ChartMenu from "./ChartMenu";
import { ChartMenuItemProperties } from "./ChartMenuItem";
import OrderDimensions from "./OrderDimensions/OrderDimensions";
import SliceByDimensions from "./SliceByDimensions/SliceByDimensions";

import "../../../Dashboard.less";

export default function ChartVisualMenu() {
  const { chart, graph } = useContext(ChartContext);
  const dispatch = useAppDispatch();
  const [showPrecentageState, setShowPrecentageState] = useState<boolean>(chart?.showPercent || false);
  const [showAxis, setShowAxis] = useState<boolean>(false);
  const [flipChart, setFlipChart] = useState<boolean>(false);
  const [showBounds, setShowBounds] = useState<boolean>(true);

  const options: ChartMenuItemProperties[] = [
    {
      key: "showPrecentage",
      label: "Precentage",
      icon: <PercentageOutlined />,
      toggle: true,
      disabled: chart && ["pie", "line"].includes(chart.chartType) === false,
      action: () => {
        if (!showPrecentageState) {
          graph!.current!.options.plugins!.datalabels!.formatter = precetageLabels;
          graph!.current!.options.plugins!.tooltip!.callbacks!.label = precentageTooltip;
        } else {
          graph!.current!.options.plugins!.datalabels!.formatter = commaify;
          graph!.current!.options.plugins!.tooltip!.callbacks!.label = commafiyTooltip;
        }
        graph!.current?.update();
        setShowPrecentageState(!showPrecentageState);
      },
    },
    {
      key: "showAxis",
      label: "Axis",
      icon: <BorderInnerOutlined />,
      toggle: true,
      disabled: chart && ["pie", "line", "bar"].includes(chart.chartType) === false,
      action: () => {
        if (graph?.current?.options.scales?.x?.grid) {
          graph!.current!.options.scales!.x!.grid!.display = showAxis;
        }
        if (graph?.current?.options.scales?.y?.grid) {
          graph!.current!.options.scales!.y!.grid!.display = showAxis;
        }
        graph!.current?.update();
        setShowAxis(!showAxis);
      },
    },
    {
      key: "flipChart",
      label: "Flip",
      icon: <SwapOutlined />,
      toggle: true,
      disabled: chart && ["bar"].includes(chart.chartType) === false,
      action: () => {
        if (chart) {
          dispatch(flipChartAxis(chart.id));
          setFlipChart(!flipChart);
        }
      },
    },
    {
      key: "showBounds",
      label: "Bounds",
      icon: <PauseOutlined />,
      toggle: true,
      initialClickedState: true,
      disabled: chart && ["line", "bar"].includes(chart.chartType) === false,
      action: () => {
        const annotations: any = graph?.current?.options?.plugins?.annotation?.annotations;
        if (annotations) {
          for (let annotation in annotations) {
            annotations[annotation].display = !showBounds;
          }
        }
        graph?.current?.update();
        setShowBounds(!showBounds);
      },
    },
    {
      key: "showAllLabels",
      label: "ShowAllLabelsInGraph",
      icon: <SmallDashOutlined />,
      toggle: true,
      disabled: chart && ["line", "bar", "pie"].includes(chart.chartType) === false,
      action: () => {
        const datalabels: any = graph?.current?.options?.plugins?.datalabels;
        if (datalabels) {
          datalabels.display = chart?.showAllLabels
            ? function (context: any) {
                return context.dataset.data[context.dataIndex] !== 0;
              }
            : function (context: any) {
                return context.dataset.data[context.dataIndex] !== 0 ? "auto" : false;
              };
        }
        graph?.current?.update();
        dispatch(updateShowAllLabels({ id: chart!.id, showAllLabels: !chart?.showAllLabels }));
      },
    },
    {
      key: "order",
      label: "OrderBy",
      icon: <OrderedListOutlined />,
      disabled: chart && ["line", "bar", "pie", "table"].includes(chart.chartType) === false,
      action: undefined,
      customComponent: <OrderDimensions />,
    },
    {
      key: "slice",
      label: "Slicers",
      icon: <AlignCenterOutlined />,
      disabled: chart && ["line", "bar", "pie", "table"].includes(chart.chartType) === false,
      action: undefined,
      customComponent: <SliceByDimensions />,
    },
  ];

  return <ChartMenu options={options} icon={<PieChartFilled className="dashboard-menu-icon" />} />;
}
