import { Steps, Upload, Row, Col, Select, Button, Table, Spin, Empty } from "antd";
import { TFunction } from "i18next";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ConfirmationModal } from "../../components/ConfirmationModal/ConfirmationModal";
import HBBreadcrumbs from "../../components/HBComponents/Breadcrumbs/HBBreadcrumbs";
import PageConfigurationContext from "../../context/pageContext";
import { hbApi } from "../../store/api";
import { RootState } from "../../store/store";
import { BaseEntityType } from "../../types/entityBase";
import { ColumnMap, EntityPropertyInfo } from "../../types/import";
import { CategoryPage } from "../../types/page";
import useRouter from "../../utils/hooks/useRouter";
import Layout from "../Layout/Layout";
import "./Import.less";
import NotAllowedPage from "../NotAllowedPage/NotAllowedPage";
import { isAdmin } from "../pageConfig/category/utilities";

const { Step } = Steps;
const { Option } = Select;

const emptyHeader = "DontImport";

interface ImportState {
  currentStep: number;
  isLoading: boolean;
  file?: File | null;
  headers?: string[];
  data?: [];
  entity: EntityPropertyInfo[];
  error?: { title: string; message: string };
  customPropsFetched: boolean;
  autoMapped: boolean;
}

const findMatchingColumn = (headers: string[] | undefined, prop: EntityPropertyInfo): string | undefined => {
  if (prop.column) return prop.column;
  if (!headers) return undefined;
  return headers.find(
    h => h.toLowerCase() === prop.property.toLowerCase() || h.toLowerCase() === prop.title.toLowerCase()
  );
};

const executeInsert = (
  endpoint: string,
  jwt: string,
  state: ImportState,
  setState: React.Dispatch<React.SetStateAction<ImportState>>,
  t: TFunction
): void => {
  if (!state.file) return;
  setState({ ...state, isLoading: true });
  const data = new FormData();
  data.append("file", state.file);
  data.append("mapping", generateMappingTemplate(state.entity, true));
  hbApi
    .post(`${endpoint}/import`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${jwt}`,
      },
    })
    .then(() => {
      setState({ ...state, isLoading: false, currentStep: 4 });
    })
    .catch(e => {
      setState({
        ...state,
        error: {
          title: t("OperationFailed"),
          message: e.message || t("BulkLoadOperationFailed"),
        },
      });
    });
};

const generateMappingTemplate = function (properties: EntityPropertyInfo[], forImport: boolean) {
  return JSON.stringify(
    properties
      .filter(p => p.column)
      .map(p => {
        const obj = {
          type: "Header",
          property: forImport ? p.property : p.customProperty ? `__cprop_${p.property}__` : p.property,
          value: p.column,
        } as ColumnMap;

        if (forImport && p.customProperty) {
          obj.properties = { Dictionary: "CustomPropertyValues" };
        }

        return obj;
      })
  );
};

const getPreview = (
  state: ImportState,
  setState: React.Dispatch<React.SetStateAction<ImportState>>,
  jwt: string,
  t: TFunction
): void => {
  if (state.file == null) return;
  setState({ ...state, currentStep: 2, isLoading: true });
  const data = new FormData();
  data.append("file", state.file);
  data.append("mapping", generateMappingTemplate(state.entity, false));
  hbApi
    .post("/Import/preview?limit=5", data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${jwt}`,
      },
    })
    .then(res => {
      setState({ ...state, data: res.data, currentStep: 3, isLoading: false });
    })
    .catch(e => {
      setState({
        ...state,
        error: {
          title: t("OperationFailed"),
          message: e?.message || t("GeneratingPreviewDataFailed"),
        },
      });
    });
};

const getHeaders = (
  e: any,
  state: ImportState,
  setState: React.Dispatch<React.SetStateAction<ImportState>>,
  jwt: string,
  t: TFunction
): void => {
  const file = e.file as File;
  const data = new FormData();
  data.append("file", file);
  setState({ ...state, file, isLoading: true });
  hbApi
    .post("/Import/headers", data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${jwt}`,
      },
    })
    .then(res => {
      res.data.splice(0, 0, emptyHeader);
      for (const p of state.entity) {
        p.column = undefined;
      }
      setState({
        ...state,
        file,
        data: [],
        headers: res.data,
        currentStep: 1,
        isLoading: false,
      });
    })
    .catch(e => {
      setState({
        ...state,
        error: {
          title: t("OperationFailed"),
          message: e?.message || t("ExtractingHeadersFailed"),
        },
      });
    });
};

const ImportPage = (): JSX.Element => {
  const pageConfig = useContext(PageConfigurationContext) as CategoryPage<BaseEntityType>;
  const [state, setState] = useState({
    currentStep: 0,
    isLoading: true,
    file: null,
    headers: [],
    entity: pageConfig?.entityProperties ?? [],
    customPropsFetched: false,
    autoMapped: false,
  } as ImportState);
  const jwt = useSelector((state: RootState) => state.user.jwt);
  const dispatch = useDispatch();
  const { history } = useRouter();
  const { t } = useTranslation();
  const customProperties = useSelector(pageConfig.customPropertiesSelector);

  useEffect(() => {
    if (state.customPropsFetched) return;
    if (pageConfig.fetchCustomProps && !customProperties) {
      dispatch(pageConfig.fetchCustomProps());
    } else if (customProperties && customProperties.length > 0 && state.isLoading) {
      const props = pageConfig.entityProperties ?? [];
      for (const p of customProperties) {
        props.push({
          title: p.name,
          property: p.id.toString(),
          customProperty: true,
        });
      }
      setState({
        ...state,
        entity: props,
        isLoading: false,
        customPropsFetched: true,
      });
    } else {
      setState({ ...state, isLoading: false, customPropsFetched: true });
    }
  }, []);

  if (!isAdmin()) {
    return <NotAllowedPage />;
  }

  const headerOptions = state.headers
    ? state.headers.map((header, index) => (
        <Option value={header} key={index}>
          {header}
        </Option>
      ))
    : [];

  const mapColumns = state.entity.map((prop, index) => {
    if (!prop.column && !state.autoMapped) {
      prop.column = findMatchingColumn(state.headers, prop);
    }
    return (
      <Row key={index} style={{ marginTop: 5 }}>
        <Col span={2}> {t(prop.title)} </Col>
        <Col span={6}>
          <Select
            size="small"
            allowClear={true}
            style={{ width: 120 }}
            placeholder={t(emptyHeader)}
            disabled={state.currentStep != 1}
            value={prop.column}
            className={prop.required && !prop.column ? "required" : prop.creation && !prop.column ? "creation" : ""}
            onClear={() => {
              const entity = state.entity;
              entity[index].column = undefined;
              setState({ ...state });
            }}
            onSelect={(value: { toString: () => string | undefined }) => {
              const entity = state.entity;
              if (value.toString() === emptyHeader) {
                entity[index].column = undefined;
              } else {
                entity[index].column = value.toString();
              }
              setState({ ...state });
            }}
          >
            {headerOptions}
          </Select>
          {prop.required && !prop.column && <span style={{ color: "#d9042b" }}>&nbsp; * {t("RequiredField")}</span>}
          {!prop.required && prop.creation && !prop.column && (
            <span style={{ color: "#666" }}>&nbsp; * {t("RequiredForCreation")}</span>
          )}
        </Col>
      </Row>
    );
  });

  const downloadTemplate = URL.createObjectURL(
    new Blob([generateMappingTemplate(state.entity, true)], {
      type: "application/json",
    })
  );

  if (state.currentStep == 1 && !state.autoMapped) {
    setState({ ...state, autoMapped: true });
  }

  const isMappingValid = state.entity.filter(e => e.required === true && !e.column).length !== 0;

  const previewColumns = pageConfig.entityProperties
    ? state.entity
        .filter(prop => prop.column)
        .map((prop, index) => {
          return {
            index,
            dataIndex: prop.customProperty ? `__cprop_${prop.property}__` : prop.property,
            title: t(prop.title),
          };
        })
    : [];

  return (
    <Spin spinning={state.isLoading}>
      <Layout contentStyles={{ padding: 0 }} entityKey={pageConfig.id} hasTableSearchBar={false}>
        <ConfirmationModal
          visible={state.currentStep === 4}
          confirmOkClick={() => history.push(`/${pageConfig.id}`)}
          confirmationType="notification"
          messages={[t("Processing"), t("ResultEmail")]}
        />
        <ConfirmationModal
          visible={!!state.error}
          confirmOkClick={() => setState({ ...state, error: undefined })}
          confirmationType="notification"
          messages={[
            state.error?.title ?? t("SomethingWentWrong"),
            state.error?.message ?? t("ContactAdministrator"),
            "error",
          ]}
        />
        <Row>
          <HBBreadcrumbs customPage={{ id: "import", label: t("ImportButton") }} />
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Steps current={state.currentStep} size="small" direction={"vertical"}>
            <Step
              title={t("ChooseAFile")}
              description={
                <>
                  <Upload
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    listType="text"
                    maxCount={1}
                    showUploadList={false}
                    customRequest={e => getHeaders(e, state, setState, jwt, t)}
                  >
                    {state.currentStep !== 0 && (
                      <span>
                        {t("SelectedFile")}: {state.file?.name}
                        <a>
                          {" "}
                          ({state.currentStep < 4 && t("ChangeFile")}
                          {state.currentStep >= 4 && t("Restart")})
                        </a>
                      </span>
                    )}
                    {state.currentStep === 0 && <Button>{t("UploadButton")}</Button>}
                  </Upload>
                </>
              }
            />
            <Step
              title={t("SetColumns")}
              description={
                <>
                  {state.currentStep >= 1 && (
                    <>
                      {mapColumns}
                      <Row>
                        <Col span={2} />
                        <Col span={6}>
                          <a className="download-template" download="template.json" href={downloadTemplate}>
                            {t("DownloadMappingTemplate")}
                          </a>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={2} />
                        <Col span={6}>
                          {state.currentStep === 1 && (
                            <Button
                              disabled={isMappingValid}
                              style={{ marginTop: 5 }}
                              onClick={() => getPreview(state, setState, jwt, t)}
                            >
                              {t("EmployeeImportPreview")}
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </>
                  )}
                  {state.currentStep < 1 && <span>{t("ImportAssignProperties")}</span>}
                </>
              }
            />
            <Step
              title={t("FieldReview")}
              description={
                <>
                  {state.currentStep >= 2 && (
                    <>
                      <Table
                        locale={{
                          emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("NoData")} />,
                          triggerDesc: t("SortDesc"),
                          triggerAsc: t("SortAsc"),
                          cancelSort: t("SortCancel"),
                        }}
                        dataSource={state.data}
                        columns={previewColumns}
                        showHeader={true}
                        pagination={false}
                        rowKey={"id"}
                      />
                      {state.currentStep < 4 && (
                        <Button
                          style={{ marginTop: 5 }}
                          size={"small"}
                          onClick={() => setState({ ...state, currentStep: 1, data: [] })}
                        >
                          {t("ChangeColumns")}
                        </Button>
                      )}
                    </>
                  )}
                  {state.currentStep < 2 && <span>{t("PreviewSeveralRecord")}</span>}
                </>
              }
            />
            <Step
              title={t("ConfirmTitle")}
              description={
                <>
                  {state.currentStep === 3 && (
                    <Button onClick={() => executeInsert(pageConfig.entityEndpoint, jwt, state, setState, t)}>
                      {t("ImportButton")}
                    </Button>
                  )}
                  {state.currentStep < 3 && <span>{t("ProceedToFileUpload")}</span>}
                  {state.currentStep > 3 && <span>{t("ExecutingLoadInBackground")}</span>}
                </>
              }
            />
          </Steps>
        </Row>
      </Layout>
    </Spin>
  );
};

export default ImportPage;
