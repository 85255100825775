import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu } from "antd";
import { ColumnAction } from "../../../types/page";

type TProps = {
  buttonOptions?: {
    primaryButton: ColumnAction<Record<string, unknown>>;
    dropdownButtons: ColumnAction<Record<string, unknown>>[];
  };
  row: Record<string, unknown>;
  onClick: (columnAction: ColumnAction<Record<string, unknown>>, entity: Record<string, unknown>) => void;
};

export const HBTableCellDropdownButton = ({ buttonOptions, row, onClick }: TProps): JSX.Element => {
  const menu = (
    <Menu>
      {buttonOptions?.dropdownButtons.map(button => (
        <Menu.Item key={button.label} onClick={() => onClick(button, row)}>
          {button.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  return !!(buttonOptions && buttonOptions.dropdownButtons && buttonOptions.dropdownButtons.length) ? (
    <Dropdown.Button
      overlay={menu!}
      trigger={["click"]}
      className="buttonGroup"
      icon={<DownOutlined />}
      onClick={event => {
        event.preventDefault();
        event.stopPropagation();

        onClick(buttonOptions.primaryButton, row);
      }}
    >
      {buttonOptions?.primaryButton.label}
    </Dropdown.Button>
  ) : (
    <Button
      className="buttonGroup"
      onClick={event => {
        event.preventDefault();
        event.stopPropagation();

        buttonOptions?.primaryButton && onClick(buttonOptions.primaryButton, row);
      }}
    >
      {buttonOptions?.primaryButton.label}
    </Button>
  );
};
