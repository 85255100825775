import { Typography, Col, Tooltip, Row, Space } from "antd";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../store/store";
import { ColumnAction, ColumnType, ColumnWithDifferentCells } from "../../types/page";
import useRouter from "../../utils/hooks/useRouter";
import EditableInput from "../EditableInput/EditableInput";
import { EditableCellSearch } from "../FuseSearch/EditableCellSearch";
import { HBTableCellButtons } from "../HBComponents/Buttons/HBTableCellButtons";
import { HBTableCellDropdownButton } from "../HBComponents/Buttons/HBTableCellDropdownButton";
import HBDropdown from "../HBComponents/Dropdown/HBDropdown";
import "./tableCellRenderer.less";

type TProps = {
  c: ColumnWithDifferentCells<Record<string, unknown>>;
  entity: Record<string, unknown>;
  columnButtonsOnClick?: (action: ColumnAction<Record<string, unknown>>, e: Record<string, unknown>) => void;
  columnDropdownOnChange?: (
    newValue: string | number | boolean | null,
    entity: Record<string, unknown>,
    c: ColumnWithDifferentCells<Record<string, unknown>>
  ) => void;
};

export const CellRenderer = ({ c, entity, columnButtonsOnClick, columnDropdownOnChange }: TProps): JSX.Element => {
  const handleDropdownClick = (e: string | number | boolean | null) => {
    c.cellDropdownOnChange && columnDropdownOnChange && columnDropdownOnChange(e, entity, c);
  };
  const { history } = useRouter();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const textValue = c.renderCustomValue ? c.renderCustomValue(entity) : c.renderValue(entity);
  const defaultTextRender = (
    <Col
      className={clsx("col-text-wrapper", { clickable: c.onCellOnClick })}
      onClick={() => (c.onCellOnClick ? c.onCellOnClick(entity) : null)}
    >
      {c.valueIcon && c.valueIcon(entity)}
      {textValue && (
        <Typography.Text className="w-100-col" ellipsis title={c.renderValue(entity) || ""}>
          {textValue}
        </Typography.Text>
      )}
    </Col>
  );
  const renderCell = () => {
    const cellType = c.cellType && c.cellType(entity);
    switch (cellType) {
      case "buttonActions":
        return entity.staging ? (
          <HBTableCellButtons actions={c.stagingActions!} row={entity} onClick={columnButtonsOnClick!} />
        ) : c.buttonWithDropdownActions ? (
          <HBTableCellDropdownButton
            buttonOptions={c.buttonWithDropdownActions}
            row={entity}
            onClick={columnButtonsOnClick!}
          />
        ) : null;
      case "actions":
        return (
          <div style={{ display: "flex", flexFlow: "row", alignItems: "baseline", justifyContent: "flex-start" }}>
            {c.actions?.map((action, index) => (
              <div
                key={index.toString()}
                onClick={() => {
                  if (!action.component) {
                    action.action!(entity);
                    if (action.href) {
                      history.push(action.href(entity));
                    }
                  }
                }}
                className="pointer column__action-icon"
              >
                {action.component && action.component(entity)}
                {action.conditionalLabel ? action.conditionalLabel(entity) : action.component ? null : action.label}
              </div>
            ))}
          </div>
        );
      case "dropdown":
        // TODO: Modify the onChange event to fire an async thunk, changing the status of the entity
        return (
          <HBDropdown
            onChange={handleDropdownClick}
            options={c.cellDropdownOptions ? c.cellDropdownOptions(entity) : []}
            value={c.renderValue(entity)}
            managedValue={c.renderValue(entity)}
            type={cellType}
            withHoverEffect={true}
          />
        );
      case "input":
        return (
          <EditableInput
            fieldValue={c.renderValue(entity)}
            onChange={value =>
              c.onChange
                ? dispatch(c.onChange({ value, id: entity.id as number }))
                : () => console.error("onChange is missing")
            }
            type="input"
            globalEdit={c.isCellContentVisible ? c.isCellContentVisible(entity) : true}
            isEditMode={c.isCellContentVisible ? c.isCellContentVisible(entity) : undefined}
            placeholder={t("Enter")}
            id={c.id}
            optionSelector={() => null}
            onEditableModeChanged={value =>
              c.onEditableModeChanged
                ? dispatch(c.onEditableModeChanged({ value, id: entity.id as number }))
                : console.error("onEditableModeChanged is missing")
            }
          />
        );
      case "search":
        return (
          <EditableCellSearch
            dataSelector={c.cellSearchSelector!}
            resultColumns={c.cellSearchResultColumns! as Partial<ColumnType<Record<string, unknown>>>[]}
            searchKeys={c.cellSearchKeys!}
            resultClickAction={c.cellSearchRecordClick!}
            defaultValue={entity[c.id] as string}
            currentRow={entity}
          />
        );
      case "text":
        return defaultTextRender;
      case "icon":
        return (
          <Col className="icon-column" onClick={() => (c.onCellOnClick ? c.onCellOnClick(entity) : null)}>
            {c.valueIcon && c.valueIcon(entity)}
          </Col>
        );
      default:
        return defaultTextRender;
    }
  };

  return <Row className="cell">{renderCell()}</Row>;
};
