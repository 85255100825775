import { unwrapResult } from "@reduxjs/toolkit";
import { Button, Col, Dropdown, Form, Modal, Row, Space } from "antd";
import { Location } from "history";
import { nanoid } from "nanoid";
import { ReactNode, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Prompt } from "react-router-dom";
import PageConfigurationContext from "../../context/pageContext";
import { getEmptyValue } from "../../pages/pageConfig/category/utilities";
import { userSelectors } from "../../selectors";
import { RootState, useAppDispatch } from "../../store/store";
import { HBEventName } from "../../types/analyticsTypes/HBEvent";
import { CustomPropertyType } from "../../types/customProperty";
import { BaseEntityType } from "../../types/entityBase";
import { BaseField, CategoryPage, ChangesConfirmation } from "../../types/page";
import { AdditionalProps, NoValueKey } from "../../types/utility";
import { isRoleAdminLevel } from "../../utils/functions";
import useInitTrackEvents from "../../utils/hooks/useInitTrackEvents";
import usePrevious from "../../utils/hooks/usePrevious";
import useRouter from "../../utils/hooks/useRouter";
import { useUIErrorHandling } from "../../utils/hooks/useUIErrorHandling";
import useWindowDimensions from "../../utils/hooks/useWindowDimensions";
import { ConfirmationModal } from "../ConfirmationModal/ConfirmationModal";
import RRuleComponent from "../Dashboard/ScheduledReport/SheduleComponent";
import EditableInput from "../EditableInput/EditableInput";
import HBBreadcrumbs from "../HBComponents/Breadcrumbs/HBBreadcrumbs";
import "./singleViewCard.less";
import { EditingButtons } from "../HBComponents/CreateTaskSwtich/EditingButtons";
import HbFormItem from "../HBComponents/HbFormItem/HbFormItem";
import { ModalInfo } from "./types";

type TProps = {
  isNewEntity?: boolean;
  data: Record<string, unknown> | null | undefined;
  handleSetActiveTab: (tabKey: string) => void;
  handleSetIsEdited: (isEdited: boolean) => void;
  isEdited: boolean;
};

const ICON_TYPE_KEY = "iconType";

const SingleViewCard = ({
  isNewEntity,
  data,
  handleSetActiveTab,
  isEdited,
  handleSetIsEdited,
}: TProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const primaryColor = useSelector((state: RootState) => state.common.primaryColor);
  const { history, location } = useRouter<{ prefillData: Record<string, unknown> }>();
  const [currentData, setCurrentData] = useState<Record<string, unknown> | null | undefined>(null);
  const { summaryCard, createNewEntity, updateEntity, id } = useContext(
    PageConfigurationContext
  ) as CategoryPage<BaseEntityType>;
  const [isCardEdited, setIsCardEdited] = useState(false);
  const { width } = useWindowDimensions();
  const [navigationModalVisibility, setNavigationModalVisiblity] = useState(false);
  const { errorMessages, setErrors, handleClearErrors } = useUIErrorHandling();
  const [isGlobalEditForbidden, setIsGlobalEditForbidden] = useState(false);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [nextLocation, setNextLocation] = useState<Location | null>(null);
  // const [cardWidth, setCardWidth] = useState(50);
  const [fieldSpan, setFieldSpan] = useState(6);
  const [modalInfo, setModalInfo] = useState<ModalInfo | null>(null);
  const [manuallyChangedFields, addManuallyChangedFields] = useState<string[]>([]);
  const [form] = Form.useForm();
  const { companyDictionaries, data: customProperties } = useSelector((state: RootState) => state.customProperty);
  const user = useSelector(userSelectors.getCurrentUser);
  const { track } = useInitTrackEvents();
  const [formModalVisible, setFormModalVisible] = useState(false);
  const [summaryCardIcon, setSummaryCardIcon] = useState<{
    name: string;
    alt: string;
    init?: boolean;
  } | null>({ name: "10.png", alt: "10", init: true });
  const [isIconDropdownVisible, setIsIconDropdownVisible] = useState(false);
  const prevSummaryCardIcon = usePrevious(summaryCardIcon);
  const canEditSelectedData = useSelector(summaryCard?.canEditSelector || (() => []));
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (
      prevSummaryCardIcon &&
      summaryCardIcon &&
      !summaryCardIcon.init &&
      (prevSummaryCardIcon.name !== summaryCardIcon.name || prevSummaryCardIcon.alt !== summaryCardIcon.alt)
    ) {
      setIsCardEdited(true);
    }
  }, [summaryCardIcon, prevSummaryCardIcon]);

  useEffect(() => {
    setIsCardEdited(false);
  }, [location.pathname]);

  useEffect(() => {
    if (currentData?.iconType)
      setSummaryCardIcon({ name: `${currentData?.iconType}.png`, alt: `${currentData?.iconType}`, init: true });
  }, [currentData]);

  const [svgContent, setSvgContent] = useState<string>("");

  useEffect(() => {
    const fetchSvg = async () => {
      const response = await fetch(`IconReviewTypeSvg/${summaryCardIcon?.name.replace(".png", ".svg") || 10}`);
      const svgText = await response.text();
      const parser = new DOMParser();
      const doc = parser.parseFromString(svgText, "image/svg+xml");
      const svg = doc.querySelector("svg");

      if (svg) {
        const serializer = new XMLSerializer();
        const newSvgContent = serializer.serializeToString(svg);
        setSvgContent(newSvgContent);
      }
    };

    fetchSvg();
  }, [summaryCardIcon, primaryColor]);

  // Avoid updating the state of the higher component while rendering the card.
  useEffect(() => {
    handleSetIsEdited(isCardEdited);
  }, [isCardEdited]);

  // Note: Get a warning if navigating from another screen based on condition. (e.g. Selecting employees and hitting 'Create Training')
  useEffect(() => {
    const navigationWarning = summaryCard.navigationWarning;
    if (
      location?.state?.prefillData &&
      navigationWarning &&
      (!navigationWarning.showConditionally ||
        (navigationWarning.showConditionally && navigationWarning.showConditionally("test")))
    ) {
      return setModalInfo({
        body: navigationWarning.addValueToBody ? `${t(navigationWarning.body)}` : t(navigationWarning.body),
        okText: navigationWarning.addValueToOkText ? `${t(navigationWarning.okText)}` : t(navigationWarning.okText),
        cancelText: t(navigationWarning.cancelText),
        onConfirm: () => {
          setModalInfo(null);
          navigationWarning.navigateOnOk && history.push(navigationWarning.navigateOnOk);
        },
        onCancel: () => {
          setModalInfo(null);
          navigationWarning.navigateOnCancel && history.push(navigationWarning.navigateOnCancel);
        },
      });
    }
  }, [summaryCard.navigationWarning]);

  useEffect(() => {
    // If we are in edit mode we want the local changes to remain while updating the fields that have been edited from outside (e.g. from the tab section)
    // if (data && currentData && isEdited) {
    //   return setCurrentData(() => {
    //     const externallyModifiedData: Record<string, unknown> = {};
    //     const keys = Object.keys(currentData);
    //     for (let i = 0; i < keys.length; i++) {
    //       if (summaryCard.remotelyUpdatedFieldIDs?.includes(keys[i])) {
    //         externallyModifiedData[keys[i]] = data[keys[i]];
    //       }
    //     }
    //     return { ...currentData, ...externallyModifiedData };
    //   });
    // }
    // form.setFieldsValue(currentData);

    // Note: Handles prefilling data coming from the location state
    setCurrentData(data);
    const prefillData = location?.state?.prefillData;
    if (prefillData) {
      for (const property in prefillData) {
        setCurrentData(prevData => ({ ...prevData, [property]: prefillData[property] }));
      }
    }
  }, [data]);

  useEffect(() => {
    setIsGlobalEditForbidden(false);
    if (summaryCard.globalEditForbidden && data && !isNewEntity) {
      setIsGlobalEditForbidden(summaryCard.globalEditForbidden(data));
    }
  }, [summaryCard.globalEditForbidden, data]);

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    }
  }, [data]);

  // Handle dynamic card width
  // useEffect(() => {
  //   if (summaryCard && data) {
  //     const cardWidth =
  //       Math.max(
  //         summaryCard.primaryFields.length,
  //         summaryCard.secondaryFields.length + (data["customPropertyValues"] as AdditionalProps[])?.length
  //       ) * 20;
  //     if (width <= 900) {
  //       return setCardWidth(100);
  //     }
  //     setCardWidth(cardWidth);
  //   }
  // }, [summaryCard, data]);

  // Handle dynamic field col span
  useEffect(() => {
    if (data) {
      const longestFieldLength = Math.max(
        summaryCard.primaryFields.length,
        summaryCard.secondaryFields.length + (data["customPropertyValues"] as AdditionalProps[])?.length
      );
      if (width < 1000 && longestFieldLength < 3) {
        return setFieldSpan(12);
      }
      if (width < 1200 && longestFieldLength < 3) {
        return setFieldSpan(8);
      }
    }
  }, [width, data]);

  // Note: Modal confirmation click
  const handleSetConfirmedNavigation = () => {
    setConfirmedNavigation(true);
    setNavigationModalVisiblity(false);
    if (nextLocation) {
      history.push(nextLocation);
    }
  };

  const handleBlockedNavigation = (nextLocation: Location) => {
    if (!confirmedNavigation && !navigationModalVisibility) {
      setNavigationModalVisiblity(true);
      setNextLocation(nextLocation);
      return false;
    }

    return true;
  };

  const handleModalClose = () => {
    setNavigationModalVisiblity(false);
  };

  const onSubmitChanges = async ({ additionTrigger }: { additionTrigger?: boolean }) => {
    if (!currentData) {
      return;
    }

    if (isNewEntity && createNewEntity) {
      return dispatch(
        createNewEntity({
          entity: { ...currentData, iconType: summaryCardIcon?.alt || null },
          additionTrigger: additionTrigger,
        })
      )
        .then(unwrapResult)
        .then(originalPromiseResult => {
          track({ eventName: HBEventName.CreateNew, data: { entityId: originalPromiseResult["id"], type: id } });
          setIsCardEdited(false);
          history.push(`../${id}/${originalPromiseResult.id}`);
        })
        .catch(customError => {
          setErrors(customError);
        });
    }

    if (updateEntity) {
      const updateAction = () =>
        dispatch(updateEntity({ ...currentData, iconType: summaryCardIcon?.alt || null }))
          .then(unwrapResult)
          .then(originalPromiseResult => {
            track({ eventName: HBEventName.SaveEntityChanges, data: { entity: currentData } });
            setIsCardEdited(false);
          })
          .catch(customError => {
            setErrors(customError);
          });

      if (summaryCard.confirmationMessage && summaryCard.confirmationMessage(currentData)) {
        Modal.confirm({
          content: t(summaryCard.confirmationMessage(currentData) as string),
          onOk: () => updateAction(),
        });
      } else {
        updateAction();
      }
    }
  };

  const handleCancel = () => {
    setCurrentData(data);
    setIsCardEdited(false);
    form.resetFields();
  };

  const renderValue = useCallback(
    (value: (entity: Record<string, unknown>) => string | null | ReactNode) => {
      if (!currentData) {
        return null;
      }
      return value(currentData);
    },
    [currentData]
  );

  const onModalClick = (
    id: string,
    manuallyChanged: boolean,
    newValue: string | null | boolean | number,
    confirmed: boolean
  ) => () => {
    setModalInfo(null);

    if (confirmed) {
      handleChange(id, null, manuallyChanged)(newValue);
    }
  };

  const handleChange = (
    id: string,
    changesConfirmation?: ChangesConfirmation | null,
    manuallyChanged = true,
    cleanOnChange?: string[]
  ) => (newValue: string | null | boolean | number) => {
    if (currentData && currentData[id] === newValue) return;
    if (!manuallyChangedFields.includes(id) && manuallyChanged) addManuallyChangedFields(oldArray => [...oldArray, id]);

    if (
      changesConfirmation &&
      (!changesConfirmation.showConditionally ||
        (changesConfirmation.showConditionally && changesConfirmation.showConditionally(newValue)))
    ) {
      return setModalInfo({
        body: changesConfirmation.addValueToBody
          ? `${t(changesConfirmation.body)} ${newValue}`
          : t(changesConfirmation.body),
        okText: changesConfirmation.addValueToOkText
          ? `${t(changesConfirmation.okText)} ${newValue}`
          : t(changesConfirmation.okText),
        cancelText: t(changesConfirmation.cancelText),
        onConfirm: onModalClick(id, manuallyChanged, newValue, true),
        onCancel: onModalClick(id, manuallyChanged, newValue, false),
      });
    }

    return setCurrentData(current => {
      const customProperties = current ? (current["customPropertyValues"] as AdditionalProps[]) : null;

      if (!current) {
        return current;
      }

      const keys = Object.keys(current);
      const additionalPropsKeys = customProperties?.map(property => property.name) || null;

      if (!keys.includes(id) && !additionalPropsKeys?.includes(id)) {
        return current;
      }

      if (current[id] === newValue || Number(customProperties?.find(prop => prop.name === id)?.value) === newValue) {
        return current;
      }

      // Note: Case where a custom prop was changed
      if (additionalPropsKeys?.includes(id)) {
        setIsCardEdited(true);
        track({ eventName: HBEventName.EnterEditMode, data: { entity: currentData } });
        return {
          ...current,
          customPropertyValues: customProperties?.map(prop => {
            if (prop.name === id) {
              return {
                ...prop,
                value:
                  prop.type === "Dictionary" ? (newValue === NoValueKey ? "" : newValue ? newValue : "") : newValue,
              };
            }
            return prop;
          }),
        };
      }
      // Note: Case where a static prop was changed
      setIsCardEdited(true);
      track({ eventName: HBEventName.EnterEditMode, data: { entity: currentData } });

      //specifically logic for Dictionary. Todo: improve that process
      if (
        (Object as any).values(CustomPropertyType).includes(current.type) &&
        (id === "type" || id === "dictionaryId")
      ) {
        return { ...current, [id]: newValue, defaultValue: null };
      }

      if (cleanOnChange?.length) {
        let currentToModify = JSON.parse(JSON.stringify(current));

        cleanOnChange.forEach(x => (currentToModify[x] = null));

        return {
          ...currentToModify,
          [id]: newValue,
        };
      }

      return { ...current, [id]: newValue };
    });
  };

  const customEditButtons =
    (summaryCard.customEditButtons &&
      currentData &&
      summaryCard.customEditButtons(currentData, onSubmitChanges, isNewEntity)) ||
    [];
  // Note: We check if the changed form fields have any dependent fields(config file) and if any -> change them too according to the config file.
  const handleDependentFieldsValues = (changedValues: Partial<typeof data>) => {
    const dependentFields =
      summaryCard.dependentFields &&
      currentData &&
      summaryCard.dependentFields({ ...currentData, ...changedValues }, isNewEntity, manuallyChangedFields);
    if (!dependentFields) {
      return;
    }

    for (const property in changedValues) {
      const currentProp = dependentFields.find(f => f.parentId === property);
      if (currentProp) {
        handleChange(currentProp.id, undefined, false)(currentProp.defaultValue);
        form.setFieldsValue({
          [currentProp.id]: currentProp.defaultValue,
        });
      }
    }
  };

  const canEditCard = summaryCard.canEdit
    ? summaryCard.canEdit(user, currentData, canEditSelectedData)
    : isRoleAdminLevel(user.settings.role);

  const formTypeIcons = Array.from({ length: 56 }, (_, index) => ({
    name: `${(index + 1) * 10}.png`,
    alt: `${(index + 1) * 10}`,
  }));

  const renderBooleanDependentField = (field: BaseField<Record<string, unknown>>, dependencyId: string) => {
    if (form.getFieldValue(dependencyId)) {
      return (
        <Form.Item
          key={field.id}
          name={field.id}
          dependencies={[dependencyId]}
          rules={[{ required: field.required, message: t("ErrorFieldRequired") }]}
        >
          <EditableInput
            currentData={currentData}
            selector={field.selector}
            basedOnDependensiesOptions={field.basedOnDependensiesOptions}
            fieldValue={renderValue(field.value) as string | null}
            fieldText={field.valueText && (renderValue(field.valueText) as string | null)}
            globalEdit={isEdited}
            onChange={handleChange(field.id, field.changesConfirmation)}
            placeholder={field.placeholder && t(field.placeholder)}
            multilineInput={field.multilineInput}
            type={field.type}
            id={field.id}
            title={field.title && t(field.title)}
            options={field.options}
            optionSelector={field.optionsSelector}
            handleSetActiveTab={handleSetActiveTab}
            tabSwitch={field.tabSwitchAction?.action}
            icon={field.Icon && currentData && <field.Icon value={currentData} fill={primaryColor} />}
            isNewEntity={isNewEntity}
            searchById={field.searchById}
            canEdit={canEditCard}
            dataFetchAction={field.dataFetchAction}
          />
        </Form.Item>
      );
    }
    return null;
  };

  return (
    <>
      <Prompt when={isCardEdited} message={handleBlockedNavigation}></Prompt>
      <ConfirmationModal
        visible={navigationModalVisibility}
        confirmationType="navigateAway"
        confirmOkClick={handleSetConfirmedNavigation}
        cancelClick={handleModalClose}
      />
      <ConfirmationModal
        visible={errorMessages.length > 0}
        confirmationType="cardValidationErrors"
        confirmOkClick={handleClearErrors}
        messages={errorMessages}
      />
      <ConfirmationModal
        visible={modalInfo !== null}
        message={modalInfo?.body || ""}
        okText={modalInfo?.okText || ""}
        cancelText={modalInfo?.cancelText || ""}
        confirmOkClick={modalInfo?.onConfirm || (() => undefined)}
        cancelClick={modalInfo?.onCancel || (() => undefined)}
      />
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmitChanges}
        requiredMark={false}
        validateTrigger={["onChange"]}
        onValuesChange={handleDependentFieldsValues}
      >
        <Row className="card">
          <Row className="card__breadcrumb">
            <Col>
              <HBBreadcrumbs singleEntity={data} />
            </Col>
          </Row>

          <Row className="card__content" style={{ maxWidth: "100%", minWidth: "80%" }}>
            {summaryCard.cardInformationalMessasge && !isNewEntity && (
              <Row className="card-info-message">{data && t(summaryCard.cardInformationalMessasge(data))}</Row>
            )}

            <Col>
              {(summaryCard.CardIcon || currentData?.hasOwnProperty(ICON_TYPE_KEY)) && (
                <>
                  {isIconDropdownVisible && currentData?.hasOwnProperty(ICON_TYPE_KEY) ? (
                    <Dropdown.Button
                      className="icon-type-menu"
                      placement="bottom"
                      trigger={["click"]}
                      menu={{
                        items: formTypeIcons.map(({ name, alt }) => {
                          return {
                            key: alt,
                            icon: (
                              <img
                                className="form-type-menu-icon"
                                src={`IconReviewType/${name}`}
                                alt={alt}
                                width="25"
                                height="25"
                              />
                            ),
                            onClick: () => {
                              setSummaryCardIcon({ name, alt });
                              setIsIconDropdownVisible(false);
                            },
                          };
                        }),
                      }}
                      icon={
                        <img
                          className="icon-type-menu-icon"
                          src={`IconReviewType/${summaryCardIcon?.name}`}
                          alt={summaryCardIcon?.alt}
                          width="28"
                          height="28"
                        />
                      }
                    />
                  ) : summaryCard.CardIcon ? (
                    <div className="icon-type-menu-icon-non-editable">
                      <summaryCard.CardIcon fill={primaryColor} />
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        if (!isGlobalEditForbidden && canEditCard) {
                          setIsIconDropdownVisible(true);
                        }
                      }}
                      className="icon-type-menu-icon"
                      dangerouslySetInnerHTML={{ __html: svgContent }}
                    />
                  )}
                </>
              )}
            </Col>
            <Col offset={1} className="fields-section">
              {/* Primary Start */}
              <Row className="fields-section__primary" gutter={[32, 0]}>
                {summaryCard.primaryFields.map(input =>
                  input.isHidden && input.isHidden(currentData!) ? null : (
                    <Col key={input.id} className="field-wrapper" span={fieldSpan}>
                      <Row style={{ width: "100%" }}>
                        <HbFormItem label={true} input={input}>
                          <EditableInput
                            onCardEdited={setIsCardEdited}
                            currentData={currentData}
                            selector={input.selector}
                            basedOnDependensiesOptions={input.basedOnDependensiesOptions}
                            localizedValue={
                              input.localizedValue && (renderValue(input.localizedValue) as string | null)
                            }
                            fieldValue={renderValue(input.value) as string | null}
                            fieldText={input.valueText && (renderValue(input.valueText) as string | null)}
                            globalEdit={isEdited}
                            onChange={handleChange(input.id, input.changesConfirmation, true, input.cleanOnChange)}
                            placeholder={input.placeholder && t(input.placeholder)}
                            type={input.type}
                            multilineInput={input.multilineInput}
                            id={input.id}
                            options={input.options}
                            optionSelector={input.optionsSelector}
                            icon={input.Icon && currentData && <input.Icon value={currentData} fill={primaryColor} />}
                            treeData={input.treeData}
                            handleSetActiveTab={handleSetActiveTab}
                            tabSwitch={input.tabSwitchAction?.action}
                            isNewEntity={isNewEntity}
                            editForbidden={
                              (data && input.editForbidden && input.editForbidden(data)) || isGlobalEditForbidden
                            }
                            searchById={input.searchById}
                            canEdit={canEditCard}
                            dataFetchAction={input.dataFetchAction}
                          />
                        </HbFormItem>
                      </Row>
                    </Col>
                  )
                )}
              </Row>
              {/* Secondary Start */}
              <Row className="fields-section__secondary" gutter={[32, 0]}>
                {summaryCard.secondaryFields.map(input =>
                  input.isHidden && input.isHidden(currentData!) ? null : (
                    <Col key={input.id} className="field-wrapper" span={fieldSpan}>
                      <Row style={input.dependentFields ? { width: "auto" } : { width: "100%" }}>
                        <HbFormItem input={input}>
                          <EditableInput
                            onCardEdited={setIsCardEdited}
                            currentData={currentData}
                            selector={input.selector}
                            basedOnDependensiesOptions={input.basedOnDependensiesOptions}
                            localizedValue={
                              input.localizedValue && (renderValue(input.localizedValue) as string | null)
                            }
                            fieldValue={renderValue(input.value) as string | null}
                            fieldText={input.valueText && (renderValue(input.valueText) as string | null)}
                            globalEdit={isEdited}
                            onChange={handleChange(input.id, input.changesConfirmation)}
                            placeholder={input.placeholder && t(input.placeholder)}
                            type={input.type}
                            multilineInput={input.multilineInput}
                            id={input.id}
                            title={input.title && t(input.title)}
                            options={input.options}
                            optionSelector={input.optionsSelector}
                            handleSetActiveTab={handleSetActiveTab}
                            tabSwitch={input.tabSwitchAction?.action}
                            icon={input.Icon && currentData && <input.Icon value={currentData} fill={primaryColor} />}
                            treeData={input.treeData}
                            isNewEntity={isNewEntity}
                            editForbidden={
                              (data && input.editForbidden && input.editForbidden(data)) || isGlobalEditForbidden
                            }
                            disabledDate={
                              input.disabledDate && input.disabledDate(data as Record<string, unknown> | undefined)
                            }
                            searchById={input.searchById}
                            canEdit={canEditCard}
                            dataFetchAction={input.dataFetchAction}
                          >
                            {input.title && t(input.title)}
                          </EditableInput>
                        </HbFormItem>
                      </Row>
                      <Row
                        style={
                          input.dependentFields
                            ? { width: `calc(100% / ${input.dependentFields.length + 1} + 15%)`, padding: "0 1rem" }
                            : { padding: "0 0.5rem" }
                        }
                      >
                        {/* <RENDER DEPENDENT FIELDS> */}
                        {/* Currently works only with boolean parent fields. Dependent fields become visible if parent field is 'true'*/}
                        {input.dependentFields &&
                          input.dependentFields.length > 0 &&
                          input.dependentFields?.map(field => renderBooleanDependentField(field, input.id))}
                        {/* </RENDER DEPENDENT FIELDS> */}
                      </Row>
                    </Col>
                  )
                )}
                {/* <ADDITIONAL CUSTOM PROPS>*/}
                {data &&
                  data["customPropertyValues"] &&
                  (data["customPropertyValues"] as AdditionalProps[]).map(input => {
                    const currentCompanyDictionary =
                      input.dictionaryId && companyDictionaries.find(x => x.id === input.dictionaryId);
                    return (
                      <Col key={input.name} className="field-wrapper" span={fieldSpan}>
                        <Row>
                          <Form.Item name={input.name}>
                            <EditableInput
                              onCardEdited={setIsCardEdited}
                              fieldValue={(input.dictionaryId ? Number(input.value) : input.value) || ""}
                              globalEdit={isEdited}
                              onChange={handleChange(input.name, undefined)}
                              type={input.type}
                              placeholder={t("Choose")}
                              id={input.name}
                              title={input.name && t(input.name)}
                              // TODO: add options from the dictionary of the custom field
                              options={
                                currentCompanyDictionary
                                  ? currentCompanyDictionary.dictionaryValues
                                    ? currentCompanyDictionary.dictionaryValues
                                        .filter(x => x.isActive)
                                        .map(x => ({ id: x.id, label: x.value }))
                                    : [{ id: getEmptyValue().key, label: getEmptyValue().localizedValue }]
                                  : undefined
                              }
                              optionSelector={() => null}
                              handleSetActiveTab={handleSetActiveTab}
                              isNewEntity={isNewEntity}
                              editForbidden={
                                (!isNewEntity &&
                                  summaryCard.customFieldsAdditionalProps?.editForbidden &&
                                  summaryCard.customFieldsAdditionalProps.editForbidden(data)) ||
                                isGlobalEditForbidden
                              }
                              canEdit={canEditCard}
                            >
                              {input.name && t(input.name)}
                            </EditableInput>
                          </Form.Item>
                        </Row>
                      </Col>
                    );
                  })}
              </Row>
              {currentData && summaryCard.RRuleComponent?.fieldName && (
                <Modal
                  onOk={() => setFormModalVisible(false)}
                  onCancel={() => setFormModalVisible(false)}
                  open={formModalVisible}
                >
                  <Row className="single-view-rrule">
                    <RRuleComponent
                      onChange={handleChange("recurrencyRule", undefined)}
                      form={form}
                      fieldName={summaryCard.RRuleComponent.fieldName}
                    />
                  </Row>
                </Modal>
              )}
              <Space direction="vertical"></Space>
              {/* Editing Buttons Start */}

              <EditingButtons
                isEditing={isEdited}
                customButtons={
                  summaryCard.RRuleComponent
                    ? [
                        ...customEditButtons,
                        <Button
                          className="rrule-button"
                          key={nanoid()}
                          onClick={() => setFormModalVisible(!formModalVisible)}
                        >
                          {t("SetRecurring")}
                        </Button>,
                      ]
                    : customEditButtons
                }
                handleCancel={handleCancel}
              />

              {!isEdited && summaryCard.actionComponent && !isNewEntity && currentData && (
                <Row className="fields-section__editing-buttons">
                  <Row>{summaryCard.actionComponent(currentData)}</Row>
                </Row>
              )}
            </Col>
          </Row>
        </Row>
      </Form>
    </>
  );
};

export default SingleViewCard;
