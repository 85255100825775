import { Tabs, Layout as AntDLayout } from "antd";
import Layout from "../../pages/Layout/Layout";
import CompanyMigrationManager from "./CompanyMigration/CompanyMigrationManager";

import "./ManagmentToolsPage.less";
const { TabPane } = Tabs;
const { Header } = AntDLayout;

enum ManagmentToolPage {
  MigrationManagmet = "Company Migration Managment",
}

export default function ManagmentToolsPage() {
  const onTabSelected = () => {
    return;
  };

  return (
    <Layout isSideBarOnly={true} entityKey="managmentTools">
      <div className="managment-tools-page-container">
        <Header className="layout__header">
          <Tabs destroyInactiveTabPane defaultActiveKey={ManagmentToolPage.MigrationManagmet} onChange={onTabSelected}>
            <TabPane tab={ManagmentToolPage.MigrationManagmet} key={ManagmentToolPage.MigrationManagmet}>
              <CompanyMigrationManager />
            </TabPane>
          </Tabs>
        </Header>
      </div>
    </Layout>
  );
}
