import { Badge } from "antd";
import dayjs from "dayjs";
import { orgUnitSelectors } from "../../../../selectors";
import {
  deleteNewAccountableEntryTemplate,
  fillNewAccountableEntryTemplate,
  updateNewAccountableEntryRole,
} from "../../../../store/slices/orgUnit";
import { addEntityPrivilege, updateEntityPrivilegeRole } from "../../../../store/slices/privileges";
import { Employee } from "../../../../types/employee";
import { OrgUnit } from "../../../../types/orgUnit";
import { ColumnType, ColumnWithDifferentCells } from "../../../../types/page";
import { PrivilegeData, PrivilegedEntityType, Role, Status } from "../../../../types/utility";
import { AccountabilityOptions, getCellSearchResultColumns, options } from "../common";
import { fetchEmployeeById, fetchOrgUnitById, localizeText, renderBoolStatus, renderTableStatus } from "../utilities";

export const orgUnitsTableColumns: ColumnType<OrgUnit>[] = [
  {
    id: "id",
    label: "ID",
    renderValue: value => value.id.toString(),
    sortable: false,
    optionsSelector: () => null,
    // hidden: true,
  },
  {
    id: "name",
    label: "FieldName",
    renderValue: value => value.name,
    filterType: "multiSelect",
    optionsSelector: orgUnitSelectors.uniqueNames,
    sortable: true,
  },
  {
    id: "externalId",
    label: "ExternalID",
    renderValue: value => value.externalId,
    filterType: "multiSelect",
    optionsSelector: orgUnitSelectors.uniqueExternalIds,
    sortable: true,
  },
  {
    id: "parentId",
    label: "ParentOU",
    renderValue: value => fetchOrgUnitById(value.parentId) || "Root",
    filterType: "multiSelect",
    optionsSelector: orgUnitSelectors.uniqueParentOrgUnits,
    sortable: true,
  },
  {
    id: "status",
    label: "FieldStatus",
    renderValue: () => null, // label is rendered together with icon in renderTableStatus()
    valueIcon: (value: OrgUnit): React.ReactNode | null => {
      return renderTableStatus(value.status);
    },
    sortable: true,
    filterType: "multiSelect",
    optionsSelector: () =>
      options.map(l => ({
        id: l.id,
        label: localizeText(l.label),
      })),
    width: 100,
  },
  {
    id: "managerUserId",
    label: "ManagerUser",
    renderValue: value => fetchEmployeeById(value.managerUserId) || null,
    filterType: "multiSelect",
    optionsSelector: orgUnitSelectors.uniqueManagerUserIds,
    sortable: true,
  },
];

export const employeesTabColumns: ColumnWithDifferentCells<Employee>[] = [
  {
    id: "displayName",
    label: "FieldLanguageDisplayName",
    renderValue: value => value.displayName,
    sortable: true,
    optionsSelector: () => null,
  },
  {
    id: "externalId",
    label: "ExternalID",
    renderValue: value => value.externalId || "",
    sortable: false,
    optionsSelector: () => null,
  },
  {
    id: "expirationDate",
    label: "FieldExpirationDate",
    optionsSelector: () => null,
    renderValue: value => (value.expirationDate ? dayjs(value.expirationDate).format("DD/MM/YYYY") : null),
    valueIcon: (value: Employee) => {
      if (value.expirationDate) {
        const expDate = dayjs(value.expirationDate);
        const dayDiff = expDate.diff(dayjs(), "days");
        return <Badge color={dayDiff >= 30 ? "green" : "red"} />;
      }
    },
    sortable: false,
    filterType: "dateRange",
    width: 100,
  },
  {
    id: "status",
    label: "FieldStatus",
    renderValue: value => (value.status ? localizeText(Status[value.status]) : ""),
    sortable: false,
    optionsSelector: () => null,
    width: 100,
  },
];

export const accountableTabColumns: ColumnWithDifferentCells<PrivilegeData>[] = [
  {
    id: "name",
    label: "FieldName",
    renderValue: entity => entity.name || "",
    sortable: true,
    filterType: "multiSelect",
    cellType: entity => (entity.staging ? "search" : "text"),
    cellSearchSelector: orgUnitSelectors.allOrgUnitRelations,
    cellSearchResultColumns: getCellSearchResultColumns(["name", "externalId"]),
    cellSearchKeys: ["name"],
    cellSearchRecordClick: fillNewAccountableEntryTemplate,
    optionsSelector: () => null,
    width: 100,
    primaryColumn: entity => ({
      navigationTarget: entity.userId ? `/employee/${entity.userId}` : `/orgUnit/${entity.orgUnitId}`,
    }),
  },
  {
    id: "externalId",
    label: "ExternalID",
    renderValue: entity => entity.externalId || "",
    sortable: true,
    filterType: "multiSelect",
    cellType: entity => (entity.staging ? "search" : "text"),
    cellSearchSelector: orgUnitSelectors.allOrgUnitRelations,
    cellSearchResultColumns: getCellSearchResultColumns(["name", "externalId"]),
    cellSearchKeys: ["externalId"],
    cellSearchRecordClick: fillNewAccountableEntryTemplate,
    optionsSelector: orgUnitSelectors.allOrgUnitExternalIDs,
    width: 100,
  },
  {
    id: "status",
    label: "FieldStatus",
    renderValue: entity => (entity.status ? localizeText(entity.status.toString()) : ""),
    sortable: true,
    optionsSelector: () => null,
    width: 100,
  },
  {
    id: "isExternal",
    label: "External",
    renderValue: entity => (entity.isExternal !== null ? renderBoolStatus(entity.isExternal) : ""),
    sortable: true,
    optionsSelector: () => null,
    width: 100,
  },
  {
    id: "role",
    label: "Accountability",
    renderValue: e => (e.role ? localizeText(e.role.toString()) : ""),
    cellType: () => "dropdown",
    cellDropdownOptions: () =>
      AccountabilityOptions.map(ao => ({
        id: ao.id,
        label: localizeText(ao.label),
      })),
    cellDropdownOnChange: (entity, newValue) =>
      entity.staging
        ? updateNewAccountableEntryRole({ entity: entity, newValue: newValue as Role })
        : updateEntityPrivilegeRole({
            entity: entity,
            role: newValue as Role,
            entityType: PrivilegedEntityType.OrgUnit,
            id: null,
          }),
    sortable: true,
    optionsSelector: () => null,
    width: 100,
  },
  {
    id: "action" as keyof PrivilegeData,
    label: "FieldAction",
    renderValue: () => null,
    sortable: true,
    optionsSelector: () => null,
    cellType: () => "buttonActions",
    width: 100,
    stagingActions: [
      {
        label: "Add",
        className: "hb-primary-button",
        action: entity => addEntityPrivilege({ entity: entity, entityType: PrivilegedEntityType.OrgUnit, id: null }),
      },
      {
        label: "Cancel",
        className: "hb-tertiary-button",
        action: entity => deleteNewAccountableEntryTemplate(entity),
      },
    ],
  },
];
