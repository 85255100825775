import cubejs from "@cubejs-client/core";
import jwt_decode from "jwt-decode";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { fetchCubeToken } from "../../store/slices/user";
import { RootState, useAppDispatch } from "../../store/store";

const API_URL = process.env.REACT_APP_CUBEJS_API_URL ? process.env.REACT_APP_CUBEJS_API_URL : "";

export const CUBEJS_API_URL = `${API_URL}/cubejs-api/v1`;

type useCubeAPIProps = {
  crossProjects?: boolean;
};
const useCubeApi = ({ crossProjects }: useCubeAPIProps) => {
  const dispatch = useAppDispatch();
  let apiTokenPromise: Promise<string>;

  useEffect(() => {
    apiTokenPromise = dispatch(fetchCubeToken(crossProjects)).then(res => {
      return res?.payload as string;
    });
  }, []);

  const cubejsApi = cubejs(
    () => {
      if (!apiTokenPromise) {
        apiTokenPromise = dispatch(fetchCubeToken(crossProjects)).then(res => {
          return res?.payload as string;
        });
      } else {
        apiTokenPromise.then(token => {
          try {
            const { exp } = jwt_decode<any>(token);
            let expirationDate = new Date(exp * 1000);
            const renewToken = expirationDate.getTime() < Date.now();
            if (renewToken) {
              apiTokenPromise = dispatch(fetchCubeToken(crossProjects)).then(res => {
                return res?.payload as string;
              });
            } else {
              return token;
            }
          } catch (error) {
            console.error("Invalid token", token);
          }
        });
      }
      return apiTokenPromise;
    },
    {
      apiUrl: CUBEJS_API_URL,
    }
  );

  return cubejsApi;
};

export default useCubeApi;
