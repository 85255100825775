import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import cloneDeep from "lodash/cloneDeep";
import tinycolor from "tinycolor2";
import { heatmapOptions } from "../../components/Map/MapSources/HeatmapControl";
import { MapState, SourceData } from "../../types/map";

export const initialState: MapState = {
  style: {},
  sourceData: {} as SourceData,
  heatmapValue: heatmapOptions[0],
  dateRange: undefined,
  defaultPointColor: tinycolor.random().toHexString(),
};

const slice = createSlice({
  name: "map",
  initialState,
  reducers: {
    setStyle: (state, action: PayloadAction<any | null>) => {
      if (action.payload) {
        state.style = action.payload;
      }
    },
    setHeatmapValue: (state, action: PayloadAction<any | null>) => {
      if (action.payload) {
        state.heatmapValue = action.payload;
      }
    },
    setHeatmapWeight: (state, action: PayloadAction<any | null>) => {
      if (action.payload) {
        if (state.style.layers) {
          let newStyle = { ...state.style };
          newStyle.layers = cloneDeep(newStyle.layers);
          let layer = newStyle.layers.find((layer: { id: string }) => layer.id === action.payload.layerId);
          if (layer !== null) {
            layer.paint["heatmap-weight"] = action.payload.method;
            state.style = newStyle;
          }
        }
      }
    },
    setDateRange: (state, action: PayloadAction<any | null>) => {
      if (action.payload !== undefined) {
        state.dateRange = action.payload;
      }
    },
    addLayer: (state, action: PayloadAction<any | null>) => {
      if (action.payload) {
        let newStyle = { ...state.style };
        newStyle.layers.push(action.payload.layer);
        state.style = newStyle;
      }
    },
    changeCircleColor: (state, action: PayloadAction<any | null>) => {
      if (action.payload) {
        if (state.style.layers) {
          let newStyle = { ...state.style };
          newStyle.layers = cloneDeep(newStyle.layers);
          let layer = newStyle.layers.find((layer: { id: string }) => layer.id === action.payload.layerId);
          if (layer !== null) {
            layer.paint["circle-color"] = action.payload.method;
            state.style = newStyle;
          }
        }
      }
    },
    changeCircleStroke: (state, action: PayloadAction<any | null>) => {
      if (action.payload) {
        if (state.style.layers) {
          let newStyle = { ...state.style };
          newStyle.layers = cloneDeep(newStyle.layers);
          let layer = newStyle.layers.find((layer: { id: string }) => layer.id === action.payload.layerId);
          if (layer !== null) {
            layer.paint["circle-stroke-width"] = action.payload.strokeWidth;
            layer.paint["circle-stroke-color"] = action.payload.colorMethod;
            state.style = newStyle;
          }
        }
      }
    },
    setDefaultPointColor: (state, action: PayloadAction<any | null>) => {
      if (action.payload) {
        state.defaultPointColor = action.payload;
      }
    },
    addSource: (state, action: PayloadAction<any | null>) => {
      if (action.payload) {
        let newStyle = { ...state.style };
        if (!newStyle.sources[action.payload.sourceName]) {
          newStyle.sources[action.payload.sourceName] = action.payload.source;
          state.sourceData[action.payload.sourceName] = { data: action.payload.data };
          state.style = newStyle;
        }
      }
    },
    updateSourceData: (state, action: PayloadAction<any | null>) => {
      if (state.style && action.payload) {
        let newStyle = { ...state.style };
        if (newStyle.sources && newStyle.sources[action.payload.sourceName]) {
          newStyle.sources[action.payload.sourceName].data = action.payload.data;
          state.style = newStyle;
        }
      }
    },
    filterSourceData: (state, action: PayloadAction<any | null>) => {
      if (state.style && action.payload) {
        let newStyle = { ...state.style };
        if (newStyle.sources && newStyle.sources[action.payload.sourceName]) {
          newStyle.sources[action.payload.sourceName].data = action.payload.data.filter(action.payload.filter);
          state.style = newStyle;
        }
      }
    },
    changeStyle: (state, action: PayloadAction<any | null>) => {
      if (action.payload) {
        let newStyle = { ...state.style };
        newStyle.layers.find((layer: { id: any }) => layer.id === action.payload.layer).paint = action.payload.style;
        state.style = newStyle;
      }
    },
    setFilter: (state, action: PayloadAction<any | null>) => {
      if (action.payload) {
        let newStyle = { ...state.style };
        if (action.payload.filter === "none") {
          delete newStyle.layers.find((layer: { id: any }) => layer.id === action.payload.layer).filter;
        } else {
          newStyle.layers.find((layer: { id: any }) => layer.id === action.payload.layer).filter =
            action.payload.filter;
        }
        state.style = newStyle;
      }
    },
    setViz: (state, action: PayloadAction<any | null>) => {
      if (action.payload) {
        let newStyle = { ...state.style };
        if (action.payload.visible === true) {
          newStyle.layers.find((layer: { id: any }) => layer.id === action.payload.layer).layout.visibility = "visible";
        } else {
          newStyle.layers.find((layer: { id: any }) => layer.id === action.payload.layer).layout.visibility = "none";
        }
        state.style = newStyle;
      }
    },
  },
});

export const {
  setStyle,
  addLayer,
  updateSourceData,
  setHeatmapValue,
  setHeatmapWeight,
  setDateRange,
  setDefaultPointColor,
  changeCircleColor,
  changeCircleStroke,
  filterSourceData,
  changeStyle,
  setFilter,
  setViz,
  addSource,
} = slice.actions;
export default slice.reducer;
