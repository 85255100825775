import { createAsyncThunk, createSlice, nanoid, PayloadAction } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import unionBy from "lodash/unionBy";
import { CustomError, getCustomPropertiesWithValues } from "../../pages/pageConfig/category/utilities";
import { ManualCertificate } from "../../types/certification";
import { Employee, EmployeeCertificate, EmployeePaginatedData, EmployeeState, TenantRole } from "../../types/employee";
import { Inspection } from "../../types/equipment";
import { File, FileUploadResponse } from "../../types/files";
import { ContextActions, ContextActionsPaginatedData } from "../../types/tasks";
import {
  ExplicitAdditionalProps,
  HistoryLog,
  PrivilegeData,
  Role,
  Status,
  UpdateCustomProps,
} from "../../types/utility";
import { generateFiltersAndEmptyProps, generateGridifySorterQuery } from "../../utils/gridifyQueryHelper";
import { hbApi, hbApiOptions } from "../api";
import { RootState } from "../store";
import { fetchHistoryLog } from "./commonThunks";
import {
  addEntityPrivilege,
  createNewPrivilegeTemplate,
  deleteEntityPrivilege,
  deleteMultipleEntityPrivileges,
  deleteNewPrivilegeEntryTemplate,
  fillNewPrivilegeEntryTemplate,
  getEntityPrivileges,
  updateEntityPrivilegeRole,
  updateNewPrivilegeEntryRole,
} from "./privileges";

// TODO: REMOVE DRY CODE (ACTIONS/THUNKS/TYPES). ESPECIALLY FOR TABS FUNCTIONALITY

export const initialState: EmployeeState = {
  data: [],
  basicData: [],
  isLoading: false,
  singleData: null,
  subData: {
    inspections: [],
    historyLog: [],
    accountable: [],
    certifications: [],
    files: [],
    actions: [],
  },
  searchResults: [],
  error: null,
  defaultCustomProperties: [],
  lastUpdated: dayjs().toISOString(),
  paginationInfo: {
    count: 0,
    currentPage: 0,
  },
};

export const newEmployee: Employee = {
  id: 0,
  username: null,
  orgUnitId: null,
  orgUnitName: "",
  externalId: null,
  locationId: null,
  locationName: "",
  displayName: "",
  phone: "",
  status: Status.Active,
  authType: null, // should be replaced by company auth type
  twoFactorType: null,
  email: "",
  rank: null,
  position: 0,
  expirationDate: null,
  jobTitle: null,
  isExternal: false,
  isUser: false,
  isActive: true,
  locations: [],
  tenantRole: TenantRole.User,
  projectIds: [],
};

export const addEmployee = createAsyncThunk<Employee, { entity: Employee }, { state: RootState }>(
  "@@EMPLOYEE/ADD",
  async ({ entity }, { rejectWithValue, getState }) => {
    const { user, employee } = getState();

    // Note: We need to modify the request body for the custom props, before sending it to the server
    const modifiedPropertyValues: UpdateCustomProps = {};
    entity.customPropertyValues?.forEach(prop => {
      modifiedPropertyValues[prop.propertyId] = prop.value;
    });

    try {
      const modifiedEntity = {
        ...entity,
        authType: entity.authType || user.companySettings.authType,
        twoFactorType: entity.twoFactorType || user.companySettings.twoFactorType,
        username: entity.username || null,
        customPropertyValues: modifiedPropertyValues,
      };
      const response = await hbApi.post<Employee>("/Employee", modifiedEntity, hbApiOptions(user.jwt));

      return {
        ...response.data,
        customPropertyValues: getCustomPropertiesWithValues(
          employee.defaultCustomProperties,
          response.data.customPropertyValues
        ),
      };
    } catch (e) {
      return rejectWithValue(e.errors);
    }
  },
  {
    condition: (_, { getState }) => {
      const { employee } = getState();
      return !employee.isLoading;
    },
  }
);

export const updateEmployee = createAsyncThunk<Employee, Employee, { state: RootState }>(
  "@@EMPLOYEE/UPDATE",
  async (entity, { getState, rejectWithValue }) => {
    const { user, employee } = getState();

    // Note: We need to modify the request body for the custom props, before sending it to the server
    const modifiedPropertyValues: UpdateCustomProps = {};
    entity.customPropertyValues?.forEach(prop => {
      modifiedPropertyValues[prop.propertyId] = prop.value;
    });

    try {
      const modifiedEntity = {
        ...entity,
        username: entity.username || null,
        customPropertyValues: modifiedPropertyValues,
      };
      const response = await hbApi.put<Employee>(`/Employee/${entity.id}`, modifiedEntity, hbApiOptions(user.jwt));

      return {
        ...response.data,
        customPropertyValues: getCustomPropertiesWithValues(
          employee.defaultCustomProperties,
          response.data.customPropertyValues
        ),
      };
    } catch (e) {
      if (e.status === 400) {
        return rejectWithValue(e.errors);
      }
      throw new CustomError(e.message || e.Message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { employee, user } = getState();
      return !employee.isLoading && !!user.jwt;
    },
  }
);

export const removeFiles = createAsyncThunk<number[], number[], { state: RootState }>(
  "@@EMPLOYEE/REMOVE_FILE",
  async (ids, { getState }) => {
    const { user } = getState();
    await hbApi.delete<File[]>("/File", hbApiOptions(user.jwt, ids));

    return ids;
  }
);

export const uploadFile = createAsyncThunk<File[] | null, { file: any; expiration: string }, { state: RootState }>(
  "@@EMPLOYEE/UPLOAD_FILE",
  async ({ file, expiration }, { getState }) => {
    const { user, employee } = getState();
    const formData = new FormData();
    formData.append("files[]", file);

    try {
      const fileEntity = await hbApi.post<FileUploadResponse>(
        `/File/upload?entityType=Employee&entityId=${employee?.singleData?.id || 0}&expirationDate=${expiration}`,
        formData,
        hbApiOptions(user.jwt)
      );

      return fileEntity.data?.data;
    } catch (e) {
      throw new CustomError(e.message);
    }
  }
);

export const fetchFullEmployees = createAsyncThunk<{ basicData: Employee[] }, void, { state: RootState }>(
  "@@EMPLOYEE/FETCH_BASICS",
  async (_, { getState }) => {
    const { user } = getState();
    const employees = await hbApi.get<Employee[]>("/Employee/full", hbApiOptions(user.jwt));
    return {
      basicData: employees.status === 200 ? employees.data : [],
    };
  }
);

export const fetchSingleEmployee = createAsyncThunk<
  {
    singleData: Employee;
    subData: {
      historyLog: HistoryLog[];
      inspections: Inspection[];
      files: File[];
      certificates: EmployeeCertificate[];
      actions: ContextActions[];
    };
    defaultCustomProperties: ExplicitAdditionalProps[];
  },
  string,
  { state: RootState }
>(
  "@@SINGLE_EMPLOYEE/FETCH",
  async (id, { getState, rejectWithValue }) => {
    const { user } = getState();

    let cardDataResponse;
    try {
      cardDataResponse = await hbApi.get<Employee>(`/Employee/${id}`, hbApiOptions(user.jwt));
    } catch (e) {
      return rejectWithValue(e.error);
    }
    const certificates = await hbApi.get<EmployeeCertificate[]>(`/EmployeeCertificate/${id}`, hbApiOptions(user.jwt));
    // const historyLogResponse = await hbApi.get<HistoryLog[]>("/changeme");
    // const inspectionsResponse = await hbApi.get<Inspection[]>("/inspections");
    const filesResponse = await hbApi.get<File[]>(`/File?entityType=employee&entityId=${id}`, hbApiOptions(user.jwt));
    const contextActionsResponse = await hbApi.get<ContextActionsPaginatedData>(
      `/Task/context-actions?entityId=${id}&entityType=User`,
      hbApiOptions(user.jwt)
    );

    const employeeCustomProperties = await hbApi.get<ExplicitAdditionalProps[]>(
      "/EmployeeCustomProperty",
      hbApiOptions(user.jwt)
    );

    return {
      singleData: {
        ...cardDataResponse.data,
        customPropertyValues: getCustomPropertiesWithValues(
          employeeCustomProperties.data,
          cardDataResponse.data.customPropertyValues
        ),
      },
      subData: {
        inspections: [],
        historyLog: [],
        files: filesResponse.data,
        certificates: certificates.data || [],
        actions: contextActionsResponse.data.data || [],
      },
      defaultCustomProperties: employeeCustomProperties.data,
    };
  }
  // {
  //   condition: (_, { getState }) => {
  //     const { employee } = getState();
  //     return !employee.isLoading;
  //   },
  // }
);

export const patchLocationId = createAsyncThunk<Employee, { objectId: number; newValue: number }, { state: RootState }>(
  "@@EMPLOYEE/PATCH_LOCATION_ID",
  async ({ objectId, newValue }, { getState, rejectWithValue }) => {
    const { user } = getState();

    try {
      const response = await hbApi.patch<Employee>(
        `/Employee/${objectId}`,
        [{ path: "/locationId", op: "replace", value: newValue }],
        hbApiOptions(user.jwt)
      );
      return response.data;
    } catch (e) {
      if (e.status === 400) {
        return rejectWithValue(e.data);
      }
      throw new CustomError(e.message || e.Message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { location, user } = getState();
      return !location.isLoading && !!user.jwt;
    },
  }
);

export const fetchPaginatedEmployee = createAsyncThunk<
  { primaryData: Employee[]; defaultCustomProperties: ExplicitAdditionalProps[]; possibleResults: number },
  { page?: number; pageSize?: number; forceUpdate?: boolean } | undefined,
  { state: RootState }
>(
  "@@EMPLOYEE/FETCH_PAGINATED",
  async (params, { getState }) => {
    const { user, employee, filter } = getState();

    const employeeFilters = filter.filters.employee?.employee?.activeFilters;

    const { filters, emptyPropIds } = generateFiltersAndEmptyProps(employeeFilters);

    const orders = filter.filterValues.employee?.order
      ? generateGridifySorterQuery(filter.filterValues.employee?.order)
      : undefined;

    const response = await hbApi.post<EmployeePaginatedData>(
      "/Employee/paged-list",
      {
        Page: params?.page || employee.paginationInfo.currentPage + 1,
        PageSize: params?.pageSize,
        Filter: filters,
        OrderBy: orders,
      },
      hbApiOptions(user.jwt, null, null, {
        emptyPropIds: emptyPropIds,
      })
    );

    const employeeCustomProperties = await hbApi.get<ExplicitAdditionalProps[]>(
      "/EmployeeCustomProperty",
      hbApiOptions(user.jwt)
    );

    return {
      primaryData: response.data.data,
      defaultCustomProperties: employeeCustomProperties.data,
      possibleResults: response.data.count,
    };
  },
  {
    condition: (arg, { getState }) => {
      const { employee } = getState();

      return (
        arg?.forceUpdate ||
        (dayjs(employee.lastUpdated).isBefore(dayjs()) &&
          !employee.isLoading &&
          employee.data.length !== employee.paginationInfo.count)
      );
    },
  }
);

export const searchEmployee = createAsyncThunk<
  Employee[],
  { filters?: string; page?: number } | undefined,
  { state: RootState }
>(
  "@@EMPLOYEE/SEARCH_PAGINATED",
  async (params, { getState }) => {
    const { user } = getState();
    const response = await hbApi.post<EmployeePaginatedData>(
      "/Employee/paged-list",
      {
        Page: params?.page || 1,
        PageSize: 100,
        Filter: params?.filters,
      },
      hbApiOptions(user.jwt)
    );

    return response.data.data;
  },
  {
    condition: (_, { getState }) => {
      const { employee } = getState();
      return !employee.isLoading;
    },
  }
);

export const patchOrgUnitId = createAsyncThunk<Employee, { objectId: number; newValue: number }, { state: RootState }>(
  "@@EMPLOYEE/PATCH_ORG_UNIT_ID",
  async ({ objectId, newValue }, { getState, rejectWithValue }) => {
    const { user } = getState();

    try {
      const response = await hbApi.patch<Employee>(
        `/Employee/${objectId}`,
        [{ path: "/orgUnitId", op: "replace", value: newValue }],
        hbApiOptions(user.jwt)
      );
      return response.data;
    } catch (e) {
      if (e.status === 400) {
        return rejectWithValue(e.data);
      }
      throw new CustomError(e.message || e.Message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { orgUnit, user } = getState();
      return !orgUnit.isLoading && !!user.jwt;
    },
  }
);

export const getEmployeeCertificates = createAsyncThunk<EmployeeCertificate[], number, { state: RootState }>(
  "@@EMPLOYEE/FETCH_CERTIFICATIONS",
  async (id, { getState }) => {
    const { user } = getState();

    const response = await hbApi.get<EmployeeCertificate[]>(`/EmployeeCertificate/${id}`, hbApiOptions(user.jwt));
    return response.data;
  },
  {
    condition: (_, { getState }) => {
      const { employee } = getState();
      return !employee.isLoading;
    },
  }
);

export const fetchEmployeeCustomProps = createAsyncThunk<ExplicitAdditionalProps[], void, { state: RootState }>(
  "@@EMPLOYEE_CUSTOM_PROPS/FETCH",
  async (_, { getState }) => {
    const { user } = getState();
    const response = await hbApi.get<ExplicitAdditionalProps[]>("/EmployeeCustomProperty", hbApiOptions(user.jwt));
    return response.data;
  }
);

export const setNotNeededCertificate = createAsyncThunk<
  EmployeeCertificate,
  { entity: EmployeeCertificate; newValue: boolean },
  { state: RootState }
>(
  "@@EMPLOYEE/CHANGE_CERTIFICATE_STATUS",
  async ({ entity, newValue }, { getState, rejectWithValue }) => {
    const { user } = getState();
    const body = {
      employeeId: entity.employeeId,
      certificateId: entity.certificateId,
      notNeeded: newValue,
    };
    const response = await hbApi.post<EmployeeCertificate>(
      "/EmployeeCertificate/change-no-longer-needed-status",
      body,
      hbApiOptions(user.jwt)
    );
    return { ...response.data, id: entity.id };
  },
  {
    condition: (_, { getState }) => {
      const { employee } = getState();
      return !employee.isLoading;
    },
  }
);

export const addEmployeeManualCertificate = createAsyncThunk<
  EmployeeCertificate,
  ManualCertificate,
  { state: RootState }
>(
  "@@EMPLOYEE/ADD_EMPLOYEE_MANUAL_CERTIFICATE",
  async (entity, { getState, rejectWithValue }) => {
    const { user, employee } = getState();
    if (!entity.certificateId) {
      throw new CustomError("Entity not found.");
    }
    const body = {
      employeeId: entity.employeeId ?? employee.singleData?.id,
      certificateId: entity.certificateId,
      trainingDate: dayjs.utc(entity.trainingDate).format(),
      expirationDate: entity.expirationDate,
    };

    try {
      const response = await hbApi.post<EmployeeCertificate>(
        "/EmployeeCertificate/manual-create",
        body,
        hbApiOptions(user.jwt)
      );
      return response.data;
    } catch (e) {
      if (e.status === 400) {
        return rejectWithValue(e.data);
      }
      throw new CustomError(e.message || e.Message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { employee } = getState();
      return !employee.isLoading;
    },
  }
);

const slice = createSlice({
  name: "employee",
  initialState,
  // Note: User reducers only for synchronous logic
  reducers: {
    resetCurrentPage: state => {
      state.paginationInfo.currentPage = 0;
    },
    createEmployeeTemplate: state => {
      const customProps = state.defaultCustomProperties;
      state.singleData = {
        ...newEmployee,
        customPropertyValues: getCustomPropertiesWithValues(customProps),
      };
      state.subData.historyLog = [];
      state.subData.inspections = [];
    },
    createNewAccountableEntryTemplate: createNewPrivilegeTemplate,
    fillNewAccountableEntryTemplate: {
      prepare: (payload: { row: PrivilegeData; targetEntity: Record<string, unknown> }) => ({ payload }),
      reducer: fillNewPrivilegeEntryTemplate,
    },
    updateNewAccountableEntryRole: {
      prepare: (payload: { entity: PrivilegeData; newValue: Role }) => ({ payload }),
      reducer: updateNewPrivilegeEntryRole,
    },
    deleteNewAccountableEntryTemplate: {
      prepare: (payload: PrivilegeData) => ({ payload }),
      reducer: deleteNewPrivilegeEntryTemplate,
    },
    updateLocationId: {
      prepare: (payload: number) => ({ payload }),
      reducer: (state, action: PayloadAction<number>) => {
        if (state.singleData) {
          state.singleData = { ...state.singleData, locationId: action.payload };
        }
      },
    },
    updateOrgUnitId: {
      prepare: (payload: number) => ({ payload }),
      reducer: (state, action: PayloadAction<number>) => {
        if (state.singleData) {
          state.singleData = { ...state.singleData, orgUnitId: action.payload };
        }
      },
    },
    clearEmployeeError: state => {
      state.error = null;
    },
    resetSearchResults: state => {
      state.searchResults = [];
    },
  },
  // Note: User reducers only for asynchronous logic with AsyncThunk
  extraReducers: builder => {
    builder
      // Note - Pending:
      .addCase(fetchFullEmployees.pending, state => {
        state.isLoading = true;
        state.basicData = [];
        state.error = null;
      })
      .addCase(fetchSingleEmployee.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addEmployee.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateEmployee.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      // Patch Location ID
      .addCase(patchLocationId.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      // Patch OrgUnit ID
      .addCase(patchOrgUnitId.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchPaginatedEmployee.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(searchEmployee.pending, state => {
        state.error = null;
      })
      .addCase(uploadFile.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getEntityPrivileges.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(removeFiles.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getEmployeeCertificates.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      // Note - Rejected:
      .addCase(fetchFullEmployees.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(fetchSingleEmployee.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
        state.singleData = undefined;
      })
      .addCase(addEmployee.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(updateEmployee.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(fetchPaginatedEmployee.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(searchEmployee.rejected, (state, action) => {
        state.error = action.error.message || null;
      })
      // Patch Location ID
      .addCase(patchLocationId.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      // Patch OrgUnit ID
      .addCase(patchOrgUnitId.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(uploadFile.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(removeFiles.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(fetchHistoryLog.rejected, (state, action) => {
        state.error = action.error.message || null;
      })
      .addCase(getEmployeeCertificates.rejected, (state, action) => {
        state.error = action.error.message || null;
        state.isLoading = false;
      })
      .addCase(addEmployeeManualCertificate.rejected, (state, action) => {
        state.error = action.error.message || null;
      })
      .addCase(getEntityPrivileges.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      // Note - Fulfilled:
      .addCase(fetchPaginatedEmployee.fulfilled, (state, action) => {
        if (action.meta.arg?.page === 1) {
          state.data = action.payload.primaryData;
          state.paginationInfo.currentPage = 1;
        } else {
          state.data = unionBy(state.data, action.payload.primaryData, "id");
          state.paginationInfo.currentPage = state.paginationInfo.currentPage + 1;
        }
        state.paginationInfo.count = action.payload.possibleResults;
        state.defaultCustomProperties = action.payload.defaultCustomProperties;
        state.error = null;
        state.lastUpdated = dayjs().toISOString();
        state.isLoading = false;
      })
      .addCase(searchEmployee.fulfilled, (state, action) => {
        state.searchResults = [...action.payload];
        state.error = null;
      })
      .addCase(fetchFullEmployees.fulfilled, (state, action) => {
        state.basicData = action.payload.basicData;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchEmployeeCustomProps.fulfilled, (state, action) => {
        state.defaultCustomProperties = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchSingleEmployee.fulfilled, (state, action) => {
        state.singleData = action.payload.singleData;
        // state.subData.historyLog = action.payload.subData.historyLog;
        // state.subData.inspections = action.payload.subData.inspections;
        state.subData.files = action.payload.subData.files;
        state.subData.actions = action.payload.subData.actions;
        if (action.payload.subData.certificates) {
          state.subData.certifications = action.payload.subData.certificates.map(c => ({ ...c, id: nanoid() }));
        }
        state.defaultCustomProperties = action.payload.defaultCustomProperties;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(addEmployee.fulfilled, (state, action) => {
        state.singleData = action.payload;
        state.data = state.data ? [...state.data, action.payload] : [action.payload];
        state.basicData = state.basicData ? [...state.basicData, action.payload] : [action.payload];
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateEmployee.fulfilled, (state, action) => {
        state.singleData = action.payload;
        const updatedEntityIndex = state.data.findIndex(employee => employee.id === action.payload.id);
        state.data[updatedEntityIndex] = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(uploadFile.fulfilled, (state, action) => {
        const filesArray = [...state.subData.files];

        if (action.payload && action.payload.length) filesArray.unshift(action.payload[0]);
        state.subData.files = filesArray;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(removeFiles.fulfilled, (state, action) => {
        state.subData.files = state.subData.files.filter(x => !action.payload.includes(x.id));
        state.isLoading = false;
        state.error = null;
      })
      .addCase(patchLocationId.fulfilled, (state, action) => {
        if (state.singleData) {
          state.singleData.locationId = action.payload.locationId;
          const updatedEntityIndex = state.data.findIndex(employee => employee.id === action.payload.id);
          state.data[updatedEntityIndex].locationId = action.payload.locationId;
          state.isLoading = false;
          state.error = null;
        }
      })
      .addCase(patchOrgUnitId.fulfilled, (state, action) => {
        if (state.singleData) {
          state.singleData.orgUnitId = action.payload.orgUnitId;
          const updatedEntityIndex = state.data.findIndex(employee => employee.id === action.payload.id);
          state.data[updatedEntityIndex].orgUnitId = action.payload.orgUnitId;
          state.isLoading = false;
          state.error = null;
        }
      })
      // Add EmployeeEmployee Relation
      .addCase(getEntityPrivileges.fulfilled, (state, action) => {
        state.subData.accountable = action.payload;
        state.isLoading = false;
        state.error = null;
      })

      // Add EmployeeOrgUnit Relation
      .addCase(addEntityPrivilege.fulfilled, (state, action) => {
        state.subData.accountable = state.subData.accountable.filter(r => r.id !== action.payload.id);
        state.subData.accountable.unshift({
          ...action.payload,
          id: action.payload.id!,
          staging: false,
        });
        // state.isLoading = false;
        state.error = null;
      })

      // Delete Employee Relation
      .addCase(deleteEntityPrivilege.fulfilled, (state, action) => {
        state.subData.accountable = state.subData.accountable.filter(r => r.id !== action.payload.id);
        // state.isLoading = false;
        state.error = null;
      })

      // Change Relation Role
      .addCase(updateEntityPrivilegeRole.fulfilled, (state, action) => {
        const updatedEntityIndex = state.subData.accountable.findIndex(r => r.id === action.payload.id);
        state.subData.accountable[updatedEntityIndex].role = action.payload.role;
        // state.isLoading = false;
        state.error = null;
      })

      // Delete Bulk Relations
      .addCase(deleteMultipleEntityPrivileges.fulfilled, (state, action) => {
        const relationsToRemove = action.payload.map(r => r.id);
        state.subData.accountable = state.subData.accountable.filter(r => !relationsToRemove.includes(r.id));
        // state.isLoading = false;
        state.error = null;
      })
      .addCase(getEmployeeCertificates.fulfilled, (state, action) => {
        state.subData.certifications = action.payload ? action.payload.map(r => ({ ...r, id: nanoid() })) : [];
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchHistoryLog.fulfilled, (state, action) => {
        state.subData.historyLog = action.payload
          ? action.payload.map(r => ({ ...r, id: nanoid(), timeStamp: dayjs(r.timeStamp).format("DD/MM/YYYY") }))
          : [];
        // state.isLoading = false;
        state.error = null;
      })
      .addCase(setNotNeededCertificate.fulfilled, (state, action) => {
        const updatedEntityIndex = state.subData.certifications.findIndex(r => r.id === action.payload.id);
        state.subData.certifications[updatedEntityIndex].status = action.payload.status;
        state.error = null;
      })
      // Add Employee Manual Certificate
      .addCase(addEmployeeManualCertificate.fulfilled, (state, action) => {
        state.subData.certifications = state.subData.certifications.filter(
          r => r.certificateId !== action.payload.certificateId
        );
        const currentCertificate = { ...action.payload, id: nanoid() };
        state.subData.certifications.unshift(currentCertificate);
        // state.isLoading = false;
        state.error = null;
      });
  },
});

export const {
  createEmployeeTemplate,
  updateLocationId,
  updateOrgUnitId,
  fillNewAccountableEntryTemplate,
  updateNewAccountableEntryRole,
  deleteNewAccountableEntryTemplate,
  createNewAccountableEntryTemplate,
  clearEmployeeError,
  resetCurrentPage,
  resetSearchResults,
} = slice.actions;
export default slice.reducer;
