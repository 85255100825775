import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  LandingPageAuthType,
  LandingPageInfo,
  LandingPageOtpResponse,
  LandingPageState,
  Mode,
} from "../../types/landingPage";
import { AuthStatus, LocalStorageKeys } from "../../types/utility";
import { hbApi, hbApiOptions } from "../api";
import { RootState } from "../store";

export const initialState: LandingPageState = {
  landingPageJwt: "",
  isLoading: false,
  error: null,
  landingPageInfo: null,
  mode: Mode.Phone,
  notAllowed: false,
  notFound: false,
};

export const fetchLandingPageInfo = createAsyncThunk<
  { landingPageData: LandingPageInfo; notAllowed: boolean; notFound: boolean },
  { key: string; lang: string; isMobile: boolean },
  { state: RootState }
>(
  "@@LANDING_PAGE/FETCH",
  async ({ key, lang, isMobile }, { getState }) => {
    const { landingPage, user } = getState();
    let result: LandingPageInfo;
    let notAllowed = false;
    let notFound = false;
    const jwt = localStorage.getItem(LocalStorageKeys.LANDING_PAGE_JWT) ?? landingPage.landingPageJwt;
    const response = await hbApi.get<LandingPageInfo>(
      `/LandingPage/${key}`,
      hbApiOptions(jwt, null, { LANGUAGE_KEY: lang })
    );
    result = response.data;

    if (result.authType === LandingPageAuthType.FullLogin && user.jwt) {
      const { data, status } = await hbApi.get<LandingPageInfo>(
        `/LandingPage/get-token/${key}`,
        hbApiOptions(user.jwt, null, { LANGUAGE_KEY: lang })
      );
      if (data.needAuthentication) {
        notAllowed = true;
      }
      if (data && status === 200) {
        result = data;
      }
    }

    if (response.status === 204) {
      notFound = true;
    }

    return { landingPageData: isMobile ? { ...result, image: undefined } : result, notAllowed, notFound };
  },
  {
    condition: (_, { getState }) => {
      const { landingPage } = getState();
      return !landingPage.isLoading;
    },
  }
);

export const landingPageOtp = createAsyncThunk<
  LandingPageOtpResponse,
  { Phone?: string; OtpCode?: string; Key?: string },
  { state: RootState }
>("@@LANDING_PAGE/OTP", async model => {
  const otp = await hbApi.post<LandingPageOtpResponse>("/LandingPage", model);

  return otp.data;
});

const slice = createSlice({
  name: "landingPage",
  initialState,
  // Note: User reducers only for synchronous logic
  reducers: {
    changeMode: (state, mode) => {
      state.mode = mode.payload;
    },
    removeJwt: state => {
      state.landingPageJwt = "";
      localStorage.removeItem(LocalStorageKeys.LANDING_PAGE_JWT);
      localStorage.removeItem(LocalStorageKeys.LANG);
    },
  },
  // Note: User reducers only for asynchronous logic with AsyncThunk
  extraReducers: builder => {
    builder
      .addCase(fetchLandingPageInfo.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(landingPageOtp.pending, state => {
        state.isLoading = true;
        state.error = null;
      })

      .addCase(fetchLandingPageInfo.fulfilled, (state, action) => {
        state.landingPageInfo = action.payload.landingPageData;
        state.notAllowed = action.payload.notAllowed;
        state.notFound = action.payload.notFound;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(landingPageOtp.fulfilled, (state, action) => {
        if (action.payload.status === AuthStatus.Authorized) {
          state.landingPageJwt = action.payload.token;
          state.error = null;
        }
        state.isLoading = false;
      });
  },
});

export const { changeMode, removeJwt } = slice.actions;
export default slice.reducer;
