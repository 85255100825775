import dayjs, { Dayjs } from "dayjs";
import { serverDateFormat } from "../../../pages/TodoList/utils";
import { setDateRange } from "../../../store/slices/map";
import { useAppDispatch } from "../../../store/store";
import { DatePicker } from "../../HBComponents/DatePicker/HBDatePicker";

const { RangePicker } = DatePicker;

type TimeSelectorProps = {
  onChangeRange?: (range: any) => void;
};

export default function TimeSelector({ onChangeRange }: TimeSelectorProps) {
  const dispatch = useAppDispatch();
  // const { dateRange } = useSelector((state: RootState) => state.map);
  const ranges: Record<string, [Dayjs, Dayjs]> = {
    Today: [dayjs(), dayjs()],
    "This Week": [dayjs().startOf("week"), dayjs().endOf("week")],
    "This Month": [dayjs().startOf("month"), dayjs().endOf("month")],
    "This Year": [dayjs().startOf("year"), dayjs().endOf("year")],
    "Last Week": [dayjs().subtract(1, "weeks").startOf("week"), dayjs().subtract(1, "weeks").endOf("week")],
    "Last Month": [dayjs().subtract(1, "months").startOf("month"), dayjs().subtract(1, "months").endOf("month")],
    "Last Year": [dayjs().subtract(1, "year").startOf("year"), dayjs().subtract(1, "year").endOf("year")],
  };

  const onChangedRange: (values: any, formatString: [string, string]) => void = range => {
    if (onChangeRange) {
      onChangeRange(range ? range.map((d: any) => d.format(serverDateFormat)) : null);
    } else {
      dispatch(setDateRange(range ? range.map((d: any) => d.format(serverDateFormat)) : null));
    }
  };
  return <RangePicker allowClear ranges={ranges} onChange={onChangedRange} />;
}
