import { memo } from "react";
import { TFunction } from "react-i18next";
import { ReactComponent as ArrowsIcon } from "../../media/arrows.svg";
import { ReactComponent as ClockIcon } from "../../media/clock.svg";
import { TodoListAppointment } from "../../types/todolist";
import { TodoListCardStatus } from "../../types/utility";
import { getAppointmentUrl } from "../../utils/functions";
import useIsMobile from "../../utils/hooks/useIsMobile";
import useLegacyBaseUrl from "../../utils/hooks/useLegacyUrl";
import { renderCardStatus } from "../pageConfig/category/utilities";

interface AppointmentCardProps {
  x: TodoListAppointment;
  t: TFunction<"translation">;
}

const AppointmentCard = ({ x, t }: AppointmentCardProps) => {
  const matchedRecurrency = x.recurrency && x.recurrency.match(/(?:FREQ=).*?(?=;)/)![0].replace("FREQ=", "");
  const legacyBaseUrl = useLegacyBaseUrl();
  const isMobile = useIsMobile();

  const onAppointmentClick = (appointmentId: number) => () => {
    const appointmentUrl = getAppointmentUrl(legacyBaseUrl, appointmentId);
    !isMobile ? window.open(appointmentUrl, "_blank") : (window.location.href = appointmentUrl);
  };

  return (
    <div className={`todo-cart ${x.id ? "todo-cart-pointer" : ""}`} onClick={onAppointmentClick(x.id)}>
      <div className="cart-content">
        <div className="cart-title">{x.title}</div>
        <div className="recurrency">
          {t("FieldAppointment")}
          {matchedRecurrency && (
            <>
              {" | "}
              <ArrowsIcon />
              {matchedRecurrency.charAt(0).toUpperCase() + matchedRecurrency.toLowerCase().slice(1)}
            </>
          )}
        </div>
        <div className="date-wrapper">
          <ClockIcon />
          <span className="date">
            {x.startDate && x.startDate + " - "} {x.endDate}
          </span>
        </div>
      </div>
      {x.cardStatus && (
        <div className="status">
          {x.cardStatus !== TodoListCardStatus.Open && t(x.cardStatus)}
          {renderCardStatus(x.cardStatus, t)}
        </div>
      )}
    </div>
  );
};

export default memo(AppointmentCard);
