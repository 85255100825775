import { Badge } from "antd";
import dayjs from "dayjs";
import React from "react";
import { employeeSelectors } from "../../../../selectors";
import {
  deleteNewAccountableEntryTemplate,
  fillNewAccountableEntryTemplate,
  setNotNeededCertificate,
  updateNewAccountableEntryRole,
} from "../../../../store/slices/employee";
import { addEntityPrivilege, updateEntityPrivilegeRole } from "../../../../store/slices/privileges";
import { Employee, EmployeeCertificate, TenantRole } from "../../../../types/employee";
import { OrgUnit } from "../../../../types/orgUnit";
import { ColumnType, ColumnWithDifferentCells, TOption } from "../../../../types/page";
import { CertificateTraineeStatus, PrivilegeData, PrivilegedEntityType, Role } from "../../../../types/utility";
import {
  AccountabilityOptions,
  authTypeOptions,
  boolOptions,
  employeeStatusOptions,
  getCellSearchResultColumns,
  twoFactorTypeOptions,
} from "../common";
import {
  fetchOrgUnitById,
  localizeText,
  renderBoolStatus,
  renderEmployeeStatus,
  renderTableStatus,
} from "../utilities";

const CertificationNeededOption = [{ id: 0, label: localizeText("CertificationRequired") }];
const CertificationNotNeededOption = [{ id: 1, label: localizeText("NoLongerRequired") }];

export const orgUnitsTableColumns: ColumnType<OrgUnit>[] = [
  {
    id: "id",
    label: "ID",
    renderValue: value => value.id.toString(),
    sortable: false,
    optionsSelector: () => null,
    // hidden: true,
  },
  {
    id: "name",
    label: "FieldName",
    renderValue: value => value.name,
    sortable: true,
    optionsSelector: () => null,
  },
  {
    id: "externalId",
    label: "ExternalID",
    renderValue: value => value.externalId,
    sortable: true,
    optionsSelector: () => null,
  },
  {
    id: "parentId",
    label: "ParentOU",
    renderValue: value => fetchOrgUnitById(value.parentId!) || "Root",
    optionsSelector: () => null,
    sortable: true,
  },
  {
    id: "status",
    label: "FieldStatus",
    optionsSelector: () => null,
    width: 100,
    renderValue: () => null, // Note: We render the value together with the icon when we want to show a tag
    valueIcon: value => {
      // TODO: Check if other colors need to be added
      return renderTableStatus(value.status);
    },
    sortable: true,
  },
];

export const employeesTableColumns: ColumnType<Employee>[] = [
  {
    id: "externalId",
    label: "ExternalID",
    renderValue: value => value.externalId,
    sortable: false,
    filterType: "multiSelect",
    valueType: "string",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "User",
    width: 100,
  },
  {
    id: "id",
    label: "ID",
    renderValue: value => value.id.toString(),
    sortable: true,
    optionsSelector: () => null,
    width: 30,
  },
  {
    id: "email",
    label: "TestEmail",
    renderValue: value => value.email,
    sortable: true,
    filterType: "multiSelect",
    valueType: "string",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "User",
    width: 100,
  },
  {
    id: "phone",
    label: "FieldPhone",
    renderValue: value => value.phone,
    sortable: true,
    filterType: "multiSelect",
    valueType: "string",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "User",
    width: 100,
  },
  {
    id: "displayName",
    label: "FieldLanguageDisplayName",
    renderValue: value => value.displayName,
    sortable: true,
    valueType: "string",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "User",
    filterType: "multiSelect",
  },
  {
    id: "position",
    label: "FieldPosition",
    renderValue: value => value.position?.toString(),
    sortable: true,
    filterType: "multiSelect",
    optionsSelector: employeeSelectors.uniquePositions,
    width: 100,
  },
  {
    id: "orgUnitId",
    label: "OrganizationalUnit",
    renderValue: value => value.orgUnitName,
    sortable: true,
    valueType: "string",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "OrgUnit",
    asyncFetchFilterOptionsUseKeyValue: true,
    propName: "name",
    filterType: "multiSelect",
  },
  {
    id: "locationId",
    label: "FieldLocation",
    renderValue: value => value.locationName,
    sortable: true,
    valueType: "string",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "Location",
    asyncFetchFilterOptionsUseKeyValue: true,
    propName: "name",
    filterType: "multiSelect",
  },
  {
    id: "expirationDate",
    label: "FieldExpirationDate",
    optionsSelector: () => null,
    renderValue: value => (value.expirationDate ? dayjs(value.expirationDate).format("DD/MM/YYYY") : null),
    valueIcon: value => {
      if (value.expirationDate) {
        const expDate = dayjs(value.expirationDate);
        const dayDiff = expDate.diff(dayjs(), "days");
        return <Badge color={dayDiff >= 30 ? "green" : "red"} />;
      }
    },
    sortable: true,
    filterType: "dateRange",
    width: 100,
  },
  {
    id: "isExternal",
    label: "External",
    renderValue: value => renderBoolStatus(value.isExternal),
    sortable: true,
    filterType: "boolean",
    optionsSelector: () =>
      boolOptions.map(l => ({
        id: l.id,
        label: localizeText(l.label),
      })),
    width: 100,
  },
  {
    id: "isUser",
    label: "User",
    renderValue: value => renderBoolStatus(value.isUser),
    sortable: true,
    filterType: "boolean",
    optionsSelector: () =>
      boolOptions.map(l => ({
        id: l.id,
        label: localizeText(l.label),
      })),
    width: 100,
  },
  {
    id: "status",
    label: "FieldStatus",
    renderValue: () => null, // label is rendered together with icon in renderTableStatus()
    valueIcon: (value: Employee): React.ReactNode | null => renderEmployeeStatus(value.status),
    sortable: true,
    optionsSelector: () =>
      employeeStatusOptions.map(l => ({
        id: l.id,
        label: localizeText(l.label),
        icon: l.icon,
      })),
    filterType: "multiSelect",
    width: 100,
  },
  {
    id: "username",
    label: "FieldUserName",
    renderValue: value => value.username,
    sortable: true,
    filterType: "multiSelect",
    valueType: "string",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "User",
    width: 100,
  },
  {
    id: "tenantRole",
    label: "TenantUserRole",
    renderValue: value => localizeText(value.tenantRole),
    sortable: true,
    filterType: "multiSelect",
    valueType: "string",
    optionsSelector: () => tenantRoleOptions.map(role => ({ ...role, label: localizeText(role.label) })),
    width: 100,
  },
  {
    id: "authType",
    label: "FieldAuthType",
    renderValue: e =>
      authTypeOptions.find(o => o.id === e.authType)
        ? localizeText(authTypeOptions.filter(o => o.id === e.authType)[0].label)
        : "",
    sortable: true,
    filterType: "multiSelect",
    valueType: "string",
    optionsSelector: () => authTypeOptions.map(a => ({ ...a, label: localizeText(a.label) })),
    width: 100,
  },
  {
    id: "twoFactorType",
    label: "FieldTwoFactorType",
    renderValue: e =>
      twoFactorTypeOptions.find(o => o.id === e.twoFactorType)
        ? localizeText(twoFactorTypeOptions.filter(o => o.id === e.twoFactorType)[0].label)
        : "",
    sortable: true,
    filterType: "multiSelect",
    valueType: "string",
    optionsSelector: () => twoFactorTypeOptions.map(a => ({ ...a, label: localizeText(a.label) })),
    width: 100,
  },
];

export const employeesTabColumns: ColumnWithDifferentCells<PrivilegeData>[] = [
  {
    id: "name",
    label: "FieldName",
    renderValue: value => value.name || "",
    sortable: true,
    filterType: "multiSelect",
    cellType: e => (e.staging ? "search" : "text"),
    cellSearchSelector: employeeSelectors.allEmployeeRelations,
    cellSearchResultColumns: getCellSearchResultColumns(["name", "externalId"]),
    cellSearchKeys: ["name"],
    cellSearchRecordClick: fillNewAccountableEntryTemplate,
    optionsSelector: () => null,
    width: 100,
    primaryColumn: e => ({
      navigationTarget: e.userId ? `/employee/${e.userId}` : `/orgUnit/${e.orgUnitId}`,
    }),
  },
  {
    id: "externalId",
    label: "ExternalID",
    renderValue: value => value.externalId || "",
    sortable: true,
    filterType: "multiSelect",
    cellType: e => (e.staging ? "search" : "text"),
    cellSearchSelector: employeeSelectors.allEmployeeRelations,
    cellSearchResultColumns: getCellSearchResultColumns(["name", "externalId"]),
    cellSearchKeys: ["externalId"],
    cellSearchRecordClick: fillNewAccountableEntryTemplate,
    optionsSelector: employeeSelectors.allEmployeeExternalIDs,
    width: 100,
  },
  {
    id: "status",
    label: "FieldStatus",
    renderValue: value => (value.status ? localizeText(value.status) : ""),
    sortable: true,
    optionsSelector: () => null,
    width: 100,
  },
  {
    id: "isExternal",
    label: "External",
    renderValue: value => (value.isExternal !== null ? renderBoolStatus(value.isExternal) : ""),
    sortable: true,
    optionsSelector: () => null,
    width: 100,
  },
  {
    id: "role",
    label: "Accountability",
    renderValue: e => (e.role ? localizeText(e.role.toString()) : ""),
    cellType: () => "dropdown",
    cellDropdownOptions: () => AccountabilityOptions,
    cellDropdownOnChange: (e, newValue) =>
      e.staging
        ? updateNewAccountableEntryRole({ entity: e, newValue: newValue as Role })
        : updateEntityPrivilegeRole({
            entity: e,
            role: newValue as Role,
            id: null,
            entityType: PrivilegedEntityType.Employee,
          }),
    sortable: true,
    optionsSelector: () => null,
    width: 100,
  },
  {
    id: "action" as keyof PrivilegeData,
    label: "FieldAction",
    renderValue: () => null,
    sortable: true,
    optionsSelector: () => null,
    cellType: () => "buttonActions",
    width: 100,
    stagingActions: [
      {
        label: "Add",
        className: "hb-primary-button",
        action: entity => addEntityPrivilege({ entity: entity, entityType: PrivilegedEntityType.Employee, id: null }),
      },
      {
        label: "Cancel",
        className: "hb-tertiary-button",
        action: entity => deleteNewAccountableEntryTemplate(entity),
      },
    ],
    // buttonWithDropdownActions: {
    //   primaryButton: {
    //     label: "Action Button",
    //     action: entity => console.log("clicked primary button"),
    //   },
    //   dropdownButtons: [
    //     {
    //       label: "Delete Relation",
    //       className: "hb-primary-button",
    //       action: entity => deleteEmployeeRelation(entity), // TODO: Implement a thunk for deleting a LocationOrgUnit relation which would be based on 'staging'
    //       changesConfirmation: {
    //         body: "Are you sure you wish to remove this entity?",
    //         okText: "Remove",
    //         cancelText: "Keep",
    //         addValueToBody: false,
    //         addValueToOkText: false,
    //       },
    //     },
    //   ],
    // },
  },
];

export const certificationsTableColumns: ColumnWithDifferentCells<EmployeeCertificate>[] = [
  {
    id: "certificateName",
    label: "FieldCertificate",
    renderValue: value => value.certificateName,
    sortable: true,
    optionsSelector: () => null,
    // hidden: true,
    primaryColumn: e => ({
      navigationTarget: `/certification/${e.certificateId}`,
    }),
  },
  {
    id: "certificateExpirationDate",
    label: "FieldExpirationDate",
    renderValue: value =>
      value.certificateExpirationDate ? dayjs(value.certificateExpirationDate).format("DD/MM/YYYY") : null,
    valueIcon: value => {
      if (value.certificateExpirationDate) {
        const expDate = dayjs(value.certificateExpirationDate);
        const dayDiff = expDate.diff(dayjs(), "days");
        return <Badge color={dayDiff >= 30 ? "green" : "red"} />;
      }
    },
    sortable: true,
    optionsSelector: () => null,
  },
  {
    id: "nextTrainingSessionDate",
    label: "FieldNextTrainingSession",
    renderValue: value =>
      value.nextTrainingSessionDate
        ? dayjs(value.nextTrainingSessionDate).format("DD/MM/YYYY")
        : localizeText("Undefined"),
    valueIcon: value => {
      if (value.nextTrainingSessionDate) {
        const expDate = dayjs(value.nextTrainingSessionDate);
        const dayDiff = expDate.diff(dayjs(), "days");
        return <Badge color={dayDiff >= 30 ? "green" : "red"} />;
      }
    },
    sortable: true,
    optionsSelector: () => null,
  },
  {
    id: "status",
    label: "FieldStatus",
    optionsSelector: () => null,
    cellType: () => "dropdown",
    cellDropdownOptions: e =>
      e.status === CertificateTraineeStatus.IsNoLongerNeeded ? CertificationNeededOption : CertificationNotNeededOption,
    cellDropdownOnChange: (e, newValue) => setNotNeededCertificate({ entity: e, newValue: !!newValue }),
    renderValue: e =>
      e.status === CertificateTraineeStatus.IsNoLongerNeeded
        ? localizeText("NoLongerRequired")
        : localizeText(e.status),
    sortable: true,
  },
];

export const tenantRoleOptions: TOption[] = [
  {
    id: TenantRole.User,
    label: TenantRole.User,
  },
  {
    id: TenantRole.Admin,
    label: TenantRole.Admin,
  },
];
