import { Radio, RadioChangeEvent } from "antd";
import { useTranslation } from "react-i18next";
import { OrdersOptions } from "./OrderDimensions";

type OrderDimensionItemProps = {
  item: OrdersOptions;
  onOrderChanged: () => void;
};

export default function OrderDimensionItem({ item, onOrderChanged }: OrderDimensionItemProps) {
  const { t } = useTranslation();
  const onRadioChange: (e: RadioChangeEvent) => void = e => {
    item.order = e.target.value;
    onOrderChanged();
  };
  return (
    <div className="dimension-order-item-container">
      <div className="dimension-order-item-options-group">
        {item.dimension ? t(item.dimension, item.title) : item.title}
      </div>
      <Radio.Group
        onChange={onRadioChange}
        value={item.order === "none" ? undefined : item.order}
        defaultValue={undefined}
      >
        <Radio.Button value="asc">{t("DashboardOrderByAsc")}</Radio.Button>
        <Radio.Button value="desc">{t("DashboardOrderByDesc")}</Radio.Button>
        <Radio.Button value={undefined}>{t("DashboardOrderByNone")}</Radio.Button>
      </Radio.Group>
    </div>
  );
}
