import { Button, Tooltip } from "antd";
import { forwardRef } from "react";
import { useSelector } from "react-redux";
import tinycolor from "tinycolor2";
import { RootState } from "../../store/store";
import { AvailableComapny } from "../../types/user";

export type CompanyMenuItemProps = {
  company: AvailableComapny;
  onChangeCompany: (companyId: number) => void;
};
const CompanyMenuItem = forwardRef<HTMLElement, CompanyMenuItemProps>(({ company, onChangeCompany }, ref) => {
  const { settings, companySettings } = useSelector((state: RootState) => state.user);

  const getSelectedStyle = () => {
    return { background: tinycolor(companySettings.colors.mainColor).setAlpha(0.07).toRgbString() };
  };

  const isActiveCompany: (company: AvailableComapny) => boolean = company => company.id === settings.companyId;

  return (
    <Button
      className={isActiveCompany(company) ? "selected-company-radio-button" : "company-radio-button"}
      onClick={() => onChangeCompany(company.id)}
      key={company.id}
      ref={ref}
      value={company.id}
      style={company.id === settings.companyId ? getSelectedStyle() : {}}
    >
      <Tooltip title={company.name} placement={settings.direction === "ltr" ? "right" : "left"}>
        <div className="company-button-text">{company.name}</div>
      </Tooltip>
    </Button>
  );
});

export default CompanyMenuItem;
