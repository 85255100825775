import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { ApiError } from "../types/apiError";

export const api = axios.create({
  // TODO: FIX API
  baseURL: "https://602b973eef26b40017f1471d.mockapi.io/api/v0",
  headers: {
    "Content-Type": "application/json",
  },
});

export const hbApiOptions = (
  token: string,
  additionalData?: any,
  additionalHeaders?: any,
  additionalParams?: any
): AxiosRequestConfig => ({
  headers: {
    Authorization: `Bearer ${token}`,
    ...additionalHeaders,
  },
  params: additionalParams,
  data: additionalData ? additionalData : undefined,
});

export const getApiUrl = () => {
  return process.env.REACT_APP_API_URL;
};

export const createAxiosWithApiErrorHandler = (): AxiosInstance => {
  const a = axios.create({
    baseURL: getApiUrl(),
    headers: {
      "Content-Type": "application/json",
    },
  });

  a.interceptors.response.use(
    response => response,
    error => {
      if (error.response.data) {
        const errorData = error.response.data;
        const apiError: ApiError = {
          ...errorData,
          message: errorData?.message || errorData?.Message,
          status: error.response.status,
        };
        if (apiError) {
          throw apiError;
        }
      }
      throw error;
    }
  );

  return a;
};

export const hbApi = createAxiosWithApiErrorHandler();
