import { ExportAction, ColumnManagementAction, GetFiltersUrl } from "../../../actions/menuActions";
import { ReactComponent as Logo } from "../../../media/eq-list-icon.svg";
import { equipmentTypeSelectors, pageSelectors } from "../../../selectors";
import {
  addEquipmentType,
  clearEquipmentTypeError,
  createEquipmentTypeTemplate,
  createNewEqTypeInspTypeRelationEntryTemplate,
  deleteInspectionTypeEquipmentTypeRelation,
  fetchEquipmentTypes,
  fetchSingleEquipmentType,
  updateEquipmentType,
} from "../../../store/slices/equipmentType";
import { adminOrSuUserOnly } from "../../../store/slices/privileges";
import { EquipmentTypePage, EquipmentType, EqTypeInspTypeRelation } from "../../../types/equipmentType";
import { CategoryPage } from "../../../types/page";
import { UserRole } from "../../../types/user";
import { TableAddButtonType } from "../../../types/utility";
import { boolOptions } from "./common";
import { eqTypeInspTypeRelationTableColumns, equipmentTypeTableColumns } from "./equipmentTypeConfigPageData";
import { localizeText, prepareDynamicColumns, removeColumnFilters } from "./utilities";

const equipmentTypeConfigPage = (): CategoryPage<EquipmentTypePage> => ({
  id: "equipmentType",
  title: "PluginTypeName",
  entityEndpoint: "/EquipmentType",
  PageIcon: Logo,
  hasGroupView: false,
  hasListView: true,
  primaryErrorSelector: equipmentTypeSelectors.error,
  clearError: clearEquipmentTypeError,
  createNewEntityTemplate: createEquipmentTypeTemplate,
  createNewEntity: addEquipmentType,
  updateEntity: updateEquipmentType,
  breadcrumbSingleValue: e => {
    return { id: e.id?.toString(), label: e.name };
  },
  fetchSingle: fetchSingleEquipmentType,
  intializeDataActions: [fetchEquipmentTypes],
  primarySingleEntitySelector: equipmentTypeSelectors.single,
  customPropertiesSelector: equipmentTypeSelectors.equipmentTypeCustomProperties,
  isLoading: pageSelectors.isEquipmentTypePageLoading,
  lastUpdatedSelector: equipmentTypeSelectors.equipmentTypeLastUpdatedText,
  addButton: {
    label: "AddType",
    action: () => console.log("Create new Equipment Type"),
  },
  listView: {
    table: {
      tableSelector: equipmentTypeSelectors.all,
      fetchListViewData: fetchEquipmentTypes,
      columns: equipmentTypeTableColumns,
      rowSelection: true,
      defaultColumnKeys: ["id", "name", "createTaskOnBrokenStatus", "createTaskOnInactiveStatus"],
      type: "table",
    },
    generateDynamicColumns: (props, data) => prepareDynamicColumns<EquipmentType>(props, data),
  },
  summaryCard: {
    primaryFields: [
      {
        id: "name",
        title: "FieldName",
        value: e => e.name,
        type: "input",
        required: true,
        placeholder: "Enter",
        optionsSelector: () => null,
      },
    ],
    secondaryFields: [],
  },
  tabsPanel: [
    {
      key: "inspectionType",
      label: "PluginInspectionName",
      tabSelector: equipmentTypeSelectors.eqTypeInspTypeRelationSelector,
      columns: removeColumnFilters(eqTypeInspTypeRelationTableColumns) as [],
      rowSelection: true,
      type: "table",
      addButton: {
        action: createNewEqTypeInspTypeRelationEntryTemplate,
        label: "AddEqTypeInspType",
        type: TableAddButtonType.AddInline,
      },
      generateDynamicColumns: (props, data) => [],
      customPropertiesSelector: pageSelectors.nullableCustomPropertiesSelector,
      dropdownButtonActions: [
        //NOTE: in future it will be added again, for now it removed per Siso's request
        // {
        //   label: "Delete",
        //   action: entities => deleteInspectionTypeEquipmentTypeRelation(entities as EqTypeInspTypeRelation[]),
        //   privilege: adminOrSuUserOnly,
        //   type: "bulk",
        //   changesConfirmation: {
        //     body: "ConfirmEntitiesRemoval",
        //     okText: "Remove",
        //     cancelText: "Keep",
        //     addValueToBody: false,
        //     addValueToOkText: false,
        //   },
        // },
      ],
    },
  ],
  listViewActions: [ExportAction, ColumnManagementAction, GetFiltersUrl],
  allowedUserRoles: [UserRole.SU, UserRole.Admin, UserRole.TenantAdmin],
});

export default equipmentTypeConfigPage;
