import { useContext } from "react";
import { useTranslation } from "react-i18next";
import PageConfigurationContext from "../../../context/pageContext";
import { BaseEntityType } from "../../../types/entityBase";
import { BaseField, CategoryPage } from "../../../types/page";
import EditableInputMobileFirst from "../../EditableInput/EditableInputMobileFirst";
import { SingleViewContext } from "../SingleViewCardMobileFirst";

enum InputTypes {
  TITLE = "title",
  ID = "id",
  STATUS = "status",
  MAIN_CONTEXT_RELATION = "mainContextRelation",
  ADDITIONAL_MAIN_CONTEXT_RELATION = "additionalContextRelation",
  DATE = "date",
}

export const ICON_TYPE_KEY = "iconType";

export interface Input {
  type: string;
  id: string;
  className?: string;
  tag?: string;
  isOptional?: boolean;
}

export interface Group {
  className: string;
  inputs: Input[];
}

const inputs: Record<string, Input> = {
  title: { type: InputTypes.TITLE, id: InputTypes.TITLE, tag: "h1" },
  id: { type: InputTypes.ID, id: InputTypes.ID },
  status: { type: InputTypes.STATUS, id: InputTypes.STATUS },
  context: { type: InputTypes.MAIN_CONTEXT_RELATION, id: "context", className: "role", isOptional: true },
  additionalContext: {
    type: InputTypes.ADDITIONAL_MAIN_CONTEXT_RELATION,
    id: "additionalContext",
    className: "role",
    isOptional: true,
  },
  expiry: { type: InputTypes.DATE, id: "expiry", isOptional: true },
};

const groups: Group[] = [
  { className: "name-id", inputs: [inputs.title, inputs.id] },
  { className: "status-role", inputs: [inputs.status, inputs.context, inputs.additionalContext] },
  { className: "date", inputs: [inputs.expiry] },
];

const DetailsComponent: React.FC<{ isNewEntity?: boolean }> = () => {
  const { t } = useTranslation();
  const { title, mobileFirstTitle, summaryCard } = useContext(PageConfigurationContext) as CategoryPage<BaseEntityType>;
  const context = useContext(SingleViewContext);

  if (!context) {
    throw new Error("DetailsComponent must be used within a SingleViewContext.Provider");
  }

  const {
    isEdited,
    handleSetActiveTab,
    isNewEntity,
    data,
    isGlobalEditForbidden,
    canEditCard,
    renderValue,
    handleChange,
    currentData,
  } = context;

  const renderEditableInputMobileFirst = (
    input: BaseField<Record<string, unknown>> | null | undefined,
    className: string,
    showTitle?: boolean
  ) => {
    if (!input || (data && input.isHiddenOnMobile?.(data))) {
      return null;
    }

    return (
      <EditableInputMobileFirst
        dataFetchAction={input.dataFetchAction}
        onChange={handleChange(input.id, input.changesConfirmation)}
        className={className}
        currentData={currentData}
        selector={input.selector}
        basedOnDependensiesOptions={input.basedOnDependensiesOptions}
        localizedValue={input.localizedValue && (renderValue(input.localizedValue) as string | null)}
        fieldValue={renderValue(input.value) as string | null}
        fieldText={input.valueText && (renderValue(input.valueText) as string | null)}
        globalEdit={isEdited}
        placeholder={input.placeholderMobileFirst && t(input.placeholderMobileFirst)}
        type={input.type}
        multilineInput={input.multilineInput}
        id={input.id}
        title={(showTitle && input.title && t(input.title)) || ""}
        options={input.options}
        optionSelector={input.optionsSelector}
        handleSetActiveTab={handleSetActiveTab}
        tabSwitch={input.tabSwitchAction?.action}
        treeData={input.treeData}
        isNewEntity={isNewEntity}
        editForbidden={(data && input.editForbidden && input.editForbidden(data)) || isGlobalEditForbidden}
        disabledDate={input.disabledDate && input.disabledDate(data as Record<string, unknown> | undefined)}
        searchById={input.searchById}
        canEdit={canEditCard}
      >
        {input.title && t(input.title)}
      </EditableInputMobileFirst>
    );
  };

  return (
    <div className="details">
      <span className="title">{t(mobileFirstTitle || title)}</span>

      {isNewEntity ? (
        <h1 className="create-title">{t("CreateNew")}</h1>
      ) : (
        <>
          {groups.map(({ className, inputs }) => (
            <div key={className} className={className}>
              {inputs.map(({ type, id, tag, className, isOptional }) => {
                const InputElement = tag === "h1" ? "h1" : "span";
                return (
                  <InputElement key={id} id={id} className={className}>
                    {renderEditableInputMobileFirst(summaryCard.mobileSingleView?.summary[type], id, isOptional)}
                  </InputElement>
                );
              })}
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default DetailsComponent;
