import { createAsyncThunk, createSlice, nanoid } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import unionBy from "lodash/unionBy";
import { CustomError } from "../../pages/pageConfig/category/utilities";
import {
  CertificatePaginatedData,
  Certification,
  CertificationState,
  EmployeeCertificateListView,
  GroupViewCertification,
} from "../../types/certification";
import { Inspection } from "../../types/equipment";
import { File, FileUploadResponse } from "../../types/files";
import { Training } from "../../types/training";
import {
  ExplicitAdditionalProps,
  HistoryLog,
  PrivilegeData,
  Role,
  Status,
  UpdateCustomProps,
} from "../../types/utility";
import { generateFiltersAndEmptyProps, generateGridifySorterQuery } from "../../utils/gridifyQueryHelper";
import { hbApi, hbApiOptions } from "../api";
import { RootState } from "../store";
import { shouldUpdate } from "./common";
import { fetchHistoryLog } from "./commonThunks";
import {
  addEntityPrivilege,
  createNewPrivilegeTemplate,
  deleteEntityPrivilege,
  deleteMultipleEntityPrivileges,
  deleteNewPrivilegeEntryTemplate,
  fillNewPrivilegeEntryTemplate,
  getEntityPrivileges,
  updateEntityPrivilegeRole,
  updateNewPrivilegeEntryRole,
} from "./privileges";

export const initialState: CertificationState = {
  // data: [],
  basicData: [],
  groupViewData: [],
  listViewData: [],
  isLoading: false,
  singleData: null,
  subData: {
    inspections: [],
    historyLog: [],
    accountable: [],
    trainings: [],
    files: [],
    userCertifications: [],
  },
  error: null,
  defaultCustomProperties: [],
  lastUpdated: dayjs().toISOString(),
  searchResults: [],
  paginationInfo: {
    count: 0,
    currentPage: 0,
  },
};

export const newCertification: Certification = {
  id: 0,
  name: "",
  externalId: null,
  isManual: false,
  duration: 0,
  aboutToExpireDays: 60,
  supplier: "",
  ownerUserId: null,
  status: Status.Active,
  iconType: "10",
};

// Note: We actually expect the response to be of type 'GroupViewCertification' but we cast it in the reducer.
export const addCertification = createAsyncThunk<
  Certification | GroupViewCertification,
  { entity: Certification },
  { state: RootState }
>(
  "@@CERTIFICATE/ADD",
  async ({ entity }, { rejectWithValue, getState }) => {
    const { user } = getState();

    // Note: We need to modify the request body for the custom props, before sending it to the server
    const modifiedPropertyValues: UpdateCustomProps = {};
    entity.customPropertyValues?.forEach(prop => {
      modifiedPropertyValues[prop.propertyId] = prop.value;
    });

    try {
      const modifiedEntity = { ...entity, customPropertyValues: modifiedPropertyValues };
      const response = await hbApi.post<Certification>("/Certificate", modifiedEntity, hbApiOptions(user.jwt));
      return response.data;
    } catch (e) {
      return rejectWithValue(e.errors);
    }
  },
  {
    condition: (_, { getState }) => {
      const { certification } = getState();
      return !certification.isLoading;
    },
  }
);

// Note: We actually expect the response to be of type 'GroupViewCertification' but we cast it in the reducer.
export const updateCertification = createAsyncThunk<
  Certification | GroupViewCertification,
  Certification,
  { state: RootState }
>(
  "@@CERTIFICATION/UPDATE",
  async (entity, { getState, rejectWithValue }) => {
    const { user } = getState();

    // Note: We need to modify the request body for the custom props, before sending it to the server
    // const modifiedPropertyValues: UpdateCustomProps = {};
    // entity.customPropertyValues?.forEach(prop => {
    //   modifiedPropertyValues[prop.propertyId] = prop.value;
    // });

    // const modifiedEntity = { ...entity, customPropertyValues: modifiedPropertyValues };

    try {
      const response = await hbApi.put<Certification>(`/Certificate/${entity.id}`, entity, hbApiOptions(user.jwt));
      return response.data;
    } catch (e) {
      return rejectWithValue(e.errors);
    }
  },
  {
    condition: (_, { getState }) => {
      const { certification, user } = getState();
      return !certification.isLoading && !!user.jwt;
    },
  }
);

export const fetchFullCertifications = createAsyncThunk<
  { basicData: Certification[]; userCertifications: Certification[] },
  void,
  { state: RootState }
>("@@CERTIFICATION/FETCH_BASIC", async (_, { getState }) => {
  const { user } = getState();
  const certifications = await hbApi.get<Certification[]>("/Certificate/full", hbApiOptions(user.jwt));
  const userCertifications = await hbApi.get<Certification[]>("/Certificate/userOwned", hbApiOptions(user.jwt));

  return {
    basicData: certifications.data,
    userCertifications: userCertifications.data,
  };
});

export const fetchCertificateCards = createAsyncThunk<
  GroupViewCertification[],
  boolean | undefined,
  { state: RootState }
>("@@CERTIFICATION_CARDS/FETCH", async (_, { getState }) => {
  const { user } = getState();
  const certificateCards = await hbApi.get<GroupViewCertification[]>(
    "/Certificate/CertificateCardView",
    hbApiOptions(user.jwt)
  );

  return certificateCards.data;
});

export const fetchCertificateTrainings = createAsyncThunk<Partial<Training>[], number, { state: RootState }>(
  "@@CERTIFICATE/FETCH_TRAININGS",
  async (id, { getState }) => {
    const { user } = getState();

    const response = await hbApi.get<Training[]>(`/Training?CertificateId=${id}`, hbApiOptions(user.jwt));
    return response.data;
  }
  // {
  //   condition: (_, { getState }) => {
  //     const { employee } = getState();
  //     return !employee.isLoading;
  //   },
  // }
);

export const removeFiles = createAsyncThunk<number[], number[], { state: RootState }>(
  "@@CERTIFICATION/REMOVE_FILE",
  async (ids, { getState }) => {
    const { user } = getState();
    await hbApi.delete<File[]>("/File", hbApiOptions(user.jwt, ids));

    return ids;
  }
);

export const uploadFile = createAsyncThunk<File[] | null, { file: any; expiration: string }, { state: RootState }>(
  "@@CERTIFICATION/UPLOAD_FILE",
  async ({ file, expiration }, { getState }) => {
    const { user, certification } = getState();
    const formData = new FormData();
    formData.append("files[]", file);

    try {
      const fileEntity = await hbApi.post<FileUploadResponse>(
        `/File/upload?entityType=Certification&entityId=${
          certification?.singleData?.id || 0
        }&expirationDate=${expiration}`,
        formData,
        hbApiOptions(user.jwt)
      );

      return fileEntity.data?.data;
    } catch (e) {
      throw new CustomError(e.message);
    }
  }
);

export const fetchSingleCertification = createAsyncThunk<
  {
    singleData: Certification;
    subData: { historyLog: HistoryLog[]; inspections: Inspection[]; files: File[] };
    defaultCustomProperties: ExplicitAdditionalProps[];
  },
  string,
  { state: RootState }
>("@@SINGLE_CERTIFICATION/FETCH", async (id, { getState, rejectWithValue }) => {
  const { user } = getState();
  try {
    const cardDataResponse = await hbApi.get<Certification>(`/Certificate/${id}`, hbApiOptions(user.jwt));
    // const historyLogResponse = await hbApi.get<HistoryLog[]>("/changeme");
    // const inspectionsResponse = await hbApi.get<Inspection[]>("/inspections");
    const filesResponse = await hbApi.get<File[]>(
      `/File?entityType=Certification&entityId=${id}`,
      hbApiOptions(user.jwt)
    );

    return {
      singleData: cardDataResponse.data,
      subData: {
        inspections: [],
        historyLog: [],
        files: filesResponse.data,
      },
      defaultCustomProperties: [],
    };
  } catch (e) {
    if (e.status === 400 || e.status === 403 || e.status === 404) {
      return rejectWithValue(e.data);
    }
    throw new CustomError(e.message || e.Message);
  }
});

// export const patchLocationId = createAsyncThunk<Employee, { objectId: number; newValue: number }, { state: RootState }>(
//   "@@EMPLOYEE/PATCH_LOCATION_ID",
//   async ({ objectId, newValue }, { getState }) => {
//     const { user } = getState();

//     const response = await hbApi.patch<Employee>(
//       `/Employee/${objectId}`,
//       [{ path: "/locationId", op: "replace", value: newValue }],
//       hbApiOptions(user.jwt)
//     );
//     return response.data;
//   },
//   {
//     condition: (_, { getState }) => {
//       const { location, user } = getState();
//       return !location.isLoading && !!user.jwt;
//     },
//   }
// );

export const fetchCertificationCustomProps = createAsyncThunk<ExplicitAdditionalProps[], void, { state: RootState }>(
  "@@CERTIFICATION_CUSTOM_PROPS/FETCH",
  async (_, { getState }) => {
    const { user } = getState();
    const response = await hbApi.get<ExplicitAdditionalProps[]>("/CertificateCustomProperty", hbApiOptions(user.jwt));
    return response.data;
  },
  {
    condition: (_, { getState }) => {
      const { certification } = getState();
      return !certification.isLoading;
    },
  }
);

export const fetchPaginatedCertificate = createAsyncThunk<
  {
    primaryData: EmployeeCertificateListView[];
    defaultCustomProperties: ExplicitAdditionalProps[];
    possibleResults: number;
  },
  { page?: number; pageSize?: number; forceUpdate?: boolean } | undefined,
  { state: RootState }
>(
  "@@CERTIFICATION/FETCH_PAGINATED",
  async (params, { getState }) => {
    const { user, certification, filter } = getState();

    const certificationFilters = filter.filters.certification?.certification?.activeFilters;

    const { filters, emptyPropIds } = generateFiltersAndEmptyProps(certificationFilters);

    const orders = filter.filterValues.certification?.order
      ? generateGridifySorterQuery(filter.filterValues.certification?.order)
      : undefined;

    const response = await hbApi.post<CertificatePaginatedData>(
      "/EmployeeCertificate/paged-list",
      {
        Page: params?.page || certification.paginationInfo.currentPage + 1,
        PageSize: params?.pageSize,
        Filter: filters,
        OrderBy: orders,
      },
      hbApiOptions(user.jwt, null, null, {
        emptyPropIds: emptyPropIds,
      })
    );
    return {
      primaryData: response.data.data.map(x => ({ ...x, id: nanoid() })),
      defaultCustomProperties: [],
      possibleResults: response.data.count,
    };
  },
  {
    condition: (arg, { getState }) => {
      const { certification } = getState();

      return (
        arg?.forceUpdate ||
        (dayjs(certification.lastUpdated).isBefore(dayjs()) &&
          !certification.isLoading &&
          certification.listViewData.length !== certification.paginationInfo.count)
      );
    },
  }
);

export const searchCertification = createAsyncThunk<
  EmployeeCertificateListView[],
  { filters?: string; page?: number } | undefined,
  { state: RootState }
>(
  "@@CERTIFICATION/SEARCH_PAGINATED",
  async (params, { getState }) => {
    const { user } = getState();
    const response = await hbApi.post<CertificatePaginatedData>(
      "/EmployeeCertificate/paged-list",
      {
        Page: params?.page || 1,
        PageSize: 100,
        Filter: params?.filters,
      },
      hbApiOptions(user.jwt)
    );
    return response.data.data.map(x => ({ ...x, id: nanoid() }));
  },
  {
    condition: (_, { getState }) => {
      const { certification } = getState();
      return !certification.isLoading;
    },
  }
);

const slice = createSlice({
  name: "Certificate",
  initialState,
  // Note: User reducers only for synchronous logic
  reducers: {
    resetCurrentPage: state => {
      state.paginationInfo.currentPage = 0;
    },
    createCertificationTemplate: state => {
      //   const customProps = state.defaultCustomProperties;
      state.singleData = {
        // customPropertyValues: customProps.map(prop => ({
        //   name: prop.name,
        //   propertyId: prop.id,
        //   type: prop.type,
        //   value: prop.defaultValue,
        // })),
        ...newCertification,
      };
      state.subData.historyLog = [];
      state.subData.inspections = [];
      state.subData.accountable = [];
    },
    createNewAccountableEntryTemplate: createNewPrivilegeTemplate,
    fillNewAccountableEntryTemplate: {
      prepare: (payload: { row: PrivilegeData; targetEntity: Record<string, unknown> }) => ({ payload }),
      reducer: fillNewPrivilegeEntryTemplate,
    },
    updateNewAccountableEntryRole: {
      prepare: (payload: { entity: PrivilegeData; newValue: Role }) => ({ payload }),
      reducer: updateNewPrivilegeEntryRole,
    },
    deleteNewAccountableEntryTemplate: {
      prepare: (payload: PrivilegeData) => ({ payload }),
      reducer: deleteNewPrivilegeEntryTemplate,
    },
    clearCertificationError: state => {
      state.error = null;
    },
    resetSearchResults: state => {
      state.searchResults = [];
    },
  },
  // Note: User reducers only for asynchronous logic with AsyncThunk
  extraReducers: builder => {
    builder
      // Note - Pending:
      .addCase(fetchFullCertifications.pending, (state, { payload }) => {
        state.isLoading = true;
        state.basicData = [];
        state.error = null;
      })
      .addCase(uploadFile.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(removeFiles.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSingleCertification.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addCertification.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateCertification.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchCertificateCards.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getEntityPrivileges.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchPaginatedCertificate.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(searchCertification.pending, state => {
        state.error = null;
      })
      // Note - Rejected:

      .addCase(fetchFullCertifications.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(fetchSingleCertification.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(addCertification.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(updateCertification.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(fetchCertificateCards.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })

      .addCase(uploadFile.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(removeFiles.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(fetchHistoryLog.rejected, (state, action) => {
        state.error = action.error.message || null;
      })
      .addCase(fetchCertificateTrainings.rejected, (state, action) => {
        state.error = action.error.message || null;
      })
      .addCase(getEntityPrivileges.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(fetchPaginatedCertificate.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(searchCertification.rejected, (state, action) => {
        state.error = action.error.message || null;
      })
      // Patch Location ID
      //   .addCase(patchLocationId.rejected, (state, action) => {
      //     state.isLoading = false;
      //     state.error = action.error.message || null;
      //   })
      // Note - Fulfilled:
      .addCase(fetchFullCertifications.fulfilled, (state, { payload }) => {
        state.basicData = payload.basicData;
        state.subData.userCertifications = payload.userCertifications;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchPaginatedCertificate.fulfilled, (state, action) => {
        if (action.meta.arg?.page === 1) {
          state.listViewData = action.payload.primaryData;
          state.paginationInfo.currentPage = 1;
        } else {
          state.listViewData = unionBy(state.listViewData, action.payload.primaryData, "id");
          state.paginationInfo.currentPage = state.paginationInfo.currentPage + 1;
        }
        state.paginationInfo.count = action.payload.possibleResults;
        state.defaultCustomProperties = action.payload.defaultCustomProperties;
        state.error = null;
        state.lastUpdated = dayjs().toISOString();
        state.isLoading = false;
      })
      .addCase(searchCertification.fulfilled, (state, action) => {
        state.searchResults = [...action.payload];
        state.error = null;
      })
      .addCase(fetchCertificationCustomProps.fulfilled, (state, action) => {
        state.defaultCustomProperties = action.payload;
        // state.defaultCustomProperties = action.payload.defaultCustomProperties;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchCertificateCards.fulfilled, (state, action) => {
        state.groupViewData = action.payload;
        // state.defaultCustomProperties = action.payload.defaultCustomProperties;
        state.lastUpdated = dayjs().toISOString();
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchSingleCertification.fulfilled, (state, action) => {
        state.singleData = action.payload.singleData;
        state.subData.historyLog = action.payload.subData.historyLog;
        state.subData.inspections = action.payload.subData.inspections;
        state.subData.files = action.payload.subData.files;
        state.defaultCustomProperties = action.payload.defaultCustomProperties;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(addCertification.fulfilled, (state, action) => {
        state.singleData = action.payload;
        // state.data.push(action.payload);
        state.groupViewData.push(action.payload as GroupViewCertification); // Note: Temporary casting not to break the page.ts configuration
        state.basicData = state.basicData ? [...state.basicData, action.payload] : [action.payload];
        state.isLoading = false;
        state.error = null;
      })
      .addCase(uploadFile.fulfilled, (state, action) => {
        const filesArray = [...state.subData.files];

        if (action.payload && action.payload.length) filesArray.unshift(action.payload[0]);
        state.subData.files = filesArray;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(removeFiles.fulfilled, (state, action) => {
        state.subData.files = state.subData.files.filter(x => !action.payload.includes(x.id));
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateCertification.fulfilled, (state, action) => {
        state.singleData = action.payload;
        const updatedEntityIndex = state.groupViewData.findIndex(employee => employee.id === action.payload.id);
        state.groupViewData[updatedEntityIndex] = action.payload as GroupViewCertification; // Note: Temporary casting not to break the page.ts configuration
        state.isLoading = false;
        state.error = null;
      })
      // Get CertificateEmployees and CertificateOrgUnits relations
      .addCase(getEntityPrivileges.fulfilled, (state, action) => {
        state.subData.accountable = action.payload;
        state.isLoading = false;
        state.error = null;
      })

      // Add CertificateEmployee
      .addCase(addEntityPrivilege.fulfilled, (state, action) => {
        state.subData.accountable = state.subData.accountable.filter(r => r.id !== action.payload.id);
        state.subData.accountable.unshift({
          ...action.payload,
          id: action.payload.id!,
          staging: false,
        });
        // state.isLoading = false;
        state.error = null;
      })

      // Delete EquipmentEmployee relation
      .addCase(deleteEntityPrivilege.fulfilled, (state, action) => {
        state.subData.accountable = state.subData.accountable.filter(r => r.id !== action.payload.id);
        // state.isLoading = false;
        state.error = null;
      })

      // Change Relation Role
      .addCase(updateEntityPrivilegeRole.fulfilled, (state, action) => {
        const updatedEntityIndex = state.subData.accountable.findIndex(r => r.id === action.payload.id);
        state.subData.accountable[updatedEntityIndex].role = action.payload.role;
        // state.isLoading = false;
        state.error = null;
      })

      // Delete Bulk Relations
      .addCase(deleteMultipleEntityPrivileges.fulfilled, (state, action) => {
        const privilegesToRemove = action.payload.map(r => r.id);
        state.subData.accountable = state.subData.accountable.filter(r => !privilegesToRemove.includes(r.id));
        // state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchHistoryLog.fulfilled, (state, action) => {
        state.subData.historyLog = action.payload
          ? action.payload.map(r => ({ ...r, id: nanoid(), timeStamp: dayjs(r.timeStamp).format("DD/MM/YYYY") }))
          : [];
        // state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchCertificateTrainings.fulfilled, (state, action) => {
        state.subData.trainings = action.payload ? action.payload : [];
        // state.isLoading = false;
        state.error = null;
      });
  },
});

export const {
  createCertificationTemplate,
  fillNewAccountableEntryTemplate,
  updateNewAccountableEntryRole,
  deleteNewAccountableEntryTemplate,
  createNewAccountableEntryTemplate,
  clearCertificationError,
  resetCurrentPage,
  resetSearchResults,
} = slice.actions;
export default slice.reducer;
