type CubeStatus = {
  [key: string]: string;
};

export const CubeTaskStatus: CubeStatus = {
  "Late Task": "#f50",
  "Closed Task": "#87d068",
  default: "default",
};

export enum CubeTasks {
  id = "HbTask.id",
  taskStatus = "HbTask.taskStatus",
  description = "HbTask.description",
  title = "HbTask.title",
  taskDelay = "HbTask.taskDelay",
  count = "HbTask.count",
}
