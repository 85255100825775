import { BaseEntityType } from "./entityBase";
import { ExplicitAdditionalProps } from "./utility";

export type InspectionType = Record<string, unknown> & {
  id: number;
  name: string;
  recurrency: number;
  recurrencyType: InspectionRecurrencyType;
  isReviewRequired: boolean;
  ownershipType: InspectionOwnershipType;
  orgUnitId?: number | null;
  reviewId?: number | null;
  userId?: number | null;
  iconType: string;
};

export type InspectionTypeSingleView = {
  id: number;
  name: string;
  recurrency: number;
  recurrencyType: InspectionRecurrencyType;
  isReviewRequired: boolean;
  ownershipType: InspectionOwnershipType;
  orgUnitId?: number | null;
  reviewId?: number | null;
  userId?: number | null;
  iconType: string;
};

export interface InspectionTypeState {
  data: InspectionType[];
  isLoading: boolean;
  singleData: InspectionTypeSingleView | null;
  subData: Record<string, never>;
  error: string | null;
  defaultCustomProperties: ExplicitAdditionalProps[];
  lastUpdated: string;
}

export type InspectionTypePage = BaseEntityType & {
  entityData: {
    primaryData: InspectionTypeSingleView;
  };
  subData: Record<string, never>;
  listViewData: InspectionType;
};

export enum InspectionRecurrencyType {
  Daily = "Daily",
  Weekly = "Weekly",
  Monthly = "Monthly",
  Quarterly = "Quarterly",
  Yearly = "Yearly",
  Fixed = "Fixed",
}

export enum InspectionOwnershipType {
  EquipmentOwner = "EquipmentOwner",
  User = "User",
  OrgUnit = "OrgUnit",
}
