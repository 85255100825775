import React from "react";

type LandingPageBubbleProps = {
  color: string;
  radius: string;
  top: string;
  left: string;
};

export default function LandingPageBubble({ color, radius, top, left }: LandingPageBubbleProps) {
  return (
    <div
      style={{
        position: "absolute",
        backgroundColor: color,
        borderRadius: "50%",
        width: radius,
        height: radius,
        zIndex: 0,
        top,
        left,
      }}
    />
  );
}
