import { useState, useEffect } from "react";
import { ConfirmationType } from "../../components/SingleViewCard/types";

type ReturnType = {
  errorMessages: string[];
  errorType?: ConfirmationType;
  setErrors: (errors: Record<string, string[]>, errorType?: ConfirmationType) => void;
  handleClearErrors: () => void;
};

export const useUIErrorHandling = (): ReturnType => {
  const [errors, setErrors] = useState<Record<string, string[]>>({});
  const [errorType, setErrorType] = useState<ConfirmationType | undefined>();
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const handleClearErrors = () => {
    setErrors({});
  };

  const setNewError = (errors: Record<string, string[]>, errorType?: ConfirmationType) => {
    setErrors(errors);
    if (errorType) {
      setErrorType(errorType);
    }
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0) {
      setErrorType(undefined);
      return setErrorMessages([]);
    }
    // Note: This handles thrown Errors. TODO: Handle custom errors in a better more customizable way.
    if (errors.message) {
      return setErrorMessages([(errors.message as unknown) as string]);
    }
    const newMessages: string[] = [];
    setErrorMessages(prevMessages => {
      for (const errorField in errors) {
        if (Array.isArray(errors[errorField])) {
          const errorArray = errors[errorField];
          const extendedErrorArray = errorArray.map(err => (err = errorField ? `${errorField}: ${err}` : err));
          newMessages.push(...extendedErrorArray);
        }
        if (typeof errors[errorField] === "string") {
          const error = (errorField !== "0" ? `${errorField}: ${errors[errorField]}` : errors[errorField]) as string;
          newMessages.push(error);
        }
      }
      return [...prevMessages, ...newMessages];
    });
  }, [errors]);

  return { errorMessages, errorType, setErrors: setNewError, handleClearErrors };
};
