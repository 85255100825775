/***
 * Various Review Severity levels
 */
export class ReviewSeverity {
  public static readonly Critical: string = "Critical";
  public static readonly Defect: string = "Defect";
  public static readonly Positive: string = "Positive";
}

/**
 * Review Question Types
 * List of all questions used in the PDF rendering process
 */
export class ReviewQuestionType {
  public static readonly CheckBox: string = "CheckBoxTemplate";
  public static readonly ComboBoxWithRadioButtons: string = "ComboBoxWithRadioButtonsTemplate";
  public static readonly ComboBoxWithCheckBoxList: string = "ComboBoxWithCheckBoxListTemplate";
  public static readonly MandatoryCheckbox: string = "MandatoryCheckboxTemplate";
  public static readonly MultiSelect: string = "MultiSelectTemplate";
  public static readonly AutoCompleteMultiValues: string = "AutoCompleteMultiValuesTemplate";
  public static readonly PluginEmployee: string = "PluginEmployeeTemplate";
  public static readonly PluginEquipment: string = "PluginEquipmentTemplate";
  public static readonly Signature: string = "SignatureTemplate";
  public static readonly RadioButtonWithCheckBoxList: string = "RadioButtonWithCheckBoxListTemplate";
  public static readonly InfoText: string = "InfoTextTemplate";
  public static readonly TimeOnly: string = "TimeOnlyTemplate";
  public static readonly DocumentTemplate: string = "DocumentTemplate";
}
