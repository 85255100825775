import { Middleware } from "redux";
import { removeAuthToken } from "./slices/user";
import { RootState } from "./store";

type RequestLoggerMiddleware = Middleware<Record<string, unknown>, RootState>;

const UNAUTHORIZED_ERROR_CODE = "401";
const FORBIDDEN_ERROR_CODE = "403";

const isUnauthorizedError = (error: unknown): boolean => {
  const errorMessage = (error as { message?: string }).message;
  return Boolean(
    errorMessage && [UNAUTHORIZED_ERROR_CODE, FORBIDDEN_ERROR_CODE].some(code => errorMessage.includes(code))
  );
};
const catchUnauthorizeMiddleware: RequestLoggerMiddleware = store => next => action => {
  if (action.error && isUnauthorizedError(action.error)) {
    store.dispatch(removeAuthToken());
    return;
  }

  return next(action);
};

export default catchUnauthorizeMiddleware;
