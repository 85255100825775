import ReactDOM from "react-dom";
//import "./index.less";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import store from "./store/store";

/*if (process.env.NODE_ENV === "development") {
  startApiMockServer({ environment: "development" });
  console.log("Starting API mock server");
 
}*/

const render = () => {
  const App = require("./App").default;
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>,
    document.getElementById("root")
  );
};

render();

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./App", render);
}

reportWebVitals();
