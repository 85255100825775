import { SearchOutlined } from "@ant-design/icons";
import { ActionCreatorWithoutPayload } from "@reduxjs/toolkit";
import { AutoComplete, Input, Row, Typography } from "antd";
import { useContext, useEffect, useState } from "react";
import Highlighter from "react-highlight-words";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PageConfigurationContext from "../../context/pageContext";
import { RootState, useAppDispatch } from "../../store/store";
import { ColumnType } from "../../types/page";
import { useFuse } from "../../utils/hooks/useFuse";
import useRouter from "../../utils/hooks/useRouter";
import { AppThunk, ArrayElement } from "../../utils/types";
import "./FuseSearch.less";

type TProps = {
  data: Record<string, unknown>[];
  resultColumns?: Partial<ColumnType<Record<string, unknown>>>[] | null;
  defaultValue?: string;
  searchKeys?: string[] | null;
  onResultClick?: (record: Record<string, unknown>) => void;
  fetchOptions?: AppThunk<any, { filters?: string; page?: number } | undefined>;
  resetSearchResults?: ActionCreatorWithoutPayload<string>;
};

export const FuseSearch = ({
  data,
  resultColumns,
  searchKeys,
  onResultClick,
  defaultValue,
  fetchOptions,
  resetSearchResults,
}: TProps): JSX.Element => {
  const { t } = useTranslation();
  const pageConfig = useContext(PageConfigurationContext);
  const [options, setOptions] = useState<{ value: string | number; label: string | JSX.Element }[] | null>();
  const [value, setValue] = useState("");
  const { history } = useRouter();
  const direction = useSelector((state: RootState) => state.user.settings.direction);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (defaultValue !== undefined && value !== defaultValue) {
      return setValue(defaultValue);
    }
  }, [defaultValue]);

  const { hits, query, onSearch } = useFuse<ArrayElement<typeof data>>(data!, {
    keys: searchKeys || [],
    // location: 0,
    distance: 100,
    threshold: 0.1,
    limit: 6,
    fetchOptions,
  });
  const navigateToRecord = (id: number) => {
    history.push(`${pageConfig.id}/${id}`);
  };
  const renderItem = (record: ArrayElement<typeof data>) => ({
    key: record.id as string,
    value: record.title as string,
    label: (
      <Row
        className="main-search-dropdown__row"
        onClick={() => (onResultClick ? onResultClick(record) : navigateToRecord(record.id as number))}
      >
        {resultColumns?.map(column => {
          return (
            <Typography.Text key={column.id}>
              {column.valueIcon && column.valueIcon(record)}
              <Highlighter
                searchWords={[query]}
                autoEscape={true}
                textToHighlight={column.renderValue!(record) || ""}
                highlightStyle={{ fontWeight: 700, backgroundColor: "inherit", color: "inherit" }}
              />
            </Typography.Text>
          );
        })}
      </Row>
    ),
  });

  useEffect(() => {
    setValue("");
    resetSearchResults && dispatch(resetSearchResults());
  }, [pageConfig.id]);

  const onAutocompleteChange = (e: string) => {
    setValue(e);
  };

  useEffect(() => {
    if (Array.isArray(hits) && hits.length > 0) {
      const searchOptions = hits?.map(hit => renderItem(hit.item));
      return setOptions(searchOptions);
    }
    setOptions(null);
  }, [query, hits]);

  return (
    <AutoComplete
      className="main-search"
      popupClassName="main-search-dropdown"
      dropdownMatchSelectWidth={400}
      options={options!}
      value={value}
      onChange={onAutocompleteChange}
      // autoFocus
    >
      <Input
        className={`${query ? "search search__focused" : "search"} ${direction}`}
        prefix={<SearchOutlined style={{ opacity: 0.5 }} />}
        bordered={false}
        placeholder={t("SearchButton")}
        // allowClear
        onKeyDown={onSearch}
        onChange={onSearch}
        value={query}
      />
    </AutoComplete>
  );
};
