import { DownOutlined } from "@ant-design/icons";
import { Card, Col, Dropdown, Menu, Row, Button } from "antd";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PageConfigurationContext from "../../context/pageContext";
import { RootState, useAppDispatch } from "../../store/store";
import { BaseEntityType } from "../../types/entityBase";
import { CardBaseField, CategoryPage } from "../../types/page";
import useRouter from "../../utils/hooks/useRouter";
import "./singleCard.less";

type TProps = {
  data: Record<string, unknown>;
};

const dummyMenu = (
  <Menu onClick={() => console.log("clicked")}>
    <Menu.Item key="1">1st item</Menu.Item>
    <Menu.Item key="2">2nd item</Menu.Item>
    <Menu.Item key="3">3rd item</Menu.Item>
  </Menu>
);

export const SingleCard = ({ data }: TProps): JSX.Element => {
  const { history } = useRouter();
  const dispatch = useAppDispatch();
  const { groupView, id } = useContext(PageConfigurationContext) as CategoryPage<BaseEntityType>;
  const { t } = useTranslation();
  const primaryColor = useSelector((state: RootState) => state.common.primaryColor);
  const direction = useSelector((state: RootState) => state.user.settings.direction);

  const handleButtonClick = () => {
    const primaryButton = groupView?.cardButtons?.primaryButton;
    if (primaryButton) {
      if (primaryButton.action) {
        primaryButton.action(data);
      }
      if (primaryButton.link) {
        const linkProps = primaryButton.link(data);
        return history.push({
          pathname: linkProps.path,
          state: { prefillData: linkProps.prefillData },
        });
      }
    }
  };

  const handleCardCounterClick = (field: CardBaseField<Record<string, unknown>>) => {
    () => field.action && dispatch(field.action(data).action);
    if (field.action) {
      const fieldAction = field.action(data);
      // Note: fires the action
      dispatch(fieldAction.action());
      // Note: triggers navigation with configured state (if any)
      if (fieldAction.link) {
        history.push({
          pathname: fieldAction.link.path,
          state: { ...fieldAction.link.state, referrer: window.location.pathname },
        });
      }
    }
  };

  const menu = useMemo(() => {
    if (!groupView?.cardButtons) return null;

    return (
      <Menu>
        {groupView?.cardButtons.dropdownButtons.map(button => (
          <Menu.Item key={button.title} onClick={() => button.action && button.action(data)}>
            {t(button.title)}
          </Menu.Item>
        ))}
      </Menu>
    );
  }, [groupView?.cardButtons]);

  const handleCardNavigation = () => {
    history.push(`${id}/${data.id}`);
  };

  return (
    <Col>
      <Card className="groupView__card">
        <Row className="primaryFields">
          {groupView?.CardIcon && <Col className="icon">{<groupView.CardIcon fill={primaryColor} />}</Col>}
          <Col className="fields-wrapper">
            {groupView?.primaryFields.map(field => (
              <Col key={field.id}>
                {field.icon && <Col>{field.icon(field)}</Col>}
                <Row className="fields-wrapper__label">{field.title && t(field.title)}</Row>
                <Row
                  className="fields-wrapper__value pointer"
                  onClick={field.disableOnClickEvent ? undefined : handleCardNavigation}
                >
                  {field.value(data)}
                </Row>
              </Col>
            ))}
            <Col
              className="total-count pointer"
              onClick={() => groupView && handleCardCounterClick(groupView?.totalCountField)}
            >
              <Row className="fields-wrapper__label">
                {groupView?.totalCountField.title && t(groupView?.totalCountField.title)}
              </Row>
              <Row className="fields-wrapper__value">{`${groupView?.totalCountField.value(data)} ${t("Items")}`}</Row>
            </Col>
          </Col>
        </Row>
        <Row className="secondaryFields">
          {groupView?.secondaryFields.map(field => (
            <Col key={field.id}>
              <Row style={{ fontSize: "1.6rem" }} className="pointer" onClick={() => handleCardCounterClick(field)}>
                {field.icon && (
                  <Col style={direction === "rtl" ? { marginLeft: "0.5rem" } : { marginRight: "0.5rem" }}>
                    {field.icon(field)}
                  </Col>
                )}
                <Col style={{ fontWeight: 700 }}>{field.value(data)}</Col>
                <Col style={{ padding: "0 0.5rem" }}>{field.title && t(field.title)}</Col>
              </Row>
            </Col>
          ))}
        </Row>
        <Row justify="end">
          {groupView?.cardButtons || false ? (
            groupView?.cardButtons.dropdownButtons && groupView?.cardButtons.dropdownButtons.length ? (
              <Dropdown.Button
                overlay={menu ? menu : dummyMenu}
                trigger={["click"]}
                className="buttonGroup"
                icon={<DownOutlined />}
                onClick={handleButtonClick}
              >
                {t(groupView?.cardButtons?.primaryButton.title)}
              </Dropdown.Button>
            ) : (
              <Button className="buttonGroup buttonSingle" onClick={handleButtonClick}>
                {t(groupView?.cardButtons?.primaryButton.title)}
              </Button>
            )
          ) : undefined}
        </Row>
      </Card>
    </Col>
  );
};
