import { unwrapResult } from "@reduxjs/toolkit";
import { Avatar, Button, Image, Popover } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getFile } from "../../store/slices/common";
import { setAvatarInternalUrl } from "../../store/slices/user";
import { RootState, useAppDispatch } from "../../store/store";
import { initials } from "../../utils/functions";
import ProfileMenu from "./ProfileMenu";

import "./ProfileMenu.less";

const useAvatar = true;

export default function ProfileButton() {
  const dispatch = useAppDispatch();
  const settings = useSelector((state: RootState) => state.user.settings);
  const changeUserContextInProgress = useSelector((state: RootState) => state.user.changeUserContextInProgress);
  const avatarInternalUrl = useSelector((state: RootState) => state.user.avatarInternalUrl);

  const getAlignment = () => {
    if (settings.direction === "ltr") {
      return { points: ["bl", "tr"] };
    } else {
      return { points: ["br", "tl"] };
    }
  };
  const getIcon = () => {
    return avatarInternalUrl && useAvatar ? (
      <Avatar
        style={{ cursor: "pointer" }}
        shape="circle"
        size={45}
        src={<Image src={avatarInternalUrl} preview={false} />}
      />
    ) : (
      <Button size="large" type="primary" shape="circle" key="avatar">
        {initials(settings.displayName)}
      </Button>
    );
  };

  useEffect(() => {
    if (settings.avatarUrl && !avatarInternalUrl) {
      dispatch(getFile(settings.avatarUrl))
        .then(response => unwrapResult(response))
        .then(blob => {
          var file = new Blob([blob], { type: "arraybuffer" });
          const imageObjectURL = URL.createObjectURL(file);
          dispatch(setAvatarInternalUrl(imageObjectURL));
        })
        .catch(e => {
          dispatch(setAvatarInternalUrl(undefined)); //handle default icon
        });
    }
  }, [settings.avatarUrl, changeUserContextInProgress]);

  return (
    <Popover
      align={getAlignment()}
      placement={settings.direction === "ltr" ? "rightBottom" : "leftTop"}
      content={<ProfileMenu />}
    >
      {getIcon()}
    </Popover>
  );
}
