import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { nanoid } from "nanoid";
import { EqLocTypeInspTypeRelationStatus } from "../../types/equipmentType";
import {
  LocationTypeState,
  LocationType,
  LocationTypeSingleView,
  LocTypeInspTypeRelation,
} from "../../types/locationType";
import { ExplicitAdditionalProps } from "../../types/utility";
import { hbApi, hbApiOptions } from "../api";
import { RootState } from "../store";
import { shouldUpdate } from "./common";

export const initialState: LocationTypeState = {
  data: [],
  defaultCustomProperties: [],
  subData: {
    locTypeInspTypeRelations: [],
  },
  isLoading: false,
  singleData: null,
  error: null,
  lastUpdated: dayjs().toISOString(),
};

const newLocationType: LocationType = {
  id: 0,
  name: "",
  iconType: "10",
};

export const fetchLocationTypes = createAsyncThunk<
  { primaryData: LocationType[]; defaultCustomProperties: ExplicitAdditionalProps[] },
  boolean | undefined,
  { state: RootState }
>(
  "@@LOCATION_TYPE/FETCH",
  async (_, { getState }) => {
    const { user } = getState();
    const locationTypesResponse = await hbApi.get<LocationType[]>("/LocationType", hbApiOptions(user.jwt));

    return {
      primaryData: locationTypesResponse.data,
      defaultCustomProperties: [],
    };
  },
  {
    condition: (forceUpdate, { getState }) => {
      const { locationType } = getState();
      return shouldUpdate(locationType.lastUpdated, locationType.isLoading, forceUpdate);
    },
  }
);

export const addInspectionTypeLocationTypeRelation = createAsyncThunk<
  { res: LocTypeInspTypeRelation; initialId: number | string },
  LocTypeInspTypeRelation,
  { state: RootState }
>("@@LOCATION_TYPE/ADD_INT_EQT_RELATION", async (req, { getState }) => {
  const { user } = getState();
  const body = {
    locationTypeId: req.locationTypeId,
    inspectionTypeId: req.inspectionTypeId,
  };

  const result = await hbApi.post<LocTypeInspTypeRelation>("/LocationTypeInspectionType", body, hbApiOptions(user.jwt));
  return { res: result.data, initialId: req.id };
});

export const deleteInspectionTypeLocationTypeRelation = createAsyncThunk<
  LocTypeInspTypeRelation[],
  LocTypeInspTypeRelation[],
  { state: RootState }
>("@@LOCATION_TYPE/DELETE_INT_EQT_RELATION", async (req, { getState }) => {
  const { user } = getState();

  for (const rel of req) {
    await hbApi.delete("/LocationTypeInspectionType", hbApiOptions(user.jwt, rel));
  }
  return req;
});

export const fetchSingleLocationType = createAsyncThunk<
  {
    singleData: LocationTypeSingleView;
    subData: { locTypeInspTypeRelations: LocTypeInspTypeRelation[] };
    defaultCustomProperties: ExplicitAdditionalProps[];
  },
  string,
  { state: RootState }
>(
  "@@LOCATION_TYPE/FETCH_SINGLE",
  async (id, { getState }) => {
    const { user } = getState();

    const locationType = await hbApi.get<LocationTypeSingleView>(`/LocationType/${id}`, hbApiOptions(user.jwt));
    const locTypeInspTypeRelations = await hbApi.get<LocTypeInspTypeRelation[]>(
      `/LocationTypeInspectionType/${id}`,
      hbApiOptions(user.jwt)
    );

    return {
      singleData: locationType.data,
      subData: {
        locTypeInspTypeRelations: locTypeInspTypeRelations.data,
      },
      defaultCustomProperties: [],
    };
  },
  {
    condition: (_, { getState }) => {
      const { locationType, user } = getState();
      return !locationType.isLoading && !!user.jwt;
    },
  }
);

export const addLocationType = createAsyncThunk<LocationType, { entity: LocationType }, { state: RootState }>(
  "@@LOCATION_TYPE/ADD",
  async ({ entity }, { getState }) => {
    const { user } = getState();

    const response = await hbApi.post<LocationType>("/LocationType", entity, hbApiOptions(user.jwt));
    return response.data;
  },
  {
    condition: (_, { getState }) => {
      const { locationType, user } = getState();
      return !locationType.isLoading && !!user.jwt;
    },
  }
);

export const updateLocationType = createAsyncThunk<LocationType, LocationType, { state: RootState }>(
  "@@LOCATION_TYPE/UPDATE",
  async (entity, { getState }) => {
    const { user } = getState();
    const response = await hbApi.put<LocationType>(`/LocationType/${entity.id}`, entity, hbApiOptions(user.jwt));
    return response.data;
  },
  {
    condition: (_, { getState }) => {
      const { locationType, user } = getState();
      return !locationType.isLoading && !!user.jwt;
    },
  }
);

export const updateLocTypeInspTypeRelationStatus = createAsyncThunk<
  LocTypeInspTypeRelation,
  LocTypeInspTypeRelation,
  { state: RootState }
>("@@LOCATION_TYPE/UPDATE_INS_EQT_RELATION_STATUS", async (req, { getState }) => {
  const { user } = getState();
  await hbApi.put<LocTypeInspTypeRelation>(`/LocationTypeInspectionType/${req.id}`, req, hbApiOptions(user.jwt));
  return req;
});

const slice = createSlice({
  name: "locationType",
  initialState,
  reducers: {
    createLocationTypeTemplate: state => {
      state.singleData = newLocationType;
    },
    clearLocationTypeError: state => {
      state.error = null;
    },
    createNewLocTypeInspTypeRelationEntryTemplate: state => {
      state.subData.locTypeInspTypeRelations.unshift({
        id: nanoid(),
        locationTypeId: null,
        inspectionTypeId: null,
        staging: true,
        status: EqLocTypeInspTypeRelationStatus.Active,
      });
    },
    fillNewLocTypeInspTypeRelationEntryTemplate: {
      prepare: (payload: { row: LocTypeInspTypeRelation; targetEntity: Record<string, unknown> }) => ({ payload }),
      reducer: (
        state,
        action: PayloadAction<{ row: LocTypeInspTypeRelation; targetEntity: Record<string, unknown> }>
      ) => {
        const stagedRecordIndex = state.subData.locTypeInspTypeRelations.findIndex(r => r.id === action.payload.row.id);
        const castTargetEntity = action.payload.targetEntity as LocationType;

        state.subData.locTypeInspTypeRelations[stagedRecordIndex] = {
          ...state.subData.locTypeInspTypeRelations[stagedRecordIndex],
          locationTypeId: state.singleData?.id ?? 0,
          inspectionTypeId: castTargetEntity.id,
        };
      },
    },
    deleteNewLocTypeInspTypeRelationEntryTemplate: {
      prepare: payload => ({ payload }),
      reducer: (state, action: PayloadAction<LocTypeInspTypeRelation>) => {
        state.subData.locTypeInspTypeRelations = state.subData.locTypeInspTypeRelations.filter(
          r => r.id !== action.payload.id
        );
      },
    },
    changeLocalStatus: {
      prepare: (payload: { entity: LocTypeInspTypeRelation; status: EqLocTypeInspTypeRelationStatus }) => ({ payload }),
      reducer: (
        state,
        action: PayloadAction<{ entity: LocTypeInspTypeRelation; status: EqLocTypeInspTypeRelationStatus }>
      ) => {
        if (state.subData && state.subData.locTypeInspTypeRelations) {
          const index = state.subData.locTypeInspTypeRelations.findIndex(x => x.id === action.payload.entity.id);

          if (index >= 0) {
            state.subData.locTypeInspTypeRelations[index].status = action.payload.status;
            state.subData.locTypeInspTypeRelations[index].staging = true;
          }
        }
      },
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchLocationTypes.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSingleLocationType.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addLocationType.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateLocationType.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateLocTypeInspTypeRelationStatus.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addInspectionTypeLocationTypeRelation.pending, state => {
        state.isLoading = true;
        state.error = null;
      })

      .addCase(fetchLocationTypes.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(fetchSingleLocationType.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(addLocationType.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(updateLocationType.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(addInspectionTypeLocationTypeRelation.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(updateLocTypeInspTypeRelationStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })

      .addCase(fetchLocationTypes.fulfilled, (state, action) => {
        state.data = action.payload.primaryData;
        state.defaultCustomProperties = action.payload.defaultCustomProperties;
        state.isLoading = false;
        state.lastUpdated = dayjs().toISOString();
        state.error = null;
      })
      .addCase(fetchSingleLocationType.fulfilled, (state, action) => {
        state.singleData = action.payload.singleData;
        state.subData = action.payload.subData;
        state.defaultCustomProperties = action.payload.defaultCustomProperties;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateLocTypeInspTypeRelationStatus.fulfilled, (state, action) => {
        state.subData.locTypeInspTypeRelations = state.subData.locTypeInspTypeRelations.map(item =>
          item.id === action.payload.id ? { ...action.payload, staging: false } : item
        );
        state.error = null;
        state.isLoading = false;
      })
      .addCase(addLocationType.fulfilled, (state, action) => {
        state.singleData = action.payload;
        state.data = state.data ? [...state.data, action.payload] : [action.payload];
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateLocationType.fulfilled, (state, action) => {
        state.singleData = action.payload;
        const updatedEntityIndex = state.data.findIndex(lt => lt.id === action.payload.id);
        state.data[updatedEntityIndex] = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(addInspectionTypeLocationTypeRelation.fulfilled, (state, action) => {
        state.subData.locTypeInspTypeRelations = state.subData.locTypeInspTypeRelations.filter(
          r => r.id !== action.payload.initialId
        );
        state.subData.locTypeInspTypeRelations.unshift({
          ...action.payload.res,
          staging: false,
        });
        state.isLoading = false;
        state.error = null;
      })
      .addCase(deleteInspectionTypeLocationTypeRelation.fulfilled, (state, action) => {
        const relationsToRemove = action.payload.map(r => r.id);
        state.subData.locTypeInspTypeRelations = state.subData.locTypeInspTypeRelations.filter(
          r => !relationsToRemove.includes(r.id)
        );
        state.error = null;
      });
  },
});

export const {
  createLocationTypeTemplate,
  clearLocationTypeError,
  createNewLocTypeInspTypeRelationEntryTemplate,
  fillNewLocTypeInspTypeRelationEntryTemplate,
  deleteNewLocTypeInspTypeRelationEntryTemplate,
  changeLocalStatus,
} = slice.actions;
export default slice.reducer;
