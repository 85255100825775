import { TableColumn } from "@cubejs-client/core";
import { CubeProvider } from "@cubejs-client/react";
import { Spin, message } from "antd";
import { ReactElement, useContext, useEffect, useMemo, useState } from "react";
import { ItemCallback, WidthProvider, Responsive, Layouts, Layout as RGLayout } from "react-grid-layout";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { ExportData } from "../../actions/menuActions";
import PageConfigurationContext from "../../context/pageContext";
import i18n from "../../i18n";
import Layout from "../../pages/Layout/Layout";
import {
  addNewChart,
  fetchDashboard,
  fetchDashboards,
  fetchScheduledReport,
  resetDashboardLayoutToDefault,
  selectDashboard,
  selectDefaultDashboard,
  updateChartPostion,
} from "../../store/slices/dashboard";
import { fetchSurvey } from "../../store/slices/survey";
import { fetchCubeToken } from "../../store/slices/user";
import { RootState, useAppDispatch } from "../../store/store";
import { HBEventName } from "../../types/analyticsTypes/HBEvent";
import { BaseEntityType } from "../../types/entityBase";
import { CustomPage } from "../../types/page";
import { SmartDashboardAccess, UserRole } from "../../types/user";
import useCubeApi from "../../utils/hooks/useCubeApi";
import useInitTrackEvents from "../../utils/hooks/useInitTrackEvents";
import useRouter from "../../utils/hooks/useRouter";

import HBChart from "./Chart/HBChart";

import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "./Dashboard.less";
import DashboardSelector from "./DashboardSelector/DashboardSelector";
import DashboardSettings from "./DashboardSettings/DashboardSettings";
import ScheduledReportModal from "./ScheduledReport/ScheduledReportModal";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

type RenderSettings = {
  pageWidth: number;
  pageHeight: number;
  rowHeight: number;
};

type DashboardProps = {
  renderSettings?: RenderSettings;
};

export default function Dashboard({ renderSettings }: DashboardProps) {
  const { id: categoryId, actions, addButton } = useContext(PageConfigurationContext) as CustomPage<BaseEntityType>;
  const { params } = useRouter<{ id: number; mobile: boolean }>();
  const { track } = useInitTrackEvents();
  const dispatch = useAppDispatch();
  const [rowHeight, setRowHeight] = useState(params.mobile ? 60 : 30);
  const crossProjects = useSelector((state: RootState) => state.dashboard.single.crossProjects);
  const cubejsApi = useCubeApi({ crossProjects });
  const charts = useSelector((state: RootState) => state.dashboard.single.charts);
  const selectedDashboardId = useSelector((state: RootState) => state.dashboard.selectedDashboardId);
  const isLoading = useSelector((state: RootState) => state.dashboard.isLoading);
  const settings = useSelector((state: RootState) => state.user.settings);
  const newPassSmartDashboardAccess = useSelector(
    (state: RootState) => state.user.companySettings.newPassSmartDashboardAccess
  );
  const [editDashboardToggle, setEditDashboardToggle] = useState(false);
  const [scheduleReportModal, setScheduleReportModal] = useState(false);
  const dashboardUsers = useSelector((state: RootState) => state.dashboard.single.dashboardUsers);
  const dashboardName = useSelector((state: RootState) => state.dashboard.single.name);
  const dashboardId = useSelector((state: RootState) => state.dashboard.single.id);
  const currentUserId = useSelector((state: RootState) => state.user.id);

  useEffect(() => {
    dispatch(fetchCubeToken());
    dispatch(fetchDashboards());
    dispatch(fetchSurvey());
  }, []);

  useEffect(() => {
    if (selectedDashboardId) {
      window.history.replaceState(null, "Dashboard", `/${categoryId}/${selectedDashboardId}`);
    }
  }, [selectedDashboardId]);

  useEffect(() => {
    if (!isLoading) {
      if (params.id) {
        const dashboardFromParam = params.id;
        if (dashboardFromParam && dashboardFromParam !== dashboardId) {
          dispatch(selectDashboard(dashboardFromParam));
        }
      } else {
        dispatch(selectDefaultDashboard());
      }
    }
  }, [params.id, isLoading]);

  const refreshDashboard = () => {
    if (selectedDashboardId) {
      dispatch(fetchDashboard(selectedDashboardId));
    }
  };

  const editDashboard = () => {
    setEditDashboardToggle(!editDashboardToggle);
  };

  const showScheduleReport = () => {
    if (!scheduleReportModal && selectedDashboardId) {
      dispatch(fetchScheduledReport({ dashboardId: selectedDashboardId }));
      track({
        eventName: HBEventName.DashboardScheduledReportClicked,
        data: { dashboardId },
      });
    }
    setScheduleReportModal(!scheduleReportModal);
  };

  const resetDashboardLayout = () => {
    dispatch(resetDashboardLayoutToDefault({ dashboardId: selectedDashboardId }));
    track({
      eventName: HBEventName.DashboardScheduledReportClicked,
      data: { dashboardId },
    });
  };

  const exportAction = actions?.find(mi => mi.id == "exportAllCharts");
  if (exportAction) {
    exportAction.action = () => {
      const results = charts.map(chart => chart.resultSet);
      if (results) {
        message.loading({
          content: "Downloading Data for Dashboard As Excel...",
          duration: 0,
          key: "queryLoading",
        });
        const excelExportData = results.reduce<{ data: any; template: any[] }>(
          (acc, r, index) => {
            if (r) {
              const chartName = charts.find((c, i) => i !== index && c.name === charts[index].name)
                ? `${charts[index].name}-${index}`
                : charts[index].name;
              const template: any[] = r?.tableColumns().map<any>((column: TableColumn) => {
                const template = {
                  type: "Header",
                  property: column.dataIndex,
                  value: column.title,
                  group: index,
                  source: index,
                };
                return template;
              });
              template.push({
                type: "Option",
                property: "RTL",
                value: "true",
                group: index,
              });
              template.push({
                type: "Option",
                property: "SheetName",
                value: chartName, //name of the sheet
                group: index, //everything will be the name of the sheet
              });

              acc.template.push(...template);
              acc.data[`${index}`] = r.tablePivot();
            }
            return acc;
          },
          { data: {}, template: [] }
        );
        let today = new Date();
        let filename = `${dashboardName}-${today.toLocaleString().split(",")[0].replaceAll(".", "-")}.xlsx`;
        ExportData(excelExportData.data, excelExportData.template, filename).then(() =>
          message.destroy("queryLoading")
        );
      }
    };
  }

  const editButton = actions?.find(mi => mi.id == "editDashboard");
  if (editButton) {
    editButton.action = editDashboard;
  }

  const scheduleReportButton = actions?.find(mi => mi.id == "scheduleReport");
  if (scheduleReportButton) {
    scheduleReportButton.action = showScheduleReport;
  }

  const resetDashboardLayoutButton = actions?.find(mi => mi.id == "resetDashboardLayout");
  if (resetDashboardLayoutButton) {
    resetDashboardLayoutButton.action = resetDashboardLayout;
  }

  const addButtonAction = addButton;
  if (addButtonAction) {
    addButtonAction.action = () => dispatch(addNewChart());
  }

  const layouts = useMemo(() => {
    return charts.reduce<Layouts>(
      (acc, chart, index) => {
        if (chart.query) {
          const layout = {
            x: chart.coordinates.x,
            y: chart.coordinates.y,
            w: renderSettings ? Infinity : chart.coordinates.width,
            h: chart.coordinates.height,
            i: `${chart.id}`,
          };
          acc.lg.push(layout);
        }
        return acc;
      },
      { lg: [] }
    );
  }, [charts, editDashboardToggle]);

  const dashboard = useMemo(() => {
    return charts.reduce<ReactElement[]>((acc, chart, index) => {
      if (chart.query) {
        acc.push(
          <div
            key={chart.id}
            data-grid={{
              x: chart.coordinates.x,
              y: chart.coordinates.y,
              w: renderSettings ? Infinity : chart.coordinates.width,
              h: chart.coordinates.height,
              i: chart.id,
              // static: !editDashboardToggle,
            }}
            style={{
              height: chart.coordinates.height * rowHeight,
              width: "100%",
              minWidth: 0,
              display: "flex",
              flexFlow: "column",
            }}
          >
            <HBChart chart={chart} locked={!editDashboardToggle} />
          </div>
        );
      }
      return acc;
    }, []);
  }, [charts, editDashboardToggle]);

  useEffect(() => {
    const gridLayoutContainer = document.querySelector(".react-grid-layout");
    const children = document.querySelectorAll(".react-grid-item");

    gridLayoutContainer?.setAttribute("dir", "ltr");
    children.forEach(child => child.setAttribute("dir", i18n.dir()));
  }, [i18n, i18n.language]);

  const dashboardAllowedAccess: boolean | undefined = useMemo(() => {
    if (!newPassSmartDashboardAccess) {
      return undefined;
    }

    if (settings.isSysAdmin) {
      return true;
    }

    switch (newPassSmartDashboardAccess) {
      case SmartDashboardAccess.Disabled:
        return false;
      case SmartDashboardAccess.TenantAdmin:
        return settings.role === UserRole.TenantAdmin;
      case SmartDashboardAccess.AllUsers:
        return true;
      default:
        return false;
    }
  }, [newPassSmartDashboardAccess, settings.isSysAdmin, settings.role]);

  const isCurrentUserOwner = () => {
    return dashboardUsers
      .filter(u => u.isOwner)
      .map(u => u.userId)
      .includes(currentUserId);
  };

  const onModifyItem: ItemCallback = (layout, oldItem, newItem, placeholder, event, element) => {
    layout.forEach(chart => dispatch(updateChartPostion({ chartId: parseInt(chart.i), layout: chart })));
  };

  const onBreakpointChange: (newBreakpoint: string, newCols: number) => void = (newBreakpoint, newCols) => {
    if (["xxs", "xs"].includes(newBreakpoint)) {
      setRowHeight(60);
    }
  };

  if (!settings.isSysAdmin && dashboardAllowedAccess === false) return <Redirect to={{ pathname: "/notAllowed" }} />;
  return (
    <CubeProvider cubejsApi={cubejsApi}>
      <Layout isCustomPage entityKey={categoryId} hasTableSearchBar={false} customRefreshAction={refreshDashboard}>
        {dashboardAllowedAccess && dashboardAllowedAccess === true && (
          <>
            <div style={{ display: "flex", flexFlow: "row", justifyContent: "space-between", alignItems: "baseline" }}>
              {selectedDashboardId && <DashboardSelector editMode={editDashboardToggle} />}
              {editDashboardToggle && (
                <DashboardSettings ownerMode={isCurrentUserOwner()} setToggleState={setEditDashboardToggle} />
              )}
            </div>
            <ScheduledReportModal open={scheduleReportModal} onClose={() => setScheduleReportModal(false)} />
            <div id="dashboard">
              <ResponsiveReactGridLayout
                cols={{ lg: 15, md: 10, sm: 2, xs: 1, xxs: 1 }}
                rowHeight={rowHeight}
                layouts={layouts}
                onDragStop={onModifyItem}
                onResizeStop={onModifyItem}
                isResizable={editDashboardToggle}
                measureBeforeMount={false}
                draggableHandle=".chartHandel"
                onBreakpointChange={onBreakpointChange}
              >
                {dashboard}
              </ResponsiveReactGridLayout>
            </div>
          </>
        )}
      </Layout>
    </CubeProvider>
  );
}
