import { unwrapResult } from "@reduxjs/toolkit";
import { Modal, message } from "antd";
import fileDownload from "js-file-download";
import { AddButtonActions, SingleViewActions, SystemActions } from "../consts/listViewActions";
import { isAdmin } from "../pages/pageConfig/category/utilities";
import FormSettingsMenu from "../pages/ProfileMenu/FormSettingsMenu/FormSettingsMenu";
import { hbApi } from "../store/api";
import { cancelTraining } from "../store/slices/training";
import { forgotPassword } from "../store/slices/user";
import { AppDispatch } from "../store/store";
import { EmployeeCertificateListView } from "../types/certification";
import { Employee } from "../types/employee";
import { DropDownAction } from "../types/page";
import { Training, TrainingStatus } from "../types/training";
import { UserState } from "../types/user";
import { isRoleAdminLevel } from "../utils/functions";

export const ColumnManagementAction = {
  id: SystemActions.ColumnManagement,
  title: "ManageColumns",
  showColumnManagement: (): boolean => true,
  isDataOperation: false,
};

export const QuickCreateAppointment = {
  id: AddButtonActions.QuickCreateAppointment,
  title: "FillSurvey",
  isDataOperation: false,
};

export const AddNewAction = {
  id: SystemActions.AddNew,
  title: "CreateOrReportAnAction",
  isDataOperation: false,
};

export const ImportAction = {
  id: SystemActions.Import,
  title: "ImportButton",
  link: () => ({
    path: "import",
  }),
  canAccess: (): boolean => isAdmin(),
  isDataOperation: false,
};

export const CreateTrainingWithEmployeesAction = {
  id: SystemActions.CreateTrainingWithEmployees,
  title: "CreateTraining",
  link: () => ({ path: "/training/new" }),
};

export const SetActionStatus = {
  id: SystemActions.SetActionStatus,
  title: "CancelAction",
  isDataOperation: true,
};

export const ExportAction = {
  id: SystemActions.Export,
  title: "ExportToExcelButton",
  isDataOperation: true,
};

export const GetFiltersUrl = {
  id: SystemActions.GetFilterUrl,
  title: "GetFiltersUrl",
  isDataOperation: false,
};

export const SetFiltersToDefault = {
  id: SystemActions.SetFiltersToDefault,
  title: "SetFiltersToDefault",
  isDataOperation: false,
};

export const FormSettings = {
  id: SystemActions.FormSettings,
  title: "FormSettings",
  content: <FormSettingsMenu />,
  isDataOperation: false,
  stateBaseAccess: (user: UserState) => isRoleAdminLevel(user.settings.role) || user.personalSettings.hasForms,
};

export const ResetPassword = {
  id: SingleViewActions.ResetPassword,
  title: "ResetPasswordTitle",
  action: (_: any, __: string, ___?: any[], t?: any, dispatch?: AppDispatch) => {
    dispatch &&
      dispatch(forgotPassword())
        .then(response => unwrapResult(response))
        .then(
          response =>
            response &&
            Modal.success({
              content: t("ResetPasswordEmployeeSingleView"),
            })
        )
        .catch(e => {
          Modal.error({
            content: t(e.message),
          });
        });
  },
  isDataOperation: false,
  stateBaseAccess: (user: UserState, singleViewEntity?: any) =>
    isRoleAdminLevel(user.settings.role) && singleViewEntity?.isUser,
};

export const CancelTraining = {
  id: SingleViewActions.CancelTraining,
  title: "CancelTraining",
  action: (_: any, __: string, ___?: any[], t?: any, dispatch?: AppDispatch) => {
    dispatch &&
      dispatch(cancelTraining()).catch(e => {
        Modal.error({
          content: t(e.message),
        });
      });
  },
  isDataOperation: false,
  stateBaseAccess: (user: UserState, singleViewEntity?: any) =>
    isRoleAdminLevel(user.settings.role) && singleViewEntity?.status === TrainingStatus.Passed,
};

export const SetSertificationAction = {
  id: "setCertification",
  title: "SetCertification",
  link: () => ({
    path: "/certification",
  }),
  isDataOperation: false,
};

export const MannualyUpdateInspection = {
  id: "updateInspectionDetails",
  title: "UpdateInspectionDetails",
  isDataOperation: false,
};

export const CreateTrainingWithEmployees: DropDownAction<Employee> = {
  id: SystemActions.CreateTrainingWithEmployees,
  title: "CreateTraining",
  link: (selectedEntities: Employee[]) => {
    const employeeIds: number[] = [];
    selectedEntities.forEach(e => employeeIds.push(e.id));
    return { path: "/training/new", prefillData: { employeeIds } };
  },
  isDataOperation: false,
};

export const CreateTrainingFromEmpCertificates: DropDownAction<EmployeeCertificateListView> = {
  id: SystemActions.CreateTrainingFromEmpCertificates,
  title: "CreateTraining",
  link: (selectedEntities: EmployeeCertificateListView[]) => {
    const employeeIds: number[] = [];
    const certificateIds: number[] = [];
    const certificateId = selectedEntities.length && selectedEntities[0].certificateId;
    selectedEntities.forEach(e => {
      !employeeIds.includes(e.employeeId) && employeeIds.push(e.employeeId);
      !certificateIds.includes(e.certificateId) && certificateIds.push(e.certificateId);
    });
    return {
      path: "/training/new",
      prefillData: { employeeIds, certificateId },
      warningMessage:
        certificateIds.length > 1
          ? {
              body: "CreateTrainingWarning", //"'Create Training' is possible only for a single certification selection",
              okText: "FormsOk",
            }
          : undefined,
    };
  },
  isDataOperation: false,
};

export function ExportData(tableData: any, exportTemplate: any, fileName: string): Promise<any> {
  const request = new FormData();
  request.append("data", JSON.stringify(tableData));
  request.append("mapping", JSON.stringify(exportTemplate));
  return hbApi
    .post("/Export/xlsx", request, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      responseType: "blob",
    })
    .then(res => {
      fileDownload(res.data, fileName);
      return Promise.resolve();
    });
}
