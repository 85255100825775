import { CubeProvider } from "@cubejs-client/react";
import { useEffect } from "react";
import { fetchDimensions } from "../../../store/slices/cubeDimensions";
import { useAppDispatch } from "../../../store/store";
import useCubeApi from "../../../utils/hooks/useCubeApi";
import DimensionsTable from "./DimensionsTable/DimensionsTable";

import "./DimensionBuilder.less";

export default function DimensionBuilderPage() {
  const cubejsApi = useCubeApi({ crossProjects: undefined });
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchDimensions());
  }, []);

  return (
    <CubeProvider cubejsApi={cubejsApi}>
      <DimensionsTable />
    </CubeProvider>
  );
}
