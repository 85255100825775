import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { CustomError } from "../../pages/pageConfig/category/utilities";
import { Employee } from "../../types/employee";
import { Survey, SurveyState } from "../../types/survey";
import { ExplicitAdditionalProps, AppointmentStatus } from "../../types/utility";
import { hbApi, hbApiOptions } from "../api";
import { RootState } from "../store";

export const initialState: SurveyState = {
  data: [],
  isLoading: false,
  singleData: null,
  subData: {
    employees: [],
  },
  error: null,
  defaultCustomProperties: [],
  lastUpdated: dayjs().toISOString(),
};

const newSurvey: Survey = {
  id: 0,
  reviewId: 0,
  reviewName: "",
  surveyTitle: "",
  createdDate: "",
  completedDate: "",
  createdByUserId: 0,
  createdByUser: "",
  status: AppointmentStatus.Completed,
  fileId: "",
  projectName: "",
  submitedByUserId: 0,
  submitedByUser: "",
  totalTasks: 0,
  completedTasks: 0,
};

export const fetchSurvey = createAsyncThunk<
  { primaryData: Survey[]; defaultCustomProperties: ExplicitAdditionalProps[] },
  boolean | undefined,
  { state: RootState }
>(
  "@@SURVEY/FETCH",
  async (_, { getState }) => {
    const { user } = getState();
    const response = await hbApi.get<Survey[]>("/Appointment", hbApiOptions(user.jwt));
    return {
      primaryData: response.data,
      defaultCustomProperties: [],
    };
  },
  {
    condition: (_, { getState }) => {
      const { survey } = getState();
      return !survey.isLoading;
    },
  }
);

export const fetchSingleSurvey = createAsyncThunk<
  { singleData: Survey; subData: { employees: Employee[] }; defaultCustomProperties: ExplicitAdditionalProps[] },
  string,
  { state: RootState }
>(
  "@@SINGLE_SURVEY/FETCH",
  async (id, { getState, rejectWithValue }) => {
    const { user } = getState();

    try {
      const cardDataResponse = await hbApi.get<Survey>(`/survey/${id}`, hbApiOptions(user.jwt));
      return {
        singleData: cardDataResponse.data,
        subData: { employees: [] },
        defaultCustomProperties: [],
      };
    } catch (e) {
      if (e.status === 400) {
        return rejectWithValue(e.data);
      }
      throw new CustomError(e.message || e.Message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { survey, user } = getState();
      return !survey.isLoading && !!user.jwt;
    },
  }
);

export const setAppointmentStatus = createAsyncThunk<number | null, number, { state: RootState }>(
  "@@SET_SURVEY_STATUS/FETCH",
  async (id, { getState, rejectWithValue }) => {
    try {
      const { user } = getState();
      const response = await hbApi.get<boolean>(`/Appointment/SwitchStatus/${id}`, hbApiOptions(user.jwt));
      if (response.data) {
        return id;
      }
    } catch (e) {
      return rejectWithValue(e.message);
    }

    return null;
  }
);

export const slice = createSlice({
  name: "survey",
  initialState,
  // Note: User reducers only for synchronous logic
  reducers: {
    createSurveyTemplate: state => {
      state.singleData = newSurvey;
    },
    clearSurveyError: state => {
      state.error = null;
    },
  },
  // Note: User reducers only for asynchronous logic with AsyncThunk
  extraReducers: builder => {
    builder
      // Note - Pending:
      .addCase(fetchSurvey.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(setAppointmentStatus.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      // Note - Rejected:
      .addCase(fetchSurvey.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(setAppointmentStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      // Note - Fulfilled:
      .addCase(fetchSurvey.fulfilled, (state, action) => {
        state.data = action.payload.primaryData;
        state.defaultCustomProperties = action.payload.defaultCustomProperties;
        state.error = null;
        state.isLoading = false;
      })
      .addCase(setAppointmentStatus.fulfilled, (state, action) => {
        if (action.payload) {
          state.data = state.data.map(x =>
            action.payload === x.id
              ? {
                  ...x,
                  status: x.status === AppointmentStatus.Draft ? AppointmentStatus.Canceled : AppointmentStatus.Draft,
                }
              : x
          );
        }
        state.error = null;
        state.isLoading = false;
      });
  },
});

export const { createSurveyTemplate, clearSurveyError } = slice.actions;
export default slice.reducer;
