import arEG from "antd/es/locale/ar_EG";
import enUS from "antd/es/locale/en_US";
import frFR from "antd/es/locale/fr_FR";
import heIL from "antd/es/locale/he_IL";
import ruRU from "antd/es/locale/ru_RU";
import ukUA from "antd/es/locale/uk_UA";

import locale_ar from "dayjs/locale/ar";
import locale_en from "dayjs/locale/en";
import locale_fr from "dayjs/locale/fr";
import locale_he from "dayjs/locale/he";
import locale_ru from "dayjs/locale/ru";
import locale_uk from "dayjs/locale/uk";

export const antdLocales: { [key: string]: any } = {
  "en-US": enUS,
  "he-IL": heIL,
  "ru-RU": ruRU,
  "uk-UA": ukUA,
  ar: arEG,
  "fr-FR": frFR,
};

export const dayjsLocales: { [key: string]: any } = {
  "en-US": locale_en,
  "he-IL": locale_he,
  "ru-RU": locale_ru,
  "uk-UA": locale_uk,
  ar: locale_ar,
  "fr-FR": locale_fr,
};

export const DAY_OF_WEEK: { [key: string]: number } = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
};
