import { BaseEntityType } from "./entityBase";
import { File } from "./files";
import { InspectionTabModel } from "./inspection";
import { Location } from "./location";
import { ContextActions } from "./tasks";
import {
  AdditionalProps,
  EmployeeOrgUnitRelations,
  ExplicitAdditionalProps,
  HistoryLog,
  PrivilegeData,
  Role,
  Status,
} from "./utility";

export enum EquipmentStatus {
  Active = "Active",
  InActive = "InActive",
  Broken = "Broken",
}

export type GroupViewEquipments = {
  equipmentType: string;
  equipmentTypeId: number;
  total: number;
  active: number;
  inactive: number;
  aboutToExpired: number;
  expired: number;
  broken: number;
};

export interface EquipmentState {
  data: Equipment[];
  isLoading: boolean;
  singleData: Equipment | null | undefined;
  subData: {
    inspections: InspectionTabModel[];
    historyLog: HistoryLog[];
    accountable: PrivilegeData[];
    files: File[];
    actions: ContextActions[];
  };
  basicData: Equipment[];
  error: string | null;
  defaultCustomProperties: ExplicitAdditionalProps[];
  lastUpdated: string;
  groupViewData: GroupViewEquipments[];
  searchResults: Equipment[];
  paginationInfo: {
    currentPage: number;
    count: number;
  };
}

export type EquipmentPaginatedData = {
  data: Equipment[];
  count: number;
};

export interface EquipmentRelationsRequest extends EmployeeOrgUnitRelations {
  role: Role;
  equipmentId: number | null;
}

export type EquipmentOrgUnitEmployee = {
  externalId: string | undefined | null;
  status: Status | null;
  id: number | string;
  name: string;
  isExternal: boolean | null;
  staging?: boolean; // Is this a 'new' record (still not saved)
  activeId?: number | string; // The ID of the active counterpart of a staged new entity
  isOrgUnit?: boolean;
  isEmployee?: boolean;
  role: Role;
  equipmentId: number | null;
};

export type Equipment = Record<string, unknown> & {
  id: number;
  serialNumber: string;
  typeId: number | null;
  typeName: string;
  locationId: number | null;
  locationName: string;
  name: string;
  expiration: string;
  customPropertyValues?: AdditionalProps[];
  status: Status;
};

// export type Location = Record<string, unknown> & {
//   id: number;
//   // companyId: number;
//   parentId: number | null;
//   name: string;
//   externalIdentifier: string;
//   // description: string;
//   children?: Location[];
// };

export type Inspection = Record<string, unknown> & {
  key: string;
  date: string;
  name: string;
  type: string;
  param: string;
  status: string;
};

export type EquipmentType = {
  id: number;
  companyId: number;
  name: string;
};

export type AdditionalEquipmentData = {
  types: EquipmentType[];
};

export type EquipmentPage = BaseEntityType & {
  entityData: {
    primaryData: Equipment;
  };
  subData: {
    historyLog: HistoryLog[];
    inspections: InspectionTabModel[];
    files: File[];
    actions: ContextActions[];
  };
  tabs: Location | PrivilegeData | HistoryLog | File | InspectionTabModel | ContextActions;
  groupViewData: GroupViewEquipments;
  listViewData: Equipment;
};
